@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-social-share-panel {
    width: auto;
    min-width: 256px;
    max-width: 256px;
    border-radius: $base-border-radius;

    @include min-screen($md-breakpoint-sm) {
        max-width: 320px;
    }
}

.pf-social-share-menu__section-header {
    color: $color-licorice;
    @include typography-subhead();
    font-weight: $font-weight-medium;
}

.pf-social-share-menu__section-header--public-project {
    margin-bottom: 11px;
}

.pf-social-share-menu__section-header--private-project {
    margin-bottom: 4px;
}

.pf-social-share-menu__section-body {
    color: $color-licorice;
    @include typography-body();
    font-size: 14px;
    line-height: 20px;
}

.pf-social-share-menu__buttons {
    border-top: 1px solid $color-border-base;
}

.pf-social-share-menu-header-section--share-buttons {
    padding: 13px 16px 24px;
}

.pf-social-share-menu-header-section--private-entry {
    padding: 24px 16px;
    text-align: center;
}

.pf-social-share-menu-private-entry--lock-icon {
    width: 48px;
    height: 48px;
    margin: 0 auto 8px;
    border-radius: 50%;
    border: 2px solid $color-warning;

    md-icon.material-icons {
        margin: 10px;
        color: $color-licorice;
    }
}

.pf-social-share-menu__full-width-buttons {
    width: 100%;
    margin: 8px 0;
}

.pf-social-share-menu__full-width-button.md-button {
    height: 48px;
    padding: 0 16px;
    margin: 0;
    width: 100%;
    text-align: left;
    color: $color-licorice;
    text-transform: none;

    @include typography-body();
    font-size: 15px;
    line-height: 20px;

    md-icon.material-icons {
        margin-right: 24px;
    }
}

.pf-social-share-menu__share-button.md-button.md-portfolium-theme:not([disabled]).md-icon-button {
    margin: 0 6px;

    &:first-child {
        margin: 0 6px 0 0;
    }

    &:last-child {
        margin: 0 0 0 6px;
    }

    md-icon {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
    }
}

.pf-social-share-menu__share-button--linkedin.md-button.md-portfolium-theme:not([disabled]).md-icon-button {
    background-color: $color-linkedin;

    md-icon {
        svg path {
            fill: $color-white;
        }
    }

    &:hover,
    &:focus {
        background-color: $color-linkedin-hover;
    }
}

.pf-social-share-menu__share-button--twitter.md-button.md-portfolium-theme:not([disabled]).md-icon-button {
    background-color: $color-twitter;

    md-icon {
        svg path {
            fill: $color-white;
        }
    }

    &:hover,
    &:focus {
        background-color: $color-twitter-hover;
    }
}
