.modal {
    $modal-padding: 32px;

    fieldset {
        box-shadow: none;
        padding: 0;
    }

    label {
        cursor: pointer;
        margin-bottom: 0;
    }

    label img {
        $img-width: 300px;
        border-radius: $img-width/2;
        display: block;
        max-width: $img-width;
    }

    input[type='checkbox'] {
        display: none;
    }

    &.legacy-modal-opened {
        position: absolute;
        height: 100%;
        width: 100%;

        .modal-window {
            height: 100%;
            width: 100%;
        }
    }

    .modal-open {
        overflow: hidden;
    }

    .modal-window {
        transition: opacity 0.25s ease;
        @include position(fixed, 0 0 0 0);
        background: $color-black-alpha-48;
        opacity: 0;
        text-align: left;
        visibility: hidden;
        display: none;
        z-index: $z-index-modal;

        .modal-bg {
            @include position(absolute, 0, 0, 0, 0);
            cursor: pointer;
        }

        &.entry-sidebar {
            // width of sidebar
            margin-left: -320px;
            background-color: $color-oxford;

            .modal-inner {
                margin-top: 1em;
                max-width: 680px;
            }
        }

        @include max-screen($mobile) {
            &.entry-sidebar {
                // width of sidebar
                margin-left: 0;
            }
        }
    }

    .modal-close {
        @include position(
            absolute,
            ($modal-padding / 2),
            ($modal-padding / 2),
            none,
            none
        );
        width: 1.5em;
        height: 1.5em;
        cursor: pointer;
        background: $color-white;
        overflow: hidden;
        text-indent: -999px;
        width: 24px;

        &:after,
        &:before {
            @include position(absolute, 3px, 3px, 0, 50%);
            transform: rotate(45deg);
            height: 20px;
            width: 2px;
            background: $color-ash;
            content: '';
            display: block;
            margin: -3px 0 0 -1px;
        }

        &:hover:after,
        &:hover:before {
            background: $color-ash;
        }

        &:before {
            transform: rotate(-45deg);
        }
    }

    .modal-inner {
        transition: opacity 0.25s ease;
        border-radius: 4px;
        background: $color-white;
        margin: auto;
        max-height: 100%;
        position: relative;
        overflow: auto;
        width: 95%;
        padding: $modal-padding / 2;
        margin-top: 0.6em;
        box-shadow: $whiteframe-shadow-z5;

        @include min-screen($md-breakpoint-md) {
            margin-top: 7em;
            max-height: 80%;
            max-width: 700px;
            padding: $modal-padding;
            width: 60%;
        }

        h1 {
            color: $color-licorice;
            margin-bottom: 0.6em;
            text-align: left;
            @extend .pf-display-1;

            sup {
                color: $color-electric;
            }
        }

        p {
            font-size: $base-font-size;
            max-width: 100% !important;
            padding: 0;
            text-align: left;

            &.intro {
                color: $color-electric;
                line-height: 1.6em;
            }

            &.body {
                color: $color-licorice;
                line-height: 1.45em;

                @include min-screen($md-breakpoint-md) {
                }
            }
        }

        a.cta {
            color: $color-white;
            display: inline-block;
            margin-right: 0.5em;
            margin-top: 1em;

            &:last-child {
                padding: 0 2em;
            }
        }
    }

    .modal-state:checked + .modal-window,
    .modal-inline-state:checked + .modal-window {
        opacity: 1;
        visibility: visible;
        display: block;
    }

    .modal-state:checked + .modal-window .modal-inner,
    .modal-inline-state:checked + .modal-window .modal-inner {
        top: 0;
    }

    .empty-page.white-bg {
        .empty-speech {
            padding: 90px 0 0;

            * {
                text-align: center;
            }
        }
    }

    .segment {
        width: 100%;

        article {
            width: 100%;
            margin: 0;
            box-shadow: none;
        }

        &.fixed-width {
            width: 100%;
            min-width: 400px;
            max-width: 680px;
        }

        &.sidebar-right {
            article {
                margin: 0;
            }

            .segment-sidebar {
                width: auto;

                .modal-fixed {
                    max-width: 300px;
                    position: fixed;
                }
            }
        }
    }

    &.small {
        .modal-inner {
            width: 35%;
        }

        .modal-state:checked + .modal-window .modal-inner,
        .modal-inline-state:checked + .modal-window .modal-inner {
            top: 30px;
        }
    }
}

/* override for entry modal */
.a_entries {
    .modal {
        .modal-inner {
            @include max-screen($mobile) {
                width: 95%;
            }
        }
    }
}
