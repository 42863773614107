@import './colors';
@import './whiteframe';

$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$font-family: 'proxima-nova', helvetica, arial, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
/**
 * End of imports
 */

// Devices
$mobile-small-portrait: 320px;
$mobile-small: 480px;
$phone: 767px;
$tablet: 768px;
$tablet-portrait: 1023px;
$tablet-landscape: 1024px;

$desktop: $tablet-landscape;
$mobile: $tablet-portrait;
$phablet: min-width $tablet max-width $tablet-portrait;

$search_width: 900px;

$max-container-width: 1080px;

// Material Design Breakpoints
$md-breakpoint-sm: 600px;
$md-breakpoint-md: 960px;
$md-breakpoint-lg: 1280px;
$md-breakpoint-xl: 1920px;
$md-breakpoint-handset-portrait-md: 360px;
$md-breakpoint-handset-portrait-lg: 400px;
$md-breakpoint-handset-landscape-md: 600px;
$md-breakpoint-handset-landscape-lg: 720px;
$md-breakpoint-tablet-portrait-lg: 720px;
$md-breakpoint-tablet-landscape-lg: 1024px;

// Typography
$proxima-nova: 'proxima-nova';
$raleway: $proxima-nova;
$lato: $proxima-nova;
$code: 'Courier New', Courier, monospace;
$sans-serif: $proxima-nova;
$font-family-with-fallbacks: $proxima-nova, helvetica, arial, sans-serif;

$base-font-family: $font-family-with-fallbacks;
$header-font-family: $base-font-family;
$code-font-family: $code;
$pf-base-font-size: 14px;

$font-family-default: $base-font-family;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

// Sizes
$base-font-size: 1em;
$base-font-weight: $font-weight-regular;
$base-line-height: $base-font-size * 1.5;
$unitless-line-height: $base-line-height / ($base-line-height * 0 + 1); // Strip units from line-height: https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#Prefer_unitless_numbers_for_line-height_values
$header-line-height: $base-font-size * 1.25;
$base-border-radius: 3px;
$pf-base-border-radius: $base-border-radius;
$card-border-radius: $base-border-radius;
$split-sidebar-width: 512px;
$split-sidebar-image-width: 260px;

// Spacing
$base-spacing: 1em;
$base-content-spacing: 0 40px;
$button-padding: 0.75em 1em;
$spacing: 30px 0;

// Shadows
$base-content-shadow: 0 0 3px $color-tiara;
$base-material-shadow: 0px 3px 1px -2px $color-tiara,
    0px 2px 2px 0px $color-porcelain, 0px 1px 5px 0px $color-porcelain;

$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px $color-porcelain;
$form-box-shadow-focus: $form-box-shadow,
    0 0 5px $color-electric-light-8;
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;

// Z-indexes
// @todo: eliminate z-index layering hell for the rest of the elements
$z-index-fixed-footer: 3000;
$z-index-floating-actions: 5002;
$z-index-navbar: 5000;
$z-index-modal: 10000;
$z-index-dialog: 20000;
$z-index-sweet-alert: $z-index-dialog;
$z-index-filepicker: 30000;
$z-index-loading-bar: 40000;
$z-index-growl: 40000;
$z-index-edp-modal: 5001; // above the navbar, below floating action
$z-index-gdpr: 2147483640;
$z-index-tm-job-application-panel: 30;

// Animation defaults
$transition-default-button: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
$transition-default-card: 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
