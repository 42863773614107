.chips-temp-field {
    border: 2px solid $color-form-border;
    border-radius: $base-border-radius;

    .chips {
        padding: 0 0 8px 8px;

        li {
            display: inline-block;
            margin: 8px 8px 0 0;

            .chip {
                display: block;
                background-color: $color-porcelain;
                color: $color-licorice;
                border-radius: 1.6rem;
                height: 32px;
                line-height: 32px;
                padding: 0 8px 0 12px;
                font-size: $font-size;
                font-weight: $font-weight;

                md-icon {
                    cursor: pointer;
                }
            }

            .md-contact-avatar {
                float: left;
                margin: 0 8px 0 -12px;

                img {
                    height: 32px;
                    border-radius: 16px;
                }
            }
        }
    }

    md-autocomplete {
        box-shadow: none;
        margin: 0 8px 8px 8px;

        &.md-portfolium-theme {
            background: transparent;
        }

        md-autocomplete-wrap {
            box-shadow: none !important;
        }

        button {
            background-color: transparent;
            padding: 0;
        }

        input:not(.md-input) {
            padding: 0 8px;
            font-size: $font-size;
            font-weight: $font-weight;
            color: $color-font;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.chips-temp-field-collaborators-dropdown {
    li {
        height: auto;
        padding: 0;

        h3 {
            font-size: 14px;
            font-weight: 600;
        }

        h4 {
            white-space: normal;
            line-height: 1.4;
        }
    }
}
