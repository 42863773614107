body.safari {

    &.mobile {

        /**
         * This is to fix the mobile Safari bug where the page loads in half then bumps out
         */

        .pf-ng-app-content {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .pf-profile-quick-edit__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .pf-entry-editor--full {
        @include min-screen($md-breakpoint-md) {
            .pf-entry-editor-content {
                flex: 1 0 100%;
            }
        }
    }
}
