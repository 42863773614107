// Sidebar navigation list
.pf-sidebar-list-nav {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    min-width: 256px;
}

.pf-sidebar-list-nav__inner {
    display: flex;
    flex-direction: column;
}

// List item
.pf-sidebar-list-nav__item {
    text-align: left;
    text-transform: none;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0;
    position: relative;
    display: flex;
    color: $color-oxford;

    &.md-button.md-portfolium-theme {
        // Important is necessary here because of the default button padding override
        // for TalentMatch buttons.
        // TODO: Remove when the default button padding is changed globally
        padding: 12px 16px !important;
    }

    // Default styles for icons inside the list item
    & > md-icon {
        margin: 0 0 0 16px;
        color: $color-oxford;

        &:first-child {
            margin: 0 16px 0 0;
        }
    }

    & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

// Active state
.pf-sidebar-list-nav__item--active {
    &.md-button:not([disabled]) {
        color: $color-electric;

        md-icon {
            color: $color-electric;
        }
    }
}

// Divider
.pf-sidebar-list-nav__divider {
    border-bottom: 1px solid $color-border-base;
    margin: 16px 0;
}
