.md-button.md-icon-button.pf-button-back {
    position: absolute;
    margin: 0;
    top: 8px;
    left: 8px;
    z-index: 3;

    @include min-screen($md-breakpoint-md) {
        top: 16px;
        left: 16px;
    }

    &:not([disabled]) {
        @include min-screen($md-breakpoint-md) {
            md-icon {
                color: $color-white;
            }

            &.md-focused {
                background-color: $color-white-alpha-24;
            }
        }
    }

    &.ng-hide-remove,
    &.ng-enter {
        transition: .3s ease all;
        opacity: 0;
        transform: rotate(-90deg);

        &.ng-hide-remove-active,
        &.ng-enter-active {
            opacity: 1;
            transform: rotate(0deg);
        }
    }

    &.ng-hide-add,
    &.ng-leave {
        transition: .3s ease all;
        opacity: 1;
        transform: scale3d(1, 1, 1);

        &.ng-hide-add-active,
        &.ng-leave-active {
            opacity: 0;
            transform: scale3d(.5, .5, .5);
        }
    }
}

.md-button.md-icon-button.pf-button-close {
    position: absolute;
    margin: 0;
    top: 8px;
    right: 8px;
    z-index: 3;

    @include min-screen($md-breakpoint-md) {
        top: 16px;
        right: 16px;
    }
}
