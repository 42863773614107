/**
 * This dialog provides the user with a list of actions
 * See the save-entry-modal.component for an example
 *
 * Usage:
 *
 * <div class="pf-simple-dialog">
 *     <div class="pf-simple-dialog__header">
 *         <h5>TITLE</h5>
 *     </div>
 *     <div class="pf-simple-dialog__body">
 *         <md-button md-no-ink class="pf-simple-dialog__button">
 *             <span class="pf-simple-dialog__button-icon pf-button-success">
 *                 <md-icon>done</md-icon>
 *             </span>
 *             Save
 *         </md-button>
 *         <md-button md-no-ink class="pf-simple-dialog__button">
 *             <span class="pf-simple-dialog__button-icon pf-button-error">
 *                 <md-icon>cancel</md-icon>
 *             </span>
 *             Discard
 *         </md-button>
 *     </div>
 *     <div class="pf-simple-dialog__footer"
 *          layout="row" layout-align="end center">
 *         <md-button md-no-ink
 *              class="md-primary"
 *              ng-click="$ctrl.close()">
 *             Cancel
 *         </md-button>
 *     </div>
 * </div>
 */


@import '~client/main/sass/base/variables';

.pf-simple-dialog {
    width: 100%;
    max-width: 400px;
}

.pf-simple-dialog__header {
    padding: 24px 24px 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }
}

.pf-simple-dialog__body {
    padding: 12px 0 0;
}

.pf-simple-dialog__button {
    &.md-button {
        margin: 8px 0 0;
        text-transform: none;
        height: 48px;
        font-weight: $font-weight-regular;
        font-size: 16px;
        text-align: left;
    }

    .pf-simple-dialog__button-icon {
        background-color: $color-background-base;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: inline-block;
        margin-right: 16px;
        text-align: center;
        transition: .2s ease background-color;

        &.pf-button-success md-icon {
            color: $color-success;
        }

        &.pf-button-error md-icon {
            color: $color-error;
        }
    }

    &:hover {
        .pf-simple-dialog__button-icon {
            background-color: transparent;
        }
    }
}

.pf-simple-dialog__footer {
    padding: 0;

    .md-button {
        margin: 8px;
    }
}
