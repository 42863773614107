// TODO Remove this and fix every file that imports this one
@import 'media_sizes';

/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: http://www.google.com/design/spec/style/typography.html
 */

@mixin typography-caption() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 12px;
    font-weight: $font-weight-regular;
    line-height: 12px; // Single lines only
    letter-spacing: .2px;
}

@mixin typography-body() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 13px;
    font-weight: $font-weight-regular;
    line-height: 20px;
    letter-spacing: .1px;
}

@mixin typography-body-2() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: 24px;
    letter-spacing: .1px;
}

@mixin typography-body-3() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: .1px;
}

@mixin typography-body-4() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 20px;
    letter-spacing: .1px;
}

@mixin typography-subhead() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 24px;
    letter-spacing: .1px;
}

@mixin typography-subhead-2() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 28px;
    letter-spacing: .1px;
}

@mixin typography-title() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 20px;
    font-weight: $font-weight-medium;
    line-height: 20px; // Single lines only
    letter-spacing: .05px;
}

@mixin typography-headline() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 24px;
    font-weight: $font-weight-regular;
    line-height: 32px;
}

@mixin typography-display-1() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 34px;
    font-weight: $font-weight-regular;
    line-height: 40px;
}

@mixin typography-display-2() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 45px;
    font-weight: $font-weight-regular;
    line-height: 48px;
}

@mixin typography-display-3() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 56px;
    font-weight: $font-weight-regular;
    line-height: 56px; // Single lines only
    letter-spacing: -.05px;
}

@mixin typography-display-4() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 112px;
    font-weight: $font-weight-light;
    line-height: 112px; // Single lines only
    letter-spacing: -.1px;
}

@mixin typography-display-5() {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 60px;
    font-weight: $font-weight-bold;
    line-height: 72px; // Single lines only
    letter-spacing: -.31px;
}
