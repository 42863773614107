@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-header-mobile {
    display: block;
}

.pf-entry-viewer-header-mobile {
    padding: 12px 16px 16px;
}

.pf-entry-viewer-header-mobile-title-wrapper {
    margin: 0 0 4px;
    @include typography-subhead();
    color: $color-licorice;
    font-weight: $font-weight-medium;
    word-wrap: break-word;

    pf-blank-field {
        margin: 8px 0;
    }
}

.pf-entry-viewer-header-mobile__title {
    display: inline-block;
}

.pf-entry-viewer-header-mobile__lozenge {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
}

.pf-entry-viewer-header-mobile__stats {
    @include typography-body();
    color: $color-licorice;
    font-size: 14px;
    margin: 0;
}

.pf-entry-viewer-header-mobile__info {
    @include typography-body();
    color: $color-licorice;
    font-size: 14px;

    margin: 0 0 8px;
}

.pf-entry-viewer-header-mobile-category__divider {
    padding: 0 6px;
}

.pf-entry-viewer-header-mobile-actions {
    padding-left: 20px;
    margin-right: -12px;

    .md-button.md-icon-button {
        margin: 0 4px;
    }
}
