.pf-company-card--feed {
    $avatar-height: 64px;

    background-color: $color-white;
    box-shadow: none;
    border: 0;

    .pf-avatar {
        background-color: $color-white-alpha-16;
        height: $avatar-height;
        width: $avatar-height;
        min-width: $avatar-height;

        a,
        img {
            display: block;
            width: 100%;
            border-radius: 3px;
        }
    }

    .pf-company-details {
        margin: 0;
        position: relative;
        margin-left: 16px;
    }

    .pf-detail--name {
        @include typography-subhead();
        font-weight: $font-weight-medium;
        display: block;
    }

    .pf-detail--industry {
        @include typography-body();
        font-size: 14px;
        color: $color-ash;
    }

    .pf-actions {
        padding: 8px 16px;

        .md-button {
            margin: 0;
            min-width: 0;

            &.pf-outline.pf-connected {
                border-color: $color-porcelain;
            }
        }
    }
}
