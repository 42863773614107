#pf-floating-actions-container {
    position: fixed;
    bottom: 22px;
    right: 22px;
    margin: 0;
    padding: 0;
    z-index: $z-index-floating-actions;

    li {
        position: relative;
        margin-top: 8px;
        overflow: visible;

        &:first-child {
            margin-top: 0;
        }

        .floating-action-btn {
            height: 40px;
            width: 40px;
            line-height: 46px;
            padding: 0;
            background-color: $color-electric;
            border-radius: 20px;
            box-shadow: 0px 3px 1px -2px $color-tiara, 0px 2px 2px 0px $color-tiara, 0px 1px 5px 0px $color-porcelain;
            text-align: center;

            &:hover,
            &:focus,
            &:active {
                box-shadow: 0 2px 4px -1px $color-tiara, 0 4px 5px 0 $color-tiara, 0 1px 10px 0 $color-porcelain;
                outline: 0;
                background-color: $color-electric-light-8;
            }

            md-icon {
                color: $color-white;
            }

            .material-icons {
                color: $color-electric;
            }
        }

        .md-button.md-fab {
            margin: 0;
        }
    }
}
