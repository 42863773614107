@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-counters {
    display: block;
}

.pf-entry-viewer-counter-stat {
    margin-right: 16px;
    @include typography-body();
    color: $color-licorice;
    font-size: 14px;

    pf-blank-field {
        margin: 4px 0;
    }
}

.pf-entry-viewer-counter-stat__icon {
    color: $color-oxford;

    &.pf-entry-viewer-counter-stat__icon--primary {
        color: $color-electric;
    }
}

.pf-entry-viewer-counter-stat__count {
    margin-left: 8px;
}
