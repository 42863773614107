.pf-dialog.pf-dialog--subscription-upsell-modal {
    max-width: 800px;
    min-width: 800px;

    &.pf-dialog--subscription-upsell-modal--pmail {
        .pf-column--left {
            background-color: $color-electric-dark-6;
        }
    }

    &.pf-dialog--subscription-upsell-modal--trial {
        .pf-column--left {
            background-color: $color-barney-dark-6;
        }
    }

    &.pf-dialog--subscription-upsell-modal--generic {
        .pf-column--left {

            background-color: $color-barney-dark-6;
        }
    }

    &.pf-dialog--subscription-upsell-modal--brand-rank {
        .pf-column--left {
            background-color: $color-electric;
        }
    }

    .pf-dialog-content {
        padding: 0;
    }

    .pf-column--left {
        padding: 0 40px;

        .pf-illustration-img {
            display: block;
            margin: 40px auto 24px;
        }

        h2 {
            margin: 24px 0 24px;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $color-white;
            text-align: center;
        }

        .pf-subhead {
            font-weight: $font-weight-medium;
            color: $color-white;
        }

        .pf-column-footer {
            color: $color-white;
            font-size: 15px;
            font-weight: $font-weight-medium;
            margin-bottom: 45px;
        }
    }

    .pf-column--right {
        padding: 0 32px;
        width: 364px;

        h3 {
            margin: 40px 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: $font-weight-medium;
            color: $color-oxford;
            text-align: center;
        }

        .pf-subscription-upsell-modal-form {
            margin: 0 0 64px;
        }
    }

    .pf-subscription-upsell-modal-list {
        margin: 0 0 35px;

        & > li {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 0;
            margin: 16px 0;
            font-size: 15px;
            line-height: 20px;
            font-weight: $font-weight-medium;
            color: $color-white;

            md-icon {
                color: $color-white;
                margin: 0 24px 0 0;
            }
        }
    }

    .md-button.md-primary.md-raised:not([disabled]) {
        background-color: $color-electric-light-16;

        &:hover,
        &.md-focused {
            background-color: $color-electric;
        }
    }

    .md-button[type="submit"] {
        margin-top: 40px;
    }
}
