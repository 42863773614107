table {
  border-collapse: collapse;
  margin: ($base-line-height / 2) 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid $color-ash;
  font-weight: bold;
  padding: ($base-line-height / 2) 0;
  text-align: left;
}

td {
  border-bottom: 1px solid $color-border-base;
  padding: ($base-line-height / 2) 0;
}

tr, td, th {
  vertical-align: middle;
}

@import 'tables/header';
@import 'tables/table';
@import 'tables/wrapper';
@import 'tables/empty';
