@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-navbar-mobile {
    display: block;
    position: relative;
    background-color: $color-navbar-base;

    .md-toolbar-tools {
        .pf-navbar__title {
            @include typography-title();
            text-transform: none;
            margin: 0 0 0 16px;
            color: $color-white;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            md-icon {
                margin-left: 4px;
            }
        }
    }

    .md-button.md-icon-button.pf-navbar-standard-notifications-mobile {
        margin-right: -8px;
    }

    pf-subnav-search,
    pf-sitewide-search {
        padding: 0 8px;
    }
}

.pf-navbar-standard-search-desktop {
    max-width: 256px;
    margin: 0;
}

// hacky fix for ios to pop the keyboard on search icon click
.pf-navbar-mobile__search-focus-click {
    position: absolute;
    top: -59px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 99px;
    width: 100%;
    text-align: right;
    opacity: 0;
    border: 0;
    cursor: inherit !important;
    overflow: hidden !important;
    color: transparent;
    background: transparent;
    font-size: 1px !important;

}
