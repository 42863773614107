// Vendor
@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/open-indicator';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/position';

// Partials
@import 'sass/standard';
@import 'sass/subscriber';
@import 'sass/subnav';
@import 'sass/footer-navbar';
@import 'sass/main-nav';
@import 'sass/menus';

.pf-app-bar {
    position: relative;
    background-color: $color-navbar-base;
    box-shadow: $whiteframe-shadow-2dp;
    z-index: 3;

    &.pf-app-bar--with-subnav {
        box-shadow: none;

        &.pf-subnav-hidden {
            box-shadow: $whiteframe-shadow-2dp;
        }
    }
}

.pf-app-bar--white {
    background-color: $color-white;

    md-toolbar.md-portfolium-theme:not(.md-menu-toolbar) {
        background-color: $color-white;

        md-icon {
            color: $color-oxford;
            fill: $color-oxford;
        }
    }

    .pf-navbar-title {
        color: $color-licorice;
    }
}

.pf-app-bar__container {
    max-width: $md-breakpoint-lg;
    margin: 0 auto;
}

.pf-navbar-logo-button {
    padding: 0 8px;
    margin-right: 8px;
    margin-left: -8px;
    line-height: 0;
}

.pf-navbar-logo-button__logo {
    width: 130px;
    min-width: 114px;
}

.pf-navbar-title {
    @include typography-title();
    color: $color-white;
}

.pf-subnav-wrapper {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 48px, 0);
    transition: 0.3s ease transform;
    background-color: $color-navbar-base;
    box-shadow: $whiteframe-shadow-2dp;

    &:after {
        content: '';
        z-index: 2;
        position: absolute;
        right: 0;
        left: 0;
        top: -100%;
        background-color: $color-navbar-base;
        height: 100%;
    }

    @include min-screen($md-breakpoint-md) {
        transform: translate3d(0, 56px, 0);
    }

    &.pf-subnav-hidden {
        box-shadow: none;
        transform: translate3d(0, 0, 0);
    }
}

.pf-content-with-subnav {
    padding-top: 48px;

    @include min-screen($md-breakpoint-md) {
        padding-top: 56px;
    }
}
