.style-guide {
    margin: 30px;

    h2 {
        @extend .pf-title;
    }

    h3 {
        @extend .pf-subhead-2;
    }

    .colors {
        li {
            padding: 4px 0 4px 10px;

            &.inverse {
                color: $color-white;
            }
        }
    }

    .profiles {
        li {
            display: inline-block;
            text-align: center;
        }
    }

    form {
        a {
            &.md-button {
                vertical-align: bottom;
            }
        }
    }

    #messages, #message {
        background: $color-white;
        border-radius: 6px;
        box-shadow: 0px 0px 0px;
    }

    section {
        @include clearfix;
    }

}
