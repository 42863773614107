@keyframes cd-bounce-in {
    0% {
        transform: scale(0);
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes scaleUp {
    0% { }
    100% { transform: scale(1); opacity: 1; }
}
