/**
 * @description
 * Display a list of attachments for an import item
 *
 * ```
 * <div class="pf-import-list-attachments">
 *     <div class="pf-import-list-attachment" ng-repeat="item in items">
 *         <md-icon class="material-icons pf-import-list-attachment__icon">attachment</md-icon>
 *         filename.ext
 *     </div>
 * </div>
 * ```
 */

.pf-import-list-attachments {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -4px -8px;
}

.pf-import-list-attachment {
    position: relative;
    margin: 0 4px 8px;
    max-width: 192px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $color-background-base;
    @include whiteframe-shadow(1);
    padding: 8px 8px 8px 40px;
    border-radius: 2px;
    @include typography-body();
    color: $color-licorice;
}

.pf-import-list-attachment__icon {
    position: absolute;
    left: 8px;
    top: 6px;

    &.md-portfolium-theme {
        color: $color-oxford;
    }
}
