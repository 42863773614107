@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-entry-viewer-construction-header__stripe {
    position: relative;
    background-color: $color-fire;
    height: 4px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url('https://portfolium.com/assets/images/entry-editor/preview-stripe.svg');
        background-repeat: repeat-x;
        background-position: top center;
    }
}

.pf-entry-viewer-construction-header__heading {
    padding: 24px 48px 16px;
    @include typography-subhead();
    color: $color-licorice;
    font-weight: $font-weight-medium;

    .md-button.md-portfolium-theme {
        margin: 0;
        padding: 0 16px;
    }
}
