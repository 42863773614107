.apply-on-website-link {
    margin-top: 16px;

    a {
        color: $color-oxford;
    }
}

.pf-job-apply-btn-wrapper {
    .md-button {
        margin-right: 16px;
    }
}
