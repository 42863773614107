body.c_entries.a_embed {
    min-width: 0;

    .navigation-tools,
    #pf-floating-actions-container {
        display: none;
    }

    .main {
        .slider,
        .code {
            padding: 12px;
            text-align: center;

            p {
                font-weight: bold;

            }

            textarea {
                height: 78px;
            }
        }

        .slider {
            padding: 12px 22px;
        }
    }
}
