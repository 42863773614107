.pf-table-header {
    padding: 0 24px;
    min-height: 64px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pf-table-header--selection {
    color: $color-electric-light-16;
    background-color: $color-porcelain;
    align-items: center;

    .pf-subhead {
        color: $color-electric-light-16;
        font-weight: $font-weight-medium;
        margin: 0;
    }
}

.pf-table-header__actions {
    margin-right: -12px;

    .md-button {
        margin: 0 4px;
    }
}
