@import '~client/main/sass/base/mixins/avatars.scss';

body.c_assignments.a_score {
    md-content.main {
        background-color: $color-white;
    }
}

.pf-section--rubric-matrix-score {
    height: 100%;
    max-width: 100%;
    padding: 0;
    color: $color-licorice;

    .pf-container {
        width: 100%;
    }
}

.pf-rubric-matrix-score-heading {
    max-width: 100%;
    padding: 20px 16px 0;
    background-color: $color-porcelain;

    > div {
        padding: 0 8px;
    }
}

.pf-rubric-matrix-score-heading__header {
    @include typography-body-2;
    font-size: 14px;
}

.pf-rubric-matrix-score-heading__subhead {
    @include typography-subhead();
    margin-bottom: 20px;
}

.pf-rubric-matrix-score-heading__subhead-score {
    font-weight: 600;
    margin-left: 4px;
}

md-content.md-portfolium-theme.pf-rubric-matrix-score-body {
    height: 100%;
    background-color: $color-white;
    @include typography-body();
    font-size: 15px;
}

.pf-rubric-matrix-score__cell {
    padding: 14px 24px;
    min-height: 0;
    border-bottom: 1px solid $color-border-base;

}
.pf-rubric-matrix-score__row {
    min-height: 0;
}

.pf-rubric-matrix-score__row:last-of-type .pf-rubric-matrix-score__cell{
    border-bottom: 0;
}

.pf-rubric-matrix-score__cell--criteria {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
}

.pf-rubric-matrix-score__cell--feedback {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
}

.pf-rubric-matrix-score-body {
    box-shadow: $whiteframe-shadow-4dp;
    min-height: 0;
}

.pf-rubric-matrix-score-body-loa {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
}

.pf-rubric-matrix-score-body-loa--scored {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border: 2px solid $color-electric;
        border-radius: 3px;
        content: '';
    }
}

.pf-rubric-matrix-score-body__header {
    @include typography-body-2;
    font-size: 15px;
    min-height: 0;
}


.pf-rubric-matrix-score-body-loa__title {
    @include typography-body-2;
    line-height: 20px;
    font-size: 15px;
    border-bottom: 0;
    padding-bottom: 16px;
    min-height: 0;
}

.pf-rubric-matrix-score-body-loa__description {
    white-space: pre-line;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 0;
    min-height: 0;
}

.pf-rubric-matrix-score-body-loa__points {
    padding-top: 0;
    @include typography-body-2;
    line-height: 20px;
    font-size: 15px;
    min-height: 0;
}

.pf-rubric-matrix-score-feedback {
    margin-bottom: 24px;
}

.pf-rubric-matrix-score-feedback__avatar {
    margin-right: 16px;
    @include avatar-circle(0, 40px);
}

.pf-rubric-matrix-score-feedback-content {
    width: 100%;
    min-width: 0;
}

.pf-rubric-matrix-score-feedback-content__meta-data {
    display: inline-block;
    margin-bottom: 4px;
}

.pf-rubric-matrix-score-feedback-content__user-name {
    color: $color-licorice;
    margin-right: 10px;

    @include typography-body();
    font-size: 15px;
    font-weight: $font-weight-medium;
}

.pf-rubric-matrix-score-feedback-content__date {
    display: inline-block;
    color: $color-oxford;

    @include typography-body();
    font-size: 15px;
}

.pf-rubric-matrix-score-feedback-content__feedback {
    color: $color-licorice;

    @include typography-body();
    font-size: 15px;
}

.pf-rubric-matrix-score-feedback__empty {
    color: $color-oxford;

    @include typography-body();
    font-size: 15px;
}
