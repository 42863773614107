@import '~client/main/sass/base/variables';

.pf-talent-match-avatar__wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    img {
        border-radius: 50%;
        background-color: $color-porcelain;
    }
}

.pf-talent-match-avatar-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 11px;
    width: 11px;
    min-height: 11px;
    min-width: 11px;
    border-radius: 3px 0;
    padding: 3px 0 0 3px;
    background-color: $color-white;
    text-align: center;
    content: "";
}

md-icon.pf-talent-match-avatar-indicator__disable {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 0;
    min-width: 0;
    line-height: 1em;
    color: $color-error;
}

.pf-talent-match-avatar-indicator__enable {
    display: block;
    height: 100%;
    width: 100%;
    background-color: $color-barney;
    border-radius: 2px;
    content: "";
}
