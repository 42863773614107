.pf-button-group-tabs {
    $height-xs: 48px;
    $height: 56px;

    height: $height;
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    .md-button.md-portfolium-theme {
        padding: 0 24px;
        min-width: 0;
        min-height: $height;
        line-height: $height;
        border-radius: 0;
        margin: 0;
        color: $color-oxford;

        &:not([disabled]) {
            &:hover {
                background-color: transparent;
            }

            &.pf-active {
                box-shadow: inset 0 -2px 0 0 $color-electric;
                color: $color-electric;
            }
        }

        .md-ripple-container {
            border-radius: 0;
        }
    }
}

.pf-button-group-tabs--blue {
    .md-button.md-portfolium-theme {
        color: $color-white;

        &:not([disabled]):not(:hover).md-focused {
            background-color: $color-white-alpha-16;
        }

        // Stops hover state from sticking on mobile iOS
        @include max-screen($md-breakpoint-md - 1) {
            &:not([disabled]):not(.md-focused):hover {
                background-color: $color-white-alpha-16;
            }
        }


        &:not([disabled]) {
            &.pf-active {
                box-shadow: inset 0 -2px 0 0 $color-white;
                color: $color-white;
            }
        }
    }
}
