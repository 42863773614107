@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-export-profile {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    // Cover letter override
    .pf-sidenav-panel__content .pf-field textarea.pf-form-control {
        min-height: 98px;
        height: 98px;
    }

    .pf-export-profile__form {
        padding-top: 16px;

        .pf-field {
            padding: 0 16px;
        }
    }

    // Override sidenav panel title, let it wrap
    .pf-sidenav-panel__header {
        h2 {
            white-space: normal;
            text-overflow: inherit;
        }

        .md-button {
            margin-right: -8px;
        }
    }

    .pf-entry-list {
        // Loading spinner override
        .pf-panel-sidenav-list__loading {
            margin: 0;
            padding: 16px 0;
        }
    }

    // Override, bottom border is entry list top border
    .pf-panel-sidenav-list-search {
        border-bottom: none;
    }

    .pf-panel-sidenav-list-controls {
        flex-direction: column;
    }
}

.pf-export-profile__header {
    display: flex;
    max-width: 100%;
    width: 100%;
}

.pf-export-profile-user {
    align-items: center;
    margin-bottom: 20px;

    a {
        text-align: right;
        font-weight: $font-weight-medium;
        cursor: pointer;
    }
}

.pf-export-profile-user__details {
    max-width: 224px;
}

.pf-export-profile-user__avatar {
    @include avatar-circle(2px, 80px);
    margin-right: 23px;
    pointer-events: none;
}

.pf-export-profile__subhead {
    margin-bottom: 16px;
    color: $color-oxford;
    @include typography-subhead();
}

.pf-export-profile__subhead--bold {
    font-weight: $font-weight-medium;
}

.pf-export-profile-empty {
    padding: 48px;
    border-top: 1px solid $color-border-base;

    .pf-subhead {
        font-weight: $font-weight-medium;
    }

    .pf-body {
        margin: 8px 0 0;
        color: $color-oxford;
    }
}
