@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-gdpr-snackbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-gdpr;
}

.pf-gdpr-snackbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 24px;
    box-shadow: $whiteframe-shadow-5dp;
    background-color: $color-white;
    font-size: 15px;
    line-height: 18px;
}

.pf-gdpr-snackbar__see-details {
    font-weight: $font-weight-medium;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .pf-gdpr-snackbar {
        padding: 16px;
        text-align: center;
    }

    .pf-gdpr-snackbar > div:last-of-type {
        margin-top: 12px;
    }

    .pf-gdpr-snackbar__accept {
        width: 100%;
    }

    .pf-dialog.pf-gdpr-snackbar-modal {
        max-height: 65%;
        margin: 0 16px;
    }
}

.pf-dialog.pf-gdpr-snackbar-modal {
    max-width: 640px;

    md-dialog-actions {
        min-height: 52px;
        padding: 8px;

        .md-button {
            margin: 0;

            &.pf-gdpr-snackbar-modal__cancel {
                margin-right: 8px;
            }
        }
    }
}

.pf-gdpr-snackbar-modal__message-wrapper {
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 16px;
    }
}

.pf-gdpr-snackbar-modal__message-title {
    margin-bottom: 12px;
    color: $color-licorice;

    @include typography-body-3();
    font-weight: $font-weight-medium;
}

.pf-gdpr-snackbar-modal__message-body {
    margin-bottom: 10px;
    white-space: pre-line;
    color: $color-licorice;

    @include typography-body();
    font-size: 14px;
}

.pf-gdpr-snackbar-modal__checkbox {
    margin: 0;
    color: $color-licorice;

    @include typography-subhead-2();
}
