@import '~client/main/sass/base/mixins/media_sizes';

/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: http://www.google.com/design/spec/style/typography.html
 */

.pf-caption {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 12px;
    font-weight: $font-weight-regular;
    line-height: 12px; // Single lines only
    letter-spacing: .2px;
}

.pf-body {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 13px;
    font-weight: $font-weight-regular;
    line-height: 20px;
    letter-spacing: .1px;
}

.pf-body-2 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: 24px;
    letter-spacing: .1px;
}

.pf-body-3 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: .1px;
}

.pf-body-4 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 20px;
    letter-spacing: .1px;
}

.pf-subhead {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 24px;
    letter-spacing: .1px;
}

.pf-subhead-2 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 28px;
    letter-spacing: .1px;
}

.pf-title {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 20px;
    font-weight: $font-weight-medium;
    line-height: 20px; // Single lines only
    letter-spacing: .05px;
}

.pf-headline {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 24px;
    font-weight: $font-weight-regular;
    line-height: 32px;

    @include max-screen($mobile) {
        font-size: 18px;
        line-height: 26px;
    }
}

.pf-display-1 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 34px;
    font-weight: $font-weight-regular;
    line-height: 40px;

    @include max-screen($mobile) {
        font-size: 24px;
        line-height: 30px;
    }
}

.pf-display-2 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 45px;
    font-weight: $font-weight-regular;
    line-height: 48px;
}

.pf-display-3 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 56px;
    font-weight: $font-weight-regular;
    line-height: 56px; // Single lines only
    letter-spacing: -.05px;
}

.pf-display-4 {
    font-family: $font-family-default;
    font-style: normal;
    font-size: 112px;
    font-weight: $font-weight-light;
    line-height: 112px; // Single lines only
    letter-spacing: -.1px;
}
