.pf-entry-card--blank {
    box-shadow: none;
    background-color: $color-porcelain;
    border: 2px dashed $color-black-alpha-8;
    text-align: center;
    min-height: 400px;
    outline: 0;

    &[pf-add-entry] {
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $color-black-alpha-16;
        }
    }

    md-icon {
        margin: 0 0 8px;
        color: $color-ash;
    }

    .pf-title {
        color: $color-ash;
    }

    .pf-padding {
        padding: 16px;
    }
}
