@import '~client/main/sass/base/variables';

.pf-ranking-pill {
    width: 70px;
    height: 8px;
}

.pf-ranking-pill__item {
    border-right: 2px solid $color-white;
    background-color: $color-porcelain;
}

.pf-ranking-pill__item--filled {
    background-color: $color-electric;
}

.pf-ranking-pill__item--first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pf-ranking-pill__item--last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 0;
}
