/**
 * Coloured text lozenge, primarily for displaying status.
 *  Available in a standard and subtle variations.
 * Reference: https://docs.atlassian.com/aui/5.4.0/docs/lozenges.html
 *
    <div class="pf-lozenge pf-lozenge--primary">
        any text here
    </div>
 *
 */

.pf-lozenge {
    height: 20px;
    padding: 0 4px;
    border-radius: 3px;
    text-transform: uppercase;
    border: 2px solid $color-border-base;
    color: $color-oxford;

    @include typography-body();
    line-height: 16px;
    font-size: 12px;
    font-weight: $font-weight-medium;
}

.pf-lozenge--primary {
    border-color: $color-electric-light-40;
    color: $color-electric;
}

.pf-lozenge--warning {
    border-color: $color-fire;
    color: $color-licorice;
}

.pf-lozenge--error {
    border-color: $color-crimson;
    color: $color-error;
}
