pf-profile-about-panel {
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    .md-button.md-icon-button.pf-close-panel-btn {
        position: absolute;
        top: 10px;
        right: 8px;
        margin: 0;
        z-index: 2;
    }

    .pf-sidenav-panel__header {
        h2,
        h3 {
            padding-right: 48px;
        }
    }
}

pf-profile-about-panel-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    form {
        overflow: hidden;
    }

    pf-image-picker {
        height: 160px;
        width: 160px;
    }

    pf-date-picker {
        display: block;

        @include min-screen($md-breakpoint-sm) {
            margin: 0 -8px;

            .pf-field {
                padding: 0 8px;
            }
        }
    }

    .pf-about-panel-form--introduction__intro {
        min-height: 280px;
    }
}

pf-profile-about-panel-work-samples {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    .pf-sidenav-panel__content {
        padding: 0;
        position: relative;
    }

    .md-subheader.md-portfolium-theme {
        background-color: $color-white;
        color: $color-licorice;
        border-bottom: 1px solid $color-border-base;
    }

    md-list-item {
        & + .md-subheader {
            margin-top: 16px;
        }
    }
}

.pf-profile-about-panel-empty {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 48px;
    text-align: center;

    .pf-subhead {
        font-weight: $font-weight-medium;
    }

    .pf-body {
        margin: 8px 0 0;
        color: $color-oxford;
    }
}
