// video embed
.pf-video-embed-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.pf-video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Fullscreen slider
.pf-video-embed-container--fullscreen {
    height: 100%;
    padding-bottom: 0;
}
