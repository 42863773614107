// Assignment list wrapper
.pf-import-list {
    & > *:last-child {
        .pf-import-list-item {
            border-bottom: 0;
        }
    }
}

// Assignment list item
.pf-import-list-item {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    overflow: hidden;
    min-height: 88px;
    padding: 0 24px 0 72px;
    border-bottom: 1px solid $color-border-base;
    transition: background-color $transition-default-button;

    &[disabled] {
        background-color: $color-porcelain;

        .pf-import-list-item__toggle {
            opacity: .48;
        }
    }

    &:not([disabled]) {
        &:hover {
            cursor: pointer;
            background-color: $color-porcelain;
        }
    }
}

.pf-import-list-item__toggle {
    position: absolute;
    top: 24px;
    left: 24px;
}

.pf-import-list-item-image {
    margin-right: 12px;
}

// Assignment item content
.pf-import-list-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 16px 0;

    .pf-import-list-attachments {
        margin-top: 8px;
    }
}

.pf-import-list-item-content__details {
    max-width: 100%;
}

.pf-import-list-item-heading-wrapper {
    min-width: 0;
}

.pf-import-list-item-content__heading {
    @include typography-subhead();
    color: $color-licorice;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + .pf-inline-labels {
        margin-left: 8px;
    }
}

.pf-import-list-item-content__sub-heading {
    @include typography-body();
    color: $color-oxford;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
