body.c_notifications.a_feed {
    .segment {
        .segment-sidebar {
            @include max-screen($mobile) {
                display: none;
            }
        }

        article {
            @include max-screen($mobile) {
                width: 100%;
                margin: 0 auto;

                h1 {
                    margin-top: 0;
                    font-size: 20px;
                    padding: 10px 15px;
                }

                .padding {
                     padding: 10px;
                }

                .table-list {
                    .threads {

                        &.logo {
                            width: 64px;
                        }
                    }
                    td.title {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

#notifications {
    background: $color-white;
    border-radius: 6px;
    box-shadow: $base-content-shadow;

    .contents {
        padding-top: $base-spacing;
        padding-bottom: $base-spacing;

        ul {
            li {
                border-top: 1px solid transparentize($color-licorice, .9);
                padding: $base-spacing 0 $base-spacing $base-spacing;

                &:nth-child(odd) {
                    background: $color-porcelain;
                }

                i {
                    margin-right: 4px;
                }
            }
        }
    }
}
