@import '~client/main/sass/base/mixins/background-gradient';

.pf-entry-card--list {
    position: relative;
    padding: 8px 8px;

     & > .pf-column  {
        padding: 0 8px;
     }

    a {
        font-size: 13px;
        font-weight: $font-weight-medium;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pf-title {
        @extend .pf-body-2;
        line-height: 18px;
        color: $color-licorice;

        &:hover,
        &:focus {
            color: $color-electric;
        }
    }

    .md-menu {
        padding: 0;
    }

    .md-button.md-icon-button {
        margin: 0;
    }

    .pf-author {
        min-width: 28px;

        @include min-screen($md-breakpoint-sm) {
            margin-right: 16px;
        }
    }

    img.pf-avatar {
        $size: 28px;

        display: block;
        height: $size;
        width: $size;
        border-radius: $size / 2;
    }

    .pf-strength-meter {
        width: 100%;

        .pf-strength-meter-label {
            @extend .pf-body-2;
            line-height: 1;
            margin: 0 0 4px;
        }

        .pf-strength-meter-bar {
            $height: 6px;

            width: 100%;
            height: $height;
            border-radius: $height / 2;
            background-color: $color-porcelain;
            overflow: hidden;
            position: relative;
            margin: 0;

            .pf-strength-meter-bar-fill {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: $height / 2;
                @include background-gradient-primary-secondary();
            }
        }
    }

    .pf-column {
        height: 48px;
    }

    .pf-column--actions {
        min-width: 48px;
    }
}
