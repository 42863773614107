.pf-user-connect-card--list {
    &.md-2-line {
        position: relative;
        padding: 0px 16px;
        align-items: flex-start;
        height: auto;
        display: flex;
        align-items: center;
        min-height: 90px;
        flex: 1 1 auto;
        margin: auto;
        text-overflow: ellipsis;

        .md-avatar {
            margin: 0 16px 0 0;
            flex: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-sizing: content-box;

            img {
                border-radius: 50%
            }
        }

        .md-list-item-text {
            overflow: hidden;

            h3,
            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: $color-ash;
            }

            h3 {
                font-size: 16px;
                font-weight: 400;
            }

            a {
                color: $color-licorice;
            }
        }
    }

    pf-user-connect-btn {
        margin: 0 -16px 0 0;
    }
}
