body:not(.chrome-lt-59) .pf-sticky {
    position: -webkit-sticky;
    position: sticky;
}

body:not(.chrome-lt-59) .pf-sticky:before,
body:not(.chrome-lt-59) .pf-sticky:after {
    content: '';
    display: table;
}

