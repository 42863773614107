.pf-panel-sidenav-search {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    flex: 1;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    min-height: 48px;
    background-color: $color-white;
    border-radius: 2px;
    box-shadow: $whiteframe-shadow-1dp;

    .pf-form-control {
        border: 0;
        height: 48px;
        padding: 14px 16px;
        width: auto;
    }
}

.pf-panel-sidenav-search__loading {
    height: 40px;
    width: 40px;
    margin: 0 6px;
    position: relative;

    md-icon {
        margin: 8px;
        transition: none;
    }

    md-progress-circular {
        position: absolute;
        top: 8px;
        left: 8px;
    }
}

.pf-panel-sidenav-search__divider {
    height: 32px;
    width: 1px;
    background-color: $color-border-base;
}

.pf-panel-sidenav-search-wrapper {
    background-color: $color-electric-light-16;
    min-height: 100px;
    position: relative;

    .md-button.md-fab {
        margin: 0;
        background-color: $color-white;
        box-shadow: $whiteframe-shadow-1dp;

        &:not([disabled]) {
            &:hover,
            &.md-focused {
                box-shadow: $whiteframe-shadow-4dp;
                background-color: $color-white;
            }
        }

        md-icon {
            color: $color-electric;
        }
    }
}

.pf-panel-sidenav-search-wrapper__fab {
    position: absolute;
    bottom: -28px;
    right: 32px;
    z-index: 1;

    .md-button.md-fab {
        &.ng-enter {
            transform: scale(1);

            &.ng-enter-active {
                transform: scale(0);
            }
        }
    }

    a.pf-body {
        position: absolute;
        width: 160px;
        left: -52px;
        bottom: -32px;
        line-height: 16px;
        color: $color-white;
        text-align: center;
        font-weight: $font-weight-medium;
    }
}
