@function colorAdjust($color, $amt: 0) {
    $hue: round(hue($color)) / 1deg;
    $saturation: round(saturation($color));
    $lightness: round(lightness($color)) + $amt;
    @return unquote('hsl(#{$hue}, #{$saturation}, #{$lightness})');
}

$color-black: #000;
$color-white: #fff;
$color-licorice: #2c3a44;
$color-oxford: #384a56;
$color-ash: #8a969e;
$color-tiara: #c7cdd1;
$color-porcelain: #f5f5f5;

// primary
$color-electric: #008ee2;
$color-electric-light-51: colorAdjust($color-electric, 51%);
$color-electric-light-40: colorAdjust($color-electric, 40%);
$color-electric-light-28: colorAdjust($color-electric, 28%);
$color-electric-light-16: colorAdjust($color-electric, 16%);
$color-electric-light-8: colorAdjust($color-electric, 8%);
$color-electric-dark-6: colorAdjust($color-electric, -6%);
$color-electric-dark-12: colorAdjust($color-electric, -12%);
$color-electric-dark-24: colorAdjust($color-electric, -24%);

// secondary
$color-barney: #bf31a4;
$color-barney-light-49: colorAdjust($color-barney, 49%);
$color-barney-light-14: colorAdjust($color-barney, 14%);
$color-barney-light-4: colorAdjust($color-barney, 4%);
$color-barney-dark-6: colorAdjust($color-barney, -6%);

$color-violet: #64189a;
$color-violet-light-24: colorAdjust($color-violet, 24%);
$color-violet-dark-8: colorAdjust($color-violet, -8%);

$color-shamrock: #00ad17;
$color-shamrock-light-63: colorAdjust($color-shamrock, 63%);
$color-shamrock-dark-16: colorAdjust($color-shamrock, -16%);

$color-crimson: #ef0612;
$color-crimson-light-49: colorAdjust($color-crimson, 49%);
$color-crimson-dark-16: colorAdjust($color-crimson, -16%);

$color-fire: #fb5d13;
$color-fire-light-43: colorAdjust($color-fire, 43%);
$color-fire-dark-16: colorAdjust($color-fire, -16%);

// Opacity levels
$color-black-alpha-72: rgba($color-black, 0.72);
$color-black-alpha-48: rgba($color-black, 0.48);
$color-black-alpha-24: rgba($color-black, 0.24);
$color-black-alpha-16: rgba($color-black, 0.16);
$color-black-alpha-8: rgba($color-black, 0.08);

$color-white-alpha-72: rgba($color-white, 0.72);
$color-white-alpha-48: rgba($color-white, 0.48);
$color-white-alpha-32: rgba($color-white, 0.32);
$color-white-alpha-24: rgba($color-white, 0.24);
$color-white-alpha-16: rgba($color-white, 0.16);

// Status colors
$color-success: $color-shamrock;
$color-warning: $color-fire;
$color-error: $color-crimson;
$color-liked: $color-crimson;

// Form colors
$color-form-placeholder: $color-ash;
$color-form-placeholder-disabled: $color-tiara;
$color-form-border: $color-tiara;
$color-form-border-focus: $color-electric;

// Ribbon colors
$color-ribbon-staff-pick: $color-electric-light-8;
$color-ribbon-expert-pick: $color-barney-dark-6;
$color-ribbon-featured-pick: $color-violet-light-24;
$color-ribbon-private: $color-porcelain;

// Background Colors
$color-background-base: $color-porcelain;

// Border color
$color-border-base: $color-tiara;

// Shadow color
$color-shadow-base: $color-licorice;

// Navbar Colors
$color-navbar-base: $color-oxford;
$color-navbar-talent-match: $color-oxford;

// Other company brand colors
$color-facebook: #3b5998;
$color-facebook-hover: #2c4372;
$color-linkedin: #0e76a8;
$color-linkedin-hover: #0a567a;
$color-twitter: #00aced;
$color-twitter-hover: #0085b8;
$color-pinterest: #cc2127;
$color-pinterest-hover: #9e1a1e;
$color-instagram: #3f729b;
$color-instagram-hover: #315878;
$color-github: #787575;
$color-github-hover: #5d5b5b;
$color-microsoft-word: #2a3281;
$color-microsoft-powerpoint: #db5a26;
$color-microsoft-excel: #007133;
$color-adobe-pdf: #991d29;
