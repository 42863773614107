.pf-popover__profile {
    padding: 12px;
    max-width: 480px;
}

.pf-popover-profile-info {
    min-width: 124px;
    margin-left: 12px;
    width: 100%;
}

.pf-popover-profile-info__name {
    @include typography-body();
    color: $color-licorice;
    font-size: 16px;
    width: 100%;
    margin-bottom: 4px;
}

.pf-popover-profile-info__school {
    @include typography-subhead();
    color: $color-oxford;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    width: 100%;
}
