@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

.pf-company-list-card__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 4px;

    // Desktop
    @include min-screen($md-breakpoint-md) {
        margin: -12px;
    }
}

.pf-company-list-card-list-item {
    display: flex;
    cursor: pointer;
    padding: 12px;
    flex: 1;
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    // tablet
    @include min-screen($md-breakpoint-sm) {
        max-width: 50%;
        min-width: 50%;
        width: 50%;
    }
    // Desktop
    @include min-screen($md-breakpoint-md) {
        max-width: 33.3%;
        min-width: 33.3%;
        width: 33.3%;
    }
}

.pf-company-list-card-list-item__inner {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;

    pf-company-result-card {
        display: flex;
        flex: 1 1 auto;
        min-width: 0;
        width: 100%;
    }
}

.pf-blank-company-list-card {
    padding: 12px;
}

.pf-company-list-card-blank-content {
    min-width: 0;
}
