.pf-panel-sidenav-auth {
    height: 100vh;
}

.pf-panel-sidenav-auth__form {
    padding: 0 24px;

    .pf-credly-logo {
        margin: 0 auto 32px;
    }
}
