/**
 * Show a loading screen while Angular boots and applies layout classes based
 * on layout attributes in template markup. This is a only required for IE,
 * where the layout attribute CSS can't be included for performance reasons.
 */

body:not(.ie) {
    // Hide loading screen for all browsers other than IE
    .pf-ng-loading {
        display: none !important;
    }
}

body.ie.pf-ng-app {
    // Hide loading screen for angular apps in IE
    .pf-ng-loading {
        display: none !important;
    }
}

.pf-ng-loading {
    background-color: $color-porcelain;
    @include position(fixed, 0 0 0 0);
    // highest possible z-index to overlay everything
    z-index: 99999999999999999999;

    .pf-ng-loading-content {
        position: absolute;
        width: 192px;
        height: 72px;
        top: 50%;
        left: 50%;
        margin-top: -36px;
        margin-left: -100px;
        text-align: center;

        img {
            display: block;
            margin: 0 auto 8px;
            width: 128px;
            opacity: .18;

            @include min-screen($md-breakpoint-sm) {
                width: 192px;
            }
        }

        p {
            @extend .pf-body;
            font-weight: $font-weight-medium;
            color: $color-tiara;
            margin: 8px 0;
            text-transform: uppercase;
            letter-spacing: .4px;
        }
    }
}
