.pf-popover.pf-panel {
    animation: fadein 0.1s;
    background-color: $color-white;
    border-radius: 3px;
    @include whiteframe-shadow(3);
    display: flex;
    height: auto;
    width: auto;

    @media (max-width: $md-breakpoint-md - 1) {
        margin-left: 8px;
        max-width: 80%;
    }

    @media (min-width: $md-breakpoint-md) {
        max-width: $md-breakpoint-md;
    }

    p {
        @include typography-body-2();
        padding: 16px;
    }
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.pf-popover--pointer {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-white;
        margin: -10px auto 0 auto;
        filter: drop-shadow(0 -3px 2px $color-porcelain);
    }
}
