@import '~client/main/sass/base/variables';

pf-field-error {
    .pf-field-error {
        margin: 6px 0 0 0;
    }

    a {
        color: $color-electric;
    }
}
