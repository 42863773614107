pf-pmail-card-btn {
    .md-menu {
        padding: 0;
    }

    .md-button {
        padding: 0 8px;
        max-height: 36px;
        overflow: hidden;
        border: 1px solid transparent;
        border-color: $color-tiara;
        line-height: 34px;

        md-icon {
            margin: 0;

            &.pf-icon-prepend {
                margin-right: 4px;

                @include max-screen($md-breakpoint-sm - 1) {
                    margin: 0;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
