$color-border-base: $color-electric-dark-24;

.pf-company-card {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;

    .pf-company-image {
        position: relative;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }

        .pf-image-wrap {
            background-color: $color-porcelain;
            @include position(absolute, 0, 0, 0, 0);

            img {
                width: 100%;
                height: auto;
                display: block;
                border-radius: 3px 3px 0 0;
            }
        }
    }
}

@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/linear-gradient';
@import './card/standard';
@import './card/feed';
@import './card/micro';
