@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-pathway-requirement-submission-panel-editor {
    min-height: 0;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
}

.pf-pathway-requirement-submission-panel-editor__title {
    padding: 0 0 12px;
    color: $color-licorice;

    @include typography-subhead();
    font-weight: $font-weight-bold;
    font-size: 20px;

    .md-button.md-icon-button {
        margin: -8px 10px -8px -12px;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        padding-bottom: 16px;
    }
}

.pf-pathway-requirement-submission-panel-editor__sub-title {
    padding: 0 0 16px;
    color: $color-licorice;

    @include typography-subhead();
    line-height: 28px;

    @include max-screen($md-breakpoint-sm - 1) {
        @include typography-body();
        font-size: 14px;
    }
}

md-content.md-portfolium-theme.pf-pathway-requirement-submission-panel-editor__fields {
    background-color: $color-white;
    padding: 22px 24px;
}
