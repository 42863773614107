@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-list-draft-card {
    cursor: pointer;
}

.pf-list-card-text {
    @include typography-body();
    color: $color-licorice;
}
