md-toolbar.pf-toolbar-footer {
    $height: 48px;

    min-height: $height;
    background-color: transparent;
    border-top: 1px solid $color-border-base;
    color: $color-ash !important;

    .md-toolbar-tools {
        height: $height;
        max-height: $height;
        @extend .pf-body;
    }

    .pf-footer-links {
        & > a {
            color: $color-oxford;

            & + a,
            & + span {
                margin-left: 24px;
            }

            &:hover,
            &:focus {
                color: $color-oxford;
            }
        }
    }
}
