.tokenfield {
    &.form-control {
        border: 2px solid $color-form-border;
        box-shadow: none;

        &.focus {
            border-color: $color-form-border-focus;
            box-shadow: none;
        }
    }
}
