md-select.pf-form-control {
    height: 40px;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: auto;

    &.md-portfolium-theme {
        .md-select-value {
            border-bottom-width: 2px;
            padding-bottom: 0;

            *:first-child {
                flex: 1 1 auto !important;
                -webkit-flex: 1 1 auto !important;
                -ms-flex: 1 1 auto !important;
            }
        }

        &:not([disabled]) {
            .md-select-value {
                color: $color-licorice;

                &.md-select-placeholder {
                    color: $color-ash;
                }
            }

            &.ng-invalid.ng-touched {
                .md-select-value {
                    padding-bottom: 0;
                }
            }
        }
    }

    &--borderless.md-portfolium-theme {
        margin: 0;
        font-size: 15px;
        color: $color-licorice;
        border: 2px solid transparent;
        border-radius: 3px;
        padding: 0 12px;

        &:hover {
            border-color: $color-border-base;
        }

        &:focus {
            border-color: $color-electric;
            outline: none;
        }

        .md-select-value {
            padding: 2px 0 0;
            border-bottom-color: transparent!important;

            .md-select-icon {
                margin: 0 -6px 0 10px;
                width: 24px;
            }

            .md-select-icon:after {
                font-family: Material Icons;
                font-size: 24px;
                content: 'keyboard_arrow_down';
                transform: none;
                font-feature-settings: 'liga'; /* Support for IE. From material-icons.css. */
            }
        }
    }

    &--outline.md-portfolium-theme {
        border: 2px solid $color-border-base;
        border-radius: 3px;
        padding: 5px 8px;
        margin: 0;

        &:not([disabled]) {

            .md-select-value {
                border: 0;
                padding: 0px 2px;

                &:focus {
                    border: 0;
                }
            }

            &:focus {
                border-color: $color-electric;

                .md-select-value.md-select-placeholder {
                    border: 0;
                }
            }
        }
    }
}

.md-select-backdrop {
    transition: all .5s ease;

    &.md-portfolium-theme {
        background-color: $color-black-alpha-48;
        z-index: $z-index-navbar + 1;

        &.ng-enter {
            opacity: 0;
        }
    }
}

// Position md-select container above backdrop
.md-select-menu-container {
    &.md-portfolium-theme {
        z-index: $z-index-dialog + 2;
    }
}

.md-select-menu-container {
    md-select-menu {
        border-radius: 3px;
    }
}

md-option {
    .md-text {
        font-size: $font-size;
        font-weight: $font-weight;
    }
}

// don't change placeholder color
md-select.md-portfolium-theme.ng-invalid.ng-touched {
    .md-select-value.md-select-placeholder {
        color: $color-ash !important;
    }
}

// Style error state for submitted forms
.ng-submitted {
    md-select.pf-form-control.md-portfolium-theme {
        &:not([disabled]) {
            &.ng-invalid {
                .md-select-value {
                    border-color: $color-error;
                }
            }
        }
    }

    // highlight md-select borders when outline modifier
    md-select.pf-form-control {
        &--outline.md-portfolium-theme {
            &:not([disabled]) {
                &.ng-invalid {
                    border-color: $color-error;
                }
            }
        }
    }
}

// fix for Safari/Firefox - remove scrollbars on disabled md-select
md-select[disabled] .md-select-value {
    margin-bottom: 0px;
}
