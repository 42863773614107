.pf-dialog--v2 {
    width: 100%;
    max-width: 600px;
    margin: 0 16px;

    .pf-dialog-header {
        margin-bottom: 22px;
    }

    &.pf-dialog md-toolbar.md-portfolium-theme {
        .md-toolbar-tools {
            height: 64px;
            max-height: 64px;
            padding: 22px 24px;

            .md-button {
                margin: -16px;
                @extend .pf-text--button-1;
            }
        }
    }

    &.pf-dialog--white md-toolbar.md-portfolium-theme {
        border-bottom: none;
        margin-bottom: 8px;

        &.md-toolbar--compact {
            margin: 0;

            .md-toolbar-tools {
                padding: 20px 24px;
            }
        }
    }

    &.pf-dialog p {
        margin: 0 0 16px;
    }

    &.pf-dialog md-dialog-content {
        padding: 24px;

        &.pf-dialog-content-no-padding {
            padding: 0;

            .pf-dialog-header {
                margin: 22px 24px;
            }
        }
    }

    /**
     * Workaround for forms separated by a parent component,
     * md-dialog adds these properties ONLY to forms which are direct descendants, this helps long forms scroll with a fixed action bar
     *
     * <md-dialog>
     *      <pf-form-component>
     *          <form></form>
     *      </pf-form-component>
     * </md-dialog>
     */
    &.pf-dialog form {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    &.pf-dialog md-radio-group {
        padding-top: 8px;
    }

    &.pf-dialog md-dialog-actions {
        border-top: 1px solid $color-porcelain;
        padding: 0 8px;
        min-height: 52px;

        &.md-dialog-actions--no-border {
            border-top: none;
            margin-bottom: 8px;
        }

        .md-button {
            @extend .pf-text--button-1;
        }
    }
}
