// Base
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

md-dialog.pf-add-entry-modal {
    width: 90%;
    max-width: 848px;
    max-height: 90%;
    min-height: 320px;
}

.pf-add-entry-modal {
    text-align: center;
}

.pf-add-entry-modal-content {
    padding: 24px;
    background-color: $color-white;
}

.pf-add-entry-modal-content__header {
    @include typography-title();
    text-align: left;
    color: $color-licorice;
    line-height: 24px;
    margin-bottom: 24px;
}

.pf-add-entry-modal--loading {
    .pf-add-entry-modal-content__header,
    .pf-add-entry-modal-content-url-form,
    .pf-add-entry-modal-content__skip {
        opacity: .48;
    }
}

.pf-add-entry-modal--url-loading {
    .pf-add-entry-modal-content__header,
    .pf-add-entry-modal-content-drop-zone-browse,
    .pf-add-entry-modal-content__skip {
        opacity: .48;
    }
}


.pf-add-entry-modal-content__drop-zone {
    padding: 36px;
    margin-bottom: 24px;
    text-align: center;
    border: 2px dashed $color-border-base;
    border-radius: 3px;
}

.pf-add-entry-modal-content-drop-zone-uploading {
    margin: 36px 0 16px;
    height: 24px;
}

.pf-add-entry-modal-content-drop-zone-browse {
    padding: 6px 0;
}

.pf-add-entry-modal-content-drop-zone-browse__illustration {
    height: 70px;
    width: 114px;
    margin-bottom: 16px;
}

.pf-add-entry-modal-content-drop-zone-text {
    display: inline-block;
    @include typography-body-2();
    font-size: 16px;
    color: $color-licorice;
}

.pf-add-entry-modal-content__separator {
    display: inline-block;
    padding: 0 8px;
    border-radius: 2px;
    @include typography-body-2();
    font-size: 12px;
    line-height: 20px;
    color: $color-licorice;
    background-color: $color-porcelain;
}

.pf-add-entry-modal-content__skip {
    text-align: right;
    @include typography-body-2();
    font-size: 15px;
}

.pf-add-entry-modal-content-url-form {
    position: relative;
    margin: 16px 0 40px;
}

.pf-add-entry-modal-content-url-form__icon-area {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.pf-add-entry-modal-content-url-form-icon-area__icon {
    margin-top: 8px;
    margin-right: 8px;

    &.md-portfolium-theme {
        transition: none;
    }
}

.pf-add-entry-modal-content-progress-bar {
    display: block;
    width: 320px;
    max-width: 320px;
    margin: 0 auto 36px;
}

.pf-add-entry-modal-guide {
    width: 320px;
    max-width: 320px;
    padding: 24px;
    text-align: left;
    background-color: $color-background-base;
}

.md-button.md-icon-button.pf-add-entry-modal-guide-close {
    margin: -16px -16px 0;
}

.pf-add-entry-modal-guide-item {
    margin-bottom: 24px;
}

.pf-add-entry-modal-guide-item-number {
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 2px;
    @include typography-body-2();
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: $color-licorice;
    background-color: $color-porcelain;
}

.pf-add-entry-modal-guide-item-copy {
    margin-left: 24px;
}

.pf-add-entry-modal-guide-item-copy__title {
    margin-bottom: 4px;
    @include typography-body-2();
    line-height: 28px;
    font-size: 16px;
    color: $color-licorice;
}

.pf-add-entry-modal-guide-item-copy__text {
    margin: 0;
    @include typography-body();
    font-size: 14px;
    color: $color-licorice;
}
