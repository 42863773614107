$card-border-radius: 2px;

.pf-entry-card {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-1dp;
    overflow: hidden;
    transition: .3s ease box-shadow;
    width: 100%;
    white-space: normal;

    @include min-screen($md-breakpoint-sm) {
        border-radius: $card-border-radius;
    }

    .pf-field--title {
        @extend .pf-body;
        display: block;
        color: $color-licorice;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &:hover,
        &:focus {
            color: $color-electric;
        }
    }
}

// Default image
.pf-entry-card-default-image {
    cursor: zoom-in;
    position: relative;

    // Maintain 16x9 aspect ratio while image is loading
    &:before {
        background-color: $color-porcelain;
        display: block;
        content: "";
        width: 100%;
        padding-top: (9 / 16) * 100%;
    }
}

.pf-entry-card-default-image__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.pf-entry-card-default-image__overlay {
    @include position(absolute, 0, 0, 0, 0);
    background-color: $color-black-alpha-24;
    opacity: 0;
    transition: .3s ease opacity;
    z-index: 1;
}

.pf-entry-card-default-image--rounded {
    .pf-entry-card-default-image__overlay,
    .pf-entry-card-default-image__image {
        overflow: hidden;
        border-radius: $card-border-radius $card-border-radius 0 0;
    }
}

// Profile nugget
.pf-entry-card-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.pf-entry-card-profile__avatar {
    // TODO: use avatar-circle mixin
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-porcelain;
    cursor: zoom-in;
}

.pf-entry-card-profile__name,
.pf-entry-card-profile__school {
    @include typography-body();
}

.pf-entry-card-profile__name {
    font-weight: $font-weight-medium;

    a,
    a:hover,
    a:focus {
        color: $color-licorice;
    }
}

.pf-entry-card-profile__school {
    color: $color-oxford;
}

// Profile nugget modifiers
.pf-entry-card-profile--small {
    .pf-entry-card-profile__avatar {
        height: 32px;
        width: 32px;
        min-width: 32px;
        margin-right: 8px;
    }
}

@import './card/standard';
@import './card/feed';
