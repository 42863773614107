@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/typography';

.pf-profile-strength-comp-tips {
    margin-bottom: 8px;
}

.pf-profile-strength-comp-tips__desc {
    @include typography-body();
    font-size: $pf-base-font-size;
    color: $color-oxford;
}

.pf-profile-strength-comp-tip {
    height: 48px;
    @include typography-body();
    font-size: $pf-base-font-size;

    .pf-muted {
        color: $color-ash;
    }

    &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 $color-tiara;
    }
}

.pf-profile-strength-comp-tip__check-outline {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-left: 8px;
    border: 2px solid $color-border-base;
    border-radius: 50%;

    .material-icons {
        color: $color-ash;
    }
}

.pf-profile-strength-comp-tip__check-outline--complete {
    border: 2px solid $color-electric;
    background-color: $color-electric-light-51;

    .material-icons {
        color: $color-electric;
    }
}

.pf-profile-strength-show-more-btn {
    &.md-button.pf-outline {
        margin: 0;
        width: 100%;
        height: 36px;
        line-height: 36px;
    }
}
