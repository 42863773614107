@import '~client/main/sass/base/variables';

.pf-network-connect-member-btn__member {
    display: inline;
}

.pf-network-connect-member-btn__leave {
    display: none;
    color: $color-error;
}

.pf-network-connect-member-btn {
    &.md-button.md-portfolium-theme:not([disabled]).md-focused,
    &.md-button.md-portfolium-theme:not([disabled]):hover {
        background-color: $color-white;

        .pf-network-connect-member-btn__member {
            display: none;
        }

        .pf-network-connect-member-btn__leave {
            display: inline;
        }
    }
}
