@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

$entry-viewer-max-width: 1440px;
$entry-viewer-min-width: 960px;

.pf-entry-viewer-panel {
    overflow: auto;
}

.pf-entry-viewer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: $entry-viewer-min-width;
    padding: 24px 40px;
}

.pf-entry-viewer-panel-active {
    .md-panel._md-panel-backdrop {
        background-color: $color-licorice;
        opacity: .64;
    }
}

pf-entry-viewer {
    display: block;
    overflow: auto;
    position: relative;
    height: 100%;
    min-height: 100%;
    width: 100%;
    max-width: $entry-viewer-max-width;
    margin: 0 auto;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-13dp;
}

.pf-entry-viewer-content {
    min-width: 0;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-1dp;
    z-index: 2;
    padding: 0;
}

.pf-entry-viewer-content-heading {
    @include typography-subhead();
    color: $color-licorice;
    font-weight: $font-weight-medium;
    margin: 0 0 24px;

    pf-blank-field {
        margin: 6px 0;
    }
}

md-content.md-portfolium-theme.pf-entry-viewer-content-inner {
    display: block;
    position: relative;
    padding: 0 48px;
    background-color: $color-white;
}

.pf-entry-viewer-sidebar {
    height: 100%;
    width: 384px;
    max-width: 384px;
    min-width: 384px;
    z-index: 1;
}

.pf-entry-viewer-sidebar-section {
    border-top: 1px solid $color-border-base;
    padding: 24px 0;

    @media (min-width: $md-breakpoint-sm) {
        padding: 24px 24px 32px;
    }
}

.pf-entry-viewer-sidebar-section--first {
    border-top: 0;
}

.pf-entry-viewer-sidebar-section__heading {
    @include typography-subhead();
    color: $color-licorice;
    font-weight: $font-weight-medium;
    margin: 0 0 16px;
}

.pf-entry-viewer-sidebar-avatars {
    margin: -4px;
}

.pf-entry-viewer-sidebar-blank-pill {
    margin: 4px;

    &:nth-child(2) {
        opacity: .8;
    }

    &:nth-child(3) {
        opacity: .6;
    }
}

.pf-entry-viewer-sidebar-avatars__avatar {
    position: relative;
    margin: 4px;
}

.pf-entry-viewer-sidebar-avatars__avatar-more {
    height: 40px;
    width: 40px;
    margin: 4px;
    border-radius: 50%;
    background-color: $color-border-base;
    @include typography-body();
    text-align: center;
    line-height: 40px;
    color: $color-licorice;
    transition: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &.pf-focused {
        outline: none;
        color: $color-white;
        background-color: $color-oxford;
    }
}

.pf-entry-viewer-sidebar-section-pill.pf-pill-picker-pill {
    &:focus:not(.pf-pill-picker-pill--selected),
    &:hover,
    &.pf-focused {
        color: $color-white;
        background-color: $color-oxford;

        .pf-pill-picker-pill__text {
            color: $color-white;
        }
    }
}

.pf-entry-viewer-close.md-button.md-portfolium-theme {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0;

    md-icon {
        color: $color-white;
    }
}

.pf-entry-viewer-blank-attachments-slider {
    padding-top: (9 / 16) * 100%;
    margin-bottom: 56px;
    background-color: $color-porcelain;
    margin-bottom: 56px;
    position: relative;
    width: 100%;
}

.pf-entry-viewer-lock-interaction-wrapper {
    .pf-entry-viewer-lock-interaction {
        pointer-events: none;
    }
}

/** Mobile only styling */
.pf-entry-viewer-mobile-content {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-1dp;
    margin-bottom: 16px;

    > div {
        min-width: 0;
    }
}

.pf-entry-viewer-mobile-content__inner-wrapper {
    width: 100%;
}

.pf-entry-viewer-mobile-content_inner {
    background-color: $color-white;
    padding: 16px 16px 0;
}

.pf-entry-viewer-mobile-content__actions {
    background-color: $color-white;
    border-top: 1px solid $color-border-base;
    padding: 4px 2px;
}

.pf-entry-viewer-mobile-comments-wrapper {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-1dp;
}

.pf-entry-viewer-mobile-extras {
    margin-bottom: 8px;

    .pf-entry-viewer-sidebar-section {
        padding: 24px 16px 32px;
    }
}
