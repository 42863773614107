md-toolbar.pf-navbar.pf-navbar--standard {
    $height: 64px;
    $height-mobile: 56px;
    $color-white-08: $color-white-alpha-16;

    background-color: $color-navbar-base;

    color: $color-ash;

    user-select: none;
    -webkit-user-select: none;

    &.pf-position-fixed {
        z-index: $z-index-navbar;
        position: fixed;
        top: 0;
    }

    .md-toolbar-tools {
        height: $height;
        max-height: $height;
    }

    .pf-navbar-md-toolbar-tools--mobile {
        height: $height-mobile;
        min-height: $height-mobile;
    }

    li.active {
        & > .md-button.pf-navbar-button {
            &:not([disabled]) {
                cursor: default;
                color: $color-white;
                border-bottom: 2px solid $color-electric;
            }
        }
    }

    .md-button {
        &.pf-navbar-button {
            height: $height;
            min-height: $height;
            line-height: $height;
            border-radius: 0;
            padding: 0 16px;
            margin: 0;
            min-width: 64px;
            transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

            &:not([disabled]) {
                color: $color-white;

                &:hover {
                    background-color: transparent;
                    color: $color-white;
                }

                &.md-focused {
                    background-color: $color-white-08;
                    color: $color-white;
                }

                &.pf-active {
                    cursor: default;
                    color: $color-white;
                    border-bottom: 2px solid $color-electric;
                }
            }

            &[disabled] {
                color: $color-ash;
            }
        }

        &.pf-login-button {
            min-width: 80px;
            color: $color-white;

            &:last-child {
                margin-right: 0;
            }
        }

        &.pf-logo-button--talent-match {
            margin-left: initial;

            img {
                margin-top: initial;
                width: 128px;
            }
        }

        &.md-icon-button:not([disabled]) {
            md-icon {
                color: $color-white;
            }

            &:hover,
            &.md-focused,
            &.open {
                md-icon {
                    color: $color-white;
                }
            }

            &.pf-main-nav-button {
                margin-left: -8px;

                md-icon {
                    color: $color-white;
                }
            }
        }
    }

    .pf-navbar-links {
        & > li {
            display: flex;
        }
    }

    pf-sitewide-search {
        display: block;
        width: 100%;
    }

    .pf-search-form {
        position: relative !important;
        width: 100%;

        .pf-search-icon {
            $size: 32px;

            color: $color-white;
            display: block;
            position: absolute;
            top: 2px;
            right: 2px;
            height: $size;
            min-height: $size;
            line-height: $size;
            width: $size;
            padding: 0;
            margin: 0;
        }

        .pf-search-input {
            display: block;
            width: 100%;
        }
    }

    .md-button.pf-avatar-button {
        padding: 0;
        overflow: visible;

        .pf-avatar {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            margin: 2px;
        }

        md-icon {
            position: absolute;
            right: 0;
            top: 8px;
            font-size: 20px;
            line-height: 28px;
        }
    }

    .pf-notification-center.md-button {
            border-radius: 0;
    }

    .pf-notification-center-jewel-wrapper {
        position: absolute;
        right: 0;
        left: 14px;
        top: 5px;
        margin: 0 auto;
        opacity: 1;
        background-color: $color-navbar-base;
        padding: 2px;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        transition: .2s ease opacity;

        &.pf-hide {
            opacity: 0;
        }

    }

    .pf-notification-center-jewel {
        position: relative;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        border-radius: 50%;
        line-height: 0;
        text-align: center;
        background-color: $color-crimson;

        &:before{
          position: absolute;
          content: "";
          height: 10px;
          width: 10px;
          left: 0;
          top: 0;
          background-color: transparent;
          border-radius: 50%;
          box-shadow: 0px 0px 2px 2px $color-crimson;
          -webkit-animation: active 2s infinite linear;
          animation: active 2s infinite linear;
        }
    }

    .pf-toggle-notification-center {
        &.md-button {
            position: relative;
            overflow: visible;
        }
    }

    &.ng-animate {
        .md-button,
        md-icon,
        a {
            transition: none !important; // Disable color change animations when navbar is switching
        }
    }
}

@-webkit-keyframes active{
  0%{
    -webkit-transform:scale(.1);
    opacity:1;
  }
  70%{
    -webkit-transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}

@keyframes active{
  0%{
    transform:scale(.1);
    opacity:1;
  }
  70%{
    transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}
