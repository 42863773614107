body.firefox {
    pf-profile-about-card {
        max-height: 600px;
    }

    &.windows {
        pf-entry-card-standard {
            max-width: 600px;
        }
    }
}
