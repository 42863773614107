
@import '~client/main/sass/base/variables.scss';
@import '~client/main/sass/base/mixins/typography.scss';

pf-pmail-validation {
    display: block;
    height: 100%;

    md-content.pf-pmail-validation {
        background-color: $color-white;
        height: 100%;
        margin: 0;
        width: 100%;
        // IE Fix, scrollbar takes up width
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .md-button.md-icon-button.pf-close-panel-button {
        position: absolute;
        top: 10px;
        right: 8px;
        margin: 0;
    }
}

.pf-pmail-validation__section {
    margin-top: 40px;
}

.pf-pmail-validation__header {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 38px;
}

.pf-pmail-validation__alt-header {
    justify-content: center;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 24px;
    text-align: center;
}

.pf-pmail-validation-header__image {
    min-width: 75px;

    img {
        display: block;
        margin: auto;
    }
}

.pf-pmail-validation__info {
    margin-left: 15px;
}

.pf-pmail-validation-info__title {
    @include typography-body-2();
    color: $color-licorice;
    font-size: 16px;
}

.pf-pmail-validation-info__description {
    @include typography-body();
    color: $color-oxford;
    font-size: 14px;
    line-height: 22px;
}

.pf-pmail-validation__credits {
    @include typography-body-2();
    align-items: center;
    display: flex;
    font-size: 15px;
    width: 100%;
    min-height: 57px;
    padding: 0 16px;
}

.pf-pmail-validation-credits__required {
    color: $color-error;
}

.pf-pmail-validation-credits__valid {
    color: $color-success;
}

.pf-pmail-validation-credits__remaining {
    color: $color-oxford;
}

.pf-pmail-validation__recipient-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px;
}

.pf-pmail-validation__blocked-list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 8px 0;
}

.pf-pmail-validation-recipient {
    display: flex;
    box-shadow: $whiteframe-shadow-z1;
    height: 56px;
    margin: 0 8px 16px 8px;
    padding: 8px;
    width: 232px;

    .md-button.md-icon-button.pf-remove-user-button {
        margin-right: -8px;
    }
}

.pf-pmail-validation-recipient__info {
    max-width: 126px;
    margin-left: 12px;
    width: 100%;
}

.pf-pmail-validation-recipient__name {
    @include typography-body();
    color: $color-licorice;
    font-size: 16px;
    margin: 3px 0;
    width: 100%;
}
