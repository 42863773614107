@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/typography';

.pf-profile-quick-edit-dialog {
    width: 80%;
    height: 92%;
    max-height: 900px;
    max-width: 800px;
    overflow: hidden;

    &.pf-dialog md-dialog-content {
        padding: 0 0 16px;
    }
}

pf-profile-quick-edit {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    overflow: hidden;

    .pf-hero-image {
        &::before {
            background: transparent;
        }
    }
}

.pf-profile-quick-edit {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
}

.pf-profile-quick-edit__content {
    height: 100%;
    position: relative;
    overflow: auto;
}

.pf-profile-quick-edit__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 80px;
    height: 80px;
    padding: 16px;
}

.pf-profile-quick-edit-btn-tooltip {
    &.md-tooltip.md-origin-bottom {
        margin-top: 4px;
    }
}

.pf-profile-quick-edit-hero {
    position: relative;
}

.pf-profile-quick-edit-button__hero.md-button {
    position: absolute;
    right: 16px;
    bottom: 18px;
}

.pf-profile-quick-edit-content {
    padding: 0 24px 24px;
}

.pf-profile-quick-edit-avatar-section {
    position: relative;
}

.pf-profile-quick-edit-avatar {
    margin-top: -48px;
}

.pf-profile-quick-edit-avatar-section__button {
    padding: 6px 0 0 8px;
}

.pf-profile-quick-edit-form-container {
    padding-top: 24px;
}

.pf-field--small-margin.pf-field {
    margin-bottom: 8px;
}

.pf-profile-quick-edit-form-field__2up {
    width: calc(50% - 12px);
}

.pf-profile-quick-edit-field__footer {
    margin-top: 8px;
}

.pf-profile-quick-edit__sublink {
    font-weight: $font-weight-medium;
    line-height: 24px;
}
