@import '~client/main/sass/base/variables';

body > ui-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
}

pf-app,
pf-layout-standard {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;

    & > ui-view {
        display: flex;
        flex-direction: column;
        flex: 1;
        box-sizing: border-box;
        overflow: auto;
    }

    @import './partials/query-loading';
}
