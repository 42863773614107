@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';

md-toast {
    position: fixed !important;
    z-index: $z-index-growl !important;

    .md-toast-content {
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    &.md-top {
        top: 72px;

        @include max-screen(600px) {
            top: 64px;
        }
    }

    &.success-toast {
        .md-toast-content {
            background-color: $color-success;
        }
    }

    &.error-toast {
        .md-toast-content {
            background-color: $color-error;
        }
    }

    .md-button {
        padding: 0 8px;
    }
}

body.md-dialog-is-showing {
    .md-toast.md-portfolium-theme {
        position: fixed;
        z-index: $z-index-dialog + 1;
    }
}

md-toast.md-error-toast-theme {
    .md-toast-content {
        background-color: $color-error;
    }
}

md-toast.md-success-toast-theme {
    .md-toast-content {
        background-color: $color-success;
    }
}
