@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-sitewide-search-mobile {
    max-width: 100%;
    width: 100%;

    .pf-search-form {
        width: 100%;
    }
}

.pf-sitewide-search-mobile__search-bar {
    position: relative;
    background-color: $color-white;
    @include whiteframe-shadow(1);
    z-index: 1;
}

.pf-sitewide-search-mobile__search-input {
    $sitewide-search-mobile-input-height: 56px;

    .pf-search-input-content__action {
        height: $sitewide-search-mobile-input-height;
    }

    .pf-search-input-content__input.pf-form-control {
        height: $sitewide-search-mobile-input-height;
        border-radius: 0;
        background-color: $color-white;
    }
}

.pf-sitewide-search-mobile-panel {
    position: fixed;
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    overflow: hidden;
    background-color: $color-background-base;
}

.pf-sitewide-search-mobile__menu-wrapper {
    overflow: scroll;
}
