@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';
@import '~client/main/sass/base/mixins/background-gradient';

pf-job-apply-center {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    .pf-sidenav-panel__header {
        .md-button {
            margin: -6px -6px 0 0;
        }
    }

    // Cover letter override
    .pf-sidenav-panel__content .pf-field textarea.pf-form-control {
        min-height: 98px;
        height: 98px;
    }

    .pf-job-apply-center__form {
        padding-top: 16px;

        .pf-field {
            padding: 0 16px;
        }
    }

    // Override sidenav panel title, let it wrap
    .pf-sidenav-panel__header h2 {
        white-space: normal;
        text-overflow: inherit;
    }

    .pf-entry-list {
        padding: 0;

        // Loading spinner override
        .pf-panel-sidenav-list__loading {
            margin: 0;
            padding: 16px 0;
        }
    }

    // Override, bottom border is entry list top border
    .pf-panel-sidenav-list-search {
        border-bottom: none;
    }


    .md-list-item-text {
        // TODO Make strength meter into resuable class/mixin
        .pf-strength-meter {
            width: 80px;
            margin: 4px 0;
        }

        .pf-strength-meter-bar {
            width: 100%;
            height: 6px;
            border-radius: 3px;
            background-color: $color-porcelain;
            overflow: hidden;
            position: relative;
            margin: 0;
        }

        .pf-strength-meter-bar-fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 3px;

            @include background-gradient-primary-secondary();
        }
    }
}

.pf-job-apply-center__job-details {
    max-width: 100%;
    width: 100%;
}

.pf-job-apply-center-user {
    align-items: center;
    margin-bottom: 20px;

    a {
        text-align: right;
        font-weight: $font-weight-medium;
        cursor: pointer;
    }
}

.pf-job-apply-center-user__details {
    max-width: 224px;
}

.pf-job-apply-center-user__avatar {
    @include avatar-circle(2px, 80px);
    margin-right: 23px;
    pointer-events: none;
}

.pf-job-apply-center__subhead {
    color: $color-oxford;
    @include typography-subhead();
}

.pf-job-apply-center__subhead--margin-bottom {
    margin-bottom: 16px;
}

.pf-job-apply-center__subhead--bold {
    font-weight: $font-weight-medium;
}

.pf-job-apply-center-empty {
    padding: 48px;
    border-top: 1px solid $color-border-base;

    .pf-subhead {
        font-weight: $font-weight-medium;
    }

    .pf-body {
        margin: 8px 0 0;
        color: $color-oxford;
    }
}
