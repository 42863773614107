@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-description {
    display: block;
}

.pf-entry-viewer-description {
    @include typography-body();
    line-height: 24px;
    font-size: 14px;
}

.pf-entry-viewer-description-blank {
    pf-blank-field {
        margin: 6px 0;
    }
}
