@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-network-result {
    display: block;
    max-width: 100%;
    width: 100%;
}

.pf-network-result-wrapper {
    max-width: 100%;
    padding: 24px;
    border-top: 1px solid $color-border-base;
    background-color: $color-white;

    @include min-screen($md-breakpoint-sm) {
        padding: 24px 0 24px 16px;
    }
}

.pf-network-result__middle {
    min-width: 0;
}

.pf-network-result__left {
    max-width: 100%;
}

.pf-network-result__logo {
    @include avatar-square(2px, 96px);
    margin-right: 16px;
    pointer-events: none;

    @include max-screen($md-breakpoint-sm - 1) {
        @include avatar-square(2px, 64px);
    }
}

.pf-network-result__heading {
    @include typography-subhead();
    margin-right: 16px;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $color-licorice;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 16px;
    }
}

.pf-network-result__title {
    @include typography-body();
    color: $color-electric-dark-24;
    font-size: 14px;
    margin-bottom: 4px;

    // desktop
    @include min-screen($md-breakpoint-md) {
        @include typography-subhead();
        color: $color-licorice;
        margin: 0;
    }
}

.pf-network-result__tagline {
    margin-bottom: 0;
    font-size: 15px;
    color: $color-oxford;
    line-height: 24px;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 14px;
    }
}

.pf-network-result__entries {
    margin-left: 15px;
}

.pf-network-result-connect--btn .md-button {
    margin: 16px 0 0 -16px;
    padding: 0 16px;
}

.pf-network-result-entry__image-wrapper {
    margin-right: 20px;
    width: 140px;
}

.pf-network-result-entry__image {
    position: relative;
    display: block;
    border-radius: 3px;
    cursor: zoom-in;
}

.pf-network-result-entry__image--default {
    box-shadow: $whiteframe-shadow-1dp;
}

.pf-network-result-entry__image--empty {
    border-radius: 3px;
    background-color: $color-porcelain;
    padding-top: (9 / 16) * 100%;
    pointer-events: none;
}

.pf-network-result-entry__image--empty-1 {
    opacity: .8;
}

.pf-network-result-entry__image--empty-2 {
    opacity: .6;
}
