@import '~client/main/sass/base/variables';

pf-balance-meter-bar {
    position: relative;
    display: block;
    height: 8px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    background-color: $color-porcelain;
}

.pf-balance-meter-bar__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
}
.pf-balance-meter-bar__inner--danger {
    background-color: $color-error;
}

.pf-balance-meter-bar__inner--warning {
    background-color: $color-warning;
}

.pf-balance-meter-bar__inner--good {
    background-color: $color-barney;
}
