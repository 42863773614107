@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/media_sizes';

.pf-pathway-requirement-submission-panel-wrapper {
    width: 100%;
    height: 100%;
    max-height: 90%;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
    background-color: $color-white;
    border-radius: 3px;
    box-shadow: $whiteframe-shadow-z5;

    @include max-screen($md-breakpoint-md - 1) {
        max-height: 95%;
        max-width: 97%;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        border-radius: 0;
        max-height: 100%;
        max-width: 100%;
    }
}

.pf-pathway-requirement-submission-panel__toolbar {
    position: relative;
    z-index: 2;
    height: 65px;
    min-height: 65px;
    padding: 14px 24px;
    border-top: 1px solid $color-tiara;

    .md-button {
        margin: 0;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}

.pf-pathway-requirement-submission-panel__empty-results {
    width: 100%;
    height: 90%;
    max-width: 640px;
    max-height: 960px;
    margin: auto;
}
