.pf-dialog.pf-dialog--talent-match--networks-list {
    min-width: 960px;
    max-width: 960px;

    md-toolbar {
        .md-toolbar-tools {
            padding: 0 16px;
        }
    }
}
