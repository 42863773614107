@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-header {
    display: block;
}

.pf-entry-viewer-header {
    margin: 32px 0;
}

.pf-entry-viewer-header-info {
    min-width: 0;
}

.pf-entry-viewer-header-info-title-wrapper {
    margin: 0 0 8px;
    @include typography-headline();
    color: $color-licorice;
    font-weight: $font-weight-medium;
    word-wrap: break-word;

    pf-blank-field {
        margin: 8px 0;
    }
}

.pf-entry-viewer-header-info__title {
    display: inline-block;
}

.pf-entry-viewer-header-info__lozenge {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
}

.pf-entry-viewer-header-info__stats {
    @include typography-body();
    color: $color-licorice;
    font-size: 14px;
    margin: 0;
}

.pf-entry-viewer-header-category {
    margin-left: 8px;

    pf-blank-field {
        margin: 4px 0;
    }
}

.pf-entry-viewer-header-category__divider {
    padding: 0 6px;
}

.pf-entry-viewer-header-actions {
    padding-left: 20px;
}

.pf-entry-viewer__like-button-wrapper {
    &:last-child {
        margin-right: -8px;
    }
}

.pf-entry-viewer__share-button-wrapper {
    margin-right: -20px;
}

.pf-entry-viewer__share-button.md-button {
    padding: 0 12px;

    md-icon.md-portfolium-theme {
        margin: 6px 8px 6px -4px;
    }
}
