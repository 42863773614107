$mobile-menu-panel-width: 320px;

pf-mobile-menu {
    width: 100%;

    md-content.md-portfolium-theme {
        background-color: $color-white;
    }
}

.pf-mobile-menu__scroll-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 24px;
}

.pf-sidenav-panel.pf-sidenav-panel--mobile-menu {
    width: $mobile-menu-panel-width;
    max-width: 90%;
}
