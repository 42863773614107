@import '~client/main/sass/base/mixins/position';
@import '~client/main/sass/base/mixins/size';

pf-user-avatar {
    display: block;

    &.pf-40 {
        @include min-size(40px);
    }

    &.pf-48 {
        @include min-size(48px);
    }

    &.pf-56 {
        @include min-size(56px);
    }

    &.pf-64 {
        @include min-size(64px);
    }

    &.pf-96 {
        @include min-size(96px);
    }

    img {
        border-radius: 0;
        height: 100%;
        width: 100%;
    }

    .pf-user-avatar-image {
        border-radius: 50%;
    }

    .md-button.md-portfolium-theme {
        height: auto;
        width: auto;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        background-color: $color-porcelain;
        @include min-size(100%);

        .md-ripple-container {
            border-radius: 50%;
        }

        .pf-edit-avatar-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            margin-top: -12px;
            margin-left: -12px;
            color: $color-ash;
            transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        &.pf-user-avatar__add {
            background-color: $color-porcelain;
            border: 2px dashed $color-border-base;

            &:not([disabled]) {
                &:hover {
                    background-color: $color-porcelain;
                }

                &.md-focused {
                    background-color: $color-porcelain;
                }
            }
        }

        &.pf-user-avatar__edit {
            position: relative;

            &:after {
                content: '';
                @include position(absolute, 0, 0, 0, 0);
                z-index: 1;
                border-radius: 50%;
                transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
            }

            .pf-edit-avatar-icon {
                opacity: 0;
                color: $color-white;
            }

            &:not([disabled]) {
                &:hover,
                &.md-focused {
                    &:after {
                        background-color: $color-black-alpha-48;
                    }

                    .pf-edit-avatar-icon {
                        opacity: 1;
                    }
                }
            }

            img {
                margin-left: 0;
            }
        }

        md-icon {
            color: $color-ash;
        }
    }
}
