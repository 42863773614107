/* tweaks for the entry withn a modal */
.pf-dialog--entry-details {
    .entry-detail {
        .segment-sidebar {
            section {
                .likers {
                    max-height: 250px;

                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.modal {

    .modal-inner {
        margin-top: 3em;
        max-height: 94% !important;
        overflow-x: hidden;
    }

    .segment {
        &.fixed-width {
            max-width: 755px;
        }
    }

    .entry-detail {

        .edit-wrapper {
            right: 20px;
            top: 44px;
        }

        .entry-body {
            h1 {
                margin: 0;
                padding: 0 0 10px 0;
            }

            .profile {
                margin: 0 20px 0 0;
            }

            .tags,
            .description,
            .collaborators,
            .likers,
            .pf-file-attachments {
                margin: 50px 20px 20px 0;
            }

            .flexslider-wrapper {
                margin: 0 20px 20px 0;
            }

            .copyright {
                padding: 0;
            }
        }

        .segment-sidebar {
            top: 0;
            right: -30px;
            position: absolute;

            section {
                &:first-child {
                    margin-top: 0;
                }

                .likers,
                .collaborators {
                    max-height: 130px;
                }
            }
        }

        @include max-screen($mobile) {

            .edit-wrapper {
                right: 60px;
                top: 64px;
            }

            &.segment {
                width: auto;
                margin-top: 0;

                article {
                    width: auto;
                    float: none;
                    margin: 0;
                    padding: 0;
                }

                .segment-sidebar {
                    float: none;
                    width: auto;
                    height: auto;
                    /* this is to allow of the scroll to top button */
                    margin-bottom: 60px;
                    position: relative;

                    section {
                        width: auto;
                    }

                    .fixed {
                        position: relative;
                    }
                }
            }
        }
    }
}
