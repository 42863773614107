@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/position';

// Standalone page styles
body.c_auth.a_wizard,
.pf-app.pf-app--auth,
.pf-ng-app.pf-app--auth {
    background-color: $color-background-base;
    color: $color-oxford;

    md-content {
        background-color: $color-background-base;
    }

    .pf-simple-sign-in {
        @include min-screen($md-breakpoint-md) {
            margin: 96px auto 0;
        }
    }

    .pf-disclaimer-links {
        @extend .pf-body;
        color: $color-ash;
        margin: 40px 0 96px;
        text-align: center;

        a {
            color: $color-oxford;
            font-weight: $font-weight-medium;
            padding: 0 2px;

            &:hover, &:focus {
                color: $color-oxford;
            }
        }
    }

    @import './sass/header';
    @import './sass/footer';
}

// Dialog specific styles
md-dialog.md-portfolium-theme.pf-dialog--simple-sign-in {
    background-color: transparent;
    color: $color-ash;
    min-width: 400px;
    border-radius: 8px;

    // Make dialog full size at small breakpoint only
    @include max-screen($md-breakpoint-sm - 1) {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        min-width: 0;
        border-radius: 0;

        md-dialog-content {
            height: 100%;
        }

        .pf-simple-sign-in {
            height: 100%;
        }
    }
}

// Universal partials
@import './sass/simple-sign-in';
@import './sass/animations';
