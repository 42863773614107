.pf-pills {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;

    & > li {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;

        @include max-screen($mobile) {
            &:nth-child(n+6) {
                display: none;
            }
        }

        & > .pf-pill {
            height: 32px;
            line-height: 32px;
            padding: 0 12px;
            margin: 4px 8px 4px 0;
            min-height: 0;
            min-width: 0;
            border-radius: 16px;
            background-color: $color-porcelain;
            color: $color-oxford;
            transition: none;
            display: block;
            font-family: $base-font-family;
            font-weight: $font-weight-regular;
            font-size: 15px;
            position: relative;
            text-transform: none;
            text-overflow: ellipsis;
            max-width: 390px;

            @include max-screen(425px) {
                max-width: 335px;
            }
            @include max-screen(375px) {
                max-width: 285px;
            }
            @include max-screen(320px) {
                max-width: 230px;
            }



            &.pf-icon-pill {
                padding: 0 36px 0 12px;

                .pf-pill-icon {
                    position: absolute;
                    top: 4px;
                    right: 8px;
                }
            }
        }

        & > a.pf-pill,
        & > button.pf-pill,
        & > .md-button.md-portfolium-theme {
            &:not([disabled]) {
                &:hover,
                &.md-focused {
                    background-color: $color-tiara;
                }
            }
        }
    }
}
