@keyframes flickerAnimation {
    0% {
        opacity: .40;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: .40;
    }
}

.pf-loading-shell-container--loading {
    pf-blank-field {
        animation: flickerAnimation 2s ease infinite;
    }
}
