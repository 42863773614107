// Base
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

.pf-error-pages {
    .pf-container {
        margin: 0 auto;
        padding: 0 24px 32px;
        max-width: 1032px;

        // tablet
        @include screen($md-breakpoint-sm, $md-breakpoint-md - 1) {
            padding: 0 72px 64px;
        }

        // phone
        @include max-screen($md-breakpoint-sm - 1) {
            padding: 0 16px 64px;
        }
    }

    .pf-footer {
        border-top: 0;
    }
}

.pf-error-pages-header {
    padding: 56px 0 40px;
    box-shadow: inset 0 -1px 0 0 $color-border-base;

    // mobile
    @include max-screen($md-breakpoint-sm - 1) {
        padding: 24px 0;
    }
}

.pf-error-pages-header__title {
    color: $color-licorice;
    margin-bottom: 2px;

    @include typography-display-5();

    // mobile
    @include max-screen($md-breakpoint-sm - 1) {
        margin-bottom: 4px;

        @include typography-display-1();
        font-weight: $font-weight-bold;
    }
}

.pf-error-pages-header__subtitle {
    color: $color-oxford;
    margin: 0;

    @include typography-headline();
    font-weight: $font-weight-medium;
    line-height: 28px;

    // mobile
    @include max-screen($md-breakpoint-sm - 1) {
        @include typography-title();
        font-weight: $font-weight-medium;
        line-height: 24px;
    }
}

.pf-error-pages-body {
    padding: 40px 0;

    // mobile
    @include max-screen($md-breakpoint-sm - 1) {
        padding: 24px 0;
    }
}

.pf-error-pages-body__content {
    color: $color-licorice;
    max-width: 680px;

    @include typography-subhead-2();
    letter-spacing: 0.5px;

    p:last-child {
        margin: 0;
    }
}

.pf-error-pages-actions {
    .md-button {
        margin: 0;
    }
}
