/**
 * @description
 * Include default styling for a square avatar
 *
 * ```
 * <div class="your-avatar-class">
 *     <img class="your-avatar-class__image" src="">
 * </div>
 * ```
 *
 * @param {Size in Units} Padding
 * @param {Size in Units} Desktop size
 * @param {Size in Units} Mobile size
 */
@mixin avatar-square($padding, $size, $bgColor: $color-background-base) {
    position: relative;
    display: block;
    height: $size + ($padding * 2);
    width: $size + ($padding * 2);
    min-height: $size + ($padding * 2);
    min-width: $size + ($padding * 2);
    padding: $padding;
    border-radius: 3px;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto;
        width: $size - 2;
        height: $size - 2;
        background-color: $bgColor;
        content: "";
    }

    img {
        position: relative;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        overflow: hidden;
        border-radius: 2px;
    }
}

@mixin avatar-circle($padding, $size, $bgColor: $color-background-base) {
    @include avatar-square($padding, $size, $bgColor);

    border-radius: 50%;
    overflow: hidden;

    &:before {
        border-radius: 50%;
    }

    img {
        border-radius: 50%;
    }
}

@mixin avatar-circle-with-border($padding, $size, $bgColor: $color-background-base) {
    @include avatar-circle($padding, $size, $bgColor);

    border: 3px solid $color-white;
    padding: 0;

    &:before {
        width: $size;
        height: $size;
    }
}
