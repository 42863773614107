.pf-spotlight-panel {
    position: relative;
    padding: 24px;
    background: $color-white;
    border-radius: $base-border-radius;
    max-width: 640px;

    @include max-screen($md-breakpoint-sm - 1) {
        max-width: 90%;

        button {
            padding: 0 5px;
            font-size: 12px;
        }
    }
}

.pf-spotlight-panel-title {
    margin-bottom: 22px;
    font-weight: $font-weight-medium;

    md-icon {
        color: $color-fire;
        margin-right: 16px;
    }
}

.pf-spotlight-panel-body {
    @include typography-body();
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
}

.pf-spotlight-panel-actions {
    .md-button {
        margin: 0;
    }

    .md-button:last-child {
        margin-left: 12px;
    }
}
