@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-entry-viewer-contest {
    & + .pf-entry-viewer-contest {
        margin-top: 16px;
    }
}

.pf-entry-viewer-contest__image {
    border-radius: 2px;
}

.pf-entry-viewer-contest__info {
    margin-left: 16px;
}

.pf-entry-viewer-contest__name {
    @include typography-subhead();
    color: $color-licorice;
}

.pf-entry-viewer-contest__description {
    color: $color-oxford;
}

.pf-entry-viewer-contest__vote {
    margin: 8px 0 0;

    .md-button.md-portfolium-theme {
        margin: 0 0 0 -8px;
    }
}
