.pf-panel-sidenav-list-search__icon {
    position: absolute;
    top: 16px;
    right: 16px;

    &.material-icons {
        color: $color-tiara;
    }

    &.md-button.md-icon-button {
        top: 8px;
        right: 8px;
        margin: 0;
    }

    // Only animate the search icon and close button -- not the loading spinner
    &.material-icons,
    &.md-button.md-icon-button {
        &.ng-enter {
            transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
            transform: scale(0);

            &.ng-enter-active {
                transform: scale(1);
            }
        }

        &.ng-leave {
            transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
            transform: scale(1);

            &.ng-leave-active {
                transform: scale(0);
            }
        }
    }
}

.pf-panel-sidenav-list-search {
    position: relative;
    border-bottom: 1px solid $color-border-base;
}

.pf-panel-sidenav-list-search__search {
    &.pf-form-control {
        border: 0;
        padding: 18px 64px 18px 16px;
        height: 56px;
        border-radius: 0;
        width: 100%;
    }
}

.pf-panel-sidenav-list__loading {
    margin: 16px 0;
}

.pf-panel-sidenav-list__load-more {
    padding: 10px 16px;

    .md-button.md-portfolium-theme {
        padding: 0 8px;
        margin: 0;
        width: 100%;
    }
}
