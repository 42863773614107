.pf-dialog--white {
    md-toolbar.md-portfolium-theme {
        border-bottom: 1px solid $color-tiara;
        background-color: $color-white;
    }

    .md-toolbar-tools {
        h2 {
            color: $color-licorice;
        }

        .md-button {
            md-icon {
                color: $color-ash;
            }
        }
    }
}
