@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-pathway-requirement-submission-panel-list {
    max-height: 100%;
    height: 100%;
}

.pf-pathway-requirement-submission-panel-list__title {
    padding: 0 0 22px;
    color: $color-licorice;

    @include typography-subhead();
    font-weight: $font-weight-bold;
    font-size: 20px;
}

.pf-pathway-requirement-submission-panel-list__search {
    margin-bottom: 16px;
}

.pf-pathway-requirement-submission-panel-list__items-spinner {
    width: 100%;
    margin-top: 24px;
}

.pf-pathway-requirement-submission-panel-list__search-input {
    display: block;
}

.pf-pathway-requirement-submission-panel-list__elements {
    margin: 0 -12px;

    &--empty > div {
        padding: 12px;
    }
}

.pf-pathway-requirement-submission-panel-list__item {
    padding: 0 4px 2px;
}

.pf-pathway-requirement-submission-panel-list__item-blank {
    width: 100%;
}

.pf-pathway-requirement-submission-panel-list__item-blank-image {
    position: relative;
    width: 100%;
    padding-top: 56.52%;

    pf-blank-field {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.pf-pathway-requirement-submission-panel-list__item-blank-text {
    margin-top: 12px;
}

md-content.md-portfolium-theme.pf-pathway-requirement-submission-panel-list__items-row {
    margin: 0;
    background-color: $color-white;
    padding: 22px 24px;
}

.pf-pathway-requirement-submission-panel-list__items-load-more {
    width: 100%;
    padding: 0 12px;
    margin-top: 24px;
}

.pf-pathway-requirement-submission-panel-list__items-margin-bottom {
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    width: 100%;
}
