@import '~client/main/sass/base/variables';

.pf-comparison-slider {
    min-width: 832px;
    min-height: 616px;
    overflow: hidden;

    .pf-comparison-slider-left {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        z-index: 2;
        overflow: hidden;
    }

    .pf-comparison-slider-right {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    .pf-comparison-slider-handle {
        position: absolute;
        top: 0;
        left: 285px;
        bottom: 0;
        width: 30px;
        z-index: 3;
        cursor: ew-resize;

        .pf-handle {
            width: 4px;
            height: 680px;
            background: $color-electric;
            position: absolute;
            top: -32px;
            left: 12px;
            border-radius: 2px;
            z-index: 2;

            &:after {
                position: absolute;
                z-index: 1;
                width: 5px;
                height: 640px;
                top: 0;
                left: 2px;
                content: '';
                background-color: $color-porcelain;
                border-radius: 0 2px 2px 0;
            }

            .pf-grab {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                background-color: $color-electric;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                color: $color-white;
                text-align: center;
                line-height: 48px;
                margin-left: -24px;
                margin-top: -24px;

                md-icon {
                    color: $color-white;
                }
            }
        }
    }
}
