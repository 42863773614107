md-icon.md-portfolium-theme,
.material-icons {
    transition: 0.2s ease color;
    vertical-align: middle;

    &.md-14 {
        height: 14px;
        width: 14px;
        font-size: 14px;
        min-height: 14px;
        min-width: 14px;
    }

    &.md-16 {
        font-size: 16px;
        height: 16px;
        width: 16px;
        min-height: 16px;
        min-width: 16px;
    }

    &.md-18 {
        font-size: 18px;
        height: 18px;
        width: 18px;
        min-height: 18px;
        min-width: 18px;
    }

    &.md-24 {
        height: 24px;
        width: 24px;
        font-size: 24px;
        line-height: 24px;
        min-height: 24px;
        min-width: 24px;
    }

    &.md-32 {
        height: 32px;
        width: 32px;
        font-size: 32px;
        line-height: 32px;
        min-height: 32px;
        min-width: 32px;
    }

    &.md-40 {
        height: 40px;
        width: 40px;
        font-size: 40px;
        line-height: 40px;
        min-height: 40px;
        min-width: 40px;
    }

    &.md-48 {
        height: 48px;
        width: 48px;
        font-size: 48px;
        line-height: 48px;
        min-height: 48px;
        min-width: 48px;
    }

    &.md-64 {
        height: 64px;
        width: 64px;
        font-size: 64px;
        line-height: 64px;
        min-height: 64px;
        min-width: 64px;
    }
}

.ng-cloak,
[ng-cloak] {
    md-icon {
        text-indent: -9999px;
        overflow: hidden;
    }
}
