@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

.pf-job-list-card__list {
    margin: -12px;
}

.pf-job-list-card-list-item {
    padding: 12px;
}

.pf-job-list-card-list-item__inner {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
}

.pf-job-list-card__list--blank {
    padding: 0 16px;
}
