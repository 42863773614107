.pf-dialog--user-connect {
    min-width: 600px;

    md-input-container.md-portfolium-theme {
        margin: 0;

        .md-input.pf-form-control {
            border: 0 !important;
            padding: 0;
        }
    }

    md-dialog-actions {
        md-switch {
            margin: 0;
        }
    }
}
