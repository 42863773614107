/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: https://material.io/design/typography/the-type-system.html#type-scale
 *
 *************************************************************************************
 * IMPORTANT *************************************************************************
 *************************************************************************************
 * If you add/remove classes from here, make sure you also update the constants here:
 * core/constants/text-styles.constant.js
 *************************************************************************************
 */

/////////////////////////////////////////////
// Text color modifiers
/////////////////////////////////////////////

.pf-text--color-primary {
    color: $color-electric;
}

.pf-text--color-black {
    color: $color-licorice;
}

.pf-text--color-white {
    color: $color-white;
}

.pf-text--color-error {
    color: $color-error;
}

/////////////////////////////////////////////
// Text emphasis modifiers
/////////////////////////////////////////////

// high emphasis
.pf-text--emphasis-high {
    color: $color-licorice;

    &.pf-text--color-black {
        color: $color-licorice;
    }
}

// medium emphasis
.pf-text--emphasis-medium {
    color: $color-oxford;

    &.pf-text--color-black {
        color: $color-oxford;
    }
}

// low emphasis
.pf-text--emphasis-low {
    color: $color-ash;

    &.pf-text--color-black {
        color: $color-ash;
    }
}

// colors that have no emphasis
.pf-text--emphasis-medium,
.pf-text--emphasis-high {
    &.pf-text--color-primary {
        color: $color-electric;
    }

    &.pf-text--color-white {
        color: $color-white;
    }

    &.pf-text--color-error {
        color: $color-error;
    }
}
