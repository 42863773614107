.pf-input-group {
    position: relative; // For dropdowns
    display: table;
    border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

    .pf-form-control {
        // Ensure that the input is always above the *appended* addon button for
        // proper border colors.
        position: relative;
        z-index: 2;

        // IE9 fubars the placeholder attribute in text inputs and the arrows on
        // select elements in input groups. To fix it, we float the input. Details:
        // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
        float: left;

        width: 100%;
        margin-bottom: 0;
    }
}

// Display as table-cell
.pf-input-group-addon,
.pf-input-group-btn,
.pf-input-group .form-control {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

// Addon and addon wrapper for buttons
.pf-input-group-addon,
.pf-input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}

// Text input groups
.pf-input-group-addon {
    padding: 5px;
    font-size: $pf-base-font-size;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    background-color: $color-white;
    border: 2px solid $color-form-border;
    border-radius: $pf-base-border-radius;

    md-icon {
        transition: none;
    }
}

// Reset rounded corners
.pf-input-group .pf-form-control:first-child,
.pf-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.pf-input-group .pf-form-control:last-child,
.pf-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
