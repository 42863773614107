@import '~client/main/sass/base/mixins/background-gradient';

.pf-company-card--standard {
    .pf-avatar {
        $size: 80px;
        $borderWidth: 3px;

        position: absolute;
        bottom: -(($size / 2) + $borderWidth);
        left: 50%;
        margin-left: -(($size / 2) + $borderWidth);
        z-index: 1;
        background-color: $color-porcelain;
        border-radius: 3%;
        height: $size + ($borderWidth * 2);
        width: $size + ($borderWidth * 2);
        border: $borderWidth solid $color-white;
        overflow: hidden;

        a {
            display: block;

            &:focus {
                img {
                    opacity: .7;
                }
            }
        }
    }

    .pf-company-details {
        margin: 52px 16px 16px;
        text-align: center;
    }

    .pf-detail--name {
        @extend .pf-subhead;
        line-height: 20px;
        font-weight: $font-weight-medium;
        margin: 0 0 8px;
        display: block;
        color: $color-licorice;
    }

    .pf-detail--industry {
        @extend .pf-body;
        margin: 0 0 8px;
        color: $color-ash;
    }

    .pf-detail--tagline {
        @extend .pf-body;
        color: $color-ash;
    }

    .pf-actions {
        padding: 0 10px 16px;
    }
}

.pf-company-card-cover-standard-photo {
    @include position(absolute, 0, 0, 0, 0);

    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 1;
}

.pf-company-card-cover-standard-photo--default {
    @include background-gradient-primary-secondary();
}
