/*
1. !Base
2. !Navigation
3. !Layout
4. !Footer
5. !Forms
6. !Avatars
7. !Widgets
8. !Authenticated Overrides
9. !Utilities
*/

/* ===============
   !Base
   =============== */

html,
body {
    font-weight: $base-font-weight;
    height: 100%;
    margin: 0;
    padding: 0;
}

body.act-as {
    border: 10px solid $color-electric;

    &:not(.subscriber) {
        .pf-navbar--standard {
            top: 10px;
        }
    }
}

header {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    z-index: 3;

    &.is-fixed {
        position: fixed;
    }
}

ol {
    &.decimal {
        list-style-type: decimal;
        margin: 20px 0;

        li {
            margin-left: 20px;
        }
    }
}

ul {
    &.dot {
        list-style-type: disc;
        margin: 20px 0;

        li {
            margin-left: 20px;
        }
    }
}

img {
    border: 0;
}

a[data-href] {
    cursor: pointer;
}

hr.gradient {
    background-image: -webkit-linear-gradient(
        left,
        $color-licorice,
        $color-tiara,
        $color-licorice
    );
    border: 0;
    height: 1px;
    margin: 38px 0;
}

i {
    span {
        display: none;
    }
}

pre {
    background-color: $color-porcelain;
    border: 1px solid $color-tiara;
    border-radius: 5px;
    padding: 20px;
}

/* ===============
   Heros
   =============== */

.hero {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 80px 0;
    position: static;
    min-height: 340px;

    .hero-logo img {
        height: 3em;
        margin-bottom: 2.5em;
    }

    .avatar-spinner {
        text-align: center;
        font-size: 68px;
        color: $color-white;
        padding-left: 60px;
        opacity: 0.6;
    }

    .hero-inner {
        @include clearfix;
        padding-bottom: 0;
        margin: auto;
        text-align: center;
        color: $color-white;

        .btn {
            margin-top: 40px;
            font-size: 22px;
            font-weight: 600;
            padding: 17px 35px;
        }

        .hero-copy {
            margin-top: 40px;
            text-align: center;

            &.pad {
                margin: 100px 0;
            }

            h1 {
                @include typography-display-3();
                margin-bottom: 20px;
                text-shadow: 0 1px 10px $color-oxford;

                @include max-screen($mobile) {
                    @include typography-display-1();
                }

                sup {
                    color: $color-electric;
                }
            }

            p {
                @include typography-headline();
                margin: auto;
                margin-bottom: 0;
                text-shadow: 0 1px 10px $color-oxford;

                @include max-screen($mobile) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }

    @include max-screen(1500px) {
        background-size: 1500px auto; /* Force the image to its minimum width */
    }

    @include max-screen($mobile) {
        background-size: $mobile auto; /* Force the image to its minimum mobile width */
        min-height: 220px;
        padding: 60px 0 0;

        .hero-inner {
            padding: 0 30px;

            .hero-copy {
                margin-top: 0;

                h1 {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 26px;
                }
            }
        }
    }
}

/* ===============
   !Layout
   =============== */

md-content {
    &.main {
        width: 100%;
        background-color: $color-porcelain;
    }
}

.main {
    transition: transform 0.4s ease-in-out;

    // only add transform when we animate or else it breaks fixed position css images bg's
    &.animation {
        transform: translateZ(0);
        backface-visibility: hidden;
        @include clearfix;
    }

    &.vertical {
        top: 0;
        height: 100%;
        padding: 0;

        &:before {
            display: none;
        }

        .bottom-gray,
        .bottom {
            @include min-screen($desktop) {
                padding-left: $split-sidebar-width + 10;
                width: 99%;
            }

            @include max-screen($mobile-small) {
                li {
                    margin-bottom: 10px;
                }

                a {
                    float: none;
                }

                .float-right {
                    display: none;
                }
            }
        }
    }

    // this slides the page to expose the footer sidebar
    &.lateral-menu-is-open {
        transform: translateX(-260px);
    }
    &.large-head {
        padding: 0;

        &:before {
            display: none;
        }
    }
}

/* ===============
   !Filepicker
   =============== */

.picker,
.resume-picker {
    display: block !important;
}

#filepicker_shade,
#avpw_holder {
    z-index: $z-index-filepicker !important;
}

#avpw_holder {
    position: relative;

    .avpw .avpw_text_input {
        box-sizing: inherit;
        margin-bottom: 0;
    }
}

/* ===============
   !Forms
   =============== */

// depreciated, only used in Messages modal
.flash-box {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    border-radius: $base-border-radius;

    button,
    button[type='button'] {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: $color-licorice;
        padding: 10px 16px;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        color: $color-ash;

        &:hover,
        &:focus {
            color: $color-oxford;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.growl {
        background: $color-white;
        box-shadow: $whiteframe-shadow-z3;
        color: $color-licorice;
        cursor: pointer;
        font-weight: 200;
        margin: 0;
        padding: 0;
        position: fixed;
        left: 50%;
        margin-left: -150px;
        text-align: center;
        top: 0;
        width: 300px;
        z-index: $z-index-growl;
        border-radius: 0 0 $base-border-radius $base-border-radius;

        span,
        strong {
            display: inline-block;
            height: 100px;
        }

        span {
            font-size: 80px;
            line-height: 110px;
            font-weight: 200;
            position: relative;
            vertical-align: middle;
            width: 70px;
        }

        strong {
            font-size: 36px;
            font-weight: 200;
            line-height: 110px;
            margin-left: 15px;
            text-transform: capitalize;
            vertical-align: top;
        }

        p {
            font-size: 18px;
            padding: 20px 20px 30px;
        }

        &.notice span:before {
            color: #c5b358; // depreciated, flash box class will be deleted
            content: '\f071';
            font-family: FontAwesome;
        }

        &.success span:before {
            color: $color-shamrock;
            content: '\f058';
            font-family: FontAwesome;
        }

        &.error span:before {
            color: $color-crimson;
            content: '\f057';
            font-family: FontAwesome;
        }
    }
}

.has-error {
    color: $color-error !important;

    input {
        &:focus {
            border-color: $color-error !important;
        }
    }
}

.error {
    background: $color-crimson-light-49;
    color: $color-crimson-dark-16;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 10px 36px 10px 16px;

    a {
        color: $color-crimson-dark-16;
    }
}

/* ===============
   !Utilities
   =============== */

/* total hack for dropdown */
body {
    &:not(.pf-app) {
        md-backdrop {
            position: fixed;
        }
    }
}

.no-animate {
    // Use this for transitions
    &.ng-enter,
    &.ng-leave,
    &.ng-animate {
        -webkit-transition: none !important;
        transition: none !important;
    }
    // Use this for keyframe animations
    &.ng-animate {
        -webkit-animation: none 0s;
        animation: none 0s;
    }
}

.spinner {
    animation: rotateplane 1.2s infinite ease-in-out;
    background-color: $color-licorice;
    height: 30px;
    margin: 100px auto;
    width: 30px;
}

#infscr-loading {
    background: $color-tiara;
    border-radius: $base-border-radius * 2;
    bottom: 11px;
    display: block;
    left: 47%;
    padding: 5px 5px 0 5px;
    position: fixed;

    img {
        width: 20px;
        height: 20px;
    }
}

.inf-card-spinner {
    bottom: 0;
    position: absolute;
    width: 100%;

    hr {
        background-image: -webkit-linear-gradient(
            left,
            transparent,
            $color-tiara,
            transparent
        );
        border: 0;
        height: 1px;
        margin: 38px 0;
    }

    div {
        background-color: $color-porcelain;
        color: $color-oxford;
        left: 50%;
        margin-left: -24px;
        padding: 10px;
        position: absolute;
        top: 12px;
        z-index: 2;
    }
}

.segment {
    @include clearfix;

    .inf-card-spinner {
        div {
            background-color: $color-white;
        }
    }
}

#salesforce_account_uuid__c,
label[for='salesforce_account_uuid__c'] {
    display: none;
}

.inf-messages-spinner,
.inf-notifications-spinner {
    display: block;
    position: absolute;
    top: 12px;
    left: 50%;
    margin-left: -7px;
}

.inf-comments-spinner {
    color: $color-licorice;
    display: block;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
    left: auto;
}

#codeigniter_profiler {
    pre {
        word-break: break-all;
        word-wrap: break-word;
    }
}

.overflow-hidden:not(.md-dialog-is-showing) md-content.main {
    overflow: hidden;
}
.center {
    text-align: center;
}
.hide-all {
    display: none !important;
}
.highlight {
    background-color: $color-electric-light-51;
}
.bold {
    font-weight: bold;
}
.capitalize {
    text-transform: uppercase;
}
.clearfix {
    @include clearfix;
}

hr.fade {
    background-image: -webkit-linear-gradient(
        left,
        transparent,
        $color-tiara,
        transparent
    );
    background-image: -moz-linear-gradient(
        left,
        transparent,
        $color-tiara,
        transparent
    );
    background-image: -ms-linear-gradient(
        left,
        transparent,
        $color-tiara,
        transparent
    );
    background-image: -o-linear-gradient(
        left,
        transparent,
        $color-tiara,
        transparent
    );
    border: 0;
    clear: both;
    height: 1px;
    margin: 20px 0 0;
}

.no-transition {
    transition: none !important;
}
.fixed {
    position: fixed;
}

.mar-top-10 {
    margin-top: 10px !important;
}
.mar-top-20 {
    margin-top: 20px !important;
}
.mar-top-30 {
    margin-top: 30px !important;
}
.mar-top-50 {
    margin-top: 50px !important;
}
.mar-bot-20 {
    margin-bottom: 20px !important;
}
.mar-bot-50 {
    margin-bottom: 50px !important;
}
.pad-30 {
    padding: 30px !important;
}
.pf-pointer {
    cursor: pointer;
}
.wrap {
    display: table-cell;
    float: left;
    margin: 0;
    width: 99%;
    word-break: break-word;
    word-wrap: break-word;
}
.wrap-basic {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    min-width: 0;
}

.wrap-advanced {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: keep-all;
    word-break: break-word;

    min-width: 0;
}

[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-variable-width {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;

    & > * {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.empty-page {
    margin: 50px auto;
    text-align: center;
    width: 100%;

    img {
        height: 140px;
        margin-bottom: -70px;
        width: 140px;
    }

    .empty-speech {
        background: $color-white;
        width: 60%;
        min-width: 280px;
        padding: 80px 30px;
        border-radius: $base-border-radius;
        margin: 0 auto;
        box-shadow: 0 1px 2px $color-tiara;
    }

    p {
        margin-top: 15px;
        font-size: 18px;
        line-height: 26px;

        @include max-screen($mobile) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &.white-bg {
        margin-top: 0;

        .empty-speech {
            box-shadow: none;
            padding: 40px 0 0;
        }
    }
}

/** WCAG **/
.for-screenreader {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.a11y-navigation {
    display: inline-block;
    padding: 12px 8px;

    &:focus-within {
        left: 0;
        top: 0;
        height: auto;
        width: auto;
    }

    .md-button {
        margin: 0;
        z-index: $z-index-modal;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}

.a11y-content {
    height: 0;
    margin-top: 64px;
    position: absolute;
}

/* ===============
   Progress Bar Phases
   =============== */

.pf-progress-bar-fill--20-percent {
    background-color: $color-crimson;
}

.pf-progress-bar-fill--40-percent {
    background-color: $color-fire;
}

.pf-progress-bar-fill--60-percent {
    background-color: $color-electric;
}

.pf-progress-bar-fill--80-percent {
    background-color: $color-shamrock;
}

.pf-progress-bar-fill--100-percent {
    background-color: $color-shamrock-dark-16;
}

.width-fix {
    min-width: 0;
}

.height-fix {
    min-height: 0;
}

.md-panel-inner-wrapper:has(.pf-sidenav-panel) {
    height: 100% !important;
}
