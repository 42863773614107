.pf-pmail-card--feed {
    $height: 96px;

    background-color: $color-white;
    border: 1px solid $color-white-alpha-16;
    box-shadow: none;
    height: $height;

    .pf-avatar {
        background-color: $color-white-alpha-16;
        box-shadow: 1px 0 0 0 $color-white-alpha-16;
        height: $height;
        min-width: $height;
        width: $height;

        a,
        md-icon {
            display: block;
            width: 100%;

            &.general-message {
                font-size: 48px;
                margin-left: 25px;
                margin-top: 23px;
                opacity: 0.5;
            }
        }

    }

    .pf-pmail-details {
        margin: 14px 16px 0;
        position: relative;

        @include min-screen($md-breakpoint-sm) {
            margin: 14px 0 14px 16px;

            &:after {
                content: '';
                bottom: -11px;
                height: 57px;
                left: 0;
                position: absolute;
                right: 0;
                @include linear-gradient(transparent, $color-white);
            }
        }
    }

    .pf-detail--header {
        @extend .pf-body;
        font-weight: $font-weight-medium;
        display: block;
    }

    .pf-detail--sub-header {
        @extend .pf-body;
        color: $color-ash;
    }

    .pf-actions {
        padding: 8px;

        .md-button {
            margin: 0;
            min-width: 0;
        }
    }
}
