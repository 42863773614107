@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/position';

pf-read-more {
    display: block;
    position: relative;
}

.pf-read-more-content {
    width: 100%;
    white-space: pre-line;
}

.pf-read-more-content--visible {
    padding-bottom: 75px;
}

.pf-read-more-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    height: 56px;
    border-top: 1px solid $color-form-border;
    background-color: $color-white;
    margin-top: 8px;
    z-index: 2;
}

.pf-read-more-content--hidden {
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:after {
        width: 100%;
        content: '';
        height: 270px;
        @include position(absolute, none, 0, 0, 0);
        background-image: linear-gradient(0deg, $color-white 25%, $color-white-alpha-48 40%, transparent 100%);
    }
}
