@import './sidenav';
@import './modal';
@import './panel-sidenav-badge-list';
@import './panel-sidenav-entry-list';
@import './panel-sidenav-list';
@import './panel-sidenav-search';

// Base styles for panels
.pf-panel {
    background-color: $color-white;
    color: $color-licorice;
    @include whiteframe-shadow(10);

    md-toolbar.md-portfolium-theme:not(.md-menu-toolbar) {
        background-color: $color-white;
        color: $color-licorice;

        md-icon {
            color: $color-ash;
            fill: $color-ash;
        }
    }
}

.pf-panel--rounded {
    border-radius: $base-border-radius;
}

// Override panel backdrop color
.md-panel._md-panel-backdrop {
    background-color: $color-ash;
}
