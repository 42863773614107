@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-attachment-viewer {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.pf-attachment-viewer__container,
.pf-attachment-viewer__container .pf-attachment-viewer {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-licorice;
}

.pf-attachment-viewer-inner,
.pf-attachment-viewer-wrapper {
    height: 100%;
}

.pf-attachment-viewer-wrapper__audio .mejs__audio,
.pf-attachment-viewer-wrapper__audio audio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pf-attachment-viewer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    padding: 0 24px;
}

.pf-attachment-viewer-site-pill {
    @include typography-body-2();
    height: 40px;
    min-height: 40px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: $font-weight-medium;
    background-color: $color-white;
    color: $color-licorice;
    margin: 6px 4px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    width: fit-content;
}

.pf-attachment-viewer-site-pill__icon {
    // float: left;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 4px !important;
}

.pf-attachment-viewer-site-pill__title {
    padding: 0 16px;
}

.pf-attachment-viewer-site-pill__title--with-icon {
    padding-left: 8px;
}

.pf-attachment-viewer-button,
a.pf-attachment-viewer-button {
    &.md-button.md-portfolium-theme:not([disabled]) {
        @include typography-body-2();
        display: flex;
        height: 40px;
        line-height: 40px;
        margin: 6px 4px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: $font-weight-medium;
        text-transform: none;
        background-color: $color-white;
        color: $color-licorice;

        md-icon {
            color: $color-oxford;
        }

        &:hover,
        &:active,
        &.md-focused {
            background-color: $color-porcelain;
        }
    }
}

.pf-attachment-viewer-button md-icon.right-icon {
    margin-right: 0;
    margin-left: 6px;
}

.pf-attachment-viewer-button md-icon.left-icon {
    margin-right: 6px;
    margin-left: 0;
}

.pf-attachment-viewer__default-image {
    margin: 0 auto 24px;
}

.pf-attachment-viewer-wrapper__download,
.pf-attachment-viewer-wrapper__link {
    background-color: $color-porcelain;
}

.pf-attachment-viewer-content__download {
    .pf-attachment-viewer__default-image {
        margin-left: auto;
        margin-right: auto;
    }
}

// media players fill the entire container
.pf-attachment-viewer-wrapper__player {
    iframe {
        width: 100%;
        height: 100%;
    }
}

.pf-attachment-viewer-wrapper__audio {
    background-color: $color-licorice;
}

.pf-attachment-viewer-wrapper__link {
    display: flex;
    flex-direction: column;
}

.pf-attachment-viewer-wrapper__link--default {
    flex-direction: row;
}

.pf-attachment-viewer-content__link {
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    transform: none;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
}

.pf-attachment-viewer-content__link--default {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pf-attachment-viewer__og-image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
}

.pf-attachment-viewer-content-actions__link {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    padding: 0 8px;
    transform: translateX(-50%);
    margin-bottom: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pf-attachment-viewer-content-actions__link--default {
    position: relative;
    left: 0;
    transform: none;
}

// hide image while loading
.pf-attachment-viewer-wrapper__image img[data-lazy] {
    display: none;
}

.pf-attachment-viewer-content__link-mobile {
    padding: 10px 12px;

    .pf-attachment-viewer-content-actions__link {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin-bottom: 0;

        a.pf-attachment-viewer-button.md-button {
            margin: 6px auto;
        }
    }

    .pf-attachment-viewer-site-pill__title--with-icon {
        margin-right: 16px;
        margin-left: 8px;
    }

    .pf-attachment-viewer__default-image-wrapper {
        padding: 12px 0;
    }

    .pf-attachment-viewer__default-image {
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }
}
