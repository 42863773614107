md-menu-item {
    & > .md-button {
        &:focus {
            outline-width: 0;
        }

        md-icon.pf-md-icon {
            font-size: 24px;
        }

        &.md-portfolium-theme {
            border-radius: 0;
            letter-spacing: normal;
        }

        md-checkbox {
            margin: 0;
            display: flex;
        }
    }
}

md-menu-content {
    border-radius: 3px;
}
