.pf-job-card--feed {
    $height: 96px;
    $avatar-height: 64px;

    background-color: $color-white;
    box-shadow: none;
    border: 0;

    .pf-avatar {
        height: $avatar-height;
        width: $avatar-height;
        min-width: $avatar-height;

        a,
        img {
            border-radius: 3px;
            display: block;
            width: 100%;
        }
    }

    .pf-job-details {
        margin: 0 0 0 16px;
        position: relative;
    }

    .pf-detail--header {
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight-medium;
        display: block;
    }

    .pf-detail--sub-header {
        font-size: 14px;
        line-height: 20px;
        color: $color-oxford;
    }

    .pf-actions {
        padding: 8px 16px;

        .md-button {
            margin: 0;
            min-width: 0;
        }
    }
}

.pf-job-card-btn {
    max-height: 36px;

    .md-menu {
        padding: 0;
    }

    .md-button {
        border: 1px solid transparent;
        border-color: $color-tiara;
        line-height: 34px;
        max-height: 36px;
        overflow: hidden;
        padding: 0 8px;

        md-icon {
            margin: 0;

            &.pf-icon-prepend {
                margin-right: 4px;

                @include max-screen($md-breakpoint-sm - 1) {
                    margin: 0;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
