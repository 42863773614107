@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-card-full-width {
    display: block;
}

// Default image column
.pf-entry-card-full-width__default-image {
    margin: 24px 0 24px 24px;

    .pf-entry-card-default-image {
        min-width: 341px;
        border-radius: 2px;
    }
}

// Metadata column
.pf-entry-card-full-width-metadata {
    padding: 24px;
}

.pf-entry-card-full-width-metadata__title {
    @include typography-title();
    margin: 0 0 16px;

    a,
    a:hover,
    a:focus {
        color: $color-licorice;
    }
}

.pf-entry-card-full-width-metadata__description {
    @include typography-body();
    color: $color-licorice;
    margin: 0 0 16px;
}

.pf-entry-card-full-width-metadata__category {
    @include typography-body();
    margin: 0 0 24px;

    a,
    a:hover,
    a:focus {
        color: $color-oxford;
    }
}

.pf-entry-card-full-width-metadata__profile {
    pf-entry-card-like {
        margin: 0 -8px 0 8px;

        .md-button.md-icon-button {
            margin: 0;
        }
    }
}
