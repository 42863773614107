md-radio-group.pf-form-control {
    md-radio-button {
        margin: 0;
        padding: 12px 0;

        .md-container {
            top: 15px;
            transform: unset;
        }

        .md-label {
            margin-left: 54px;
            color: $color-licorice;
        }
    }
}
