@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/position';

pf-read-more-clamp {
    // hack to remove elements from view immediately (ng-if/ng-hide/ng-show..)
    .ng-leave { display:none; }
}

.pf-read-more-clamp {
    position: relative;
    opacity: 0;
    display: block;
    width: 100%;
}

.pf-read-more-clamp--show {
    opacity: 1;

    .pf-read-more-clamp__copy {
        position: relative;
        opacity: 1;
    }
}

.pf-read-more-clamp__copy {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 24px;
    width: 100%;
    margin-bottom: 0;
    opacity: 0;
}

.pf-read-more-clamp__copy--active {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.pf-read-more-clamp__more-less {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 8px;
    cursor: pointer;
    @include typography-body-2();
    font-size: 14px;
    color: $color-electric;

    &:before {
        content: '';
        width: 24px;
        height: 100%;
        @include position(absolute, null, 0, 0, -24px);
    }
}

.pf-read-more-clamp__more-less--grey-background {
    background-color: $color-background-base;

    &:before {
        background: linear-gradient(
            -90deg,
            $color-background-base 0%,
            transparent 100%
        );
    }
}

.pf-read-more-clamp__more-less--white-background {
    background-color: $color-white;

    &:before {
        background: linear-gradient(
            -90deg,
            $color-white 0%,
            transparent 100%
        );
    }
}

.pf-read-more-clamp__more-less--open {
    bottom: -20px;
}

