md-toolbar.pf-navbar.pf-navbar--subscriber {
    background-color: $color-navbar-talent-match;
    transition: none;
    min-height: 64px;

    .pf-notification-center-jewel-wrapper {
        background-color: $color-navbar-talent-match;
    }

    .md-toolbar-tools {
        @include position(absolute, 0, 0, 0, 0);

        > .md-button:last-child {
            margin-right: 0;
        }
    }

    &.pf-light {
        background-color: $color-white !important;
        box-shadow: $whiteframe-shadow-z2;

        .pf-total-selected {
            margin: 0 64px;
            color: $color-electric;
        }

        .md-button:not([disabled]) {
            color: $color-electric;

            md-icon {
                color: $color-electric;
            }

            &:hover,
            &:focus {
                background-color: $color-porcelain;

                md-icon {
                    color: $color-electric;
                }
            }
        }

        ul li.nav-link > a {
            color: $color-electric;
            transition: none;
        }

        a.pf-title {
            color: $color-electric;

            &:hover {
                color: $color-electric-dark-12;
            }
        }
    }

    .md-button {
        &.pf-navbar-button {
            &:not([disabled]) {
                &.pf-active {
                    border-bottom: 2px solid $color-electric;
                }
            }

            &.pf-logo-button {
                margin: 0;
                min-height: 29px;
                height: 29px;
                line-height: 21px;
                padding: 4px;
                border-radius: 2px;

                img {
                    position: static;
                }
            }
        }
    }

    .pf-state-name {
        $height: 32px;

        min-width: 100px;
        border-left: 1px solid $color-white-alpha-16;
        height: $height;
        line-height: $height;
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: $color-white;
        padding-left: 24px;
        margin-left: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pf-mass-employer-actions-all {
        margin-left: 24px;
    }

    .pf-navbar-button-no-icon {
        padding: 0 8px;
        min-width: 0;
    }

    a.pf-title {
        margin-left: 8px;
        display: block;
    }

    pf-previous-state-btn {
        .md-button.md-icon-button {
            margin-left: -8px;
        }

        a.pf-title {
            color: $color-white-alpha-72;

            &:hover {
                color: $color-white;
            }
        }
    }
}

pf-return-url-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}
