/**
 * @description Use it to show empty results for any search, listing from the api
 *
 * ```
 <div class="pf-no-results">
    <img ng-src="{{'image_path'|asset}}"
         alt="Empty Search"
         height="256"
         width="256">
    <h2> Empty </h2>
    <p class="pf-no-results__subhead">
        Sorry, no results.
    </p>
</div>

 * ```
 *
 **/

 .pf-no-results {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0 48px;
    z-index: 2;
}

.pf-no-results--no-image {
    padding-top: 96px;
}

.pf-no-results__headline {
    @include typography-title();
    color: $color-licorice;
    margin: 0 0 8px;
}

.pf-no-results__subhead {
    @include typography-subhead();
    color: $color-oxford;
}
