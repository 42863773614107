@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-candybar {
    width: 100%;
    padding: 12px 16px;
    border-radius: 3px;
    text-align: left;
    color: $color-white;
    background-color: $color-electric;
    box-shadow: $whiteframe-shadow-2dp;

    @include typography-body();
    font-size: 14px;
    font-weight: $font-weight-medium;

    a {
        color: $color-white;

        &:focus,
        &:hover {
            color: $color-white;
            text-decoration: underline;
        }
    }
}

.pf-candybar__icon.material-icons {
    margin: 0 16px 0 0;
    color: $color-white;
}
