.pf-entry-card-footer {
    padding: 6px 8px;
    box-shadow: inset 0 1px 0 0 $color-porcelain;
    align-items: center;
    overflow: hidden;

    .md-button {
        &.md-icon-button {
            $size: 36px;

            margin: 0 8px;
            padding: 6px;
            height: $size;
            width: $size;
        }
    }

    pf-entry-card-like {
        .md-button.md-icon-button {
            md-icon {
                top: 6px;
                left: 6px;
            }
        }
    }

    & > .md-button {
        &:first-child {
            margin-left: 0;
        }
    }

    .pf-icon-button-jewel {
        margin-left: -8px;
        font-size: 14px;
        font-weight: $font-weight-medium;
        color: $color-ash;
    }

    pf-entry-card-like {
        .md-button.md-icon-button {
            margin-left: 0;
        }
    }

    .md-menu {
        padding: 0;

        &:last-child {
            .md-button {
                margin-right: 0;
            }
        }
    }
}
