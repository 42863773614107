@import '~client/main/sass/base/mixins/typography';

md-menu-content.pf-user-profile-menu {
    max-height: 386px;

    a.md-button:not([disabled]) {
        color: $color-licorice;
    }

    md-menu-item.pf-profile-link {
        $height: 64px;

        min-height: $height;
        height: $height;

        a.md-button {
            padding-top: 10px;
        }

        p {
            line-height: 20px;

            strong {
                font-weight: $font-weight-medium;
            }

            small {
                font-size: 13px;
            }
        }
    }
}

md-menu-content.pf-switch-menu {
    max-height: 320px;
    padding: 16px;

    a.md-button:not([disabled]) {
        color: $color-licorice;
    }

    md-menu-item.pf-switch-link {
        $height: 72px;

        min-height: $height;
        height: $height;

        a.md-button {
            padding-top: 11px;

            &:not([disabled]) {
                color: $color-licorice;
            }

            &[disabled] {
                opacity: 1;
            }
        }

        &.pf-switch-link--active {
            .pf-switch-link__heading {
                color: $color-electric;
            }
        }

        .pf-active-tag {
            font-size: 12px;
            padding: 0 8px;
            border-radius: 2px;
            color: $color-white;
            background-color: $color-electric;
            margin: 0 0 0 8px;
            display: inline-block;
            line-height: 22px;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
        }

        .pf-switch-link__heading {
            @include typography-title();
            color: $color-licorice;
            line-height: 24px;
        }

        .pf-switch-link__subhead {
            @include typography-subhead();
            color: $color-oxford;
        }
    }
}
