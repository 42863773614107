@import '~client/main/sass/base/variables';
pf-language-picker {
    width: 100%;
}

.language-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 48px;
}

.language-picker-item {
    padding-right: 16px;
}

.language-picker-divider {
    height: 28px;
    background-color: $color-licorice;
    width: 1px;
    margin-left: 16px;
    margin-right: 0px;
    display: inline-block;
    margin-bottom: -8px;
}
