@import '~client/main/sass/base/variables';

.pf-search-input-content {
    z-index: 1;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &.pf-disabled {
        background-color: $color-porcelain;
        cursor: not-allowed;
    }
}

.pf-search-input-content__input.pf-form-control {
    height: 48px;
    width: 100%;
    padding: 14px 48px 14px 16px;
    color: $color-licorice;

    &:not(.pf-form-control--material) {
        border: 0;
    }

    &:hover,
    &:focus {
        background-color: $color-white;
        outline: 0;
    }

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color: $color-ash;
    }

    &:-ms-reveal,
    &::-ms-reveal,
    &:-ms-clear,
    &::-ms-clear {
        display: none;
    }
}

.pf-search-input-content__action {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    right: 0;
    z-index: 2;

    & > .material-icons {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & > .md-button.md-icon-button {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 4px;
    }

    & > md-progress-circular {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &.pf-search-input-content__action--scale {
        transition: transform $transition-default-button;

        &.ng-enter {
            transform: scale(0);

            &.ng-enter-active {
                transform: scale(1);
            }
        }

        &.ng-leave {
            transform: scale(1);

            &.ng-leave-active {
                transform: scale(0);
            }
        }
    }
}

// Modifiers
pf-search-input {
    // Raised focus state
    &.pf-search-input--raised {
        .pf-search-input-content__input.pf-form-control {
            &:not([disabled]) {
                background-color: $color-porcelain;
                transition: background-color $transition-default-button, box-shadow $transition-default-button;

                &:focus {
                    background-color: $color-white;
                    box-shadow: $whiteframe-shadow-3dp;
                }
            }
        }

    }
}
