@import '~client/main/sass/base/variables';

pf-content-report-form {
    display: contents;
}

.pf-content-report-form__section {
    padding: 0 24px 8px;
    margin-bottom: 8px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-border-base;
    }
}
