$color-border-base: $color-electric-dark-24;

.pf-job-card-feed {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    margin-top: 16px;
    overflow: hidden;
    width: 100%;

    .pf-job-image {
        position: relative;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            content: "";
            display: block;
            padding-top: (9 / 16) * 100%;
            width: 100%;
        }

        .pf-image-wrap {
            background-color: $color-porcelain;
            @include position(absolute, 0, 0, 0, 0);

            img {
                border-radius: 3px 3px 0 0;
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }
}

@import './card/feed';
@import './card/list';
