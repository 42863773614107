@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-job-result-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
}

.job-result-card {
    flex: 1 1 auto;
    width: 100%;
    padding: 24px 24px 8px;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    overflow: hidden;
    max-width: 100%;
    cursor: pointer;
    // TODO: Add new sass token for transition default
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover,
    &:focus {
        box-shadow: $whiteframe-shadow-z3;
    }
}

.pf-job-result-card__image {
    @include avatar-square(0, 64px);
    margin-bottom: 16px;
}

.pf-job-result-card__title {
    max-width: 100%;
    @include typography-subhead();
    margin-bottom: 4px;
    font-weight: $font-weight-medium;
    color: $color-licorice;
    word-wrap: break-word;
}

.pf-job-result-card__company {
    max-width: 100%;
    @include typography-body();
    color: $color-electric-dark-24;
    font-size: 16px;
    margin-bottom: 4px;
    word-wrap: break-word;
}

.pf-job-result-card__location-date {
    @include typography-body();
    margin-bottom: 24px;
    color: $color-oxford;
    font-size: 14px;
    word-wrap: break-word;
}

.pf-job-result-card__btn.md-button {
    margin: 0 0 0 -16px;
}
