/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: https://material.io/design/typography/the-type-system.html#type-scale
 *
 *************************************************************************************
 * IMPORTANT *************************************************************************
 *************************************************************************************
 * If you add/remove classes from here, make sure you also update the constants here:
 * core/constants/text-styles.constant.js
 *************************************************************************************
 */

/////////////////////////////////////////////
// Text base class
/////////////////////////////////////////////

.pf-text {
    font-style: normal;
    font-family: $font-family-default; // proxima-nova, helvetica, arial, sans-serif
    font-weight: $font-weight-regular; // 400
    color: $color-licorice; // black, high emphasis
}

/////////////////////////////////////////////
// Text scale classes
/////////////////////////////////////////////

.pf-text--heading-1 {
    font-size: 96px;
    line-height: 104px;
    letter-spacing: -1.5px;
}

.pf-text--heading-2 {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.31px;
}

.pf-text--heading-3 {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0px;
}

.pf-text--heading-4 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: .25px;
}

.pf-text--heading-5 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0px;
}

.pf-text--heading-6 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .15px;
}

.pf-text--subtitle-1 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .15px;
}

.pf-text--subtitle-2 {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .1px;
}

.pf-text--body-1 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: .5px;
}

.pf-text--body-2 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .25px;
}

.pf-text--button-1 {
    font-weight: $font-weight-bold; // 700
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.pf-text--caption-1 {
    font-weight: $font-weight-medium; // 600
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .4px;
}

.pf-text--overline-1 {
    font-weight: $font-weight-bold; // 700
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@import 'color';
@import 'weight';
@import 'alignment';
