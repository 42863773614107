@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-company-result {
    display: block;
    max-width: 100%;
    width: 100%;
}

.pf-company-result-wrapper {
    padding: 16px;
    background-color: $color-white;
    border-top: 1px solid $color-border-base;

    @include min-screen($tablet) {
        padding: 24px 16px;
    }
}

.pf-company-result__logo {
    @include avatar-square(2px, 96px);
    margin-right: 16px;
    pointer-events: none;

    @include max-screen($md-breakpoint-sm - 1) {
        @include avatar-square(2px, 64px);
    }
}

.pf-company-result__left {
    min-width: 0;

    @include min-screen($md-breakpoint-sm) {
        max-width: 50%;
    }
}

.pf-company-result__middle {
    min-width: 0;
}

.pf-company-result__heading {
    @include typography-subhead();
    margin-right: 16px;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $color-licorice;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 16px;
    }
}

.pf-company-result__location {
    @include typography-body();
    color: $color-electric-dark-24;
    font-size: 14px;
    margin-bottom: 4px;

    // desktop
    @include min-screen($md-breakpoint-md) {
        @include typography-subhead();
        color: $color-licorice;
        margin: 0;
    }
}

.pf-company-result__industry {
    margin-bottom: 0;
    font-size: 15px;
    color: $color-oxford;
    line-height: 24px;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 14px;
    }
}

.pf-company-result-connect-btn .md-button {
    position: relative;
    margin: 16px 0 0 -16px;
    padding: 0 16px;

    &:not(.pf-pending) {
        color: $color-electric;
    }
}

.pf-company-result__description {
    margin-left: 15px;
    line-height: 24px;

    @include min-screen($md-breakpoint-sm) {
        width: 50%;
        max-width: 50%;
    }
}
