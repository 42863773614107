@import '~client/main/sass/base/variables';

.pf-job-register-header {
    margin-top: 20px;
    text-align: center;
}

.pf-post-job-sign-up .md-toolbar-tools {
    padding: 0;

    a {
        font-size: 13px;
        color: $color-electric;

        &:hover {
            color: $color-electric-dark-12;
        }
    }
}

@media only screen and (max-width: 600px) {

    .pf-form-control.pf-post-job-sign-up--firstname {
        margin-bottom: 12px;
    }
}

.pf-job-register-header img {
    margin: 10px 0 33px;
    height: 30px;
}

.pf-job-register {
    margin: 0 auto 24px;
    display: block;
    width: 90%;
    background-color: $color-white;
    border-radius: 2px;
    box-shadow: $whiteframe-shadow-z2;
    padding: 24px;

    md-dialog-actions {
        padding: 8px 16px;
        min-height: 68px;
    }

    .pf-prominent {
        font-weight: $font-weight-bold;
        color: $color-licorice;
    }

    @media only screen and (min-width: 600px) {
        width: 512px;
    }
}

.pf-field--two-columns {
    justify-content: space-between;

    &>span {
        max-width: 100%;
        width: 100%;
    }

    @media only screen and (min-width: 640px) {
        &>span{
            max-width: 48%;
            width: 48%;
        }
    }
}

md-dialog-actions {
    display: block;
    width: 100%;
    text-align: right;
}
