&.md-raised,
&.md-raised.md-portfolium-theme {
    border-radius: $base-border-radius;

    &:not([disabled]) {
        color: $color-electric;

        // Shadow on hover/focus states
        &:hover,
        &:focus {
            box-shadow: $whiteframe-shadow-z3;
        }

        // Primary Color
        &.md-primary {
            color: $color-white;
            background-color: $color-electric;

            md-icon {
                color: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-electric-dark-12;
            }
        }

        &.pf-btn-white {
            background-color: $color-white;
            color: $color-electric;

            md-icon {
                color: $color-electric;
            }

            &:hover,
            &:focus {
                background-color: $color-porcelain;
            }
        }

        // Accent Color
        &.md-accent {
            background-color: $color-barney-light-4;

            &:hover {
                background-color: $color-barney-light-14;
            }
        }

        // Warn Color
        &.md-warn {
            color: $color-white;
            background-color: $color-error;

            &:hover,
            &:focus {
                background-color: $color-crimson-dark-16;
            }
        }

        // Dark Color
        &.pf-dark {
            color: $color-white;
            background-color: $color-licorice;

            &:hover,
            &:focus {
                background-color: $color-oxford;
            }
        }

        // Facebook
        &.pf-btn-facebook {
            color: $color-white;
            background-color: $color-facebook;

            &:hover,
            &:focus {
                background-color: $color-facebook-hover;
            }
        }

        // LinkedIn
        &.pf-btn-linkedin {
            color: $color-white;
            background-color: $color-linkedin;

            md-icon {
                color: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-linkedin-hover;
            }
        }

        // Twitter
        &.pf-btn-twitter {
            color: $color-white;
            background-color: $color-twitter;

            md-icon {
                color: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-twitter-hover;
            }
        }
    }
}
