@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/background-gradient';

.pf-live-progress-bar {
    position: relative;
    width: 100%;
    height: 12px;
    background-color: $color-tiara;
    overflow: hidden;
    border-radius: 6px;
}

.pf-live-progress-bar--thin {
    height: 8px;
    border-radius: 4px;
}

.pf-live-progress-bar__fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 6px;
    @include background-gradient-primary-secondary();
    transition: width .6s ease-in-out;
}
