@import '~client/main/sass/base/variables.scss';
@import '~client/main/sass/base/mixins/typography.scss';

pf-pmail-composer {
    height: 100%;

    md-content.pf-pmail-composer {
        background-color: $color-white;
        height: 100%;
        margin: 0;
        width: 100%;

        form {
            height: 100%;
        }

        .pf-sidenav-panel__footer {
            box-shadow: none;

            .pf-button-footer {
                width: 100%;

                .pf-submit-button {
                    padding: 0 30px;
                }
            }
        }

        .ng-submitted .pf-form-control:not([disabled]).ng-invalid {
            &::-webkit-input-placeholder { color: $color-error; }
            &::-moz-placeholder { color: $color-error; }
            &:-ms-input-placeholder { color: $color-error; }
            &:-moz-placeholder { color: $color-error; }
        }

        .pf-form-control,
        .md-input,
        .md-input.ng-invalid {
            border: 0;

            &:focus {
                outline: 0;
            }
        }

        .pf-field-label {
            margin: 0 16px 0 16px;
            align-self: center;
        }

        md-input-container.md-portfolium-theme {
            margin: 0;

            .md-resize-wrapper {
                height: 100%;

                textarea.pf-form-control {
                    min-height: 160px;
                    padding: 0;
                }
            }
        }
    }
}

.pf-pmail-composer__header-container {
    align-items: center;
    width: 100%;
    height: 61px;
    padding: 19px 16px;

    .md-button.md-icon-button.pf-close-panel-button {
        position: absolute;
        top: 10px;
        right: 8px;
        margin: 0;
    }
}

.pf-pmail-composer__header {
    @include typography-title();
    color: $color-licorice;
}

.pf-pmail-composer__recipient-list {
    align-items: flex-end;
    border-bottom: 1px solid $color-porcelain;
    display: flex;
    flex-wrap: wrap;
    min-height: 61px;
    padding: 6px 12px 6px 12px;
    width: 100%;
}

.pf-pmail-composer-recipient {
    position: relative;
    margin: 4px 4px;
    height: 40px;
    width: 40px;

    &:hover {
        .pf-avatar {
            display: none;
        }

        .pf-toggle {
            display: block;
        }
    }

    .pf-avatar {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
    }

    .pf-toggle {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.pf-pmail-composer-recipient__remove.md-button.md-icon-button {
    margin: 0;
}

.pf-pmail-composer__credits {
    @include typography-body();

    align-items: flex-end;
    color: $color-oxford;
    flex: 1;
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 2px;
    min-width: 115px;
    padding: 16px 0 0;
}

.pf-pmail-composer-credits__required {
    @include typography-body-2();

    color: $color-licorice;
    font-size: 15px;
    line-height: 15px;
}

.pf-pmail-composer__job {
    border-bottom: 1px solid $color-porcelain;
    height: 57px;
    max-width: 512px;
    width: 100%;

    .pf-form-control {
        @include typography-subhead();
        color: $color-licorice;
        font-weight: $font-weight-medium;
        height: 100%;
        line-height: 16px;
        padding: 0 16px 0 0;
    }

    md-select.pf-form-control.md-portfolium-theme:not([disabled]) {
        .md-select-value {
            @include typography-subhead();
            border: none;
            color: $color-licorice;
            font-weight: $font-weight-medium;
            line-height: 16px;
        }
    }
}


.pf-pmail-composer__subject {
    border-bottom: 1px solid $color-porcelain;
    height: 57px;
    width: 100%;

    .pf-form-control {
        @include typography-subhead();
        border: none;
        color: $color-licorice;
        font-weight: $font-weight-medium;
        height: 100%;
        line-height: 16px;
        padding: 0 16px 0 16px;
    }
}

.pf-pmail-composer__salutation {
    margin: 21px 0 8px;
    padding: 0 16px;

    pre.pf-send-message-token {
        border: 0;
        border-radius: 3px;
        line-height: 16px;
        margin: 0 2px 0 10px;
        padding: 4px 6px;
    }
}


.pf-pmail-composer__body {
    padding: 20px 16px;

    md-input-container.md-portfolium-theme {
        height: auto;
        min-height: 100%;

        .md-input {
            @include typography-body();
            font-size: 15px;
            line-height: 15px;
            color: $color-licorice;
        }
    }
}

.pf-pmail-composer__job-link {
    padding: 0 16px;
}

.pf-pmail-composer__company-link {
    padding: 0 16px;
}

.pf-pmail-composer-token {
    padding: 4px 6px;
    border-radius: 3px;
    background-color: $color-porcelain;
    border: 0;
    font-size: 13px;
    line-height: 16px;
}

.pf-pmail-composer__select-message-template {
    @include typography-body-2();
    font-size: 15px;
    line-height: 15px;

    md-select.md-portfolium-theme {
        .md-select-value.md-select-placeholder {
            color: $color-electric-light-16;
            border: none;

        }
    }
}

.pf-pmail-composer-job-select__container {
    max-width: 512px;
    width: 512px;

    .pf-form-control {
        height: 48px;
        padding: 18px 16px;
        border: 0;

        &:focus {
            background-color: $color-porcelain;
        }
    }

    md-select-menu {
        min-height: 264px;

        md-content {
            min-height: 264px;

            .pf-job-search-icon {
                position: absolute;
                top: 12px;
                right: 8px;
                margin: 0;
                color: $color-ash;
            }

            .md-text {
                width: 100%;

                .pf-job-option__title {
                    margin-right: 5px;
                }

                .pf-job-option-location {
                    color: $color-ash;
                    font-size: 14px;
                    margin-left: 16px;
                }
            }
        }
        // Fixes icon alignment when jobs < 5
        &:not(.md-overflow) md-content {
            .pf-job-search-icon {
                top: 19px;
            }
        }
    }
}
