/**
 * @description
 * Modify content card padding and button positioning with pf-content-card--sm/md/lg/xl
 *
 * <div class="pf-content-card pf-content-card--sm">
 *      <div class="pf-content-card-header pf-content-card-header--padded pf-content-card-header--bottom-border">
 *          <div class="pf-content-card-btn-group pf-content-card-btn-group--right">
 *              <md-button></md-button>
 *              <md-button></md-button>
 *          </div>
 *      </div>
 *      <div class="pf-content-card-body pf-content-card-body--padded">
 *      </div>
 * </div>
 */

.pf-content-card {
    display: block;
    background-color: $color-white;
    border-radius: 2px;
    @include whiteframe-shadow(2);
}

.pf-content-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}

.pf-content-card-header--bottom-border {
    border-bottom: 1px solid $color-border-base;
}

.pf-content-card-btn-group {
    .md-button {
        margin: 0;
        padding: 0 8px;
    }
}

.pf-content-card-title {
    color: $color-licorice;
    @include typography-title();
}

.pf-content-card-subhead {
    color: $color-licorice;
    @include typography-subhead();
}

.pf-content-card-body {
    color: $color-licorice;
    @include typography-body-2();
    font-weight: $font-weight-regular;
}

.pf-content-card__loading-container {
    padding: 16px;
}

// small
.pf-content-card--sm {
    .pf-content-card-body--padded {
        padding: 16px;
    }

    .pf-content-card-header--padded {
        padding: 0 16px;
    }

    .pf-content-card-btn-group--right {
        margin-right: -8px;
    }

    .pf-content-card-btn-group--left {
        margin-left: -8px;
    }
}

// medium
.pf-content-card--md {
    .pf-content-card-body--padded {
        padding: 24px;
    }

    .pf-content-card-header--padded {
        padding: 0 24px;
    }
}

// large
.pf-content-card--lg {
    .pf-content-card-body--padded {
        padding: 32px;
    }

    .pf-content-card-header--padded {
        padding: 0 32px;
    }
}

// extra large
.pf-content-card--xl {
    .pf-content-card-body--padded {
        padding: 40px;
    }

    .pf-content-card-header--padded {
        padding: 0 40px;
    }
}

.pf-content-card--md,
.pf-content-card--lg,
.pf-content-card--xl {
    .pf-content-card-btn-group--right {
        margin-right: -16px;
    }

    .pf-content-card-btn-group--left {
        margin-left: -16px;
    }
}
