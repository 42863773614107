// Base
@import '~client/main/sass/base/variables';

.pf-dropzone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10000;
}

.pf-dropzone--active {
    background-color: $color-black-alpha-72;

    &:before {
        position: absolute;
        top: 16px;
        right: 16px;
        bottom: 16px;
        left: 16px;
        border: 4px dashed $color-white;
        border-radius: 3px;
        content: '';
    }
}

.pf-dropzone--deactive {
    .pf-dropzone__button {
        display: none;
    }
}

.pf-dropzone__button.md-button.md-portfolium-theme:not([disabled]).md-icon-button {
    color: $color-white;
}

.pf-dropzone__button.md-button.md-raised.md-portfolium-theme:not([disabled]).pf-icon-text-button {
    padding-top: 6px;
    padding-bottom: 6px;
    color: $color-electric;
    font-size: 16px;

    md-icon {
        margin-right: 6px;
    }

    span {
        text-transform: initial;
    }
}
