$card-background: $color-white;
$card-image-background: $color-oxford;
$card-height: 400px;
$new-card-height: 426px;
$card-img-height: 170px;
$card-width: 278px;
$card-width-mobile: 278px;
$card-margin: 40px;
$card-total-width: $card-width + $card-margin;
$body-padding: 40px;
$user-card-width: 278px;
$user-card-fixed-width: 306px;
$user-card-total-width: $user-card-width + $card-margin;
$user-card-fixed-total-width: $user-card-fixed-width + $card-margin;

.cards {
    @include clearfix;

    margin: 20px auto 0;
    padding-bottom: 80px;
    position: relative;

    @include max-screen($phone) {
        width: $card-width-mobile;
    }

    // widget styles
    &.widget {
        background-color: transparent;
        border-radius: 3px;
        margin: 0;

        .card {
            &.entry-card {
                max-width: 100%;
                margin: 0;

                @include max-screen($phone) {
                    max-width: 100%;
                    width: 100%;
                }

                .card-image {
                    a {
                        display: flex;
                        height: 170px;

                        img {
                            cursor: pointer;
                            height: auto;
                            width: auto;
                        }
                    }
                }

                .card-profile {
                    .avatar {
                        display: block;
                        height: 28px;
                        width: 28px;
                        border-radius: 14px;
                    }
                }

                .card-cat {
                    bottom: 43px;
                }
            }
        }
    }

    // base card styles

    .card {
        &.entry-card,
        &.company-card,
        &.user-card,
        &.network-card {
            background-color: $card-background;
            border-radius: $card-border-radius;
            box-shadow: $base-material-shadow;
            float: left;
            height: $card-height;
            margin: 0 ($card-margin / 2) $card-margin;
            position: relative;
            width: $card-width;
            transition: all 0.2s ease-in-out;

            @include max-screen($phone) {
                max-width: $card-width;
                width: 100%;
                margin: 0 0 20px 0;
            }

            // stops cards from smushing together
            @media only screen and (min-width: 635px) and (max-width: 768px) {
                margin: 20px 20px 20px 20px;
            }

            &.dimmed {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            .featured-date {
                background-color: $color-violet-dark-8;
                border-top-left-radius: $base-border-radius;
                box-shadow: 2px 1px 1px $color-ash;
                color: $color-white;
                display: none;
                font-size: 14px;
                font-weight: 600;
                left: 0;
                padding: 2px 10px;
                position: absolute;
                top: 0;
                z-index: 9;
            }

            .card-cat {
                height: 27px;

                a {
                    color: $color-oxford;
                    display: inline-block;
                    margin: 0 10px 7px;
                    padding: 0;
                    @extend .pf-body;

                    &:hover {
                        color: $color-oxford;
                    }
                }
            }

            .card-image {
                height: $card-img-height;
                overflow: hidden;
                position: relative;

                a {
                    display: block;
                }

                img {
                    transition: all 0.2s ease-in-out;
                    background: $card-image-background;
                    border-top-left-radius: $card-border-radius;
                    border-top-right-radius: $card-border-radius;
                    height: $card-img-height;
                    width: $card-width;
                    width: 100%;
                }

                @include share-buttons;

                &:hover {
                    .buttons {
                        opacity: 1;
                        top: 10px;
                    }
                }

                &:active {
                    .buttons {
                        opacity: 0;
                    }
                }
            }

            .card-profile {
                @include clearfix;
                @extend .pf-caption;
                height: 64px;
                padding: 14px 10px 0;

                > a {
                    display: block;
                    float: left;
                    margin-right: 10px;

                    &.pf-avatar-icon {
                        &:focus {
                            outline: 0;
                            border-radius: 50%;
                            box-shadow: 0px 0px 10px $color-electric;
                        }
                    }

                    &.pf-avatar-username {
                        &:focus,
                        &:hover {
                            outline: 0;
                            color: $color-electric;
                        }
                    }
                }

                .profile {
                    float: left;
                    line-height: 16px;
                    width: 180px;

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .profile-name {
                        clear: both;
                        font-size: 13px;
                        font-weight: 300;

                        strong {
                            font-weight: 600;
                        }

                        a {
                            color: $color-licorice;
                        }
                    }

                    .entry-title {
                        @extend .pf-body-2;
                        line-height: 15px;
                        color: $color-electric;
                        margin-bottom: 2px;
                        max-height: 32px;
                        overflow: hidden;
                        text-transform: uppercase;
                    }
                }
            }

            &.simple {
                height: 320px;
                border-radius: 0;

                .card-profile {
                    height: auto;

                    .profile {
                        width: 100%;

                        .entry-title {
                            color: $color-oxford;
                        }
                    }
                }

                .entry-title {
                    font-weight: 600;
                    color: $color-oxford;
                }

                .card-copy {
                    color: $color-oxford;
                }
            }

            .card-copy {
                @extend .pf-body;
                height: 74px;
                margin: 14px 10px;
                padding: 0;

                .card-description {
                    position: relative;
                    overflow: hidden;
                    min-height: 60px;
                    color: $color-licorice;
                    cursor: pointer;

                    &:after {
                        content: '';
                        height: 32px;
                        @include position(absolute, none, 0, 0, 0);
                        background-image: linear-gradient(
                            0deg,
                            $color-white-alpha-72 0%,
                            $color-white-alpha-32 30%,
                            transparent 100%
                        );
                    }
                }
            }

            .card-extras {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                min-height: 40px;
                border-top: 1px solid $color-porcelain;
                border-bottom-left-radius: $card-border-radius;
                border-bottom-right-radius: $card-border-radius;
                padding: 4px 10px;
                background-color: $color-white;

                .actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .md-button {
                        margin: 0px;
                        min-height: 28px;
                        line-height: 28px;
                        height: 28px;

                        &.md-icon-button {
                            height: 28px;
                            width: 28px;
                            line-height: 24px;
                            padding: 2px;
                        }

                        i.material-icons {
                            line-height: 28px;
                        }

                        &.liked,
                        &.member,
                        &.pending {
                            cursor: default;
                        }

                        &.pf-action-button {
                            padding: 0 12px;

                            md-icon {
                                margin-top: -2px;
                            }
                        }
                    }
                }

                .card-stats {
                    float: right;
                    line-height: 30px;
                    overflow: auto;
                    padding: 0;

                    ul li {
                        color: $color-oxford;
                        float: left;
                        font-size: 13px;
                        font-weight: 500;
                        padding: 0 5px;

                        i {
                            color: $color-oxford;
                            margin-right: 4px;
                        }
                    }
                }
            }

            &:active {
                background-color: $card-background;

                .card-header {
                    background-color: $card-background;
                }
            }
        }

        &.entry-card {
            height: $new-card-height;
            overflow: hidden;

            .card-image {
                img {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            .card-profile {
                width: 100%;
                padding: 0 16px;
                height: 48px;
                line-height: 48px;
                background-color: $color-white;
                border-radius: 3px 3px 0 0;
                overflow: hidden;
                z-index: 10;

                a {
                    display: block;
                    color: $color-licorice;
                    font-size: 13px;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .avatar.small {
                    display: block;
                    height: 28px;
                    width: 28px;
                    border-radius: 14px;
                }
            }

            .card-copy {
                height: 76px;
                margin: 15px;

                .entry-title {
                    a {
                        @extend .pf-title;

                        font-size: 13px;
                        line-height: 18px;
                        margin: -4px 0 6px;
                        color: $color-licorice;

                        &:focus,
                        &:hover {
                            color: $color-electric;
                        }
                    }
                }
            }

            .card-cat {
                background-color: $color-white;
                position: absolute;
                width: 100%;
                bottom: 46px;
                height: 30px;
            }

            .card-extras {
                height: 44px;
                padding: 7px 10px;

                .actions {
                    .entry-like {
                        &:focus {
                            background-color: $color-porcelain;
                        }
                    }

                    .liked {
                        background-color: transparent;
                        cursor: default;

                        md-icon {
                            color: $color-liked;
                        }
                    }

                    .pf-icon-button-jewel {
                        margin: 0 10px 0 5px;
                        font-size: 14px;
                        font-weight: 600;
                        color: $color-ash;
                    }

                    .pf-share-button {
                        min-height: 28px;
                        line-height: 28px;
                        top: 0;
                        height: 28px;
                        width: 28px;
                    }

                    md-menu {
                        padding: 0px;
                        float: right;
                    }
                }
            }

            &:hover {
                box-shadow: $whiteframe-shadow-z3;
            }

            // wider card for discover page to fit 3 x 2 panel
            &.discover-card {
                // width: 300px; // breaks discover cards now that margin and padding have been increased to match angular cards

                @include max-screen($phone) {
                    width: 100%;
                    margin: 0 0 20px;
                }
                @include max-screen($mobile) {
                    max-width: 300px;
                }
            }

            // custom card for slider panel on discover page
            &.wide-card {
                .card-cat {
                    left: 418px;
                }

                @include max-screen($mobile) {
                    width: 300px;
                    max-width: 300px;
                }

                @include min-screen($tablet-landscape) {
                    width: 890px;
                    height: auto;
                    position: relative;
                    @include clearfix;

                    .card-image {
                        float: left;
                        margin-right: 15px;
                        height: 226px;
                        border-right: 1px solid $color-porcelain;

                        img {
                            border-top-right-radius: 0;
                            border-bottom-left-radius: $card-border-radius;
                            width: 400px;
                            height: 226px;
                        }
                    }

                    .card-profile {
                        font-size: 15px;

                        .profile {
                            p {
                                float: none;
                                max-width: 330px;
                            }

                            .profile-name {
                                font-size: 14px;
                            }
                        }
                    }

                    .card-copy {
                        height: 50px;

                        p {
                            font-size: 15px;
                        }
                    }

                    .card-cat {
                        a {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    .card-extras {
                        width: 520px;
                        left: 400px;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            &.add-entry {
                transition: all 0.2s ease-in-out;

                background-color: $color-tiara;
                border: 4px dashed$color-tiara;
                color: $color-oxford;
                text-align: center;
                text-shadow: 0px 1px 0px $color-white-alpha-16;

                h2 {
                    @extend .pf-title;
                }

                i {
                    &.plus,
                    &.material-icons {
                        color: $color-oxford;
                        margin-bottom: 10px;
                        margin-top: 146px;
                    }

                    &.plus {
                        font-size: 70px;
                    }
                }

                &:hover,
                &:focus {
                    background: $color-tiara;
                    cursor: pointer;
                    outline: 0;
                }

                @include max-screen(580px) {
                    height: 200px;

                    i {
                        &.plus,
                        &.material-icons {
                            margin-top: 58px;
                        }
                    }
                }
            }

            .card-image {
                img {
                    cursor: zoom-in;

                    @include max-screen($mobile) {
                        cursor: pointer;
                    }
                }
            }
        }

        &.company-card {
            $company-card-height: 296px;
            $company-card-img-height: 130px;
            height: $company-card-height;

            .card-image {
                height: $company-card-img-height;

                img {
                    height: $company-card-img-height;
                }
            }

            .company-profile {
                @include clearfix;

                font-size: 0.9em;
                font-weight: 400;
                line-height: 1.5em;
                margin: 0 10px 8px 0;

                .avatar {
                    background: $color-white;
                    float: left;
                    height: 60px;
                    margin: -30px 10px 0 5px;
                    padding: 3px;
                    position: relative;
                    width: 60px;
                    z-index: 10;
                    border-radius: 3px;
                }

                .profile {
                    line-height: 16px;
                    max-width: 240px;

                    p {
                        margin: 0;
                        padding: 0;

                        &.company-title {
                            color: $color-oxford;
                            font-weight: 600;
                            margin-top: 10px;
                        }

                        &.company-industry {
                            color: $color-oxford;
                            font-size: 13px;
                        }
                    }
                }
            }

            .company-copy {
                color: $color-oxford;
                font-size: 12px;
                font-weight: 500;
                height: 75px;
                padding: 5px 15px 15px 15px;
            }
        }

        &.user-card {
            $user-card-height: 300px;
            $user-card-cover-height: 156px;

            height: $user-card-height;
            width: $user-card-width;

            .card-image {
                width: $user-card-width;
                height: $user-card-cover-height;
                position: relative;
                overflow: visible;

                @include max-screen($phone) {
                    width: 100% !important;
                }

                // Maintain 16x9 aspect ratio while image is loading
                &:before {
                    display: block;
                    content: '';
                    padding-top: (9 / 16) * 100%;
                }

                .image-wrap {
                    background-color: $color-porcelain;
                    @include position(absolute, 0, 0, 0, 0);

                    img {
                        width: $user-card-width;
                        height: $user-card-cover-height;
                        display: block;
                        border-radius: 3px 3px 0 0;

                        @include max-screen($phone) {
                            width: 100% !important;
                        }
                    }
                }

                .avatar {
                    $size: 80px;
                    $borderWidth: 3px;

                    position: absolute;
                    bottom: -(($size / 2) + $borderWidth);
                    left: 50%;
                    margin-left: -(($size / 2) + $borderWidth);
                    z-index: 10;
                    background: $color-white;
                    background-color: $color-porcelain;
                    border-radius: 50%;
                    height: $size + ($borderWidth * 2);
                    width: $size + ($borderWidth * 2);
                    border: 3px solid $color-white;
                    overflow: hidden;

                    img {
                        height: $size + ($borderWidth * 2);
                    }

                    a {
                        display: block;

                        &:focus {
                            img {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            .user-profile {
                @include clearfix;
                font-size: 0.9em;
                font-weight: 400;
                line-height: 1.5em;
                letter-spacing: 0.1px;
                margin: 52px 16px 16px;
                text-align: center;

                .profile {
                    line-height: 16px;

                    p {
                        margin: 0;
                        padding: 0;

                        &.user-title a {
                            color: $color-licorice;
                            // @extend .pf-title;
                            margin: 0 0 8px;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 600;
                            font-style: normal;
                            display: block;

                            &:focus {
                                outline: 0;
                                border-bottom: 2px dotted $color-electric;
                            }
                        }

                        &.user-school {
                            color: $color-oxford;
                            // @extend .pf-body-2;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 24px;
                            padding: 0;
                        }

                        &.user-major {
                            color: $color-oxford;
                            // @extend .pf-body;
                            min-height: 24px;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding: 0;
                        }
                    }

                    a.mail-link {
                        color: $color-oxford;
                        // @extend .pf-body;
                        min-height: 24px;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding: 0;
                    }
                }
            }

            .user-copy {
                color: $color-oxford;
                font-size: 12px;
                font-weight: 500;
                height: 75px;
                padding: 5px 15px 15px;
            }

            .card-extras {
                color: $color-ash;
                padding: 0 10px 16px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                line-height: 1.5em;
                border: none;

                button {
                    min-height: 34px;
                    line-height: 34px;

                    &.pf-pending {
                        padding: 0 8px;
                        color: $color-ash;
                        border: 1px solid $color-tiara;
                    }
                }

                a {
                    min-height: 34px;
                    line-height: 34px;
                }

                .pf-connected {
                    color: $color-ash;
                }

                .md-menu {
                    padding: 0;
                }

                .pf-pending {
                    color: $color-ash;
                }

                .connect {
                    padding: 0 8px;
                    color: $color-ash;
                    border: 1px solid $color-tiara;
                }

                .md-button {
                    &.cancel {
                        &:hover {
                            color: $color-error;
                        }
                    }
                }
            }

            &.simple {
                $profile-width: 200px;
                $profile-gradient-top: $color-licorice;
                $profile-gradient-bottom: transparent;

                border-radius: 0;
                height: $profile-width;
                width: $profile-width;

                a {
                    display: block;
                    height: $profile-width;
                    width: $profile-width;
                }

                img {
                    background: $card-image-background;
                    width: $profile-width;
                }

                .profile-details {
                    background: -moz-linear-gradient(
                        top,
                        $color-licorice 0%,
                        transparent 100%
                    ); /* FF3.6+ */
                    background: -webkit-linear-gradient(
                        top,
                        $color-licorice 0%,
                        transparent 100%
                    ); /* Chrome10+,Safari5.1+ */
                    background: -ms-linear-gradient(
                        top,
                        $color-licorice 0%,
                        transparent 100%
                    ); /* IE10+ */
                    background: linear-gradient(
                        to bottom,
                        $color-licorice 0%,
                        transparent 100%
                    ); /* W3C */

                    left: 0;
                    padding: 10px;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    h3 {
                        color: $color-white;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 0 0 6px;
                        padding: 0;
                    }

                    h4 {
                        color: $color-white;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                @include max-screen($mobile) {
                    width: 200px !important;
                }
            }
        }

        &.network-card {
            background-size: cover;

            @include max-screen($mobile) {
                height: 206px;
            }

            a {
                display: block;
                height: $card-height;
                width: $card-width;
            }

            .network-logo {
                transition: all 0.2s ease-in-out;
                background: $color-white;
                border-radius: $base-border-radius;
                height: 92px;
                left: 83px;
                position: absolute;
                top: 75px;

                @include max-screen($mobile) {
                    top: 32px;
                }

                img {
                    width: 90px;
                    height: 90px;
                    padding: 5px;
                }
            }

            .network-footer {
                background: $color-white;
                border-bottom-left-radius: $card-border-radius;
                border-bottom-right-radius: $card-border-radius;
                bottom: 0;
                color: $color-oxford;
                font-size: 14px;
                font-weight: 600;
                height: 56px;
                left: 0;
                padding: 16px 12px;
                position: absolute;
                text-align: center;
                width: 100%;
            }
        }
    }

    &.fluid {
        @for $i from 2 through 8 {
            $container-width: $i * $card-total-width;

            @include min-screen($container-width + $body-padding) {
                width: $container-width;
            }
        }

        &.users {
            @for $i from 2 through 6 {
                $container-width: $i * $user-card-total-width;

                @include min-screen($container-width) {
                    width: $container-width;
                }
            }
        }
    }

    &.fixed {
        @include min-screen($desktop) {
            // 2 user cards wide
            width: 2 * $user-card-fixed-total-width;
        }

        .user-card {
            max-width: $user-card-fixed-width;
            width: 100%;

            .card-image {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}

md-menu-content {
    // set hover colors to match angular cards
    .md-button {
        &.deny {
            &:hover {
                color: $color-ash;

                md-icon {
                    color: $color-ash;
                }
            }
        }

        &.accept {
            &:hover {
                color: $color-ash;
            }
        }
    }
}
