.pf-auth-slide-container {
    position: relative;
    height: 100%;

    @include min-screen($md-breakpoint-sm) {
        @include position(absolute, 0, 0, 0, 0);
        overflow: hidden;
    }

    & > [ui-view] {
        overflow: hidden;
    }

    .pf-auth-slide {
        @include position(absolute, 0, 0, 0, 0);
        background-color: $color-background-base;

        @include min-screen($md-breakpoint-md) {
            background-color: $color-white;
        }

        .pf-form-container {
            padding: 0 16px 16px;

            @include min-screen($md-breakpoint-md) {
                padding: 48px 32px 32px;
            }

            h1 {
                md-icon {
                    margin-right: 4px;
                }
            }

            .md-button:not(.md-icon-button) {
                width: 100%;

                @include min-screen($md-breakpoint-md) {
                    width: auto;

                    &.pf-btn-block {
                        width: 100%;
                    }
                }

                &.md-primary.md-raised[disabled] {
                    background-color: inherit;
                    color: $color-white !important;
                    box-shadow: none;

                    &.pf-btn-loading {
                        background-color: $color-electric !important;
                        box-shadow: $whiteframe-shadow-z1;

                        &.pf-btn-loading-visible {
                            background-color: transparent !important;
                            color: $color-white !important;
                            box-shadow: none;
                        }
                    }
                }
            }

            .pf-form-actions {
                margin-top: 32px;

                .pf-field {
                    margin-bottom: 16px;
                }
            }
        }

        .pf-header-logo {
            padding: 24px 16px 32px;

            & > img {
                height: 20px;

                @include min-screen($md-breakpoint-sm) {
                    height: 24px;
                }
            }
        }

        .pf-slide-header {
            padding: 16px;

            @include min-screen($md-breakpoint-md) {
                padding: 16px 32px;
            }
        }

        .pf-slide-footer {
            padding: 0 32px 32px;

            p {
                margin: 0;
            }

            .pf-alert-msg {
                padding-left: 16px;
            }
        }

        .pf-child-slide-wrapper {
            position: relative;
            overflow: scroll;
        }

        .pf-or-login {
            position: relative;
            margin-bottom: 0;

            @include min-screen($md-breakpoint-md) {
                margin-bottom: 16px;
            }

            &:after {
                display: block;
                content: '';
                border-bottom: 1px solid $color-border-base;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                z-index: 1;
            }

            .pf-or-copy {
                z-index: 2;
                background-color: $color-porcelain;
                color: $color-licorice;
                padding: 0 16px;
                font-size: 14px;

                @include min-screen($md-breakpoint-md) {
                    background-color: $color-white;
                }
            }
        }

        .pf-social-links {
            margin-top: 16px;

            .pf-field {
                margin: 0;

                @include min-screen($md-breakpoint-sm) {
                    margin: 0 6px;

                    &.pf-btn-fb {
                        margin-left: 0;
                    }

                    &.pf-btn-li {
                        margin-right: 0;
                    }
                }
            }

            .md-button {
                padding: 0 8px;
                text-transform: none;
                background-color: $color-white;
                color: $color-licorice;
                margin-top: 12px;

                @include min-screen($md-breakpoint-sm) {
                    margin-top: 0;
                }

                img {
                    vertical-align: middle;
                    color: $color-ash;
                    margin: -1px 3px 0 0;
                    width: 24px;
                    height: 24px;
                }
            }

            @include min-screen($md-breakpoint-md) {
                margin-top: 0;
            }
        }
    }

    .pf-auth-slide--register {
        .pf-login-button {
            margin: 0 -16px 0 0;
            min-width: 0;
        }
    }

    .pf-auth-slide--login {
        .pf-form-container {
            @include min-screen($md-breakpoint-md) {
                padding: 0 32px;
            }
        }

        .pf-slide-header {
            position: relative;
            padding: 16px;
            min-height: 0;

            @include min-screen($md-breakpoint-md) {
                padding: 6px 32px;
            }

            &:after {
                display: block;
                content: '';
                border-bottom: 1px solid $color-border-base;
                position: absolute;
                left: 16px;
                right: 16px;
                top: 50%;
                z-index: 1;

                @include min-screen($md-breakpoint-md) {
                    left: 64px;
                    right: 64px;
                }
            }

            .pf-avatar--profile {
                z-index: 2;
            }
        }
    }
}
