.pf-dialog--table {
    max-width: 960px;

    md-toolbar.md-portfolium-theme {
        border-bottom: 0;

        .md-toolbar-tools {
            height: 64px;
            max-height: 64px;
            padding: 0 24px;
        }
    }

    md-dialog-content {
        padding: 0;
    }
}
