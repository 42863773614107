@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';

pf-progress-bar {
    width: 100%;

    .pf-progress-bar-container {
        height: 8px;
        border-radius: 6px;
        background-color: $color-porcelain;
    }

    .pf-progress-bar__inner {
        border-radius: 6px;
        height: 100%;
    }
}
