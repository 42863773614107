.pf-popover-entry-strength {
    width: 192px;
    padding: 12px 16px 16px;
}

.pf-popover-entry-strength__header {
    @include typography-subhead();
    font-weight: $font-weight-medium;
    margin-bottom: 8px;
}

.pf-popover-entry-strength-list-item {
    @include typography-body();
    line-height: 24px;

    .material-icons {
        font-size: 18px;
        height: 18px;
        width: 18px;
        min-height: 18px;
        min-width: 18px;
        max-height: 18px;
        max-width: 18px;
        margin: 0;
    }
}

.pf-popover-entry-strength-list-item__icon-container {
    width: 18px;
    margin-right: 14px;
}
