pf-search-input.pf-form-control {
    height: 40px;
    padding: 0;
    border-color: $color-form-border;
    border-width: 2px;
    border-style: solid;
    box-shadow: none;
    font-size: $font-size;
    line-height: 1.4;
    font-weight: $font-weight;
    color: $color-font;
    border-radius: $base-border-radius;
    outline: 0;
    -webkit-appearance: none;

    .pf-search-input-content__input.pf-form-control {
        height: 100%;
        padding: 10px 48px 10px 16px;
    }

    .pf-search-input-content__action {
        height: 100%;
        width: 40px;

        & > .md-button.md-icon-button {
            right: 0;
            height: 100%;
            width: 100%;
            padding: 6px;
        }
    }

    &:not([disabled]) {
        &:focus {
            border-color: $color-form-border-focus;
            box-shadow: none;

            & + .pf-input-group-addon {
                border-color: $color-form-border-focus;
            }
        }
    }

    &:not([disabled]).pf-form-control-focus {
        border-color: $color-form-border-focus;
    }

    &[disabled] {
        opacity: 1;
        border-color: $color-form-border;
        background-color: $color-porcelain;
    }

    &.pf-form-control-lg {
        height: 48px;
        padding: 10px 14px;
        font-size: 16px;
    }
}
