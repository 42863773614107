
/**
* Design pattern for alert dialogs
* User pf-alert-dialog--stacked with pf-stacked buttons for mobile
*
*<div class="pf-alert-dialog pf-alert-dialog--stacked">
*   <div class="pf-alert-dialog-header">
*       <md-icon
*           class="pf-alert-dialog-header--icon">
*       </md-icon>
*       <div class="pf-alert-dialog-header--title"></div>
*   </div>
*   <p class="pf-alert-dialog--text"></p>
*   <div class="pf-alert-dialog-buttons pf-alert-dialog-buttons--raised">
*       <md-button></md-button>
*       <md-button></md-button>
*   </div>
*   <div class="pf-alert-dialog-buttons pf-alert-dialog-buttons--stacked">
*       <md-button
*           class="pf-stacked">
*       </md-button>
*       <md-button
*           class="pf-stacked">
*       </md-button>
*   </div>
*</div>
*/

.pf-alert-dialog {
    max-width: 640px;
    width: 100%;
    padding: 22px 24px 24px;
    background: $color-white;
    border-radius: $base-border-radius;

    .pf-alert-dialog-buttons--stacked {
        display: none;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        max-width: 85%;
    }
}

.pf-alert-dialog--stacked {
    padding-bottom: 8px;

    .pf-alert-dialog-header--icon {
        display: none;
    }

    .pf-alert-dialog-buttons--raised {
        display: none;
    }

    .pf-alert-dialog-buttons--stacked {
        display: block;
    }
}

.pf-alert-dialog-header {
    margin-bottom: 12px;
}

.pf-alert-dialog-header--title {
    color: $color-licorice;
    @include typography-title();
    line-height: 24px;
}

md-icon.pf-alert-dialog-header--icon {
    margin: 0 16px 0 0;
    color: $color-fire;
}

.pf-alert-dialog--text {
    margin-bottom: 24px;
    color: $color-licorice;
    @include typography-subhead();
}

.pf-alert-dialog-buttons {
    text-align: right;
}

.pf-alert-dialog-buttons--raised {
    margin: -2px -6px -6px;

    .md-button.md-portfolium-theme {
        margin: 6px;
    }
}

.pf-alert-dialog-buttons--stacked {
    margin: 0 -24px;
}
