$segment-width: 1000px;

.segment {
    width: $segment-width;

    @include max-screen($mobile) {
        width: 100%;
    }

    &.sidebar-right {
        article {
            margin: 63px 30px 100px 0;
        }
    }

    &.no-sidebar {
        margin: 0 auto;

        @include max-screen($mobile) {
            width: 100%;
        }

        article {
            width: $segment-width;
            float: none;
            margin: 0 0 100px 0;

            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }

    .segment-sidebar {
        float: left;
        min-height: 300px;
        width: 300px;

        .fixed {
            max-width: 300px;
        }

        .md-button {
            width: 100%;
            margin: auto 0;
            min-height: 45px;
            line-height: 45px;
        }

        &.no-hover {
            section li:hover {
                border-right: none;
            }
        }

        section {
            background: $color-white;
            border-radius: $base-border-radius;
            box-shadow: $whiteframe-shadow-z1;
            margin-top: 15px;
            margin-bottom: 20px;
            width: 300px;

            &.no-shadow {
                box-shadow: none;
            }

            &:first-child {
                margin-top: 63px;
            }

            & > ul {
                & > li {
                    transition: all 0.2s ease-in-out;
                    border-top: 1px solid $color-border-base;
                    line-height: 56px;

                    & > a {
                        @include clearfix;
                        display: block;
                        padding: 0 24px;
                        font-size: 14px;
                        font-weight: $font-weight-medium;
                        letter-spacing: .1px;
                        line-height: 56px;
                        color: $color-ash;
                        text-transform: uppercase;

                        &:hover {
                            color: $color-ash;
                        }
                    }

                    &:first-child {
                        border-top: none;
                    }

                    &.active {
                        box-shadow: inset -2px 0 0 0 $color-electric;

                        & > a {
                            color: $color-ash;
                        }
                    }

                    img {
                        float: left;
                        margin: 10px 24px 10px -14px;
                    }

                    &.share-buttons {
                        text-align: center;
                        line-height: 1;
                        padding: 16px;

                        .buttons {
                            div {
                                text-align: center;
                                margin: 0 4px;

                                i {
                                    margin: 0;
                                    line-height: 48px;
                                }
                            }
                        }
                    }
                }
            }

            .action {
                background: center center no-repeat;
                background-color: $color-black-alpha-48;
                background-size: 20px 20px;
                border-radius: $base-border-radius;
                color: $color-white;
                cursor: pointer;
                display: inline-block;
                font-size: 16px;
                height: 48px;
                margin: 0;
                overflow: hidden;
                width: 48px;
                transition: .2s ease all;

                i {
                    font-size: 23px;
                    margin: 15px 0 0 15px;
                }

                &.facebook {
                    background-color: $color-facebook;
                }
                &.twitter {
                    background-color: $color-twitter;
                }
                &.pinterest {
                    background-color: $color-pinterest;
                }
                &.linkedin {
                    background-color: $color-linkedin;
                }
                &.embed {
                    background-color: $color-electric-light-8;
                }
                &.email {
                    background-color: $color-electric-light-8;

                    a {
                        color: $color-white;
                    }
                }

                &:hover {
                    &.facebook {
                        background-color: $color-facebook-hover;
                    }
                    &.twitter {
                        background-color: $color-twitter-hover;
                    }
                    &.pinterest {
                        background-color: $color-pinterest-hover;
                    }
                    &.linkedin {
                        background-color: $color-linkedin-hover;
                    }
                    &.embed {
                        background-color: $color-electric;
                    }
                }
            }

            .likers {
                max-height: 130px;
                overflow-y: scroll;
            }

            .tab-header {
                font-size: 16px;
                color: $color-ash;
                margin: 5px 0px;
                border-bottom: none;
                text-transform: uppercase;
            }
        }

        .btn.big {
            transition: all .2s ease-in-out;
            font-size: 18px;
            margin-top: 40px;
            padding: 20px;
            display: block;
            text-align: center;
            width: 100%;

            &:nth-child(n+3) {
                margin-top: 10px;
            }
        }
    }

    article {
        @include clearfix;
        background: $color-white;
        border-radius: $base-border-radius;
        box-shadow: $whiteframe-shadow-z1;
        float: left;
        margin: 63px 0 100px 30px;
        min-height: 300px;
        padding: 0;
        width: 670px;

        &.no-shadow {
            box-shadow: none;
        }

        .button-wrapper {
            @include share-buttons;
            display: inline;
            position: absolute;

            .buttons {
                float: none;
                left: 5px;
                opacity: 1;
                right: initial;
                top: 5px;
            }
        }

        .tab-container {
            height: 64px;
            padding: 0 24px;

            a {
                transition: .2s ease all;
                border: none;
                color: $color-ash;
                float: left;
                font-size: 14px;
                letter-spacing: .1px;
                font-weight: $font-weight-medium;
                height: 64px;
                line-height: 64px;
                padding: 0 24px;
                text-transform: uppercase;

                &:hover {
                    color: $color-ash;
                }

                &.active {
                    color: $color-ash;
                    box-shadow: inset 0 -2px 0 0 $color-electric;
                }
            }
        }

        .cancel {
            @include clearfix;
            margin: 0;

            a {
                float: right;
                margin: 200px 0 0;

                @include max-screen($mobile) {
                    margin-top: 100px;
                }
            }
        }

        ul li {
            color: $color-oxford;
            margin-top: 24px;

            label {
                margin-right: 10px;
            }
        }

        h1 {
            @extend .pf-display-1;
            border-bottom: 1px solid $color-border-base;
            color: $color-licorice;
            margin: 0;
            padding: 32px;
            text-transform: none;

            @include max-screen($mobile) {
                padding: 0 0 0 16px !important;
                line-height: 48px;
                font-size: 20px;
            }

            span {
                line-height: 40px;

                @include max-screen($mobile) {
                    line-height: 48px;
                }
            }

            a {
                transition: all 0.2s ease-in-out;
                color: $color-ash;
                float: right;
                font-size: 14px;
                font-weight: $font-weight-medium;
                letter-spacing: .1px;
                margin: 0;
                padding: 0 16px;
                text-transform: uppercase;

                &:hover {
                    color: $color-ash;
                }

                &.active {
                    box-shadow: inset 0 -2px 0 0 $color-electric;
                    color: $color-ash;
                }

                @include max-screen($mobile) {
                    font-size: 13px;
                    padding: 0 10px;
                }
            }

            .md-button {
                margin: 2px 0;

                @include max-screen($mobile) {
                    margin: 6px;
                }
            }
        }

        h3 {
            @extend .pf-headline;
            margin-top: 30px;
            padding: 15px 40px 0 40px;

            @include max-screen($mobile) {
                margin-top: 10px;
                font-size: 20px;
                padding: 15px 20px 0 20px;
            }

            a {
                float: right;
                margin-right: 40px;
                font-size: 12px;

                &.message-delete {
                    margin-right: 10px;
                }
            }
        }

        p.explainer {
            color: $color-oxford;
            margin: 14px 30px 10px 40px;
            text-transform: none;

            @include max-screen($mobile) {
                margin-left: 20px;
            }
        }

        .padding-small {
            @include clearfix;
            padding: 15px 5px;
        }

        .padding {
            @include clearfix;
            padding: 30px 40px;

            @include max-screen($mobile) {
                padding: 10px 20px;
            }
        }

        form {
            padding: 32px;

            @include max-screen($mobile) {
                padding: 16px;
            }

            h3 {
                @extend .pf-headline;
                margin: 0 0 24px;
                padding: 0;
            }

            .pf-field {
                .md-button {
                    margin-left: 0;
                    margin-right: 12px;
                }
            }
        }

        fieldset {
            background: $color-white;
            border: none;
            padding: 0;
            position: relative;
            box-shadow: none;

            p {
                @extend .pf-field;

                &.explainer {
                    margin-left: 0;
                }
            }

            hr {
                margin: 32px 0;
            }

            #settings-disable-reason-other {
                display: none;
            }

            .label-switch {
                color: $color-oxford;
                margin-top: 0;
                width: 100%;
            }

            .label-switch-wrapper {
                text-align: left;

                .label-switch {
                    margin-right: 5px;
                    margin-top: -8px !important;
                    width: auto;
                }

                span {
                    vertical-align: super;
                }
            }

            .btn.top {
                position: absolute;
                right: 0;
                top: -100px;
            }
        }

        .btn-facebook, .btn-linkedin, .btn-twitter {
            font-weight: 700;
            margin-bottom: 10px;
            min-width: 240px;
        }

        img.preview {
            width: 300px;
        }

        a.cancel:not(.btn) {
            margin-top: 7px;
            float: right;
            color: $color-oxford;
            font-weight: 600;

            &:hover {
                color: $color-crimson-dark-16;
            }
        }
    }
}
