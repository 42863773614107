@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

$card-border-radius: 2px;

pf-entry-card-standard {
    display: block;
    height: 100%;

    .pf-entry-card {
        height: 100%;
    }
}

// Header
.pf-entry-card-standard-header {
    align-items: center;
    min-height: 48px;
    padding: 4px 16px;
    background-color: $color-white;
}

// Metadata
.pf-entry-card-standard__title {
    @include typography-body();
    font-weight: $font-weight-medium;
    margin: 16px 16px 4px;

    a,
    a:hover,
    a:focus {
        color: $color-licorice;
    }
}

.pf-entry-card-standard__description {
    max-width: 100%;
    @include typography-body();
    margin: 0 16px 8px;
    color: $color-licorice;
}

.pf-entry-card-standard__category {
    @include typography-body();
    margin: 0 16px 16px;

    a,
    a:hover,
    a:focus {
        color: $color-oxford;
    }
}

// Footer
.pf-entry-card-standard-footer {
    align-items: center;
    min-height: 48px;
    border-top: 1px solid $color-border-base;
    padding: 4px;

    .pf-entry-card-standard-footer__icon-button {
        margin: 0 4px;

        .md-button.md-icon-button {
            margin: 0;
        }
    }

    .pf-entry-card-standard-footer__vote-button {
        margin: 0 4px;

        .pf-entry-vote-button.md-button {
            margin: 0;
            padding: 0 8px;
        }
    }
}

// Like count
.pf-entry-card-like-jewel {
    @include typography-body();
    font-size: 14px;
    line-height: 36px;
    font-weight: $font-weight-medium;
    color: $color-oxford;
    margin-left: -6px;
}

// Hidden user profile header
.pf-entry-card-standard--hidden-header {
    position: relative;

    &:focus,
    &:focus-within,
    &:hover {
        .pf-entry-card-standard-header {
            transform: translate3d(0, 100%, 0);
        }

        .pf-entry-card-default-image__overlay {
            opacity: 1;
        }
    }

    .pf-entry-card-standard-header {
        position: absolute;
        top: -48px;
        left: 0;
        width: 100%;
        transform: translate3d(0, 0, 0);
        transition: .3s ease transform;
        z-index: 2;
        border-radius: $card-border-radius $card-border-radius 0 0;
    }

    .pf-entry-card-default-image,
    .pf-entry-card-default-image__overlay {
        overflow: hidden;
        border-radius: $card-border-radius $card-border-radius 0 0;
    }
}
