.pf-company-card--micro {
    $height: 140px;
    $width: 96px;

    background-color: transparent;
    box-shadow: none;
    height: $height;
    white-space: normal;

    .pf-avatar {
        background-color: $color-white-alpha-16;
        height: $width;
        width: $width;
        position: relative;
        z-index: 2;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: $whiteframe-shadow-z1;

        & > a {
            display: block;
        }

        img {
            display: block;
            width: 100%;
        }

        &:after {
            content: '';
            z-index: 1;
            @include position(absolute, 0, 0, 0, 0);
            @include linear-gradient(transparent 0%, $color-ash 70%, $color-oxford 100%);
            border-radius: 3px;
        }

    }

    .pf-detail--name {
        @extend .pf-body;
        line-height: 16px;
        font-weight: $font-weight-medium;
        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        padding: 4px 8px;
        width: 100%;
        color: $color-white;
        z-index: 3;
    }

    .pf-actions {
        .md-button {
            margin: 0;
            min-width: 0;
            letter-spacing: 0;
            text-align: center;
            margin-top: 8px;
            width: $width;
        }
    }
}
