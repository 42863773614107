@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/media_sizes';

.pf-dialog.pf-dialog--external-job-apply {
    @include min-screen($md-breakpoint-sm) {
        min-width: 480px;
        max-width: 480px;
        max-height: 90%;
    }

    md-dialog-content {
        padding: 16px 16px 8px;
    }
}

.pf-external-apply-modal {
    .pf-field-row {
        margin: 0 -8px;

        .pf-field {
            padding: 0 8px;
        }
    }
}

.pf-external-apply-modal__subhead {
    margin: 0 0 16px;
    color: $color-oxford;
}
