$tab-height: 48px;
$tab-height-tall: 56px;

// Tab button
.pf-tab-btn.md-button.md-portfolium-theme {
    position: relative;
    margin: 0;
    padding: (($tab-height - 18px) / 2) 12px;
    min-height: $tab-height;
    min-width: 0;
    line-height: 18px;
    border-radius: 0;
    color: $color-oxford;

    @include min-screen($md-breakpoint-sm) {
        padding: (($tab-height - 18px) / 2) 24px;
    }

    &:not([disabled]) {
        &:hover {
            background-color: transparent;
        }

        &.pf-tab-btn--active {
            box-shadow: inset 0 -2px 0 0 $color-electric;
            color: $color-electric;

            .pf-tab-icon {
                color: $color-electric;
            }
        }
    }

    &[disabled] {
        &.md-button.md-portfolium-theme {
            @include disabled-basic();

            md-icon  {
                @include disabled-basic();
            }
        }
    }

    .md-ripple-container {
        border-radius: 0;
    }
}

.pf-tab-btn--tall.md-button.md-portfolium-theme {
    padding: (($tab-height-tall - 18px) / 2) 12px;
    min-height: $tab-height-tall;

    @include min-screen($md-breakpoint-sm) {
        padding: (($tab-height-tall - 18px) / 2) 24px;
    }
}

.pf-tab-btn--blue.md-button.md-portfolium-theme {
    color: $color-white;

    &:not([disabled]) {
        &.pf-tab-btn--active {
            box-shadow: inset 0 -2px 0 0 $color-white;
            color: $color-white;

            .pf-tab-icon {
                color: $color-white;
            }
        }

        &:not([disabled]):not(:hover).md-focused {
            background-color: $color-white-alpha-16;
        }

        // Stops hover state from sticking on mobile iOS
        @include max-screen($md-breakpoint-md - 1) {
            &:not([disabled]):not(.md-focused):hover {
                background-color: $color-white-alpha-16;
            }
        }

    }
}
