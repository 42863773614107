@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

$color-tiara: $color-tiara;

pf-entry-viewer-comments-toolbar {
    display: block;
}

// Container element
.pf-entry-viewer-comments-toolbar {
    padding: 0 48px;
    background-color: $color-white;
    height: 56px;
    width: 100%;
    border-top: 1px solid $color-tiara;
}

// Text input
.pf-entry-viewer-comments-toolbar__input {
    margin-right: 16px;

    .pf-form-control {
        border: 0;
        padding: 10px 16px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

.pf-entry-viewer-comments-toolbar__like-btn {
    margin-left: -16px;
}

// Add comment button
.pf-entry-viewer-comments-toolbar__add-comment {
    &.md-button.md-portfolium-theme {
        margin: 0 -8px 0 0;
        padding: 0 8px;
    }
}

// Mobile modifiers
.pf-entry-viewer-comments-toolbar-mobile {
    border-top: 1px solid $color-tiara;
    padding: 16px;
}

.pf-entry-viewer-comments-toolbar-mobile__textarea {
    height: 110px;
    width: 100%;
    margin: 0;

    .pf-form-control {
        border: 0;
        padding: 12px 16px;
        height: 100%;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}
