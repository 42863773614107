html {
    background-color: transparent;
}

body {
    -webkit-font-smoothing: antialiased;
    background: $color-background-base;
    color: $color-licorice;
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $unitless-line-height;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font-family;
    line-height: $header-line-height;
    margin: 0;
    text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

h1 {
    font-size: $base-font-size * 2.25; // 16 * 2.25 = 36px
}

h2 {
    font-size: $base-font-size * 2; // 16 * 2 = 32px
}

h3 {
    font-size: $base-font-size * 1.75; // 16 * 1.75 = 28px
}

h4 {
    font-size: $base-font-size * 1.5; // 16 * 1.5 = 24px
}

h5 {
    font-size: $base-font-size * 1.25; // 16 * 1.25 = 20px
}

h6 {
    font-size: $base-font-size;
}

p {
    margin: 0 0 ($base-line-height * .5);
}

a {
    transition: color 0.2s ease;
    color: $color-electric;
    text-decoration: none;

    &:hover {
        color: $color-electric-dark-12;
    }

    &:active, &:focus {
        color: $color-electric-dark-12;
    }
}

hr {
    border-bottom: 1px solid $color-border-base;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: $base-line-height 0;
}

img {
    margin: 0;
    max-width: 100%;
}

blockquote {
    border-left: 2px solid $color-border-base;
    color: $color-oxford;
    margin: $base-line-height 0;
    padding-left: $base-line-height / 2;
}

cite {
    color: $color-ash;
    font-style: italic;

    &:before {
        content: '\2014 \00A0';
    }
}
