.my-jobs {
    min-height: 1000px;

    .table-list {
        td.logo {
            img {
                margin-right: 10px;
            }
        }
    }

    .segment-sidebar,
    .tab-container {
        @include max-screen($mobile) {
            display: none;
        }
    }

    article {

        form {
            padding: 30px 40px 0 40px;

            textarea {
                height: 100px;
            }
        }

        @include max-screen($mobile) {
            margin: 0 auto;
            width: 100%;

            form {
                padding: 15px;
            }

            h1 {
                font-size: 18px;
                margin-top: 0;
                padding: 10px 15px;
            }

            .padding {
                 padding: 10px;
            }

            .table-list {
                .threads {

                    &.logo {
                        width: 64px;
                    }
                }
                td.title {
                    width: auto !important;
                }
            }
        }
    }
}

md-dialog.pf-dialog--interested {
    max-width: 520px;
}

.pf-view--jobs {
    padding: 0 16px;
}

.pf-related--jobs {
    hr {
        margin: 0;
    }

    .heading{
        @extend .pf-title;
        padding: 24px;
        margin: 0;
    }

    .pf-related-job {
        overflow: hidden;
        width: 100%;
        padding: 24px;

        .pf-result-header {
            padding: 0 0 16px 0;

            .pf-title{
                font-size: 17px;
                word-wrap: break-word;
            }

            a {
                display: block;
                margin-right: 4px;
            }

            p {
                margin-bottom: 0;
            }

            .pf-title-2 {
                text-transform: capitalize;
                padding-top: 16px;

                a {
                    .job-image {
                        float: left;
                        padding-right: 16px;
                    }

                    &.company-name {
                        padding-top: 6px;
                        font-size: 15px;
                        color: $color-licorice;
                    }
                }
            }
        }

        .pf-result-body {
            background-color: $color-white;
            color: $color-oxford;

            .pf-body {
                line-height: 17px;
            }

            .pf-job-type {
                font-size: 13px;
                margin: 0 0 5px 0;

                strong {
                    text-transform: capitalize;
                }
            }

        }

        .pf-result-footer {
            text-transform: capitalize;

            .pf-body {
                color: $color-ash;
                font-weight: 600;
                margin: 0;
            }
        }

        .pf-column--apply {
            margin: 0 24px;

            .md-button {
                margin: 0;
            }
        }
    }

    .pf-job-search-no-results {
        margin: 40px 0;
        text-align: center;

        .pf-title {
            line-height: 32px;
        }
    }
}

body.c_jobs.a_index {
    .modal-content {
        label {
            color: $color-oxford;
            text-transform: uppercase;
        }
    }
}

.pf-job-apply {
    h1.pf-job-apply--header {
        font-size: 18px;
        border-bottom: 1px solid $color-tiara;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .entries-select {
        border-top: 1px solid $color-tiara;
        margin-top: 20px;
        padding-top: 20px;

        .selectable-entries ul li div label img {
            width: 50px;
            height: 50px;
        }

        .selectable-entries ul li div label span {
            margin-top: 0;
        }
    }

    .pf-user-profile {

        .avatar {
            height: 100px;
            min-width: 100px;
            width: 100px;

            @include max-screen($mobile) {
                height: 50px;
                margin-right: 10px;
                min-width: 50px;
                width: 50px;
            }
        }
        .user-data {
            margin-left: 20px;

            a {
                color: $color-oxford;
                font-size: 13px;
            }

            .username {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
            }
            .school {
                font-size: 16px;
                margin: 0;
            }

            @include max-screen($mobile) {
                margin-left: 0;
            }
        }

        .pf-complete-profile {
            font-size: 12px;
            margin-top: 10px;
        }
    }

    .pf-has-resume {
        text-align: center;

        a {
            padding: 10px 15px 0px;
            color: $color-ash;
            display: block;
        }

        md-icon {
            font-size: 50px;
            height: 50px;
        }
    }

    .pf-add-resume {
        border: 2px dashed $color-porcelain;
        border-radius: 4px;
        text-align: center;

        .pf-uploaded-resume {
            padding: 10px 0;

            a {
                padding: 10px 15px 0px;
                color: $color-ash;
                display: block;
            }

            md-icon {
                height: 50px;
                font-size: 50px;
            }
        }

        pf-resume-upload {
            max-width: 100%;
            word-break: break-word;
            display: block;
            position: relative;

            .pf-resume-loading {
                opacity: .24;
            }

            md-progress-circular {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -12px;
                margin-left: -12px;
                z-index: 1;
            }
        }

        .pf-muted {
            color: $color-ash;
            font-size: 13px;
        }

        @include max-screen($mobile-small) {
            margin-top: 16px;
        }
    }

    .pf-job-apply__resume-image {
        display: block;
        height: 96px;
        margin: 0 auto 16px;
    }
}


.pf-job-interested {
    .apply-on-website-button,
    .apply-on-website-link {
        color: $color-licorice;
        text-decoration: underline
    }

    .md-primary {
        float: right;
    }
}
