.introjs-overlay {
    background: -ms-radial-gradient(
        center,
        ellipse cover,
        $color-ash 0%,
        $color-licorice 100%
    ); /* IE10+ */
    background: radial-gradient(
        ellipse at center,
        $color-ash 0,
        $color-licorice 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b000000', endColorstr='#e6000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.introjs-tooltip {
    padding: 15px 20px;
}

.introjs-helperLayer {
    background-color: $color-white-alpha-32;
}

.introjs-helperNumberLayer {
    background: $color-electric;
    line-height: 1em;
    padding: 1px;
}

.introjs-tooltiptext {
    h3 {
        text-align: center;
    }

    hr.gradient {
        margin: 10px 0;
    }
}

.introjs-skipbutton {
    background: none;
    border: none;
    float: left;
    padding: 4px 0;

    &:hover {
        box-shadow: none;
    }

    &:focus {
        background: none;
    }
}
