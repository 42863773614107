pf-entry-card-like {
    display: flex;
    flex-direction: row;

    .md-button.md-icon-button {
        position: relative;

        md-icon {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 1;

            &.pf-liked {
                color: $color-liked;
                z-index: 2;
            }
        }

        &.pf-liked {
            cursor: default;
        }
    }
}
