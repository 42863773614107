.auth-page {
    h1 {
        font-size: 34px;
        font-weight: 300;
        margin-bottom: 10px;

        @include max-screen($mobile) {
            font-size: 28px;
            text-align: center;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: 300;

        @include max-screen($mobile) {
            text-align: center;
        }
    }

    .auth-form {
        margin-top: 15px;

        .pf-forgot-password {
            float: right;

            @include max-screen($mobile-small-portrait) {
                font-size: 10px;
            }
        }

        @include max-screen($mobile) {
            margin-top: 24px;
        }
    }

    .image-sidebar,
    .vertical-content {
        display: table-cell;
    }

    .vertical-content {
        padding: 60px 60px 60px 590px;

        .icon {
            display: block;
            height: 260px;
            margin: 20px auto;
            width: 260px;
        }

        @include max-screen($mobile) {
            padding: 10px;
        }
    }

    .image-sidebar {
        width: $split-sidebar-width;
        background: $color-porcelain;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        position: fixed;
        top: 0;

        .pf-logo {
            position: absolute;
            width: 100%;
            padding: 0 64px;
            top: 96px;
            text-align: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .pf-network-logo {
            position: absolute;
            bottom: 60px;
            width: 100%;
            padding: 0 64px;
            text-align: center;
            overflow: hidden;

            img {
                max-height: 240px;
                max-width: 100%;
            }
        }

        .attribution {
            background: $color-porcelain;
            bottom: 0;
            left: 0;
            position: absolute;
            width: 100%;

            .entry-profile {
                @include clearfix;
                padding: 10px;

                .avatar {
                    margin-right: 10px;
                    float: left;
                }

                .profile {
                    float: left;
                    line-height: 18px;

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .profile-name {
                        color: $color-white;
                        font-size: 14px;
                    }

                    .entry-title {
                        color: $color-electric;
                        font-weight: bold;
                    }
                }
            }
        }

        .referrer {
            background: $color-porcelain;
            border-radius: 50%;
            width: 300px;
            height: 300px;
            margin: 0 auto;
            position: relative;
            margin-top: 100px;

            .profile {
                color: $color-white;
                text-align: center;
                padding-top: 50px;
                font-weight: bold;
            }
        }

        @include max-screen($mobile) {
            display: none;
        }
    }

    .or-sso-options {
        color: $color-oxford;
        font-style: italic;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;
    }
}
