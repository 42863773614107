pf-pmail-invite {
    .md-button:not([disabled]) {
        md-icon {
            color: $color-ash;

            &:last-child {
                margin-top: -4px;
                margin-right: -4px;
            }
        }
    }
}
