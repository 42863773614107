.pf-label {
    padding: 0 4px;
    @include typography-body();
    line-height: 18px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $color-licorice;
    border-radius: 2px;
}
