@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/position';

.pf-simple-sign-in {
    $color-text: $color-electric-dark-24;

    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 432px;
    margin: 0 auto;
    height: 100%;
    min-height: 480px;
    color: $color-ash;

    @include min-screen($md-breakpoint-md) {
        overflow: hidden;
        max-width: 720px;
        min-width: 720px;
        max-height: 480px;

        border-radius: 8px;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        box-shadow: $whiteframe-shadow-z2;
    }

    md-icon {
        color: $color-ash;
    }

    .pf-subhead {
        color: $color-licorice;
        margin-bottom: 12px;
        text-align: center;
        font-weight: $font-weight-medium;
    }

    .pf-centered {
        text-align: center;
    }

    p {
        margin: 0 0 16px;
        color: $color-ash;

        &.pf-status-update {
            @include typography-subhead();
        }
    }

    .material-icons {
        &.pf-centered {
            display: block;
            margin: 0 auto;
        }
    }

    .pf-simple-sign-in__radio-button {
        color: $color-licorice;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    @import './column-sidebar';
    @import './column-content';
    @import './slides';
    @import './back-button';
    @import './avatar';
}
