@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-viewer-profile {
    display: block;
}

.pf-viewer-profile-info {
    margin-left: 16px;
}

.pf-entry-viewer-profile-user-details__name {
    @include typography-subhead-2();
    line-height: 24px;
    color: $color-licorice;
    font-weight: $font-weight-medium;
    margin: 0 0 8px;

    pf-blank-field {
        margin: 6px 0;
    }
}

.pf-entry-viewer-profile-user-details__school {
    @include typography-body();
    color: $color-licorice;

    pf-blank-field {
        display: block;
        margin: 4px 0;
    }
}

.pf-entry-viewer-profile-user-details__avatar {
    display: block;
    margin: 0 0 0 24px;
}

.pf-entry-viewer-profile-actions {
    margin: 24px 0 0;
    min-height: 36px;

    .md-button {
        margin: 0 12px 0 0;
        padding: 0 16px;

        &.pf-outline {
            padding: 0 16px;
        }
    }
}

.pf-entry-viewer-profile-connect-btn {
    .pf-remove-connection-text,
    .pf-connected-text {
        color: $color-white !important;
    }
}
