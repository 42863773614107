@import '~client/main/sass/base/variables';

pf-entry-viewer-badges {
    display: block;
}

.pf-entry-viewer-badges {
    margin-bottom: 24px;
    overflow: hidden;
    padding: 0 16px;
}

.pf-entry-viewer-badges__badge {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 2px 8px;
    margin-right: 8px;
}

.pf-entry-viewer-badges__badge--expert-pick {
    border-color: $color-ribbon-expert-pick;

    &,
    &:hover,
    &:focus {
        color: $color-ribbon-expert-pick;
    }
}

.pf-entry-viewer-badges__badge--staff-pick {
    border-color: $color-ribbon-staff-pick;

    &,
    &:hover,
    &:focus {
        color: $color-ribbon-staff-pick;
    }
}

.pf-entry-viewer-badges__badge--featured-pick {
    border-color: $color-ribbon-featured-pick;

    &,
    &:hover,
    &:focus {
        color: $color-ribbon-featured-pick;
    }
}
