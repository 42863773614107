.pf-pill-picker {
    width: 100%;
    height: 100%;
}

.pf-pill-picker-list {
    max-width: 100%;
    min-width: 0;
    margin: -4px;
}

.pf-pill-picker-list__repeater {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.pf-pill-picker-header {
    margin-bottom: 0;
}

.pf-pill-picker-header__title {
    @include typography-subhead();
    font-weight: $font-weight-medium;
    color: $color-licorice;
}

.pf-pill-picker-header__link {
    @include typography-body();
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $color-electric;
}

.pf-pill-picker__search-input {
    display: block;
    margin-bottom: 24px;
    background-color: transparent;

    .pf-search-input-content__action {
        md-progress-circular {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.pf-pill-picker--mobile {
    .pf-pill-picker-list {
        padding: 0 16px;
    }

    .pf-pill-picker__search-input {
        margin-bottom: 16px;

        .pf-search-input-content__input.pf-form-control {
            height: 56px;
            padding: 16px 48px 16px 16px;
        }
    }

    .pf-search-input-content__action {
        height: 56px;
        width: 56px;

        md-progress-circular {
            top: 8px;
        }
    }
}

// TODO refactor these classes to replace pf-pills
.pf-pill-picker-pill {
    @include typography-body();
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 12px;
    margin: 4px;
    line-height: 32px;
    border-radius: 50px;
    background-color: $color-porcelain;
    border: 1px solid $color-tiara;
    color: $color-licorice;
    transition: none;
    cursor: default;
    // needed for ellipsis
    max-width: 100%;

    &:focus:not(.pf-pill-picker-pill--with-actions),
    &:hover:not(.pf-pill-picker-pill--with-actions) {
        color: $color-licorice;
    }

    &:focus {
        outline: 0;

        &.pf-pill-picker-pill--with-actions:not(.pf-pill-picker-pill--selected) {
            color: $color-licorice;
        }
    }

    &:hover,
    &.pf-focused {
        &.pf-pill-picker-pill--with-actions:not(.pf-pill-picker-pill--selected) {
            color: $color-white;
            background-color: $color-oxford;

            .pf-pill-picker-pill__icon {
                color: $color-white;
            }
        }

        &.pf-pill-picker-pill--with-actions.pf-pill-picker-pill--selected {
            .pf-pill-picker-pill__icon--remove {
                display: block;
                background-color: $color-electric;
            }

            .pf-pill-picker-pill__icon--selected {
                display: none;
            }
        }
    }
}

.pf-pill-picker-pill--with-actions {
    padding-right: 0;
    cursor: pointer;

    .pf-pill-picker-pill__text {
        margin-right: 32px;
    }
}

.pf-pill-picker-pill--selected {
    color: $color-white;
    background-color: $color-electric;

    &:hover,
    &:focus,
    &.pf-focused {
        color: $color-white;
    }
}

.pf-pill-picker-pill__avatar {
    @include avatar-circle(0, 32px);
    margin: 0 8px 0 -12px;
}

.pf-pill-picker-pill__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pf-pill-picker-pill__icon {
    &.material-icons {
        position: absolute;
        right: 0;
        top: 50%;
        bottom: 50%;
        margin-right: 4px;
        transition: none;
        white-space: nowrap;
        display: inline-block;
        border-radius: 50%;
    }
}

.pf-pill-picker-pill__icon--selected {
    &.material-icons {
        color: $color-white;
    }
}

.pf-pill-picker-pill__icon--remove {
    &.material-icons {
        display: none;
        color: $color-white;
        background-color: $color-oxford;
    }
}
