body.chrome {

    &.mobile {

        &.ios {

            /**
             * This is to fix the mobile Safari bug where the page loads in half then bumps out
             * NOTE: on iOS Chrome is "Safari" at the lower-level browser engine
             */

            .pf-ng-app-content {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
}
