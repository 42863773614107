// temp for ugly button borders:
button {
    border: 0;
}

// Full width buttons
.btn-block,
.pf-btn-block {
    display: block;
    width: 100%;
    margin: 0;
}

// Button groups
@import './buttons/button-group';
@import './buttons/action-undo-warning';

// Buttons
.md-button {
    padding: 0 16px;
    font-weight: $font-weight-medium;
    letter-spacing: 0.4px;
    color: $color-licorice;

    // Button style partials
    @import './buttons/raised';
    @import './buttons/fab';
    @import './buttons/outline';
    @import './buttons/stacked';

    // Icons inside normal buttons
    i.material-icons {
        line-height: 36px;
        font-size: 18px;
        float: left;
        margin-right: 4px;
    }

    // Icons inside icon buttons
    &.md-icon-button {
        md-icon.material-icons {
            color: $color-oxford;
        }
    }

    // full width button
    &.md-full-width {
        width: 100%;
    }

    // Deny hover state
    &.deny,
    &.cancel,
    &.pf-warn-hover {
        &:hover {
            color: $color-error;

            md-icon {
                color: $color-error;
            }
        }
    }

    // Accept hover state
    &.accept {
        &:hover {
            color: $color-success;
        }
    }

    // Chip button
    &.pf-chip {
        padding: 0 16px;
        height: 36px;
        line-height: 33px;
        font-weight: $font-weight-regular;
        font-size: 15px;
        border-radius: 3px;
        border: 2px solid $color-porcelain;
        background-color: $color-porcelain;
        text-transform: none;

        &:not([disabled]) {
            background-color: $color-porcelain;

            &:hover,
            &:focus {
                background-color: $color-porcelain;
            }
        }
    }

    // Social buttons
    &.pf-btn-social {
        md-icon {
            color: $color-white;

            svg path {
                fill: $color-white;
            }

            &.pf-md-icon {
                margin: -4px 8px 0 0;
            }
        }

        &.md-raised {
            md-icon {
                &.pf-md-icon {
                    line-height: 12px;
                }
            }
        }
    }

    &.pf-outline-btn {
        border: 1px solid $color-tiara;

        md-icon {
            svg path {
                fill: $color-electric;
            }
        }
    }

    // text button with icon
    &.pf-icon-text-button {
        &:not(.md-primary):not(.pf-btn-white):not([disabled]) {
            color: $color-licorice;

            i.material-icons,
            md-icon.material-icons {
                color: $color-oxford;
            }
        }

        i.material-icons {
            margin: 0 4px 0 -8px;
            font-size: 24px;
            line-height: 36px;
        }

        md-icon.material-icons {
            margin: -2px 8px 0 -8px;
        }

        md-icon.material-icons.pf-icon-right {
            margin: -2px -8px 0 8px;
        }
    }

    // Button loading spinner
    pf-button-loading {
        display: block;

        md-progress-circular {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -12px;
            margin-left: -12px;
        }

        .pf-hidden {
            visibility: hidden;
        }
    }
}

// Link Overrides
a.md-button {
    &:not([disabled]) {
        color: $color-oxford;

        &.md-primary {
            color: $color-electric;
        }

        &.md-raised {
            color: $color-white;
        }
    }

    &[disabled] {
        @include disabled-basic();
    }
}

// Sizing
.pf-button-lg,
.large-button {
    font-size: 16px;
    line-height: 44px;
    font-weight: $font-weight-medium;
}
