body.c_settings {

    .segment {

        min-height: 1000px;

        .segment-sidebar {
            @include max-screen($mobile) {
                display: none;
            }
        }

        article {

            @include max-screen($mobile) {
                width: 100%;
                margin: 0 auto;

                .tab-container {
                    display: none;
                    height: 35px;
                    padding: 0 10px;

                    a {
                        font-size: 9px;
                        font-weight: 600;
                        height: 35px;
                        padding: 10px;
                        margin: 0;
                    }
                }

                form {
                    padding: 20px;
                }

                h1 {
                    margin-top: 0;
                    font-size: 20px;
                    padding: 10px 15px;
                }

                .padding {
                     padding: 10px;

                     &.notifications {
                        span {
                            font-size: 14px;
                            position: absolute;
                        }
                    }
                }
            }

            .tm-settings-hr {
                margin: 1.5em 30px 0 30px;
            }

            .tm-stats-wrapper {
                padding-left: 20px;

                ul {
                    li {
                        .tm-titles {
                            font-size: 15px
                        }
                        .tm-stats {
                            font-size: 24px;
                            display: inline;
                        }
                    }
                }
            }


            .account-management {
                padding: 0 40px 40px 40px;

                @include max-screen($mobile) {
                    padding: 0 16px 16px 16px;
                }

                table {
                    border-collapse: collapse;

                    thead {
                         tr {
                            padding: 0;

                            .account-header {
                                padding-left: 16px;
                                text-align: left;
                            }

                            .options-header {
                                padding-right: 16px;
                                text-align: right;
                                width: 90px;
                            }
                        }
                    }

                    tbody {
                        tr {
                            .account-row {
                                padding-left: 16px;
                                text-align: left;

                                .wrapper {

                                    .address-dim {
                                        color: gray;
                                        display: inline;
                                        font-size: 14px;
                                    }

                                    .email-status {
                                        .email-primary {
                                            font-size: 14px;
                                            color: $color-electric-light-8;
                                            display: inline;

                                            .middot {
                                                color: $color-licorice;
                                            }
                                        }

                                        .email-verified {
                                            font-size: 14px;
                                            color: $color-ash;
                                            display: inline;
                                        }
                                    }
                                    .address-status {
                                        .address-primary {
                                            font-size: 14px;
                                            color: $color-electric-light-8;
                                            display: inline;
                                        }
                                    }
                                }
                            }

                            .options-row {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .md-open-menu-container {
        md-menu-content {
            md-menu-item {
                .md-button{
                    .menu-icon{
                        margin: auto 3px auto 0;
                    }
                }
            }
            .menu-highlight-fix {
                display: none;

            }
        }

    }
}

.smarty-ui-wrapper {
    position: relative;
}

.smarty-ui {
    top: 64px !important;
    left: 0 !important;

    .smarty-tag {
        display: none !important;
    }
}

.state, .zip {
    width: 99%;

    .required {
        color:$color-crimson;
        font-size: 15px;
    }
}

#create-email,
#update-email,
#create-address,
#update-address{
    h1 {
        @include max-screen($mobile) {
            font-size: 28px;
            margin: 4px 0;
        }
    }
}
