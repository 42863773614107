.pf-entry-card-header {
    position: absolute;
    top: -48px;
    left: 0;
    width: 100%;
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
    background-color: $color-white;
    z-index: 5;
    transition: .3s ease transform;
    border-radius: 3px 3px 0 0;
    overflow: hidden;

    a {
        display: block;
        color: $color-licorice;
        font-size: 13px;
        font-weight: $font-weight-medium;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .pf-author {
        margin-right: 8px;
        min-width: 28px;
    }

    img.pf-avatar {
        $size: 28px;

        display: block;
        height: $size;
        width: $size;
        border-radius: $size / 2;
    }

    .md-button {
        &.md-icon-button {
            $size: 36px;

            margin: 0 8px;
            height: $size;
            width: $size;
            line-height: $size;
            min-height: $size;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
