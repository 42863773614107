/*
1. Portfolio
2. Profile
3. Network
4. Company
5. Jobs
6. Pagination
*/

.waiting {
    color: $color-tiara;
    display: block;
    text-align: center;
    margin-top: 80px;
    font-size: 150px;
}

.go-wild {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    color: $color-electric;
}

.entry-like,
.entry-vote {
    &.liked,
    &.voted {
        border-color: $color-ash;
        cursor: default;
    }
    &.md-button.md-raised {
        &.liked,
        &.voted {
            background-color: transparent !important;
            border-color: $color-ash !important;
            color: $color-oxford !important;
            cursor: default;
            box-shadow: 0 0 0;

            md-icon {
                color: $color-oxford !important;
            }

            &:hover,
            &:focus {
                background-color: $color-ash !important;
                border-color: $color-ash !important;
            }
        }
    }
}

/* ===============
   !Header
   =============== */

.fadein {
    transition: opacity 2s ease-in;
    opacity: 1;
}

.header.fluid {
    transition: all 0.2s ease-in-out;
    background-color: $color-oxford;
    background-image: url('https://portfolium.com/assets/images/hero/darkbg7.jpg');
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
    color: $color-white;
    margin: 0;
    padding: 80px 0;
    position: relative;
    min-height: 400px;

    &.expert-picks,
    &.staff-picks {
        min-height: 268px;
        height: 268px;
    }

    @include max-screen($mobile) {
        padding: 20px 0 10px;
        min-height: 0;
    }

    &.min,
    &.min-filters {
        min-height: 0;
    }

    h1 {
        @include typography-display-3();

        @include max-screen($mobile) {
            @include typography-display-1();
        }
    }

    h2 {
        @include typography-headline();

        @include max-screen($mobile) {
            @include typography-subhead();
            margin-bottom: 10px;
        }
    }

    .category-pills {
        margin-top: 30px;

        @include max-screen($mobile) {
            margin-top: 10px;
        }
    }

    .btn {
        background-color: $color-white;
        border: 1px solid $color-white;
        color: $color-oxford;
        font-size: 1em;
        font-weight: 600;
        margin: 5px 10px 5px 0px;
        padding: 14px 20px;

        @include max-screen($mobile) {
            font-size: 12px;
            margin: 4px 4px 4px 0px;
            padding: 8px 10px;
        }

        &.done {
            float: right;
        }

        &.inverse {
            transition: all 0.2s ease-in-out;
            background-color: transparent;
            color: $color-white;

            &.dashed {
                border: 1px dashed $color-white;
            }

            &:hover,
            &:focus {
                background: $color-white;
                color: $color-oxford;
                outline: 0;
            }
        }
    }

    > .container {
        padding: 20px;

        &.centered {
            margin: 0 auto;
            max-width: 960px;
        }

        .title {
            transition: all 600ms cubic-bezier(0.175, 0.885, 0.335, 1);
            .action {
                background: center center no-repeat;
                background-color: $color-black-alpha-48;
                background-size: 20px 20px;
                border-radius: $card-border-radius;
                color: $color-white;
                cursor: pointer;
                display: inline-block;
                font-size: 16px;
                height: 50px;
                margin: 10px;
                overflow: hidden;
                width: 50px;
                transition: all 0.1s ease-in-out;

                i {
                    font-size: 23px;
                    margin: 15px 0 0 4px;
                }

                &.facebook {
                    background-color: $color-facebook;
                }
                &.twitter {
                    background-color: $color-twitter;
                }
                &.pinterest {
                    background-color: $color-pinterest;
                }
                &.linkedin {
                    background-color: $color-linkedin;
                }
                &.embed {
                    background-color: $color-electric-light-8;
                }

                &:hover {
                    &.facebook {
                        background-color: $color-facebook-hover;
                    }
                    &.twitter {
                        background-color: $color-twitter-hover;
                    }
                    &.pinterest {
                        background-color: $color-pinterest-hover;
                    }
                    &.linkedin {
                        background-color: $color-linkedin-hover;
                    }
                    &.embed {
                        background-color: $color-electric;
                    }
                }
            }

            // special for discover flexslider
            h1.discover {
                width: 768px;
                float: left;
                margin-top: -20px;
                margin-left: 248px;
                margin-bottom: 10px;
                text-align: left;
                overflow: hidden;
                height: 70px;

                @include max-screen(400px) {
                    width: 282px;
                    margin: 0 auto;
                }
            }
        }

        .filters {
            display: none;
            text-align: center;

            > span {
                display: inline-block;
                font-size: 36px;
                line-height: 48px;
                font-weight: $font-weight-regular;
                padding: 0 16px;
                vertical-align: middle;
            }

            .btn {
                display: inline-block;
                background-color: $color-white;
                border-radius: $base-border-radius;
                color: $color-oxford;
                font-size: 14px;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                letter-spacing: 0.1px;
                font-weight: 600;
                padding: 0 16px;
                height: 48px;
                line-height: 48px;
            }

            .dropdown {
                color: $color-licorice;
                display: inline-block;
                position: relative;
                vertical-align: middle;
            }
        }
    }
}

@include max-screen(1500px) {
    .header.fluid {
        background-size: 1500px auto; /* Force the image to its minimum width */
    }
}

/* ===============
   !Jobs
   =============== */

.job-hero {
    .title {
        position: relative;

        .blue-arrow {
            position: absolute;
            width: 122px;
            top: 40px;
            left: -56px;
        }
    }

    .big-job-search {
        @include clearfix;

        margin-top: 20px;
        text-align: left;

        .pf-control-label {
            padding: 0;
            margin: 0;

            i {
                color: $color-electric;
                left: 10px;
                position: absolute;
                top: 13px;
                z-index: 3;

                @include max-screen($mobile) {
                    top: 18px;
                }
            }
        }

        .pf-field {
            position: relative;
            width: 50%;
            float: left;

            @include max-screen($mobile) {
                width: 100%;
                margin-bottom: 0;
            }

            input[type='submit'] {
                background-color: $color-electric-light-8;
                border-radius: 3px;
                border: 0;
                color: $color-white;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                z-index: 2;

                @include max-screen($mobile) {
                    top: 5px;
                }
            }
        }

        .pf-form-control {
            &.search-keywords,
            &.search-locations {
                padding: 14px 14px 14px 40px;
                height: 50px;
                font-size: 18px;

                @include max-screen($mobile) {
                    font-size: 16px;
                }
            }

            &.search-keywords {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right-width: 1px;

                @include max-screen($mobile) {
                    margin: 5px 0;
                    border-radius: $base-border-radius;
                }
            }

            &.search-locations {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left-width: 1px;

                @include max-screen($mobile) {
                    margin: 5px 0;
                    border-radius: $base-border-radius;
                }
            }
        }
    }
}

.jobs-container {
    margin: 20px auto;
    max-width: 1024px;
}

.job {
    $job-gutter: 1.4em;
    $job-image-padding: 0.3em;
    $job-image-width: 85px;
    $job-image-height: 85px;
    $job-image-vert-alignment: top;

    position: relative;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    border-radius: 3px;
    margin-bottom: 16px;
    width: 100%;
    padding: 16px;

    &:first-child {
        border-top: none;
    }

    .job-image,
    .job-content {
        display: table-cell;
        vertical-align: $job-image-vert-alignment;
    }

    .job-image {
        float: left;
        padding-right: 15px;
        width: 100px;

        img {
            background: $color-white;
            border-radius: $base-border-radius;
            display: block;
            height: $job-image-height;
            padding: $job-image-padding;
            width: $job-image-width;
        }

        .job-reverse-order & {
            padding-right: 0;
            padding-left: 10px;
        }

        @include max-screen($phone) {
            width: 60px;

            img {
                width: 45px;
                height: 45px;
            }
        }
    }

    .job-content {
        padding-right: 50px;
        width: 100%;

        @include max-screen($mobile) {
            padding-right: 20px;
        }

        .job-title {
            a {
                text-transform: capitalize;
                color: $color-licorice;
            }
        }

        .job-company {
            text-transform: capitalize;
        }

        p {
            color: $color-oxford;
            margin-bottom: 0.5em;
        }

        .job-detail {
            font-size: 14px;
            font-weight: 600;
            color: $color-ash;
        }
    }

    .job-actions {
        float: right;

        .btn {
            font-weight: 600;
            margin-right: 30px;
            width: 137px;
        }

        @include max-screen($phone) {
            float: none;

            .btn {
                display: block;
                font-size: 14px;
                margin-right: 0;
                text-align: center;
                width: auto;
            }
        }
    }
}

/* ===============
   !Notifications
   =============== */

.table-list.notifications {
    table {
        margin-bottom: 5px;
    }

    td.title {
        padding: 10px 0 0 5px;
        line-height: 18px;

        .date {
            padding: 10px 0;
            display: inline-block;
        }

        a {
            display: block;
        }
    }

    td.logo.message {
        width: 60px;
        text-align: center;

        img {
            padding: 0;
            margin: 0;
        }
    }

    .highlight {
        background-color: transparent;

        table {
            border-left: 3px solid $color-electric;
        }

        td.title {
            color: $color-electric;
        }
    }
}

