.pf-settings-template {
    position: relative;
}

.pf-settings-template__subnav {
    width: 100%;
}

.pf-settings-template-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: $md-breakpoint-lg;
    margin: 0 auto;
    padding: 0 0 80px;

    @include min-screen($md-breakpoint-md) {
        flex-direction: row;
        padding: 24px 16px 80px;
    }
}

.pf-settings-template-content__column {
    padding: 0 0 24px;
    min-width: 0;
}

.pf-settings-template-content__column--left {
    width: 256px;
    min-width: 256px;
    max-width: 256px;
    margin-right: 24px;
}

.pf-settings-template-content__column--center {
    display: flex;
    flex: 1;

    & > * {
        width: 100%;
    }
}

.pf-settings-template-content__column--right {
    width: 256px;
    min-width: 256px;
    max-width: 256px;
    margin-left: 24px;
}
