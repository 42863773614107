@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/typography';

.pf-error-page {
    background-color: $color-white;
    color: $color-licorice;
    font-size: 16px;

    .pf-error__container {

        p {
            font-size: 16px;
        }

        .pf-error-centered {
            width: 100%;
            max-width: 960px;
            margin: auto;

            @include max-screen($md-breakpoint-sm - 1) {
                padding: 0 5%;
            }

            .pf-error-image-wrapper {
                margin: 40px 0 0;
                text-align: center;

                img {
                    max-width: 600px;
                }
            }

            .pf-error-content {
                margin: 40px 0;
                text-align: center;

                h1 {
                    font-size: 34px;
                    margin: 0 0 24px;
                    font-weight: normal;

                    @include max-screen($md-breakpoint-sm - 1) {
                        font-size: 24px;
                    }
                }
            }

            .pf-error-suggestions {

                @include min-screen($md-breakpoint-sm) {
                    display: flex;
                }

                .pf-error-suggestion-item {
                    display: flex;
                    font-size: 16px;
                    padding: 40px 30px;

                    a {
                        font-weight: 600;
                        color: $color-electric;
                        cursor: pointer;
                    }

                    p {
                        margin: 4px 0 0;
                    }

                    .pf-error-suggestion-content {
                        padding-left: 28px;
                    }

                    .pf-error-suggestion-image {
                        width: 40px;
                        padding-top: 16px;
                    }

                    @include min-screen($md-breakpoint-sm) {
                        height: 160px;
                        flex: 1;
                    }

                    @include min-screen($md-breakpoint-sm) {

                        &.middle{
                            border: $color-tiara solid 0px;
                            border-right-width: 1px;
                            border-left-width: 1px;
                        }
                    }

                    @include max-screen($md-breakpoint-sm - 1) {

                        &.middle {
                            border: $color-tiara solid 0px;
                            border-top-width: 1px;
                            border-bottom-width: 1px;
                        }
                    }
                }
            }
        }
    }
}

.pf-error-page--full-width {
    background-color: inherit;
}

.pf-error__container,
.pf-error__footer .pf-footer__inner {
    padding: 48px 24px 96px;
    max-width: $max-container-width;
    width: 100%;
    margin: 0 auto;

    @include max-screen($md-breakpoint-md - 1) {
        padding: 56px 72px 96px;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        padding: 24px 16px;
    }
}

.pf-error__heading {
    margin-bottom: 2px;
    color: $color-licorice;

    // TODO: replace with future h1 style
    font-family: $font-family-default;
    font-style: normal;
    font-size: 60px;
    font-weight: $font-weight-bold;
    line-height: 72px;
    letter-spacing: -0.31px;

    @include max-screen($md-breakpoint-sm - 1) {
        margin-bottom: 5px;

        font-size: 34px;
        line-height: 40px;
    }
}

.pf-error__subheading {
    padding-bottom: 40px;
    border-bottom: 1px solid $color-border-base;
    margin-bottom: 40px;
    color: $color-oxford;

    // TODO: replace with future h2 style
    font-family: $font-family-default;
    font-style: normal;
    font-size: 24px;
    font-weight: $font-weight-medium;
    line-height: 28px;
    letter-spacing: -0.31px;

    @include max-screen($md-breakpoint-sm - 1) {
        padding-bottom: 24px;
        margin-bottom: 24px;

        font-size: 20px;
        line-height: 24px;
    }
}

.pf-error__body {
    margin-bottom: 40px;
    color: $color-licorice;

    @include typography-subhead-2();
    letter-spacing: 0.5px;

    @include max-screen($md-breakpoint-sm - 1) {
        margin-bottom: 24px;
    }
}

.pf-error__cta.md-button {
    margin: 0;
}
