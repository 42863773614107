ul, ol {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &%default-ul {
        list-style-type: disc;
        margin-bottom: $base-line-height / 2;
        padding-left: $base-line-height;
    }

    &%default-ol {
        list-style-type: decimal;
        margin-bottom: $base-line-height / 2;
        padding-left: $base-line-height;
    }

    &.inline {
        li {
            display: inline;
        }
    }
}

dl {
    margin-bottom: $base-line-height / 2;

    dt {
        font-weight: bold;
        margin-top: $base-line-height / 2;
    }

    dd {
        margin: 0;
    }
}
