.pf-query-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;

    &.ng-enter {
        transition: .3s ease trasnform, .3s ease opacity;
        opacity: 0;
        transform: translate3d(0, -100%, 0);

        &.ng-enter-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &.ng-leave {
        transition: .3s ease trasnform, .3s ease opacity;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        &.ng-leave-active {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }
    }
}
