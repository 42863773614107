@import '~client/main/sass/base/variables';
@import './shepherd-theme-arrows';

/**
 * Overrides for generated tether-shepherd tooltip theme
 */
.shepherd-step {
    font-size: 15px;
}

.shepherd-element {
    &.shepherd-theme-arrows {
        .shepherd-content {
            color: $color-white;

            .shepherd-text {
                padding: 24px;
            }

            * {
                font-size: 15px;
                font-family: $font-family-default;
                font-weight: $font-weight-medium;
            }

            footer {
                padding: 0 16px 16px;

                .shepherd-buttons li {
                    .shepherd-button {
                        margin: 0 8px 0 0;
                        padding: 0 8px;
                        line-height: 36px;
                        font-size: 14px;
                        font-weight: $font-weight-medium;
                        text-transform: uppercase;
                        letter-spacing: .1px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}
