@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables.scss';

pf-pmail-center-panel {
    height: 100%;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;

    @include min-screen($md-breakpoint-sm) {
        width: 512px;
    }
}

.pf-pmail-center-panel {
    height: 100%;
}
