pf-media-slider {
    display: block;
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 72px;
}

.pf-media-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    padding: 0;
    margin: 0;
}

.pf-media-slider__inner {
    position: relative;
    height: 100%;
    width: 100%;
}

.pf-media-slider-slides {
    height: 100%;
}

.pf-media-slider__nav-button {
    position: absolute;
    top: 50%;
    width: 40px;
    z-index: 1;
    transform: translateY(-50%);

    .md-button.md-portfolium-theme.md-fab,
    .md-button.md-portfolium-theme.md-fab:not([disabled]).md-focused,
    .md-button.md-portfolium-theme.md-fab:not([disabled]):hover {
        background-color: $color-white;
    }
}

.pf-media-slider__nav-button--left {
    left: 0;

    .md-button.md-portfolium-theme {
        margin: 0 -50%;
    }
}

.pf-media-slider__nav-button--right {
    right: 0;

    .md-button.md-portfolium-theme {
        margin: 0 50%;
    }
}

.pf-media-slider__button-wrapper {
    display: flex;
    margin-right: -12px;
}

.pf-slider-image-loading {
    position: absolute;
    top: 0;
    z-index: 20005;
}

.pf-media-slider__mobile {
    margin-left: -16px;
    margin-right: -16px;

    .pf-media-slider__default-image {
        width: 110px;
    }

    .pf-media-slider__default {
        h4 {
            margin-top: 12px;
            margin-bottom: 8px;
            font-size: 16px;
        }

        p {
            font-size: 14px;
            margin: 8px 0;
        }
    }

    .pf-media-slider-toolbar {
        padding: 0 6px;
        margin-top: 8px;
        min-height: 38px;

        .pf-media-slider__dots {
            margin-top: 0px;
        }
    }

    .pf-media-slider__counter {
        @include typography-body();
        text-align: center;
        color: $color-oxford;
        font-weight: $font-weight-medium;
        margin-left: 4px;
    }
}

.pf-media-slider__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .slick-list,
    .slick-track {
        height: 100%;
        min-width: 100%;
    }
}

.pf-media-slider__slide {
    height: 100%;
}

.pf-media-slider__default {
    text-align: center;
    height: 100%;

    h4 {
        margin-top: 24px;
        color: $color-licorice;
        font-size: 20px;
        font-weight: 600;
    }

    p {
        color: $color-licorice;
        font-size: 16px;
    }

    a {
        cursor: pointer;
    }
}

.pf-media-slider-attachment-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pf-media-slider-attachment-mobile__inner {
    height: 100%;
    border-top: 1px solid $color-border-base;
    border-bottom: 1px solid $color-border-base;
}

.pf-media-slider-attachment-mobile__file {
    padding: 8px 24px;
    text-align: center;
}

.pf-media-slider-toolbar {
    margin-top: 16px;
    position: relative;
    height: 40px;
    min-height: 40px;

    .md-button.md-portfolium-theme {
        margin: 0 4px;
    }

    .slick-dots {
        display: none;
        text-align: center;
        margin: 8px 0;
        height: 24px;
        overflow: hidden;

        @include min-screen($md-breakpoint-md) {
            display: block;
        }

        li {
            display: inline-block;
            height: 24px;
            width: 24px;

            &:hover {
                cursor: pointer;

                button {
                    background-color: $color-tiara;
                }
            }

            button {
                display: block;
                float: left;
                position: relative;
                margin: 8px;
                padding: 0;
                height: 8px;
                width: 8px;
                text-indent: -9999px;
                overflow: hidden;
                background: transparent;
                border-radius: 50%;
                background-color: $color-porcelain;
                cursor: pointer;
                transition: .3s ease all;
                outline: 0;

                &:focus,
                &:active {
                    height: 10px;
                    width: 10px;
                    margin: 7px;
                }

                &:focus {
                    background-color: $color-tiara;
                }

                &:active {
                    background-color: $color-electric-light-16;
                }
            }

            &.slick-active {
                button {
                    background-color: $color-electric-light-16;
                    height: 10px;
                    width: 10px;
                    margin: 7px;
                }
            }
        }
    }
}

.pf-media-slider-toolbar--mobile {
    background-color: transparent;
    padding: 10px 16px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: $color-ash;

    .slick-dots {
        li {
            button {
                background-color: $color-white-alpha-48;
            }
        }
    }
}

.pf-media-slider-toolbar__info {
    position: relative;
    z-index: 2;

    &.md-button.md-icon-button {
        margin-left: -8px;
    }
}

.pf-media-slider-toolbar__info--mobile {
    &.md-button.md-icon-button {
        margin: 0 0 0 8px;
    }
}

.pf-media-slider-caption-popover {
    padding: 24px;
    max-height: 160px;
    overflow: auto;

    @media (min-width: $md-breakpoint-sm) {
        max-width: 448px;
    }
}

.pf-media-slider-caption-popover__title {
    @include typography-body-2();
    word-break: break-word;
    font-size: 16px;
    color: $color-licorice;
    display: inline-block;
}

.pf-media-slider-caption-popover__caption {
    display: block;
    padding: 0;
    margin: 12px 0 0;
    @include typography-body();
    word-break: break-word;
    white-space: pre-line;
    font-size: 14px;
    color: $color-licorice;
}

.pf-media-slider__button {
    margin: 6px 4px;

    &.md-button.md-portfolium-theme.md-fab md-icon {
        color: $color-oxford;

    }
}

.pf-media-slider__button--download {
    height: 40px;
}

.pf-media-slider-page-count__wrapper,
.pf-media-slider__dots-wrapper {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0px;
    left: 0px;
}

.pf-media-slider-page-count__wrapper {
    @include typography-body-2();
    font-size: 14px;
    font-weight: $font-weight-medium;
}

// Fullscreen slider

.pf-video-embed-container--fullscreen {
    height: 100%;
    width: 100%;
    padding-bottom: 0;
}

.pf-media-slider-fullscreen__wrapper {
    height: 100%;
    position: relative;
}

.pf-media-slider-toolbar--dark {
    background-color: $color-licorice;
    padding: 6px 10px;

    .slick-dots {
        li {
            button {
                background-color: $color-white-alpha-48;

                &:focus {
                    background-color: $color-white-alpha-72;
                }
            }

            &:hover {
                button {
                    background-color: $color-white-alpha-72;
                }
            }
        }
    }

    .pf-muted-button--dark {
        background-color: $color-white-alpha-16;

        &:not([disabled]) {

            &:hover,
            &:focus {
                background-color: $color-white-alpha-24 !important;
            }

            md-icon {
                color: $color-porcelain;
            }
        }

        &[disabled] {
            background-color: $color-white-alpha-16 !important;
        }
    }
}

.pf-media-slider-fullscreen {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    padding: 24px 40px;
    background-color: $color-licorice !important;
}

.pf-media-slider-fullscreen__mobile-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
}

.pf-media-slider-fullscreen__close-btn {
    md-icon {
        color: $color-white !important;
    }
}

.pf-media-slider-fullscreen__container {
    background-color: $color-licorice !important;
    color: $color-white !important;

    // fullscreen mods
    .pf-media-slider-toolbar {
        height: auto;
        margin-top: 0;
    }

    .pf-media-slider__dots-wrapper {
        height: 100%;
        padding: 0;
    }
}

.pf-media-slider__slider--fullscreen {
    height: 100%;

    .slick-list {
        height: 100%;
    }

    .slick-track {
        display: flex;
        height: 100%;

        .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                max-height: 100%;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

.pf-media-slider__no-attachments-wrapper {
    border: 2px dashed $color-porcelain;
    border-radius: 4px;
}

.pf-media-slider__no-attachments {
    position: relative;
    max-width: 70%;
    max-height: 80%;
}

// adjustments for video in fullscreen modal
.pf-attachment-viewer__fullscreen {
    display: block;
    width: 100%;
    height: 100%;

    .pf-attachment-viewer-inner__image {
        width: 100%;
        height: 100%;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .pf-attachment-viewer-inner,
    .pf-attachment-viewer-wrapper {
        width: 100%;
        height: 100%;
    }
}

.pf-media-slider__mobile {
    .pf-attachment-viewer-inner {
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
    }

    .pf-attachment-viewer-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
