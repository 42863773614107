@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-pagination-filter-modal.pf-dialog--v2 {
    max-width: 480px;
}

.pf-pagination-filter-type-input {
    display: block;
    padding: 0 16px;
}

.pf-pagination-filter-name {
    text-transform: none;
    @include typography-subhead();
}

.pf-pagination-filter-list-section {
    &:not(:last-child) {
        .pf-pagination-filter-list-toggle {
            margin-bottom: 16px;
        }
    }
}

.pf-pagination-filter-list-toggle {
    display: none;
    padding: 0 8px;

    &--open {
        display: block;
    }
}

.pf-pagination-filter-list-toggle-btn {
    &.md-button {
        text-align: start;
        height: 48px;
        line-height: 48px;
        margin: 0 8px;
    }

    .pf-pagination-filter-toggle-icon {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
    }
}

.pf-pagination-filter-list-toggle-btn--open {
    .pf-pagination-filter-toggle-icon {
        transform: rotate(180deg);
    }
}

input.pf-pagination-filter-list-text {
    &.pf-form-control {
        margin-top: 4px;

    }
}
