md-toolbar.md-portfolium-theme.pf-trial-banner {
    background-color: $color-electric-light-16;
    height: 40px;
    min-height: 40px;
    min-width: 1024px;
    width: 100%;

    .md-button.md-icon-button {
        position: absolute;
        margin: 0;
        right: 0;
        top: 0;

        md-icon {
            color: $color-white !important;
        }
    }

    .pf-trial-banner-link {
        color: $color-white;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 40px;
        min-height: 40px;
        padding: 0 8px;
        text-align: center;
        outline: 0;

        &:focus,
        &:hover {
            color: $color-white;
            text-decoration: underline;
        }
    }
}
