md-autocomplete.pf-form-control {
    height: auto;
    padding: 0;
    border: 0;
    background: $color-white;

    md-autocomplete-wrap {
        box-shadow: none !important;
    }

    input:not(.md-input) {
        border: 2px solid $color-form-border;
        box-shadow: none;
        border-radius: $base-border-radius;
        font-family: $raleway;
        font-size: $font-size;
        padding-left: 10px;
        padding-right: 36px;
        font-size: $font-size;
        line-height: 1.4;
        font-weight: $font-weight;
        color: $color-font;
        margin: 0;
        -webkit-appearance: none;

        &:not([disabled]) {
            &:focus {
                border-color: $color-form-border-focus;
                box-shadow: none;
            }
        }
    }

    button {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    &.pf-form-control-lg {
        height: 48px;
        padding: 0;

        md-autocomplete-wrap {
            height: 48px;
        }

        input:not(.md-input) {
            padding-left: 12px;
            padding-right: 36px;
            font-size: 16px;
            height: 48px;
        }

        button {
            top: 8px;
            right: 8px;
        }
    }

    &.pf-form-control--material {
        input:not(.md-input) {
            padding-left: 0;
            padding-right: 36px;
            border: 0;
            border-bottom: $color-form-border 2px solid;
            border-radius: 0;
            background-color: transparent;

            &:not([disabled]):focus {
                border-bottom: $color-electric 2px solid;
            }
        }
    }
}

.md-autocomplete-suggestions,
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
    &.md-portfolium-theme {
        z-index: $z-index-dialog + 3;
    }
}

.md-autocomplete-suggestions {
    &.md-portfolium-theme {
        li {
            font-size: $font-size;
            font-weight: $font-weight;
        }
    }
}

// Style error state for submitted forms
.ng-submitted {
    md-autocomplete.pf-form-control {
        input:not(.md-input) {
            &:not([disabled]) {
                &.ng-invalid {
                    &, &:hover {
                        border-color: $color-error;
                    }
                }
            }
        }
    }
}
