pf-company-connect-btn {
    .md-menu {
        padding: 0;
    }

    .md-button {
        padding: 0 8px;
        max-height: 36px;
        overflow: hidden;
        border: 1px solid transparent;
        line-height: 34px;

        &[disabled] {
            border-color: transparent;
        }

        md-icon {
            margin: 0;

            &.pf-icon-prepend {
                margin-right: 4px;

                @include max-screen($md-breakpoint-sm - 1) {
                    margin: 0;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .pf-remove-connection-text {
            @include position(absolute, 0, 0, 0, 0);
            display: none;
            line-height: 34px;
            color: $color-error;
        }

        &.pf-connected {
            color: $color-oxford;
            border-color: transparent;
        }

        &.pf-button-lg {
            .pf-remove-connection-text {
                line-height: 48px;
            }
        }

        &.pf-connected:not(.pf-mobile):not([disabled]) {
            &:hover,
            &:focus {
                .pf-connected-text {
                    visibility: hidden;
                }

                .pf-remove-connection-text {
                    display: block;
                }
            }
        }
    }
}
