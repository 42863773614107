&.md-fab {
    padding: 0 6px;

    &:not([disabled]) {
        // Shadow on hover/focus states
        &:hover,
        &:focus {
            box-shadow: $whiteframe-shadow-z3;
        }

        // Primary Color
        &.md-primary {
            &:hover,
            &:focus {
                background-color: $color-electric !important;
            }
        }
    }
}
