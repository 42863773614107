pf-navbar-user-dropdown {
    display: block;
    .md-portfolium-theme:not(.md-menu-toolbar) md-icon{
        color: rgba(255,255,255,0.8);
    }
}

.md-open-menu-container {
    md-menu-divider.pf-navbar-user-dropdown__divider {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
