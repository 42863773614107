@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

.pf-user-card {
    width: 100%;
    min-width: 256px;
    max-width: 256px;
    padding: 0 12px 16px;
}

.pf-user-card--extra-dense {
    .pf-user-card__avatar {
        margin: -27px 0 8px;
    }

    .pf-user-card-name {
        margin-bottom: 2px;
        @include typography-subhead();
        font-weight: $font-weight-medium;
    }

    .pf-user-card-school-major {
        color: $color-licorice;
    }
}

.pf-user-card__lozenge {
    margin-top: 12px;
}

.pf-user-card__cover-image {
    .pf-hero-image {
        margin: 0 -12px;
    }
}

.pf-user-card__avatar {
    margin: -32px 0 8px;
    @include avatar-circle-with-border(3px, 56px, $color-porcelain);
}

.pf-user-card-name {
    color: $color-licorice;
}

.pf-user-card-school-major {
    @include typography-body();
}

.pf-user-card-stats {
    margin-top: 16px;
}

.pf-user-card-stats--blank {
    margin-top: 26px;
}

.pf-user-card-stats__stat {
    &:not(last-child) {
        margin-right: 16px;
    }
}

.pf-user-card-stats__count {
    color: $color-licorice;
    @include typography-subhead();
    font-weight: $font-weight-medium;
}

.pf-user-card-stats__name {
    color: $color-licorice;
    @include typography-body();
    font-size: 14px;
    line-height: 16px;
}
