.pf-fade {
    &.ng-enter-prepare {
        opacity: 0;
    }

    &.ng-enter,
    &.ng-hide-remove {
        opacity: 0;
        transition: .3s ease opacity;

        &.ng-enter-active,
        &.ng-hide-remove-active {
            opacity: 1;
        }
    }

    &.ng-leave,
    &.ng-hide-add {
        opacity: 1;
        transition: .3s ease opacity;

        &.ng-leave-active,
        &.ng-hide-add-active {
            opacity: 0;
        }
    }
}

/**
 * Disable slide animation on mobile devices, until this bug is resolved:
 * https://github.com/angular/angular.js/issues/12969
 */
.pf-animation--slide-left-right {
    @include min-screen($md-breakpoint-md) {
        &.ng-enter-prepare {
            opacity: 0;
        }

        &.pf-direction--forward {
            &.ng-enter {
                transform: translate3d(120%, 0, 0);

                &.ng-enter-active {
                    transform: translate3d(0, 0, 0);
                }
            }

            &.ng-leave {
                transform: translate3d(0, 0, 0);

                &.ng-leave-active {
                    transform: translate3d(-120%, 0, 0);
                }
            }
        }

        &.pf-direction--backward {
            &.ng-enter {
                transform: translate3d(-120%, 0, 0);

                &.ng-enter-active {
                    transform: translate3d(0, 0, 0);
                }
            }

            &.ng-leave {
                transform: translate3d(0, 0, 0);

                &.ng-leave-active {
                    transform: translate3d(120%, 0, 0);
                }
            }
        }

        &.ng-enter {
            transition: .3s ease all;
            opacity: 0;

            &.ng-enter-active {
                opacity: 1;
            }
        }

        &.ng-leave {
            transition: .3s ease all;
            opacity: 1;

            &.ng-leave-active {
                opacity: 0;
            }
        }
    }
}
