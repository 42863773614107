body.ie {

    pf-filepicker {
        .pf-filepicker-centered {
            z-index: 0;
        }
    }

    &.ie10 {

    }

    &.ie11 {
        md-dialog {
            .pf-simple-sign-in {
                height: 100%;
            }
        }

        pf-dashboard md-toolbar.md-portfolium-theme.pf-subnav .md-toolbar-tools.pf-user-stats .pf-container {
            max-width: none !important;
        }

        .pf-edp-user-info__school {
            width: 100%;
        }

        .pf-lti-lander {
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
        }
    }

    // Edge
    &.ie12 {

    }
}
