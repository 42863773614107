@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-comment {
    display: block;
    position: relative;
    margin: 0 0 24px;
    @include typography-body();
    font-size: 14px;
    color: $color-licorice;
}

.pf-entry-viewer-comment--loading {
    .pf-entry-viewer-comment-meta {
        pf-blank-field {
            display: block;
            margin: 6px 0;
        }
    }

    .pf-entry-viewer-comment-meta__date {
        padding: 0;
    }
}

.pf-entry-viewer-comment__avatar {
    padding-right: 16px;
}

.pf-entry-viewer-comment__text {
    margin-top: 4px;
}

.pf-entry-viewer-comment-meta__author {
    @include typography-subhead();
    margin-right: 16px;

    a {
        color: $color-licorice;

        &:hover {
            color: $color-electric;
        }
    }
}

.pf-entry-viewer-comment-meta__date {
    padding: 2px 0;
    color: $color-ash;
    @include typography-body();
}

.pf-entry-viewer-comment-remove-btn.md-button {
    margin: -8px;
}
