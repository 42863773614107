@import '~client/main/sass/base/variables';

pf-field-label {
    display: contents;

    .pf-field-label {
        display: block;
        margin: 0 0 5px;

        &.required {
            &:after {
                content: " *";
                color: $color-error;
                vertical-align: top;
            }
        }
    }

    // don't show asterisk in md-select if it's in the label
    & + md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after,
    & + md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
        content: "";
    }
}
