// Base
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-sitewide-search-menu {
    display: block;
    pointer-events: all;
}

.pf-sitewide-search-menu-panel {
    margin-top: 6px;
    border-radius: 3px;
    width: 100%;
    max-width: 384px;
    max-height: initial;
    padding: 0;
}

.pf-sitewide-search-menu-item.md-2-line {
    min-height: 60px;
    height: 60px;

    & > .md-no-style {
        min-height: 60px;
        height: 60px;
    }

    .md-list-item-inner {
        min-height: 0;
        padding: 8px 0;
    }
}

.pf-sitewide-search-menu-item--focused {
    background-color: $color-porcelain;
}

md-icon.pf-sitewide-search-menu-item__icon {
    margin-top: auto;
    margin-right: 24px;
    margin-bottom: auto;
}

.pf-sitewide-search-menu-item-title {
    margin-bottom: 2px;
    @include typography-body();
    font-size: 14px;
    line-height: 16px;
    color: $color-licorice;
}

.pf-sitewide-search-menu-item-query {
    @include typography-body();
    font-size: 14px;
    line-height: 16px;
    color: $color-licorice;
}
