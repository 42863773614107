@import '~client/main/sass/base/mixins/background-gradient';

.pf-strength-meter {
    .pf-strength-meter-bar {
        $height: 4px;
        $borderWidth: 2px;
        $borderColor: $color-white;

        position: relative;
        height: $height;
        // border-radius: $height / 2;
        background-color: $color-tiara;
        position: relative;
        margin: 16px 0 0;

        .pf-strength-meter-level {
            position: relative;
            border-left: $borderWidth solid $borderColor;

            &:first-child {
                border-left-width: 0;
            }

            .pf-strength-meter-level-fill {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: $color-electric-light-8;
            }

            &:after {
                content: '';
                position: absolute;
                right: -7px;
                top: -5px;
                height: 14px;
                width: 14px;
                border-radius: 50%;
                background-color: $color-tiara;
                z-index: 3;
            }
        }

        .pf-strength-meter-level-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .pf-strength-meter-bar-fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 1;
            min-width: $height;
            transition: .3s ease width;
            @include background-gradient-primary-secondary();

            .pf-strength-level-indicator {
                position: absolute;
                width: 100px;
                height: 32px;
                top: -32px;
                right: -50px;

                text-align: center;
                @extend .pf-subhead-2;
                color: $color-ash;
                font-weight: $font-weight-medium;
                line-height: 32px;
            }
        }
    }
}

.pf-strength-label {
    font-size: 13px;
    letter-spacing: .1px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    margin: 16px 0 0;
    color: $color-oxford;
    text-align: right;
}

.pf-profile-strength-tips {
    h4 {
        font-weight: $font-weight-medium;
        margin: 0 0 8px;
    }

    ul {
        & > li {
            margin: 0 0 4px;

            a {
                @extend .pf-body;
                color: $color-electric;
            }
        }
    }
}
