@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/action-card';

pf-badge-card {
    display: block;
    max-width: 100%;
}

.pf-badge-card {
    text-align: center;
    @include action-card(24px 24px 35px);

    &:hover {
        .pf-badge-card__name {
            text-decoration: underline;
        }
    }
}

.pf-badge-card__image {
    height: 128px;
    width: 128px;
    margin-bottom: 16px;
}

.pf-badge-card__name {
    color: $color-electric;

    @include typography-subhead();
    font-weight: $font-weight-medium;
    letter-spacing: 0.15px;
}

.pf-badge-card__awarded-at {
    margin-top: 4px;
    color: $color-licorice;

    @include typography-subhead();
    letter-spacing: 0.15px;
}
