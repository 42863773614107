.pf-entry-viewer-teammates-teammate__remove-btn.md-button.md-icon-button {
    position: absolute;
    top: -7px;
    right: -7px;
    min-width: 0;
    line-height: 16px;
    padding: 0;
    height: 20px;
    min-height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 0;

    .material-icons {
        font-size: 16px;
    }

    md-icon {
        height: 16px;
        width: 16px;
        min-height: 16px;
        min-width: 16px;
        line-height: 16px;
        margin: 2px;
    }
}
