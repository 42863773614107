$notification-panel-width: 480px;

pf-notification-center-panel {
    height: 100%;
}

.pf-notification-center-panel__header {
    padding: 24px;
    background-color: $color-white;
    z-index: 1;
    @include whiteframe-shadow(1);

    .pf-panel-tabs {
        margin: -24px -24px -24px;
        background-color: transparent;

        .pf-button-group-tabs {
            height: 60px;
            justify-content: flex-start;

            .md-button.md-portfolium-theme {
                font-size: 13px;
                line-height: 60px;
                min-height: 60px;
                width: 50%;

                &.pf-active {
                    box-shadow: inset 0 -3px 0 0 $color-electric;
                }
            }
        }
    }
}

.pf-notification-center-panel__subhead {
    background-color: $color-background-base;
    border-bottom: 1px solid $color-border-base;
    min-height: 48px;
    overflow: hidden;

    .md-button.md-portfolium-theme {
        margin: 0;
    }

    h3,
    p {
        color: $color-ash;
    }

    h3 {
        font-size: 13px;
        font-weight: 600;
    }
}

.pf-notification-center-panel__content {
    height: auto;

    md-list {
        padding: 0;
    }

    md-progress-circular {
        left: 50%;
        margin-left: -12px;
    }

    pf-notification-list {
        .pf-notification-center-template-holder {
            display: flex;
            align-items: center;
        }
    }

    .pf-notification-action-button {
        margin-right: -16px;
    }

    .pf-notification-center-panel-list {
        background-color: $color-white;
        border-bottom: 1px solid $color-border-base;
    }

    .pf-notification-center-panel-list-item {
        background-color: transparent;
        text-align: left;
        cursor: default;
        outline: 0;

        .md-avatar {
            &.pf-company {
                border-radius: 10%;
            }
        }

        &.pf-disabled {
            pointer-events: none;
            opacity: .5;
        }

        + .pf-notification-center-panel-list-item {
            border-top: 1px solid $color-border-base;
        }
    }

    .pf-notification-center-panel-list-item__text {
        overflow: hidden;
        padding: 10px 0;
        align-items: inherit;

        .pf-notification-center-panel-list-item__from-user {
            font-weight: 600;
        }

        .pf-notification-center-panel-list-item__notification {
            font-weight: 600;
        }

        h3,
        p {
            @include typography-body();
            color: $color-oxford;
            word-break: break-word;
        }

        @-moz-document url-prefix() {
            h3,
            p {
                word-break: break-all;
            }
        }

        p {
            margin: inherit;
        }

        h3 {
            font-size: 15px;
            font-weight: 400;
        }

    }

    .pf-notification-center-panel-list-item--highlight {
        background-color: $color-porcelain;
    }
}

.pf-notification-center-panel__close-button {
    &.md-button.md-portfolium-theme {
        border: 1px solid $color-border-base;
        border-radius: 0;
        height: 56px;
        margin: 0;
        width: 100%;
    }

    md-icon {
        font-size: 20px;
    }
}
