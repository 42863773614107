/** TODO Figure out a way to use this shared class with profileAboutPanleEntry
 *
 * @description
 * Entry list styles, use with md-list
 *
 * ```
 * <md-list class="pf-entry-list">
 *      <div class="pf-strength-meter">
 *          <div class="pf-strength-meter-bar">
 *              <div class="pf-strength-meter-bar-fill">
 *              </div>
 *          </div>
 *      </div>
 * </md-list>
 * <div class="pf-entry-list-empty">
 *      <div class="pf-entry-list-empty__text"></div>
 *      <div class="pf-entry-list-empty__image"></div>
 *      <a>Add Work!</a>
 * </div>
 * ```
 *
 **/

.pf-entry-list {
    padding: 0;

    md-list-item {
        border-bottom: 1px solid $color-border-base;

        &:first-child {
            border-top: 1px solid $color-border-base;
        }

        .md-list-item-inner .md-avatar {
            height: 54px;
            width: 96px;
            border-radius: 2px;
        }

        &.pf-selected {
            background-color: $color-porcelain;
        }
    }

    .md-button.md-icon-button {
        md-icon {
            transition: none;
        }
    }

    .pf-strength-meter {
        width: 80px;
        margin: 4px 0;
    }

    .pf-strength-meter-bar {
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background-color: $color-porcelain;
        overflow: hidden;
        position: relative;
        margin: 0;
    }

    .pf-strength-meter-bar-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 3px;
        background-color: $color-electric-light-8;
    }
}

.pf-entry-list-empty {
    margin: 52px 0 105px 0;
    padding: 0 16px;
    text-align: center;

    a {
        text-transform: uppercase;
    }
}

.pf-entry-list-empty__text {
    max-width: 335px;
    color: $color-oxford;
    @include typography-subhead();
}

.pf-entry-list-empty__image {
    height: 128px;
    margin-bottom: 16px;
}
