pf-user-connect-btn {
    .md-menu {
        padding: 0;
    }

    .md-button {
        background-color: $color-white;

        &.md-portfolium-theme {
            &.pf-notification-button {
                background-color: $color-white;
                border: 1px solid $color-tiara;

                &[disabled] {
                    border: 1px solid $color-tiara;
                }
            }
        }

        md-icon {
            &.pf-simple-approve-deny--green {
                color: $color-success;
            }

            &.pf-simple-approve-deny--red {
                color: $color-error;
            }
        }

        &.pf-connected {
            color: $color-oxford;
        }

        &.pf-pending {
            background-color: $color-white;
            color: $color-ash;

            md-icon {
                color: $color-oxford;
            }
        }

        .pf-remove-connection-text {
            @include position(absolute, 0, 0, 0, 0);
            display: none;
            line-height: 36px;
            color: $color-error;
        }

        &.pf-button-lg {
            .pf-remove-connection-text {
                line-height: 48px;
            }
        }

        &.pf-connected:not(.pf-mobile):not([disabled]) {
            &:hover,
            &:focus {
                .pf-connected-text {
                    visibility: hidden;
                }

                .pf-remove-connection-text {
                    display: block;
                }
            }
        }
    }

    .pf-simple-approve-deny--response {
        &.md-button {
            &.md-portfolium-theme {
                background-color: $color-white;
                border: 1px solid $color-tiara;
            }
        }
    }
}
