@import '~client/main/sass/base/variables';

pf-entry-vote-button {
    display: block;

    .md-button.md-portfolium-theme.md-raised {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 8px;
        min-width: 0;

        md-icon {
            font-size: 18px;
            margin: 0 2px 0 0;
            padding: 3px;
        }
    }

    .md-button.md-portfolium-theme,
    .md-button.md-portfolium-theme.pf-voted {
        color: $color-electric;

        md-icon {
            color: $color-electric;
        }
    }
}

.pf-entry-vote-button__icon.material-icons {
    margin: 0 8px 0 0;
}
