// Style a panel to look like a sidenav
.pf-sidenav-panel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;

    @include min-screen($md-breakpoint-sm) {
        width: 480px;
    }
}

// ngMaterial toolbar overrides
.pf-sidenav-panel__toolbar,
.pf-sidenav-panel__footer {
    $height: 60px;

    min-height: $height;
    @include whiteframe-shadow(1);

    .md-toolbar-tools {
        height: $height;
        max-height: $height;

        h2 {
            color: $color-licorice;
            @include typography-title();
        }
    }
}

// Top header section
.pf-sidenav-panel__header {
    position: relative;
    padding: 20px 16px;
    background-color: $color-white;
    @include whiteframe-shadow(1);

    h2,
    h3 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    h2 {
        @include typography-title();
        color: $color-licorice;

        & + h3 {
            margin-top: 4px;
        }
    }

    h3 {
        @include typography-subhead();
        color: $color-oxford;
    }

    .pf-panel-tabs {
        margin: 20px -16px -20px;
        background-color: transparent;
    }
}

// Panel content
.pf-sidenav-panel__content {
    .pf-field {
        // Make form elements full width
        .pf-form-control {
            width: 100%;
        }

        // Default min height for textareas
        textarea.pf-form-control {
            min-height: 192px;
        }
    }
}

.pf-sidenav-panel--padded {
    padding: 16px;
}

// Panel footer
.pf-sidenav-panel__footer {
    $height: 60px;

    .pf-button-footer {
        padding: 0 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: $height;

        & > span {
            padding: 0 8px;

            .md-button {
                width: 100%;
                margin: 0;
            }
        }
    }
}
