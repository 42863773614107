
.pf-panel-sidenav-badge-list {
    background-color: $color-white;
    @include whiteframe-shadow(1);
}

.pf-panel-sidenav-badge-list__add-badge {
    padding: 8px 8px 0;

    .md-button.md-portfolium-theme {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        @include typography-subhead();
        font-weight: $font-weight-medium;
        text-transform: none;
        color: $color-oxford;
        border: 2px dashed $color-border-base;
        padding: 24px;
        border-radius: 2px;

        md-icon {
            margin: 0 8px 0 0;
            color: $color-ash;
        }
    }
}
