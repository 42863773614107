$profile-width: 1532px;
$profile-break: min-width $desktop max-width $profile-width;

.c_users .card.add-entry,
.c_users.a_likes .self .card.add-entry {
    display: none;
}

.c_users .self .card.add-entry {
    display: block;
}

.c_users.a_index,
.c_users.a_search {
    .cards {
        margin-top: 0;

        &.fluid {
            width: auto;
        }
    }
}

.c_users,
.c_companies,
.c_networks,
.c_contests,
.c_search.a_talent,
.c_jobs.a_feed {
    .hero {
        @include max-screen($mobile) {
            min-height: 200px !important;
        }
    }

    .shares {
        height: 250px;
        left: 0;
        position: absolute;
        right: 0;
        top: 64px;
        @include share-buttons;

        @include max-screen($mobile) {
            height: auto;
        }

        .buttons {
            top: 64px;

            @include max-screen($mobile) {
                opacity: 1;
                top: 12px;
                right: 12px;
                width: 32px;

                .action {
                    margin: 0 0 4px;
                    height: 32px;
                    width: 32px;
                    line-height: 32px;
                    text-align: center;

                    i {
                        line-height: 32px;
                        margin: 0;
                    }
                }
            }
        }

        &:hover .buttons {
            opacity: 1;
            top: 64px;
        }
    }

    .hero {
        min-height: 325px;
    }

    .full-width {
        @include clearfix;
        background-image: linear-gradient(
            0deg,
            $color-black-alpha-8 0%,
            $color-black-alpha-8 60%,
            transparent 100%
        );
        height: 128px;
        width: 100%;
        margin-top: -128px;
    }

    .profile-navbar {
        position: relative;
        height: 64px;
        background: $color-white;
        margin: 0 0 20px;

        @include max-screen($mobile) {
            margin: 0;

            &.self {
                height: 0;
            }
        }

        span.more {
            float: left;
            position: relative;
        }

        .username,
        .hashtag {
            font-weight: $font-weight-medium;
            margin: 0 16px;
        }
    }

    .stuck-bar {
        position: fixed;
        top: 60px;
        height: 64px;
        width: 100%;
        background: $color-white;
        box-shadow: $whiteframe-shadow-z1;
        z-index: $z-index-navbar - 2;

        @include max-screen($mobile) {
            display: none;
        }

        .profile-navbar-angled {
            display: none;
        }
    }

    .navbar-contents {
        margin: 0 auto;
        width: 100%;
        @include min-screen($profile-width) {
            width: $profile-width;
        }

        .navbar-contents-container {
            height: 64px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0 auto;
            position: relative;
        }

        h1 {
            @extend .pf-display-2;
            color: $color-white;

            @include max-screen($mobile) {
                font-size: 24px;
                line-height: 32px;
            }

            a {
                color: $color-white;

                &:hover {
                    color: $color-white;
                }
            }

            i {
                color: $color-white;
                font-size: 24px;
            }

            &.modal-title {
                color: $color-licorice;
            }
        }

        .profile-navbar-angled {
            width: 100%;
            position: absolute;
            bottom: 64px;
            left: 0;
            padding-left: 232px;
            padding-bottom: 16px;

            &.contest {
                padding-left: 50px;
            }

            @include max-screen($mobile) {
                margin-left: 0;
                padding: 0 16px 16px;
                width: 100%;
                text-align: center;
            }
        }

        .angle {
            float: left;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 75px 0 0 75px;
            border-color: transparent transparent transparent $color-white;
            margin-top: -75px;
            margin-left: 735px;

            @include max-screen($mobile) {
                display: none;
            }
        }

        .avatar-main-holder {
            .avatar-spinner {
                position: absolute;
                top: -85px;
                left: 92px;
                z-index: 3;
                font-size: 60px;
                color: $color-white;
                opacity: 0.6;
            }

            h6 {
                display: none;
                color: $color-ash;
                position: absolute;
                padding: 0 16px;
                line-height: 64px;
                font-size: 20px;
                font-weight: $font-weight-regular;
                width: 240px;

                a {
                    color: $color-ash;

                    &:hover {
                        color: $color-electric;
                    }
                }
            }

            .avatar-main {
                width: 200px;
                height: 200px;
                padding: 4px;
                border-radius: 4px;
                background: $color-white;
                position: absolute;
                bottom: 0;
                left: 0;
                margin: 16px;

                &.user {
                    border-radius: 50%;
                    margin: 8px 16px;
                }

                @include max-screen($mobile) {
                    width: 96px;
                    height: 96px;
                    padding: 3px;
                    margin: 0 0 0 -48px !important;
                    top: auto;
                    bottom: 144px;
                    left: 50%;
                }
            }

            .avatar-main-mini {
                width: 36px;
                height: 36px;
                padding: 0;
                border-radius: 3px;
                background: $color-white;
                border: none;
                margin: 12px 16px 0 0;
                float: left;

                &.user {
                    border-radius: 50%;
                }
            }
        }

        h5 {
            @extend .pf-subhead;
            color: $color-white;

            @include max-screen($mobile) {
                font-size: 14px;

                i {
                    font-size: 16px;
                    vertical-align: middle;
                }
            }

            i {
                vertical-align: top;
            }
        }

        .ctas {
            @include clearfix;
            float: right;
            margin: 0 16px;

            @include max-screen($mobile) {
                float: none;
                width: 100%;
                text-align: center;
                margin: 0;
                padding: 0 16px;

                .btn {
                    display: inline-block;
                }
            }

            .md-button {
                margin: 14px 6px;
                vertical-align: top;
                display: inline-block;

                &.md-icon-button {
                    margin: 8px 6px;
                }
            }

            .btn {
                padding: 3px 12px;
                font-weight: 600;
                margin-bottom: 0;
                line-height: 24px;

                @include max-screen($mobile) {
                    font-size: 13px;
                }

                &.inverse {
                    padding: 2px 12px;
                    &:hover {
                        background: $color-electric;
                        color: $color-white;
                    }
                }

                &.liked,
                &.member,
                &.pending {
                    cursor: default;
                }

                &.connected:hover {
                    background: $color-electric;
                    color: $color-white;
                    font-weight: 700;

                    &.cancel {
                        background-color: $color-crimson-dark-16;
                    }
                }
            }
        }

        .filters-holder {
            position: absolute;
            margin-left: 232px;
            height: 64px;

            &.contest {
                float: none;
                margin: auto;
                text-align: center;
                border: none;

                a {
                    height: 64px;
                    padding: 26px;
                    text-transform: uppercase;
                    margin-right: 50px;
                    font-weight: 600;
                    color: $color-electric;
                    border: none;

                    &.active {
                        border-bottom: 5px solid $color-electric;
                    }
                }
            }

            @include max-screen($mobile) {
                display: none;
            }

            @include min-screen($profile-break) {
                margin-right: 10px;
            }

            > a {
                float: left;
                border-right: 1px solid $color-border-base;
                height: 64px;
                padding: 0 24px;
                text-align: center;
                font-size: 14px;
                font-weight: $font-weight-medium;
                line-height: 14px;
                letter-spacing: 0.1px;
                text-transform: uppercase;
                color: $color-ash;
                transition: 0.2s ease all;
                user-select: none;
                -webkit-user-select: none;

                &:hover {
                    color: $color-ash;
                }

                & > span {
                    color: $color-oxford;
                    display: block;
                    margin: 12px 0 6px;
                    font-size: 18px;
                    line-height: 18px;
                }

                & > br {
                    display: none;
                }

                &.justified {
                    min-width: 128px;
                }

                &.contest {
                    height: 50px;
                }

                @include max-screen($phone) {
                    height: 40px;
                    width: 25%;
                    max-width: 120px;
                    font-size: 12px;
                }

                @include min-screen($profile-break) {
                    width: 98px;
                    font-size: 14px;
                    line-height: 23px;
                }

                &.active {
                    color: $color-ash;
                    box-shadow: inset 0 -2px 0 0 $color-electric;
                }

                &.icon-link {
                    min-width: 64px;
                    line-height: 64px;
                    border-right: 0;
                }
            }

            &.network,
            &.company {
                border-left: none;

                > a {
                    line-height: 64px;
                    border-right: none;
                }
            }
        }
    }

    // set up this modal overwrite for about modal on network pages
    .modal {
        .modal-inner {
            p.body {
                &.fullwidth {
                    columns: 1;
                    text-align: left;
                }
            }
        }
    }
}

.c_networks,
.c_entries.a_add {
    .segment.no-sidebar {
        article {
            h3 {
                font-size: 18px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
            }

            a {
                word-wrap: break-word;
            }
        }
    }
}

.c_jobs.a_feed {
    .popup.active {
        right: 0;
        max-height: 540px;

        .container {
            max-height: 540px;

            h3 {
                font-size: 18px;
                padding: 0;
                margin: 20px 0 0 20px;
            }

            fieldset {
                padding: 8px 20px;

                label {
                    padding-top: 0;
                }
            }
        }
    }
}
