@import '~client/main/sass/base/variables';

pf-field-help-text {
    .pf-field-help-text {
        margin: 6px 0 0 0;
    }

    a {
        color: $color-electric;
    }
}

/**
 * Help text should always be placed below error messages in accordance with our design system
 */
pf-field-error + pf-field-help-text {
    .pf-field-help-text {
        margin: 0;
    }
}
