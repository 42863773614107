md-chips.pf-form-control,
md-contact-chips.pf-form-control {
    height: auto;
    padding: 0;
    border: 0;

    .md-chips {
        font-family: $base-font-family;
        font-size: $font-size;
        box-shadow: 0 2px $color-form-border;
        margin-bottom: 16px;

        &.md-focused {
            box-shadow: 0 2px $color-form-border-focus;
        }

        &.md-removable {
            md-chip {
                .md-chip-content {
                    padding-right: 8px;
                }
            }
        }

        md-chip {
            font-family: $base-font-family;
            font-weight: $font-weight-regular;
            font-size: 15px;
            text-transform: none;

            &.md-focused {
                color: $color-white;
                background: $color-electric-light-8;
            }

            md-icon.md-portfolium-theme,
            .material-icons {
                transition: none;
            }
        }

        .md-chip-input-container {
            md-autocomplete {
                min-width: 400px;
            }

            input {
                font-family: $base-font-family;
                font-weight: $font-weight-regular;
                font-size: 15px;
                margin-bottom: inherit;
                box-shadow: none;
            }
        }
    }
}

.md-contact-suggestion {
    height: 72px;
    position: relative;
    padding: 16px 0px;

    img {
        position: absolute;
        top: 8px;
        left: 0;
    }

    .md-contact-name,
    .md-contact-email {
        width: auto;
        display: block;
        line-height: 20px;
        margin: 0;
        padding-left: 56px;
    }

    span.md-contact-name {
        color: $color-licorice;
    }

    span.md-contact-email {
        color: $color-oxford;
    }
}
