md-toolbar.md-portfolium-theme.pf-subnav {
    $nav-height-mobile: 48px;
    $nav-height-desktop: 56px;

    background-color: $color-navbar-base;
    min-height: $nav-height-mobile;
    height: $nav-height-mobile;
    max-height: $nav-height-mobile;
    padding: 0;
    transition: none !important;

    @include min-screen($md-breakpoint-md) {
        min-height: $nav-height-desktop;
        height: $nav-height-desktop;
        max-height: $nav-height-desktop;
        padding: 8px 16px 0;
    }
}
