$height: 56px;

pf-footer-navbar,
.pf-footer-navbar {
    md-toolbar.pf-toolbar--footer,
    md-toolbar.md-portfolium-theme.pf-toolbar--footer {
        align-items: center;
        background-color: $color-navbar-base;
        min-height: $height;

        .md-toolbar-tools {
            height: $height;
            max-height: $height;
            padding: 0;
            justify-content: center;

            .md-button {
                &.pf-mobile-tab-btn {
                    min-height: $height;
                    border-radius: 0;
                    margin: 0;
                    padding: 0;
                    color: $color-white;
                    line-height: 11px;
                    font-size: 11px;
                    text-transform: none;
                    display: flex;
                    box-sizing: border-box;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    min-width: 0;
                    transition: none;

                    md-icon {
                        margin: 0 0 4px;
                        color: $color-white-alpha-72;
                        transition: none;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }

                &.pf-active {
                    &.pf-mobile-tab-btn {
                        color: $color-white;

                        md-icon {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }

    &.pf-position-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $z-index-navbar;
    }
}

body.pf-has-footer-navbar {
    #pf-floating-actions-container {
        bottom: 72px;

        @include min-screen($md-breakpoint-md) {
            bottom: 16px;
        }
    }

    &.visitor {
        #pf-floating-actions-container {
            bottom: 16px;
        }
    }
}
