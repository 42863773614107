@import '~client/main/sass/base/variables';

pf-filestack-viewer-mobile {
        display: block;
        height: 100%;
        width: 100%;

    pf-filestack-viewer {
        display: block;
        height: 100%;
        width: 100%;

        iframe {
            border: 0;
        }
    }
}

.pf-filestack-viewer-mobile__close {
    &.md-button,
    &.md-button.md-icon-button {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 6px;
    }
}

.pf-filestack-viewer-mobile {
    height: 100vh;
    width: 100vw;
    background-color: $color-white;
}

.pf-filestack-viewer-mobile__content {
    height: 100%;
    width: 100%;
}
