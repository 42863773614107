body.c_widget.a_company,
body.c_widget.a_network {
    background: transparent;

    a {
        transition: all 0.2s ease-in-out;
    }

    .widget-container {
        background: $color-white;
        border-radius: 7px;
        border: 1px solid $color-oxford;
        margin: 0;
        max-width: 240px;
        padding: 0 0 5px 0;
        text-align: center;

        &.width220 {
            max-width: 220px;

            img.logo {
                margin-top: 10px;
            }
        }

        &.width180 {
            max-width: 180px;

            h1 {
                font-size: 16px;
            }

            img.logo {
                margin-top: 10px;
            }

            .btn {
                font-size: 12px;
                padding: 8px 6px;
            }
        }
    }

    img.cover {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 89px;
        width: 238px;
    }

    img.image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 4px;
        background: $color-white;
        margin-top: -60px;
        border: 1px solid $color-tiara;
    }

    h1 {
        font-size: 18px;
        color: $color-licorice;
    }

    h2 {
        font-size: 12px;
        margin-top: 5px;
        color: $color-oxford;
    }

    img.logo {
        width: 30px;
        height: 30px;
        margin-top: 18px;
    }

    p {
        margin: 10px 0;
        font-weight: 500;
        font-size: 12px;
        padding: 0px 10px;
        color: $color-oxford;
        line-height: 16px;
        font-weight: 600;
    }

    .btn-social {
        width: 100%;
        margin: 0 0 4px;
    }
}

.c_widget.a_entry {
    background-color: transparent;

    .widget-error {
        padding: 30px;
        text-align: center;

        .description {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        .logo {
            img {
                height: 30px;
            }
        }
    }
}
