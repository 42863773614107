.pf-footer {
    width: 100%;
    border-top: 1px solid $color-border-base;

    .pf-container {
        padding: 0 16px;
    }

    a {
        text-decoration: none;

        &:hover {
            color: $color-electric;
        }
    }
}

.pf-footer__inner {
    padding-top: 32px;
    padding-bottom: 72px;

    @media (min-width: $md-breakpoint-sm) {
        padding-top: 40px;
    }

    @media (min-width: $md-breakpoint-md) {
        padding-top: 64px;
        padding-bottom: 136px;
    }
}

.pf-footer-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.pf-footer-main__section {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    & > a,
    & > div > a {
        color: $color-oxford;
        @include typography-body-2();
        font-size: 14px;
        font-weight: $font-weight-regular;
        margin: 2px 0;
        padding-right: 24px;
    }

    @media (min-width: $md-breakpoint-md) {
        flex: 1 1 25%;
        max-width: 25%;
        max-height: 100%;
        box-sizing: border-box;
        margin-bottom: 64px;
    }
}

.pf-footer-main__section-header {
    margin-bottom: 16px;
    text-transform: uppercase;
    color: $color-licorice;
    @include typography-body-2();
    font-size: 14px;
}

.pf-footer-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
    border-top: 1px solid $color-border-base;
    color: $color-oxford;

    @media (min-width: $md-breakpoint-md) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 40px;
    }
}

.pf-footer-bottom__section {
    margin-bottom: 32px;

    @media (min-width: $md-breakpoint-md) {
        margin-bottom: 0;
    }
}

.pf-footer-company-logo {
    height: 21px;
    width: 170px;
    margin-right: 0;

    img {
        max-width: 100%;
    }

    @media (min-width: $md-breakpoint-md) {
        margin-right: 24px;
    }
}

.pf-footer-company-details {
    @include typography-body();
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    img {
        margin: 0 4px;
    }

    @media (min-width: $md-breakpoint-md) {
        flex: 1;
        flex-direction: row;
        align-items: flex-start;
        line-height: 14px;
    }
}

.pf-footer-company-details__made-with {
    display: flex;
    align-items: center;
}

.pf-footer-company-details__divider {
    display: none;
    margin: 0 8px;

    @media (min-width: $md-breakpoint-md) {
        display: inline-block;
    }
}

.pf-footer-social-media-icons {
    margin-right: 0;

    & > a {
        display: inline-grid;
        margin: 8px;
    }

    .icon-social {
        opacity: 0.4;
        width: 24px;
        height: 24px;

        &:hover {
            opacity: 0.8;
        }
    }

    @media (min-width: $md-breakpoint-md) {
        margin: 0 -8px -8px 0;
    }
}
