.pf-entry-cards {
    margin: 0 -16px;
}

.pf-entry-card--ingester {
    width: 256px;
    margin: 0 16px;
    min-height: 384px;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    border-radius: $base-border-radius;
    overflow: hidden;
    position: relative;
    transition: .2s ease all;

    &.ng-enter {
        box-shadow: none;
        opacity: 0;

        &.ng-enter-active {
            box-shadow: $whiteframe-shadow-z1;
            opacity: 1;
        }
    }

    &.ng-leave {
        box-shadow: $whiteframe-shadow-z1;
        opacity: 1;

        &.ng-leave-active {
            box-shadow: none;
            opacity: 0;
        }
    }

    .pf-entry-card-img {
        height: 160px;
        background-color: $color-porcelain;
        position: relative;
    }

    .pf-entry-card-body {
        padding: 16px 16px 68px;
    }

    .pf-entry-card-title {
        font-size: 14px;
        line-height: 20px;
        font-weight: $font-weight-medium;
        color: $color-electric;
        text-transform: uppercase;
        margin: 0 0 8px;
    }

    .pf-entry-card-description {
        font-size: 13px;
        line-height: 20px;
        font-weight: $font-weight-regular;
        color: $color-licorice;

        .pf-inline-edit-field {
            .pf-form-control {
                min-height: 78px;
                max-height: 78px;
            }
        }
    }

    .pf-inline-edit-field {
        overflow: hidden;
        text-overflow: ellipsis;

        .pf-editable {
            &:hover,
            &:focus {
                cursor: pointer;
                background-color: $color-fire-light-43;
            }
        }

        .pf-form-control {
            min-height: 48px;
            max-height: 48px;
            padding: 6px;
            font-size: 13px;
        }
    }

    .pf-entry-card-category {
        margin: 6px 16px;

        md-select.pf-form-control.md-portfolium-theme {
            &:not([disabled]) {
                &:hover,
                &:focus {
                    .md-select-value span:not(.md-select-icon) {
                        cursor: pointer;
                        background-color: $color-fire-light-43;
                    }
                }

            }

            .md-select-value {
                font-size: 13px;
                color: $color-ash;
                border: 0;
                padding: 0;

                .md-select-icon {
                    color: $color-tiara;
                    width: 14px;
                }
            }
        }
    }

    .pf-card-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 52px;

        .md-icon-button {
            height: 36px;
            width: 36px;
            line-height: 36px;
            margin: 8px 16px;
        }
    }

    .pf-card-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($color-white, .05);
        padding: 16px;
        transition: .2s ease all;

        &.ng-hide-remove {
            opacity: 0;

            &.ng-hide-remove-active {
                opacity: 1;
            }
        }

        &.ng-hide-add {
            opacity: 1;

            &.ng-hide-add-active {
                opacity: 0;
            }
        }

        .pf-card-overlay-actions {
            margin: 32px 0 0;
            width: 100%;

            .pf-btn-block {
                margin: 4px 0;
            }
        }
    }
}
