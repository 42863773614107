.pf-file-attachments {
    color: $color-oxford;

    table {
        margin-top: 0;
    }

    tr {
        border-bottom: 1px solid $color-porcelain;

        &:last-child {
            border: 0;
        }
    }

    td {
        border: 0;

        &.pf-file-icon {
            width: 60px;
            text-align: center;
        }

        &.pf-file-name {
            padding-left: 10px;
            font-size: 14px;
            word-break: break-all;

            a {
                color: $color-oxford;
            }
        }

        &.pf-file-size {
            text-align: right;
            padding-right: 10px;
            font-size: 14px;
            width: 80px;
        }
    }

    i {

        &.word {
            &.attached {
                color: $color-microsoft-word;
            }
        }

        &.powerpoint {
            &.attached {
                color: $color-microsoft-powerpoint;
            }
        }

        &.excel {
            &.attached {
                color: $color-microsoft-excel;
            }
        }

        &.pdf {
            &.attached {
                color: $color-adobe-pdf;
            }
        }
    }
}
