.pf-field {
    margin-bottom: 24px;

    pf-field-error {
        display: none;
    }
}

.pf-field--no-margin {
    margin-bottom: 0;
}

.pf-field--inline {
    .pf-field-label {
        margin: 0 12px 2px 0;
    }
}

.ng-submitted {
    pf-field-error {
        display: block;
    }
}

.pf-no-results--autocomplete {
    text-align: center;
    color: $color-ash;
}

.pf-no-results--list {
    text-align: center;
    margin: 14px 0;
    color: $color-ash;
}
