pf-entry-card-blank {
    display: block;
}

.pf-entry-card-blank {
    &.pf-blank-list__item {
        padding: 0;
    }
}



