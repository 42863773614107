md-bottom-sheet.md-portfolium-theme {
    position: fixed;
    z-index: $z-index-dialog;
    background-color: $color-white;
    border: 0;
}

md-backdrop.md-bottom-sheet-backdrop.md-portfolium-theme {
    position: fixed;
    z-index: $z-index-dialog - 1;
}
