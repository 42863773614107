
@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-navbar-standard {
    display: block;
    position: relative;
    background-color: $color-navbar-base;

    .pf-container {
        max-width: $md-breakpoint-lg;
        margin: 0 auto;
    }

    .pf-bottom-nav-tab.md-button {
        min-height: 0;
        min-width: 80px;
        line-height: 0;
    }

    .pf-add-entry-btn {
        margin-right: 0;
        margin-left: 16px;
    }

    .pf-logo-button {
        padding: 0 16px;
        margin-right: 8px;
        margin-left: -16px;

        img {
            width: 130px;
        }
    }
}

.pf-navbar-standard-search-desktop {
    max-width: 256px;
    min-width: 120px;
    margin: 0;
}

.pf-navbar--has-mobile-content {
    // Hide the logo on mobile since there will be specialized mobile content
    .pf-logo-button {
        display: none;

        @include min-screen($md-breakpoint-md) {
            display: block;
        }
    }
}

.pf-navbar-standard-notification-btn {
    position: relative;
}

.pf-navbar-btn-search.md-button.md-icon-button {
    margin: 0;
}

.pf-navbar-btn-search--active.md-button.md-icon-button,
.pf-navbar-btn-search--active.md-button.md-icon-button:hover {
    background-color: $color-white-alpha-32;
}

.pf-switch-divider {
    height: 40px;
    background-color: $color-white-alpha-32;
    width: 1px;
    margin: 0 16px;
}

.pf-navbar-standard-search {
    .pf-search-input-content__input.pf-form-control {
        height: 40px;
        padding: 10px 40px 10px 10px;
        background-color: $color-white;
    }

    .pf-search-input-content__action {
        height: 40px;
        width: 40px;

        & > .md-button.md-icon-button:not([disabled]) {
            top: 0;
            right: 0;

            &.md-focused {
                background-color: $color-porcelain;
            }

            &:hover md-icon.md-portfolium-theme,
            md-icon.md-portfolium-theme {
                color: $color-oxford;
                fill: $color-oxford;
            }
        }

        md-icon.md-portfolium-theme {
            color: $color-oxford;
            fill: $color-oxford;
        }
    }
}
