.pf-table-wrapper {
    position: relative;
}

.pf-table-wrapper--loading {
    & > *:not(.pf-table-loading) {
        transition: .3s ease opacity;
        opacity: .32;
    }
}

.pf-table-loading {
    position: absolute;
    z-index: 1;
    top: 12px; // Center loader over the thead row
    left: 50%;
    margin-left: -16px;
    transition: .3s ease all;

    &.ng-enter {
        opacity: 0;

        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;

        &.ng-leave-active {
            opacity: 0;
        }
    }
}

.pf-table-loading--with-header {
    margin-top: 100px; // Center loader of the thead row
}
