@import 'vertical-stepper';

// Main step wrapper
.pf-stepper-step {
    // Style default paragraph text
    p {
        @include typography-body();
        color: $color-licorice;
        margin: 0 0 24px;
    }
}

// Modifiers for active & complete steps
.pf-stepper-step--active,
.pf-stepper-step--complete {
    .pf-step-indicator-number {
        background-color: $color-electric;
    }

    .pf-stepper-step-content-header__heading {
        color: $color-licorice;
    }
}

// Modifiers for complete steps
.pf-stepper-step--complete {
    .pf-step-indicator-number__complete {
        display: block;
    }

    .pf-step-indicator-number__incomplete {
        display: none;
    }
}

// Marker that shows the step number
.pf-step-indicator-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    @include typography-body();
    font-weight: $font-weight-bold;
    background-color: $color-ash;
    color: $color-white;

    md-icon {
        display: block;
        color: $color-white;
    }
}

.pf-step-indicator-number__complete {
    // Hide the checkmark unless the step is marked complete
    display: none;
}

// Step content header
.pf-stepper-step-content-header {
    margin: 0 0 16px;
}

.pf-stepper-step-content-header__heading {
    @include typography-subhead();
    color: $color-oxford;
    font-weight: $font-weight-medium;
}

// Step actions row
.pf-stepper-step-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;
    margin: 0 -4px;

    .md-button {
        margin: 0 4px;
    }
}
