@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-navbar-empty {
    display: block;
    background-color: $color-navbar-base;

    .pf-container {
        padding: 0 8px;
        margin: 0 auto;
        max-width: $md-breakpoint-lg;

        @include min-screen($md-breakpoint-md) {
            padding: 0 16px;
        }
    }
}
