// Vendor
@import '~client/main/sass/base/variables';

pf-school-autocomplete {
    display: block;
}

.md-autocomplete-suggestions.pf-school-autocomplete {
    li {
        border-bottom: 1px solid $color-border-base;
        height: 68px;

        &:last-of-type {
            border-bottom-width: 0;
        }
    }
}

.pf-school-autocomplete__school {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    word-wrap: break-word;
    font-size: 16px;
}

.pf-school-autocomplete__location {
    display: block;
    font-size: 12px;
    line-height: 1px;
    opacity: .60;
}

.pf-school-autocomplete__create {
    font-size: 16px;
    line-height: 68px;
}

/* This is not cool I am sorry guys */
md-tooltip.pf-school-autocomplete__tooltip {
    z-index:  $z-index-dialog + 5;
}
