// Main stepper container
.pf-vertical-stepper {
    padding: 12px 48px 0 24px;
}

// Individual step row
.pf-vertical-stepper-step {
    position: relative;
    padding: 0 0 0 24px;
    min-height: 64px;
}

// Modifier for last step
.pf-vertical-stepper-step--last {
    .pf-vertical-stepper-step-indicator {
        // Don't show the vertical line after last step
        &:after {
            display: none;
        }
    }
}

// Step indicator column
.pf-vertical-stepper-step-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24px;
    padding: 8px 0 0 0;

    &:after {
        content: '';
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 11px;
        width: 1px;
        background-color: $color-border-base;
    }
}

// Main step content
.pf-vertical-stepper-step-content {
    // 9px padding-top accounts for line height of the heading text
    padding: 9px 0 24px 12px;
}
