@import '~client/main/sass/base/variables';

pf-entry-card-ribbon,
.pf-entry-card-ribbon-legacy {
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 8px;
}

.pf-entry-card-ribbon {
    padding: 0 8px;
    margin: 0 0 4px;
    line-height: 28px;
    font-size: 13px;
    letter-spacing: .1px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $color-white;
    border-radius: 2px;
    background-color: $color-porcelain;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pf-entry-card-ribbon--staff-pick {
    background-color: $color-ribbon-staff-pick;
}

.pf-entry-card-ribbon--expert-pick {
    background-color: $color-ribbon-expert-pick;
}

.pf-entry-card-ribbon--featured-pick {
    background-color: $color-ribbon-featured-pick;
}

.pf-entry-card-ribbon--private {
    background-color: $color-ribbon-private;
    color: $color-oxford;
}
