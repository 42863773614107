@mixin share-buttons() {
    .buttons {
        float: right;
        opacity: 0;
        position: absolute;
        z-index: 2;
        right: 16px;
        top: -16px;
        transition: all 0.2s ease-in-out;

        .action {
            background: center center no-repeat;
            background-color: $color-black-alpha-48;
            background-size: 20px 20px;
            border-radius: $base-border-radius;
            color: $color-white;
            cursor: pointer;
            display: inline-block;
            float: left;
            font-size: 16px;
            height: 40px;
            margin-left: 4px;
            overflow: hidden;
            width: 40px;
            transition: all 0.1s ease-in-out;

            i {
                font-size: 20px;
                margin: 10px 0 0 12px;
            }

            &:hover {
                &.facebook {
                    background-color: $color-facebook;
                }
                &.twitter {
                    background-color: $color-twitter;
                }
                &.pinterest {
                    background-color: $color-pinterest;
                }
                &.linkedin {
                    background-color: $color-linkedin;
                }
                &.embed {
                    background-color: $color-electric-light-8;
                }
                &.trash {
                    background-color: $color-crimson-dark-16;
                }
            }
        }
    }
}
