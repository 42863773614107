/**
*   For use on buttons that undo an action that was already completed
*   Hides Completed action text and shows Undo action text on hover
*
*   <button class="pf-action-undo-warning-button">
*       <span class="pf-action-undo-warning-button__text--main">
*           Completed action
*       </span>
*       <span class="pf-action-undo-warning-button__text--undo">
*           Undo Action
*       </span>
*    </button>
*/

.pf-action-undo-warning-button:not([disabled]) {
    &:hover,
    &:focus {
        .pf-action-undo-warning-button__text--main {
            visibility: hidden;
        }

        .pf-action-undo-warning-button__text--undo {
            display: block;
        }
    }
}

.pf-action-undo-warning-button__text--undo {
    @include position(absolute, 0, 0, 0, 0);
    display: none;
    color: $color-error;
}
