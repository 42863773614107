@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-card-checkbox {
    outline: none;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    transition: $transition-default-card;

    &:hover {
        background-color: $color-porcelain;

        .pf-card-checkbox__title {
            text-decoration: underline;
        }
    }

    &:focus,
    &:active {
        background-color: $color-porcelain;

        .pf-card-checkbox__title {
            text-decoration: underline;
        }
    }
}

.pf-card-checkbox__image-wrapper {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 56.25%;

    &--selected:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-tiara;
        content: '';
    }

    &--unavailable {
        background-color: $color-porcelain;
    }
}

.pf-card-checkbox__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    box-shadow: $whiteframe-shadow-z1;
    width: 100%;
    height: 100%;
}

.pf-card-checkbox__unavailable-icon {
    padding: 8px;
    margin-bottom: 8px;
    background-color: $color-porcelain;
    border-radius: 50%;
    margin-bottom: 8px;

    md-icon {
        color: $color-oxford;
    }
}

.pf-card-checkbox__unavailable-text {
    color: $color-ash;

    @include typography-subhead();
}

md-icon.md-portfolium-theme.pf-card-checkbox__check {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    color: $color-white;
    transition: none;
}

.pf-card-checkbox__title {
    margin-top: 8px;
    color: $color-licorice;

    @include typography-subhead();
    font-weight: $font-weight-bold;
}

.pf-card-checkbox__description {
    margin-top: 2px;
    color: $color-licorice;

    @include typography-body();
    font-size: 14px;
}
