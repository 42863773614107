.pf-subnav-toolbar {
    width: 100%;
    min-height: 48px;
    height: 48px;

    .md-button.md-portfolium-theme {
        min-height: 48px;
        height: 48px;
    }

    @include min-screen($md-breakpoint-md) {
        width: 100%;
        min-height: 56px;
        height: 56px;

        .md-button.md-portfolium-theme {
            min-height: 56px;
            height: 56px;
        }
    }
}
