@mixin avatar($size: 80px, $borderWidth: 3px) {
    border-radius: 50%;
    height: $size + ($borderWidth * 2);
    width: $size + ($borderWidth * 2);
    line-height: $size;
    text-align: center;
    border: $borderWidth solid $color-white;
    position: relative;
    overflow: hidden;

    & > img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 50%;
        box-shadow: $whiteframe-shadow-z1;

        &.ng-enter,
        &.ng-hide-remove {
            transition: .1s ease-in all;
            opacity: 0;
            transform: scale3d(.7, .7, .7);

            &.ng-enter-active,
            &.ng-hide-remove-active {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
        }

        &.ng-leave,
        &.ng-hide-add {
            transition: .1s ease-out all;
            opacity: 1;
            transform: scale3d(1, 1, 1);

            &.ng-leave-active,
            &.ng-hide-add-active {
                opacity: 0;
                transform: scale3d(.7, .7, .7);
            }
        }

        &.ng-enter-prepare {
            opacity: 0;
        }
    }

    md-icon {
        position: absolute;
        top: 16px;
        left: 16px;
        color: $color-porcelain;
        z-index: 1;
    }
}

.pf-avatar--network {
    $size: 80px;
    $borderWidth: 3px;

    @include avatar($size, $borderWidth);
    position: absolute;
    bottom: -(($size / 2) + $borderWidth);
    left: 50%;
    margin-left: -(($size / 2) + $borderWidth);
    z-index: 2;
    background-color: $color-white;

    border-radius: 4px;
    bottom: auto;
    top: 128px;
    margin-top: -(($size / 2) + $borderWidth);

    & > img {
        border-radius: 3px;
    }
}

.pf-avatar--profile {
    @include avatar(40px, 16px);
    background-color: $color-porcelain;

    & > md-icon {
        top: 8px;
        left: 8px;
        color: $color-ash;
    }
}
