@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-breadcrumbs {
    display: inline-block;
    max-width: 100%;
    margin: 0 0 24px;
    color: $color-oxford;
}

.pf-breadcrumbs a {
    transition: none;

    @include typography-body-2();
    font-size: 14px;
    color: $color-oxford;
}

.pf-breadcrumbs a:hover {
    color: $color-licorice;
}

.pf-breadcrumbs .material-icons {
    margin-right: 4px;
}

.pf-breadcrumbs a > span {
    line-height: 26px;
}

.pf-breadcrumbs__loading-icon {
    opacity: 0.4;
}
