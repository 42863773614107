.pf-simple-accordion {
    contain: content;
    padding: 16px 0;
    box-shadow: inset 0 -1px 0 0 $color-porcelain;
}

.pf-simple-accordion-header {
    width: 100%;
    outline-offset: -3px;
    cursor: pointer;
    text-align: left;
}

.pf-simple-accordion-header:not(.collapsed) {
    margin-bottom: 24px;
}

.pf-simple-accordion-header__text {
    text-align: start
}

.pf-simple-accordion-header__icon {
    padding: 0 8px;
}

.pf-simple-accordion-body {
    margin: 0;
}
