.pf-page {
    .main {
        &:before {
            display: none;
        }
    }

    md-content {
        background-color: $color-white !important;
    }

    @import 'container';
}
