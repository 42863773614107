@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

$card-border-radius: 2px;

pf-entry-card-mobile-discover {
    display: block;
    height: 100%;

    .pf-entry-card {
        height: 100%;
        border-radius: $card-border-radius;
    }
}

.pf-entry-card-mobile-discover__default-image {
    .pf-entry-card-default-image {
        border-radius: $card-border-radius $card-border-radius 0 0;
        overflow: hidden;
    }
}

.pf-entry-card-mobile-discover__title {
    @include typography-body();
    font-weight: $font-weight-medium;
    margin: 16px 16px 4px;

    a,
    a:hover,
    a:focus {
        color: $color-licorice;
    }
}

.pf-entry-card-mobile-discover__category {
    @include typography-body();
    margin: 0 16px 8px;

    a,
    a:hover,
    a:focus {
        color: $color-oxford;
    }
}

.pf-entry-card-mobile-discover__profile {
    margin: 0 16px 16px;
}

// Footer
.pf-entry-card-mobile-discover-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 48px;
    padding: 4px;
    border-top: 1px solid $color-border-base;

    .pf-entry-card-mobile-discover-footer__icon-button {
        margin: 0 4px;

        .md-button.md-icon-button {
            margin: 0;
        }
    }
}

// Like count
.pf-entry-card-like-jewel {
    @include typography-body();
    font-size: 14px;
    line-height: 36px;
    font-weight: $font-weight-medium;
    color: $color-oxford;
    margin-left: -6px;
}
