/**
 * @description
 * Display a list of tabs that span the full width of the screen, with buttons
 * of equal width.
 *
 * Spec: https://material.io/guidelines/components/tabs.html#tabs-specs
 *
 * ```
 * <div class="pf-tabs pf-tabs--fixed">
 *     <div class="pf-tabs__inner">
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *     </div>
 * </div>
 * ```
 **/
.pf-tabs.pf-tabs--fixed {
    width: 100%;

    .pf-tabs__inner {
        width: 100%;
        justify-content: initial;
    }

    .pf-tab-btn {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        span {
            display: block;
            width: 100%;
        }
    }
}
