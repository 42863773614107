.tokenfield {
    font-weight: 400;
    padding: 5px;
    text-transform: none;

    input[type="text"] {
        margin: 5px 0;
    }

    .twitter-typeahead {
        .tt-hint {
            height: 34px;
            padding: 10px;
        }
    }

    .token {
        border: 1px solid $color-form-border;
        height: 34px;
        line-height: 32px;
        margin: 5px;
        padding: 0 10px;

        &.active,
        &.active:hover {
            border: 1px solid $color-form-border-focus;
        }

        &:hover {
            cursor: pointer;
        }

        .close {
            color: $color-ash;
            font-size: 1.4em;
            line-height: 32px;
            margin-left: 8px;
            padding-right: 0;
            transition: none;

            &:hover {
                color: $color-crimson;
            }
        }
    }
}
