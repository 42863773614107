.c_invite,
.c_connections {

    .segment {

        &.pending-connections {
            .feed {
                margin: 0 auto;
                width: 2 * $user-card-fixed-total-width;

                @include max-screen($mobile) {
                    width: 100%;
                }
            }
        }

        .segment-sidebar {
            @include max-screen($mobile) {
                display: none;
            }
        }

        article {
            @include max-screen($mobile) {
                width: 100%;
                margin: 0 auto;

                .tab-container {
                    display: none;
                    height: 35px;
                    padding: 0 10px;

                    a {
                        font-size: 9px;
                        font-weight: 600;
                        height: 35px;
                        padding: 10px;
                        margin: 0;
                    }
                }

                form {
                    padding: 20px;
                }

                h1 {
                    margin-top: 0;
                    font-size: 20px;
                    padding: 10px 15px;
                }

                .padding {
                     padding: 10px;
                }
            }
        }
    }
}
