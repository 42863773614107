@import '~client/main/sass/base/mixins/background-gradient';

.pf-entry-card--standard {
    position: relative;

    &:not(.pf-mobile):hover {
        .pf-entry-card-header {
            transform: translate3d(0, 100%, 0);
        }

        .pf-default-image {
            overflow: hidden;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            &:after {
                opacity: 1;
            }
        }
    }

    &.pf-mobile {
        padding-top: 48px;

        .pf-entry-card-header {
            transform: translate3d(0, 100%, 0);
            transition: none;
        }

        .pf-default-image .pf-image-wrap {
            border-radius: 0;
        }
    }

    .pf-default-image {
        background-color: $color-porcelain;
        position: relative;
        cursor: zoom-in;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            display: block;
            content: '';
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }

        .pf-image-wrap {
            overflow: hidden;
            @include position(absolute, 0, 0, 0, 0);

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        &:after {
            content: '';
            @include position(absolute, 0, 0, 0, 0);
            z-index: 1;
            background-color: $color-black-alpha-24;
            opacity: 0;
            transition: 0.3s ease opacity;
        }
    }

    .pf-padding {
        padding: 16px 16px 0;
    }

    .pf-field--title {
        margin: -4px 0 6px;
        font-weight: $font-weight-medium;
        word-break: break-word;
    }

    .pf-field--description {
        @extend .pf-body;
        margin: 0;
        position: relative;
        overflow: hidden;
        height: 60px;
        color: $color-licorice;
        cursor: pointer;

        &:after {
            content: '';
            height: 32px;
            @include position(absolute, none, 0, 0, 0);
            background-image: linear-gradient(
                0deg,
                $color-white-alpha-72 0%,
                $color-white-alpha-32 30%,
                transparent 100%
            );
        }
    }

    .pf-field--category {
        @extend .pf-body;
        color: $color-ash;
        margin: 16px 0;
        padding: 0 16px;

        .pf-category-link {
            @extend .pf-body;
            color: $color-ash;

            &:hover {
                color: $color-electric-dark-6;
            }
        }
    }

    .pf-strength-meter {
        padding: 0 16px 24px;

        .pf-strength-meter-label {
            @extend .pf-body-2;
            line-height: 1;
            margin: 0 0 8px;
        }

        .pf-strength-meter-bar {
            $height: 6px;

            height: $height;
            border-radius: $height / 2;
            background-color: $color-porcelain;
            overflow: hidden;
            position: relative;
            margin: 0;

            .pf-strength-meter-bar-fill {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: $height / 2;
                @include background-gradient-primary-secondary();
            }
        }
    }
}
