@import '~client/main/sass/base/variables';

pf-accordion {
    .md-button.md-icon-button {
        min-width: 40px;
        margin: 0 0 0 8px;
    }

    .pf-accordion-header {
        cursor: pointer;

        &:hover {
            .pf-accordion-header__title {
                text-decoration: underline;
            }
        }
    }

    .pf-accordion-header__title {
        margin: 16px 0;
    }

    .pf-accordion-content {
        padding: 0 0 24px;
    }

    &[disabled] {
        .pf-accordion-header {
            cursor: default;

            &:hover {
                .pf-accordion-header__title {
                    text-decoration: none;
                }
            }
        }
    }
}
