@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-pathway-requirement-submission-panel-editor-field-list,
pf-pathway-requirement-submission-panel-editor-field-list-checkbox {
    display: block;
}

md-content.md-portfolium-theme.pf-pathway-requirement-submission-panel-editor-field-list-checkbox-wrapper {
    background-color: $color-white;
    margin: 0 -12px;

    @include max-screen($md-breakpoint-md - 1) {
        flex-wrap: nowrap;
        margin: 0 -24px;
        padding: 0 12px;
    }
}

.pf-pathway-requirement-submission-panel-editor-field-list-checkbox {
    width: 33.3%;
    max-width: 33.3%;
    min-width: 33.3%;
    padding: 4px;
    outline: none;
    cursor: pointer;

    @include max-screen($md-breakpoint-md - 1) {
        width: 259px;
        max-width: 259px;
        min-width: 259px;
    }
}

.pf-pathway-requirement-submission-panel-editor-field-list__item {
    height: 48px;
    margin: 0 -12px;
    padding: 12px;
    cursor: pointer;
    outline: none;
    border-radius: 3px;

    &:hover {
        background-color: $color-porcelain;
    }

    &:focus {
        background-color: $color-porcelain;
    }
}

.pf-pathway-requirement-submission-panel-editor-field-list__title {
    color: $color-licorice;
    margin: 0 -12px;
    padding: 12px;
    border-radius: 3px;

    @include typography-subhead();
    font-weight: $font-weight-bold;
    line-height: 24px;
}

.pf-pathway-requirement-submission-panel-editor-field-list__title--toggle-enable {
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: $color-porcelain;
    }

    &:focus {
        background-color: $color-porcelain;
    }
}

.pf-pathway-requirement-submission-panel-editor-field-list__checkbox {
    padding-right: 32px;

    md-checkbox {
        margin: 0;
        width: 24px;
        height: 24px;

        .md-container {
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.pf-pathway-requirement-submission-panel-editor-field-list__text {
    color: $color-licorice;

    @include typography-subhead();
}
