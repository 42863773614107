// Use 56px height instead of 48px from the MD spec
$tab-height: 48px;

@import '~client/main/sass/base/mixins/media_sizes';
@import 'modifiers/fixed';
@import 'modifiers/icon-text';
@import 'modifiers/scrollable';
@import 'partials/button';

/**
 * @description
 * ```
 * <div class="pf-tabs">
 *     <div class="pf-tabs__inner">
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *     </div>
 * </div>
 * ```
 **/
.pf-tabs {
    display: block;
    position: relative;
    overflow: auto;
    min-height: $tab-height;
    margin: 0 auto;
}

.pf-tabs__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pf-tabs--left-aligned {
    .pf-tabs__inner {
        justify-content: flex-start;
    }
}
