@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';
@import '~client/main/sass/base/mixins/position';

pf-image-picker {
    display: block;
    position: relative;
    cursor: pointer;

    &:hover {
        opacity: .7;
    }

    .pf-loading-container {
        z-index: 3;
        @include position(absolute, 0, 0, 0, 0);
    }

    .pf-image-picker-image {
        position: relative;

        &:after {
            content: '';
            @include position(absolute, 0, 0, 0, 0);
            background-color: $color-ash;
            opacity: 0;
            transition: .2s ease opacity;
        }

        &:hover {
            &:after {
                opacity: 1;
            }

            .md-button.md-icon-button.pf-image-picker-action {
                opacity: 1;
            }
        }
    }

    .md-button.md-icon-button.pf-image-picker-action {
        position: absolute;
        z-index: 2;
        opacity: 0;
        margin: 8px;
        transition: .2s ease all;

        md-icon {
            color: $color-white;
        }

        &:focus {
            opacity: 1;
            background-color: $color-ash;
        }
    }

    .pf-image-picker-update {
        top: 0;
        right: 0;
    }

    .pf-image-picker-delete {
        top: 0;
        left: 0;
    }

    .pf-image-picker-prompt {
        &.pf-loading {
            opacity: .5;
        }
    }

    .pf-empty {
        border-radius: 4px;
        border: 2px dashed $color-border-base;
        text-align: center;
        padding: 16px;
    }
}
