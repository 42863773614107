@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

.pf-jobs-search-filters__field {
    padding: 16px 16px 24px;
    border-bottom: 1px solid $color-border-base;

    &:last-child {
        border-bottom: 0;
    }

    .pf-field {
        margin-bottom: 0;
    }
}

.pf-jobs-search-filters__header {
    p {
        margin-bottom: 0;
    }

    .md-button {
        padding: 0 8px;
        min-width: 0;
        margin: 0 -8px 0 0;
    }
}
