table.pf-table {
    margin: 0;
    padding: 0;
    table-layout: auto;

    &.pf-table-fixed {
        table-layout: fixed;
    }

    tr {

        &.pf-action-row {
            cursor: pointer;
            transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

            &:hover {
                background-color: $color-porcelain;
            }

            &:focus {
                outline: 0;
                background-color: $color-porcelain;
            }
        }

        & > td,
        & > th {
            color: $color-oxford;

            &:first-child {
                padding-left: 24px;
            }

            &:last-child {
                padding-right: 24px;
            }

            &.pf-align-right {
                text-align: right;
            }
        }

        & > th {
            font-size: 15px;
            font-weight: $font-weight-medium;
            line-height: 20px;
            letter-spacing: .1px;
            border-bottom: 1px solid $color-porcelain;
            padding: 18px 12px;
            color: $color-ash;
        }

        & > td {
            font-size: 15px;
            line-height: 20px;
            border-bottom: 1px solid $color-porcelain;
            padding: 14px 12px;

            .md-button {
                margin: 0 8px;
            }

            &.pf-button-cell {
                padding-top: 6px !important;
                padding-bottom: 6px !important;

                .md-button {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.pf-form-control-cell {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            &.pf-actions-cell {
                padding-top: 4px;
                padding-bottom: 4px;
                padding-right: 4px;
                white-space: nowrap;

                .md-button.md-portfolium-theme {
                    margin: 0 4px;

                    &.md-icon-button {
                        margin: 0 8px;
                    }
                }
            }
        }

        &:last-child {
            & > td {
                border-bottom: 0;
            }
        }

        &.pf-row-link {
            &[role="button"],
            & > td[role="button"] {
                cursor: pointer;
                outline: 0;

                &:focus,
                &:active {
                    background-color: $color-porcelain;
                }
            }
        }
    }

    tfoot {
        & > tr {
            & > td {
                border-top: 1px solid $color-porcelain;

                &.pf-pagination-cell {
                    padding: 8px 20px;

                    .md-button.md-icon-button {
                        margin: 0 8px;

                        &:last-child {
                            margin-right: -8px;
                        }
                    }
                }
            }
        }
    }

    .md-button {
        &:not([disabled]) {
            &.md-icon-button {
                color: $color-oxford;
            }
        }
    }
}
