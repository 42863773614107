.pf-entry-card--feed {
    position: relative;
    box-shadow: none;
    border: 1px solid $color-border-base;
    margin-bottom: 8px;

    .pf-entry-card-header {
        top: 0;
    }

    .pf-default-image {
        background-color: $color-porcelain;
        position: relative;
        cursor: zoom-in;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }

        .pf-image-wrap {
            overflow: hidden;
            @include position(absolute, 0, 0, 0, 0);

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        &:after {
            content: '';
            @include position(absolute, 0, 0, 0, 0);
            z-index: 1;
            background-color: $color-black-alpha-24;
            opacity: 0;
            transition: .3s ease opacity;
        }
    }

    .pf-padding {
        padding: 8px 0 8px 16px;
    }

    .pf-entry-details {
        min-height: 64px;
    }

    .pf-field--title {
        display: block;
        margin: -4px 0 0;
        font-size: 14px;
        line-height: 22px;
    }

    .pf-field--description {
        @extend .pf-body;
        margin: 0;
        position: relative;
        overflow: hidden;
        min-height: 60px;
        color: $color-licorice;
        cursor: pointer;

        &:after {
            content: '';
            height: 32px;
            @include position(absolute, none, 0, 0, 0);
            background-image: linear-gradient(0deg, $color-white-alpha-72 0%, $color-white-alpha-32 30%, transparent 100%);
        }
    }

    .pf-field--category {
        display: block;
        @extend .pf-body;
        line-height: 16px;
        color: $color-oxford;
        margin: 0;

        .pf-category-link {
            @extend .pf-body;
            font-size: 14px;
            color: $color-oxford;

            &:hover {
                color: $color-electric-dark-6;
            }
        }
    }

    .pf-entry-card-footer {
        .pf-icon-button-jewel {
            margin-right: 10px;
        }
    }
}
