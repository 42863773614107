.pf-auth-column--sidebar {
    background-color: $color-electric;
    position: relative;

    .pf-logo {
        position: absolute;
        top: 128px;
        left: 50%;
        margin-left: -73px;
        margin-top: -14px;
        z-index: 3;
        transform: translate3d(0, 0, 0);
        transition: .3s ease transform;

        &.pf-network-selected {
            transform: translate3d(0, 80px, 0);
        }
    }

    .pf-avatar {
        z-index: 2;
    }

    .pf-network-background {
        @include position(absolute, 0, 0, 0, 0);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .pf-slide-footer {
        padding: 0 32px 32px;
        color: $color-white;

        p {
            margin: 0;
        }

        md-icon {
            color: $color-white;
        }

        a {
            color: $color-white;
            display: block;

            span {
                font-weight: $font-weight-medium;
            }

            &:hover, &:focus {
                color: $color-white;
            }
        }

        .pf-alert-msg {
            padding-left: 16px;
        }
    }
}
