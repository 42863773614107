@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-user-result {
    display: block;
    max-width: 100%;
    width: 100%;
}

.pf-user-result-wrapper {
    padding: 16px;
    background-color: $color-white;
    border-top: 1px solid $color-border-base;

    @include min-screen($tablet) {
        padding: 24px 0 24px 16px;
    }
}

.pf-user-result__avatar {
    @include avatar-circle(2px, 64px);
    margin-right: 16px;
    pointer-events: none;
}

.pf-user-result__left {
    max-width: 100%;
}

.pf-user-result__middle {
    max-width: 100%;
    min-width: 0;
}

.pf-user-result__heading-wrapper {
    max-width: 100%;
    align-items: center;
}

.pf-user-result__heading {
    @include typography-subhead();
    margin-right: 16px;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $color-licorice;

    &:hover {
        opacity: .56;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 16px;
    }
}

.pf-user-result__content {
    width: 100%;
    max-width: 100%;
}

.pf-user-result__title {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    @include typography-subhead();
    color: $color-licorice;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 14px;
    }
}

.pf-user-result__tagline {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    color: $color-oxford;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 14px;
    }
}

.pf-user-result-connect-btn.md-button {
    margin: 16px 0 0 -16px;
    padding: 0 16px;
}

.pf-user-result__entries {
    margin-left: 15px;
}

.pf-user-result-entry__image-wrapper {
    margin-right: 20px;
    width: 140px;
}

.pf-user-result-entry__image {
    position: relative;
    cursor: zoom-in;
    border-radius: 3px;
}

.pf-user-result-entry__image--default {
    box-shadow: $whiteframe-shadow-1dp;
}

.pf-user-result-entry__image--empty {
    border-radius: 3px;
    background-color: $color-porcelain;
    padding-top: (9 / 16) * 100%;
    pointer-events: none;
}

.pf-user-result-entry__image--empty-1 {
    opacity: .8;
}

.pf-user-result-entry__image--empty-2 {
    opacity: .6;
}

