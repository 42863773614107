.twitter-typeahead {
    width: 100% !important;

    .tt-input,
    .tt-hint {
        color: $color-licorice;
        font-size: $base-font-size;
        width: 100% !important;
    }

    .tt-hint {
        height: 34px;
        padding: 10px;
    }

    .tt-dropdown-menu {
        border-radius: 3px;
        border: 1px solid $color-tiara;
        box-shadow: 0 1px 4px 0 $color-ash;
        margin-top: 0;
        max-height: 190px;
        overflow: auto;

        .tt-suggestion {
            color: $color-licorice;
            padding: 8px 16px 7px;

            &.tt-cursor,
            &:hover,
            &:focus {
                filter: none;

                background: $color-porcelain;
                color: $color-licorice;
                cursor: pointer;
            }

            p {
                margin: 0;
                text-transform: none;
            }

            .user {
                padding-left: 56px;
                position: relative;

                .avatar {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}
