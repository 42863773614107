&.pf-outline {
    border: 1px solid $color-tiara;
    border-radius: 3px;
    padding: 0 16px;
    min-height: 34px;
    line-height: 34px;

    .md-20 {
        height: 24px;
        width: 20px;
        font-size: 20px;
        line-height: 20px;
    }

    &:not([disabled]) {
        // Change background color on hover/focus states
        &:hover,
        &:focus {
            background-color: $color-porcelain;
        }
    }
}
