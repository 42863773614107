@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-empty-results {
    display: block;
    width: 100%;
}

.pf-empty-results {
    width: 100%;
    padding: 32px 16px;
    text-align: center;
}

.pf-empty-results__icon {
    width: 48px;
    height: 48px;
    margin: auto;
    padding: 12px;
    border-radius: 50%;
    background-color: $color-porcelain;

    md-icon {
        color: $color-oxford;
    }
}

.pf-empty-results__title {
    margin-top: 8px;
    color: $color-licorice;

    @include typography-subhead();
    font-weight: $font-weight-bold;
}

.pf-empty-results__action {
    cursor: pointer;
    color: $color-electric;
    font-weight: $font-weight-bold;
}

.pf-empty-results__sub-title {
    color: $color-oxford;

    @include typography-subhead();
}
