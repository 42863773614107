.pf-job-card--list {
    &.md-2-line {
        position: relative;
        padding: 0px 16px;
        align-items: flex-start;
        height: auto;
        display: flex;
        align-items: center;
        min-height: 90px;
        flex: 1 1 auto;
        margin: auto;
        text-overflow: ellipsis;

        .md-avatar {
            margin-bottom: 8px;
            margin: 20px;
            flex: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-sizing: content-box;
        }

        .md-list-item-text {
            overflow: hidden;

            .pf-company-title,
            .pf-job-location {
                margin-right: 15px;
            }

            .pf-company-title {
                font-weight: 600;
                line-height: 16px;

                a {
                    color: $color-licorice;
                }
            }

            .pf-job-title {
                line-height: 18px;
                font-weight: 400;

                a {
                    color: $color-ash;
                }
            }

            .pf-job-location {
                color: $color-ash;
            }

            @include max-screen($md-breakpoint-sm - 1) {
                a {
                    font-weight: 400;
                    color: $color-ash;
                }

                .pf-company-title {
                    flex-direction: row;
                    justify-content: flex-start;
                }

                .pf-job-title {
                    line-height: 16px;
                    font-size: 14px;
                    font-weight: 400;
                    color: $color-ash;
                }
            }
        }

        .pf-apply {
            padding: 0 8px;
            overflow: hidden;
            border: 1px solid transparent;
            line-height: 34px;
            max-height: 36px;

            &:not([disabled]) {
                border-color: $color-tiara;
            }
        }
    }
}
