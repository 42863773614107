.pf-hero {
    height: 576px;
    padding: 0;
    position: relative;
    @include background-gradient-primary-secondary();

    @include max-screen($mobile) {
        padding: 100px 22px 30px;
        height: auto;
    }

    h1 {
        @extend .pf-display-1;
        color: $color-white;
        margin: 0 0 16px;

        @include max-screen($mobile) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    h2 {
        @extend .pf-title;
        color: $color-white;
        font-weight: $font-weight-regular;
        margin: 0 0 32px;
        line-height: 30px;
    }

    &.pf-hero--img {
        & > * {
            position: relative;
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            opacity: .06;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
