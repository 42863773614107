.pf-table-empty-state {
    text-align: center;
    padding: 48px 24px 50px;
    font-size: 16px;
}

.pf-table-empty-state__image {
    height: 192px;
    width: 192px;
    margin-bottom: 16px;
}

.pf-table-empty-state__title {
    margin: 0;
    font-size: 20px;
    line-height: 1em;
    font-weight: $font-weight-medium;
    color: $color-licorice;
}

.pf-table-empty-state__text {
    margin: 8px 0 0;
    font-size: 16px;
    line-height: 1.5em;
    color: $color-oxford;
}

.pf-table-empty-state__link {
    margin-top: 16px;
}
