@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-comment-list {
    display: block;
}

.pf-entry-viewer-comment-list {
    padding: 16px 16px 0;
    @include typography-body();
    color: $color-licorice;
    line-height: 24px;
    font-size: 14px;

    @media (min-width: $md-breakpoint-sm) {
        margin: 48px 0 40px;
        padding: 0;
    }
}

.pf-entry-viewer-comment {
    position: relative;
    margin: 0 0 24px;
    padding-left: 64px;
    @include typography-body();
    font-size: 14px;
    color: $color-licorice;
}

.pf-entry-viewer-comment__avatar {
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 56px;
}

.pf-entry-viewer-comment-meta {
    margin: 0 0 4px;
}

.pf-entry-viewer-comment-meta__author {
    @include typography-subhead();
    margin-right: 16px;
}

.pf-entry-viewer-comment-list-load-more.md-button {
    margin-bottom: 24px;
}
