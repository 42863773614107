@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-pagination-control-current-total {
    font-size: 15px;
    font-weight: $font-weight-medium;
    color: $color-oxford;
}

.pf-search-pagination-navigation-btn.md-button.md-icon-button {
    margin: 0 0 0 16px;
}
