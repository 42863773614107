.sweet-overlay {
    z-index: $z-index-sweet-alert;
    background-color: $color-ash;
}

.sweet-alert {
    z-index: $z-index-sweet-alert + 1;
    padding: 24px 24px 0;
    font-family: $base-font-family;
    border-radius: 4px;
    box-shadow: $whiteframe-shadow-z5;

    fieldset {
        margin: 0;
        padding: 0;
    }

    h2 {
        @extend .pf-title;
        margin: 0 0 16px;
        color: $color-licorice;
        text-align: left;
    }

    p {
        @extend .pf-subhead;
        color: $color-ash;
        text-align: left;
    }

    .sa-icon {
        margin: 0 auto;
        display: none !important;
    }

    .sa-button-container {
        margin: 20px -24px 0;
        padding: 0 8px 0 16px;
        min-height: 52px;
        text-align: right;

        button {
            border-radius: 3px;
            font-family: $raleway;
            font-size: 14px;
            font-weight: 700;
            box-shadow: none !important;
            padding: 0 16px;
            font-weight: 600;
            letter-spacing: .1px;
            min-height: 36px;
            line-height: 36px;
            margin: 8px 0 8px 8px;
            display: inline-block;
            text-transform: uppercase;
            background-color: transparent !important;
            color: $color-electric !important;
            border: 0 !important;
            transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;

            &:hover,
            &:focus {
                background-color: $color-porcelain !important;
                border: 0 !important;
                box-shadow: none !important;
            }
        }
    }
}
