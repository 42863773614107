.hide-desktop {
    @include min-screen($desktop) {
        display: none !important;
    }
}

.hide-desktop-tablet {
    @include min-screen($mobile-small) {
        display: none !important;
    }
}

.hide-mobile {
    @include max-screen($mobile) {
        display: none !important;
    }
}

.hide-phone {
    @include max-screen($phone) {
        display: none !important;
    }
}

.mobile-no-pad {
    @include max-screen($mobile) {
        padding: 0 !important;
    }
}

.mobile-no-mar {
    @include max-screen($mobile) {
        margin: 0 !important;
    }
}

body.desktop {
    [hide-desktop] {
        display: none;
    }
}

body.mobile {

    [hide-mobile] {
        display: none;
    }

    .prevent-mobile {
        display: none !important;
    }

    .card {
        .card-image {
            .buttons {
                display: none;
            }
        }
    }

    .pf-edp-content__mobile {
        background-color: transparent;
    }
}

.mobile-menu {
    $mobile-menu-background: $color-white;
    $mobile-menu-color: $color-white;
    $mobile-menu-font-size: 15px;
    $mobile-menu-link-color: transparentize($mobile-menu-color, .6);
    $mobile-menu-disclaimer-color: transparentize($mobile-menu-color, .6);

    @include position(fixed, 0 auto 0 0);
    width: 264px;
    height: 100%;
    transform: translateX(-320px);
    transition: .3s ease all;
    background: $mobile-menu-background;
    color: $color-licorice;
    box-shadow: $whiteframe-shadow-z3;
    z-index: $z-index-modal;
    padding: 0;
    height: 100%;
    color: $color-white;
    font-size: $mobile-menu-font-size;
    -webkit-overflow-scrolling: touch;

    &.is-visible {
        transform: translateX(0);
    }

    .close-menu-btn {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    ul {
        & > li {
            a:not(.md-button) {
                font-size: 15px;
                padding: 0 16px;
                line-height: 48px;
                font-weight: $font-weight-regular;
                color: $color-licorice;
                display: block;

                &:hover {
                    background-color: $color-porcelain;
                }
            }

            &.divider {
                border-bottom: 1px solid $color-border-base;
                margin: 8px 0;
            }

            &.has-btn {
                padding: 8px 16px;
            }
        }
    }

    li h3 {
        color: $mobile-menu-color;
        font-size: 1em;
        font-weight: 800;
        margin-bottom: .4em;
    }

    hr {
        border: 1px solid transparentize($mobile-menu-disclaimer-color, .3);
        margin: 0 auto 10px;
    }

    p {
        color: $mobile-menu-disclaimer-color;
        font-size: .9em;
        line-height: 1.5em;
        margin: auto;
        max-width: 35em;
        text-align: center;
    }

    .quote {
        color: $color-oxford;
        font-size: 11px;
    }

    .pf-list {
        padding: 8px 0;
    }
}
