.twitter-typeahead {
    .pf-form-control {
        &.tt-input,
        &.tt-hint {
            height: 40px;
            padding: 10px;
            font-size: $font-size;
        }
    }
}
