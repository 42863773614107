@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/background-gradient';

.pf-hero-image {
    position: relative;
    background-size: 100% auto;
    background-position: top center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    // Maintain 16x9 aspect ratio while image is loading
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (1 / 4) * 100%;
        background-color: $color-black-alpha-8;
    }
}

.pf-hero-image--default {
    @include background-gradient-primary-secondary();
}
