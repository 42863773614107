.pf-section--featured-in {
    @include max-screen($mobile) {
        padding-top: 32px;
    }

    .pf-title {
        margin: 0 0 64px;

        @include max-screen($mobile) {
            line-height: 30px;
            margin-bottom: 32px;
        }
    }

    .pf-row {
        margin: 0 -16px;

        & > [flex] {
            padding: 0 16px;

            @include max-screen(960px) {
                margin: 16px 0;
            }
        }
    }

    .pf-logo {
        text-align: center;
        background-color: $color-porcelain;
        text-align: center;
        padding: 16px 16px;
        border-radius: 6px;

        img {
            width: 100%;
            max-width: 100%;
            opacity: .54;
            display: block;
            margin: 0 auto;

            @include max-screen($mobile) {
                width: 50%;
            }
        }
    }
}
