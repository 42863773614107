@import '~client/main/sass/base/mixins/avatars';
@import '~client/main/sass/base/mixins/position';

.pf-mobile-menu-auth-header {
    position: relative;
    padding: 80px 16px 16px;
    background-size: cover;
    background-position: center;

    &:before {
        @include position(absolute, 0, 0, 0, 0);
        z-index: 0;
        background-color: $color-black-alpha-24;
        content: '';
    }

    &:after {
        z-index: 1;
        @include position(absolute, 0, 0, 0, 0);
        background-image: linear-gradient(0deg, $color-black-alpha-72 0%, $color-black-alpha-48 30%, transparent 100%);
        content: '';
    }
}

.pf-mobile-menu-auth-header__content {
    position: relative;
    z-index: 2;
}

.pf-mobile-menu-auth-header-profile__avatar {
    @include avatar-circle(0, 64px);
    margin-right: 16px;
}

.pf-mobile-menu-auth-header-profile-info__name {
    display: block;
    margin: 0 0 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $color-white;
    @include typography-body-2();
    font-size: 16px;
}

.pf-mobile-menu-auth-header-profile-info__view {
    display: block;
    @include typography-body-2();
    line-height: 13px;
    color: $color-white;
}
