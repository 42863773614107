// Base
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

// Main element

pf-resume-upload {
    max-width: 100%;
    word-break: break-word;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

// Empty resume hotspot
.pf-resume-upload {
    padding: 16px;
    z-index: 1;
    border-radius: $base-border-radius;
    border: 2px dashed $color-form-border;
    cursor: pointer;
    transition: 0s ease opacity;
    text-align: center;

    md-progress-circular {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
        z-index: 1;
    }

    .pf-muted {
        color: $color-oxford;
        font-size: 13px;
    }
}

.pf-resume-upload--has-resume {
    border: 2px solid $color-form-border;
    cursor: default;
}

.pf-upload-success-icon.material-icons {
    font-size: 24px;
    margin: 0;
}

.pf-upload-content--loading {
    opacity: .70;
    cursor: default;
}

.pf-upload-content__image {
    margin: 16px;
    height: 96px;
}

.pf-resume-upload-content__success {
    @include typography-subhead();
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: $font-weight-medium;
    color: $color-oxford;
}

.pf-resume-upload-content__remove-resume {
    @include typography-subhead();
    line-height: 18px;
    font-weight: $font-weight-medium;
    color: $color-electric-light-16;
    cursor: pointer;
}

// Empty resume hotspot
.pf-resume-upload-hotspot {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    padding: 16px;
    z-index: 1;
    border-radius: 3px;
    border: 2px dashed $color-form-border;
    cursor: pointer;
    transition: 0s ease opacity;

    // Dim during loading
    &.pf-loading {
        opacity: .24;
        cursor: default;
    }
}

.pf-resume-upload-hotspot--column {
    flex-direction: column;

    .pf-resume-upload__image {
        margin: 24px 0 16px;
    }

    .pf-resume-upload-text-block {
        display: inherit;
        flex-direction: inherit;
    }
}

.pf-resume-upload-text-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.pf-resume-upload-hotspot--uploaded {
    height: 100%;
    padding: 16px;
    border: 2px solid $color-form-border;
    color: $color-oxford;

    .md-button.md-icon-button {
        align-self: flex-start;
        margin: -16px -16px 0 0;
        color: $color-form-border;
    }

    md-icon.pf-md-icon {
        margin: 0 24px 0 0;
        height: 50px;
    }
}

.pf-resume-upload-hotspot--remove {
    text-align: center;
}

.pf-resume-upload__image {
    height: 96px;
    margin-right: 40px;
}

.pf-resume-upload__file-types {
    color: $color-oxford;
    @include typography-body();
}

.pf-resume-upload__text {
    color: $color-oxford;
    @include typography-subhead();
}

.pf-resume-upload__text--bold {
    font-weight: $font-weight-medium;
}

.pf-resume-upload__text--no-margin {
    margin: 0;
}

// Loading spinner
.pf-resume-upload__loading {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
}

// Form validation styles
.ng-submitted {
    pf-resume-upload.ng-invalid {
        .pf-job-apply-resume__hotspot {
            border-color: $color-error;
        }
    }
}
