md-backdrop {
    &.md-opaque {
        &.md-portfolium-theme {
            background-color: $color-black-alpha-48;
        }
    }

    &.md-dialog-backdrop {
        &.md-portfolium-theme {
            position: fixed;
            top: 0 !important;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

md-dialog {
    h2.md-title {
        margin-bottom: 16px;
    }

    p {
        margin: 16px 0;
    }
}

body.md-dialog-is-showing {
    // Override select menu z-index when dialog is showing
    .md-select-backdrop.md-portfolium-theme {
        z-index: $z-index-dialog + 3;
    }

    .md-select-menu-container.md-portfolium-theme {
        z-index: $z-index-dialog + 4;
    }

    // Override tooltip z-index when dialog is showing
    md-tooltip {
        z-index: $z-index-dialog + 1;
    }

    // Override toast z-index when dialog is showing
    md-toast {
        z-index: $z-index-dialog + 2 !important;
    }
}

body.md-dialog-is-showing.pf-darkened-backdrop {
    // Special styling override for EDP or other cases where backdrop should be darker
    md-backdrop.md-opaque.md-portfolium-theme {
        background-color: $color-black-alpha-48 !important;
        opacity: 1;
    }
}

.fb_dialog {
    z-index: $z-index-filepicker !important;
}

.md-dialog-container {
    z-index: $z-index-dialog + 1;
}

.pf-dialog {
    $height: 60px;

    md-toolbar {
        min-height: $height;

        .md-toolbar-tools {
            height: $height;
            max-height: $height;

            h2 {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    md-dialog-content {
        display: block;
        padding: 16px;

        md-switch {
            margin: 0;
        }
    }

    md-dialog-actions {
        padding: 8px 16px;
        min-height: 68px;

        .md-button.md-portfolium-theme.pf-btn-block {
            margin: 0;
        }
    }
}

/**
 * Overrides for a basic dialog with a little more padding (24px).
 * Well suited for dialogs with a short form
 **/
.pf-dialog--md {
    md-dialog-content {
        display: block;
        padding: 24px;
    }

    md-dialog-actions {
        margin: -16px 0 0;
        padding: 16px 24px;
        min-height: 84px;
    }
}

.pf-dialog-content {
    padding: 16px;
}

.pf-dialog-disclaimer {
    padding: 0 16px 24px;
    @include typography-body();
    color: $color-oxford;
    text-align: center;

    p {
        margin: 0;

        & + p {
            margin-top: 4px;
        }
    }
}

.pf-dialog-title {
    @include typography-title();
    margin: 16px 0;
    color: $color-licorice;
}

.pf-dialog-terms {
    color: $color-ash;
    font-size: 12px;
    padding: 0;
    margin: 6px 0 0;

    a {
        color: $color-ash;
        border-bottom: 1px dotted $color-tiara;
    }
}

.pf-dialog-success-header {
    max-width: 480px;

    md-icon {
        padding: 18px;
        font-size: 24px;
        height: 64px !important;
        width: 64px !important;
        border-radius: 50%;
        border: 2px solid $color-success;
        color: $color-success;
        margin: 8px auto;
        display: block;
    }

    .pf-dialog-headline {
        text-align: center;
    }

    .pf-dialog-sub-headline {
        text-align: center;
    }
}

.pf-dialog-close-btn.md-icon-button {
    margin: -10px -10px 0 6px;
}

@import './dialogs/white';

.pf-dialog--no-border {
    md-toolbar.md-portfolium-theme {
        border-bottom: none;
    }
}

@import './dialogs/table';
@import './dialogs/simple';
@import './dialogs/alert';
@import './dialogs/v2';
