.pf-split-pane-template {
    min-height: 100%;
    margin: 0 auto;
    width: 100%;
    max-width: $md-breakpoint-lg;
    background-color: $color-white;

    @include min-screen($md-breakpoint-md) {
        padding: 24px 16px 0;
        background-color: $color-background-base;
    }
}

.pf-split-pane-template__scroll {
    overflow-y: auto;
}

.pf-split-pane-template__sidebar {
    width: 100%;
    max-width: 100%;
    background-color: $color-white;

    md-content.md-portfolium-theme {
        background-color: $color-white;
    }

    @include min-screen($md-breakpoint-md) {
        max-width: 390px;
        margin-right: 40px;
        background-color: transparent;

        md-content.md-portfolium-theme {
            background-color: transparent;
        }
    }

}

.pf-split-pane-template__content {
    background-color: $color-white;

    @include min-screen($md-breakpoint-md) {
        border-radius: 3px;
        box-shadow: $whiteframe-shadow-1dp;
    }
}

.pf-split-pane-template__content--full-height {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.pf-split-pane-template__sidebar--inactive,
.pf-split-pane-template__content--inactive {
    @include max-screen($md-breakpoint-md - 1) {
        display: none;
    }
}
