/**
 * Provide presets for material layering and shadows, but allow for custom color
 * https://www.google.com/design/spec/layout/structure.html#structure-whiteframes
 * https://github.com/angular/material/blob/master/src/core/style/variables.scss
 */
$shadow-key-umbra-opacity:      0.1;
$shadow-key-penumbra-opacity:   0.07;
$shadow-ambient-shadow-opacity: 0.06;

$whiteframe-shadows: (
    (0px 1px 3px 0px, 0px 1px 1px 0px, 0px 2px 1px -1px),
    (0px 1px 5px 0px, 0px 2px 2px 0px, 0px 3px 1px -2px),
    (0px 1px 8px 0px, 0px 3px 4px 0px, 0px 3px 3px -2px),
    (0px 2px 4px -1px, 0px 4px 5px 0px, 0px 1px 10px 0px),
    (0px 3px 5px -1px, 0px 5px 8px 0px, 0px 1px 14px 0px),
    (0px 3px 5px -1px, 0px 6px 10px 0px, 0px 1px 18px 0px),
    (0px 4px 5px -2px, 0px 7px 10px 1px, 0px 2px 16px 1px),
    (0px 5px 5px -3px, 0px 8px 10px 1px, 0px 3px 14px 2px),
    (0px 5px 6px -3px, 0px 9px 12px 1px, 0px 3px 16px 2px),
    (0px 6px 6px -3px, 0px 10px 14px 1px, 0px 4px 18px 3px),
    (0px 6px 7px -4px, 0px 11px 15px 1px, 0px 4px 20px 3px),
    (0px 7px 8px -4px, 0px 12px 17px 2px, 0px 5px 22px 4px),
    (0px 7px 8px -4px, 0px 13px 19px 2px, 0px 5px 24px 4px),
    (0px 7px 9px -4px, 0px 14px 21px 2px, 0px 5px 26px 4px),
    (0px 8px 9px -5px, 0px 15px 22px 2px, 0px 6px 28px 5px),
    (0px 8px 10px -5px, 0px 16px 24px 2px, 0px 6px 30px 5px),
    (0px 8px 11px -5px, 0px 17px 26px 2px, 0px 6px 32px 5px),
    (0px 9px 11px -5px, 0px 18px 28px 2px, 0px 7px 34px 6px),
    (0px 9px 12px -6px, 0px 19px 29px 2px, 0px 7px 36px 6px),
    (0px 10px 13px -6px, 0px 20px 31px 3px, 0px 8px 38px 7px),
    (0px 10px 13px -6px, 0px 21px 33px 3px, 0px 8px 40px 7px),
    (0px 10px 14px -6px, 0px 22px 35px 3px, 0px 8px 42px 7px),
    (0px 11px 14px -7px, 0px 23px 36px 3px, 0px 9px 44px 8px),
    (0px 11px 15px -7px, 0px 24px 38px 3px, 0px 9px 46px 8px),
);

$whiteframe-shadow-1dp: 0px 1px 3px 0px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 1px 1px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 2px 1px -1px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-2dp: 0px 1px 5px 0px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 2px 2px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 3px 1px -2px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-3dp: 0px 1px 8px 0px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 3px 4px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 3px 3px -2px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-4dp: 0px 2px 4px -1px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 4px 5px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 1px 10px 0px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-5dp: 0px 3px 5px -1px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 5px 8px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 1px 14px 0px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-6dp: 0px 3px 5px -1px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 6px 10px 0px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 1px 18px 0px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-7dp: 0px 4px 5px -2px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 7px 10px 1px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 2px 16px 1px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-8dp: 0px 5px 5px -3px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 8px 10px 1px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 3px 14px 2px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-9dp: 0px 5px 6px -3px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 9px 12px 1px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 3px 16px 2px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-10dp: 0px 6px 6px -3px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 10px 14px 1px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 4px 18px 3px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-11dp: 0px 6px 7px -4px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 11px 15px 1px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 4px 20px 3px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-12dp: 0px 7px 8px -4px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 12px 17px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 5px 22px 4px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-13dp: 0px 7px 8px -4px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 13px 19px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 5px 24px 4px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-14dp: 0px 7px 9px -4px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 14px 21px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 5px 26px 4px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-15dp: 0px 8px 9px -5px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 15px 22px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 6px 28px 5px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-16dp: 0px 8px 10px -5px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 16px 24px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 6px 30px 5px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-17dp: 0px 8px 11px -5px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 17px 26px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 6px 32px 5px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-18dp: 0px 9px 11px -5px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 18px 28px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 7px 34px 6px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-19dp: 0px 9px 12px -6px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 19px 29px 2px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 7px 36px 6px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-20dp: 0px 10px 13px -6px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 20px 31px 3px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 8px 38px 7px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-21dp: 0px 10px 13px -6px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 21px 33px 3px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 8px 40px 7px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-22dp: 0px 10px 14px -6px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 22px 35px 3px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 8px 42px 7px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-23dp: 0px 11px 14px -7px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 23px 36px 3px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 9px 44px 8px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-24dp: 0px 11px 15px -7px rgba($color-shadow-base, $shadow-key-umbra-opacity), 0px 24px 38px 3px rgba($color-shadow-base, $shadow-key-penumbra-opacity), 0px 9px 46px 8px rgba($color-shadow-base, $shadow-ambient-shadow-opacity) !default;

/**
 * For backwards compatability with old presets
 * https://github.com/angular/material/blob/master/src/components/whiteframe/whiteframe.scss
 */
$whiteframe-shadow-z1: $whiteframe-shadow-1dp;
$whiteframe-shadow-z2: $whiteframe-shadow-4dp;
$whiteframe-shadow-z3: $whiteframe-shadow-7dp;
$whiteframe-shadow-z4: $whiteframe-shadow-10dp;
$whiteframe-shadow-z5: $whiteframe-shadow-13dp;

/**
 * Mixin for defining a custom color or shadow opacity
 */
@mixin whiteframe-shadow($dp: 1, $color-shadow-base: $color-shadow-base, $shadow-key-umbra-opacity: $shadow-key-umbra-opacity, $shadow-key-penumbra-opacity: $shadow-key-penumbra-opacity, $shadow-ambient-shadow-opacity: $shadow-ambient-shadow-opacity) {
    $shadows: nth($whiteframe-shadows, $dp);

    box-shadow: nth($shadows, 1) rgba($color-shadow-base, $shadow-key-umbra-opacity), nth($shadows, 2) rgba($color-shadow-base, $shadow-key-penumbra-opacity), nth($shadows, 3) rgba($color-shadow-base, $shadow-ambient-shadow-opacity);
}
