@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/mixins/avatars';

.pf-status-widget__icon {
    margin-right: 16px;
    padding: 8px;
    border-radius: 50%;
    line-height: 0;
    background-color: $color-white;
}

.pf-status-widget__icon--avatar {
    @include avatar-circle(0, 40px);
}

.pf-status-widget__icon--loading {
    height: unset;
    width: unset;
    padding: 0;
}

.pf-status-widget__icon--pending {
    border: 1px solid $color-fire;

    md-icon {
        color: $color-fire;
    }
}

.pf-status-widget__icon--in-progress {
    border: 1px solid $color-electric;

    md-icon {
        color: $color-electric;
    }
}

.pf-status-widget__icon--submitted {
    border: 1px solid $color-shamrock;

    md-icon {
        color: $color-shamrock;
    }
}

.pf-status-widget__icon--complete {
    border: 1px solid $color-shamrock;

    md-icon {
        color: $color-shamrock;
    }
}

.pf-status-widget__icon--incomplete {
    border: 1px solid $color-crimson;

    md-icon {
        color: $color-crimson;
    }
}

.pf-status-widge__innter-wrapper {
    min-width: 0;
}

.pf-status-widget__label {
    margin-bottom: 4px;
    color: $color-oxford;
    text-transform: uppercase;

    @include typography-caption();
    line-height: 16px;
    font-weight: $font-weight-medium;
}

.pf-status-widget__value {
    width: 100%;
    color: $color-licorice;

    @include typography-subhead();
    font-weight: $font-weight-medium;

    @include max-screen($md-breakpoint-md - 1) {
        max-width: 192px;
    }
}

.pf-status-widget__value--user {
    max-width: 188px;

    @include max-screen($md-breakpoint-md - 1) {
        width: 100%;
        max-width: 100%;
    }
}
