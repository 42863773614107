/**
 * Fix layout issues for small screen breakpoint (PR Submitted to ngMaterial)
 * See: https://github.com/angular/material/issues/5166
 */
@media (min-width: $md-breakpoint-sm) and (max-width: $md-breakpoint-md - 1) {
    [hide]:not([show-gt-xs]):not([show-sm]):not([show]),
    [hide-gt-xs]:not([show-gt-xs]):not([show-sm]):not([show]) {
        display: none;
    }

    [hide-sm]:not([show-gt-xs]):not([show-sm]):not([show]) {
        display: none;
    }
}
