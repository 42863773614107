/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: https://material.io/design/typography/the-type-system.html#type-scale
 *
 *************************************************************************************
 * IMPORTANT *************************************************************************
 *************************************************************************************
 * If you add/remove classes from here, make sure you also update the constants here:
 * core/constants/text-styles.constant.js
 *************************************************************************************
 */

/////////////////////////////////////////////
// Text alignment modifiers
/////////////////////////////////////////////

.pf-text--alignment-left {
    text-align: left;
}

.pf-text--alignment-center {
    text-align: center;
}

.pf-text--alignment-right {
    text-align: right;
}
