@import '~client/main/sass/base/variables';

// Main element
pf-job-apply-resume {
    display: block;
    position: relative;
}

// Successful resume uploaded
.pf-job-apply-resume__success {
    background-color: $color-background-base;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    // Paragraph/link text
    p {
        margin: 0;
        font-size: 15px;
        line-height: 24px;
        font-weight: $font-weight-medium;
        color: $color-oxford;

        & + p {
            margin-top: 4px;
        }
    }
}

// Success icon
.pf-job-apply-resume__success-icon {
    margin: 0 0 4px;
    padding: 6px;
    border: 2px solid $color-barney;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    md-icon {
        color: $color-barney;
    }
}

// Form validation styles
.ng-submitted {
    pf-job-apply-resume.ng-invalid {
        .pf-job-apply-resume__hotspot {
            border-color: $color-error;
        }
    }
}
