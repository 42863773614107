/**
 * @description
 * Entry feed styles. Use the following markup for a fully responsive feed of
 * entry cards:
 *
 * ```
 * <div class="pf-entry-feed">
 *     <div class="pf-entry-feed-row" layout="row" layout-wrap>
 *         <div class="pf-entry-feed-column"
 *              layout="column"
 *              ng-repeat="entry in $ctrl.feed track by entry.id"
 *              flex="100"
 *              flex-gt-xs="33">
 *             <pf-entry-card-standard pf-entry="::entry"></pf-entry-card-standard>
 *         </div>
 *     </div>
 * </div>
 * ```
 *
 **/

.pf-entry-feed {
    margin: 0 -16px;

    @include min-screen($md-breakpoint-sm) {
        margin: 0;
    }
}

.pf-entry-feed-row {
    margin: 0;

    @include min-screen($md-breakpoint-sm) {
        margin: 0 -12px;
    }
}

.pf-entry-feed-column {
    padding-bottom: 24px;

    @include min-screen($md-breakpoint-sm) {
        padding-left: 12px;
        padding-right: 12px;
    }

    & > *:first-child {
        height: 100%;
    }
}
