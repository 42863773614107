/**
 * @description
 * Display a list of tabs that scrolls along the x-axis.
 *
 * Spec: https://material.io/guidelines/components/tabs.html#tabs-specs
 *
 * ```
 * <div class="pf-tabs pf-tabs--scrollable">
 *     <div class="pf-tabs__inner">
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *         <md-button class="pf-tab-btn">Button text</md-button>
 *     </div>
 * </div>
 * ```
 **/
.pf-tabs.pf-tabs--scrollable {
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        // Don't show the scrollbar
        display: none;
    }

    .pf-tabs__inner {
        position: absolute;
        justify-content: initial;
        margin-left: 60px;

        @include max-screen($md-breakpoint-sm - 1) {
            margin-left: 48px;
        }

        @include max-screen($md-breakpoint-md - 1) {
            margin-left: initial;
        }
    }
}
