.pf-form {
    margin: 0 -8px;

    .pf-field {
        margin: 0 8px;
    }

    .pf-form-control {
        min-width: 256px;
        width: auto;
    }

    .md-button {
        margin: 0 8px;
    }

    @include max-screen(959px) {
        .pf-field {
            width: 100%;
            margin: 8px 0;
        }

        .pf-form-control {
            min-width: initial;
            width: 100%;
        }

        .md-button {
            margin: 8px 0;
            width: 100%;
        }
    }
}
