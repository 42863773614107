@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-company-result-card {
    max-width: 100%;
    width: 100%;
}

.pf-company-result-card {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    overflow: hidden;
    // TODO: Add new sass token for transition default
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover,
    &:focus {
        box-shadow: $whiteframe-shadow-z3;
        outline: none;
    }
}

.pf-company-result-card__image {
    width: 100%;
}

.pf-company-result-card__lower-section {
    display: flex;
    flex: 1 1 auto;
}

.pf-company-result-card-lower-section__inner {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    min-width: 0;
    margin: 16px 16px 8px;
    flex: 1;
}

.pf-company-result-card-lower-section-inner__logo {
    flex-direction: column;
    min-width: 0;
    margin-right: 16px;
    @include avatar-square(0, 64px);
}

.pf-company-result-card-lower-section-inner__content-area {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.pf-company-result-card-lower-section-content-area__title {
    max-width: 100%;
    min-width: 0;
    @include typography-title();
    margin-bottom: 4px;
    font-weight: $font-weight-medium;
    color: $color-licorice;
    word-wrap: break-word;
}

.pf-company-result-card-lower-section-content-area__industry {
    max-width: 100%;
    min-width: 0;
    @include typography-subhead();
    color: $color-electric-dark-24;
    font-size: 16px;
    margin-bottom: 4px;
    word-wrap: break-word;
}

.pf-company-result-card-lower-section-content-area__description {
    min-width: 0;
    margin-bottom: 16px;
    @include typography-body();
    color: $color-oxford;
    font-size: 14px;
    word-wrap: break-word;
}

.pf-company-result-card-lower-section-content-area__opener {
    flex: 1;
}

.pf-company-result-card-lower-section-content-area__btn .md-button{
    margin: 0 0 0 -16px;

    &:not(.pf-pending) {
        color: $color-electric;
    }
}
