.pf-section {
    padding: 128px 0;
    overflow: hidden;

    @include max-screen($mobile) {
        padding: 32px 22px;

        a.md-button {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.pf-section--thin {
        padding: 96px 0;

        h2 {
            line-height: 26px;
        }

        p {
            margin-bottom: 0;
        }

        @include max-screen($mobile) {
            padding: 32px 22px;
        }
    }

    .pf-row {
        margin: 0 -32px;

        & > [flex] {
            padding: 0 32px;
        }
    }

    h2 {
        @extend .pf-display-1;
        margin-bottom: 16px;

        @include max-screen($mobile) {
            font-size: 24px;
            line-height: 34px;
        }
    }

    .pf-subhead-2 {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
        color: $color-oxford;

        @include max-screen($mobile) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    h3 {
        @extend .pf-title;
        margin-bottom: 16px;

        @include max-screen($mobile) {
            line-height: 30px;
        }
    }

    p,
    q {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
        color: $color-oxford;
    }

    strong {
        font-weight: $font-weight-medium;
    }

    .pf-centered {
        text-align: center;
    }

    .pf-feature-list {
        li {
            margin-bottom: 64px;
            min-height: 96px;

            h3,
            p {
                padding-left: 128px;
                line-height: 24px;
                font-size: 18px;
            }

            @include max-screen($mobile) {
                margin-bottom: 24px;

                p {
                    font-size: 16px;
                    line-height: 18px;
                }
            }

            h3 {
                font-size: 16px;
                margin-bottom: 8px;
            }

            .pf-feature {
                height: 96px;
                width: 96px;
                display: inline-block;
                float: left;
            }
        }

        &.pf-feature-list-sm {
            li {
                min-height: 64px;
            }

            h3,
            p {
                padding-left: 88px;
            }

            .pf-feature {
                height: 64px;
                width: 64px;
            }
        }
    }

    .pf-tour-content {
        h2 {
            margin-bottom: 32px;
            line-height: 42px;

            @include max-screen($mobile) {
                line-height: 30px;
            }
        }
    }

    .pf-tour-animation-container {
        position: relative;
        height: 572px;

        .pf-tour-animation {
            position: absolute;
            top: 0;
            left: 0;

            img {
                height: 616px;
                max-width: none;
            }
        }
    }
}
