fieldset {
    @include clearfix;
    border-radius: 3px;
    box-shadow: $whiteframe-shadow-z1;
    background: $color-white;
    border: 0;
    margin: 0 0 ($base-line-height / 2) 0;
    padding: $base-line-height;

    &.no-shadow {
        box-shadow: none;
    }

    input[type=submit] {
        margin-left: 0;
    }
}

input,
select {
    display: block;
    font-family: $form-font-family;
    font-size: $form-font-size;
}

[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
input:not([type]),
textarea,
.tokenfield,
select[multiple=multiple] {
    &.fsp-url-source__input{
        margin-bottom: 0;
    }

    transition: border-color;
    box-sizing: border-box;
    background-color: $color-white;
    border-radius: $form-border-radius;
    border: 1px solid $color-form-border;
    box-shadow: $form-box-shadow;
    font-family: $form-font-family;
    font-size: $form-font-size;
    margin-bottom: $base-line-height / 2;
    padding: ($base-line-height / 3) ($base-line-height / 3);
    width: 100%;

    &:focus,
    &.focus {
        border-color: $color-form-border-focus;
        box-shadow: $form-box-shadow-focus;
    }
}

textarea {
    resize: vertical;
}

input[type="search"] {
    box-sizing: border-box;
    -webkit-appearance: textfield;
}

// hide the "x" on search inputs
::-webkit-search-cancel-button {
    display: none;
}

input[type="checkbox"], input[type="radio"] {
    display: inline;
    margin-right: $base-line-height / 4;
}

input[type="file"] {
    margin-bottom: $base-line-height / 2;
    padding-bottom: ($base-line-height / 3);
    width: 100%;
}

select {
    margin-bottom: $base-line-height;
    width: 100%;
}
