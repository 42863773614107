
// Vendors
@import 'sass/vendors/animate';
@import '~sweetalert/dist/sweetalert';
@import 'sass/vendors/tokenfield/bootstrap-tokenfield';
@import 'sass/vendors/tokenfield/tokenfield-typeahead';
@import '~mediaelement/build/mediaelementplayer.css';

// Framework
@import 'sass/base/base';
@import 'sass/templates/templates';
@import '~angular-material/angular-material.css';

// Global
@import 'sass/global';

// Browser Overrides
@import 'sass/browsers/chrome';
@import 'sass/browsers/firefox';
@import 'sass/browsers/ie';
@import 'sass/browsers/safari';

// Utilities
@import 'sass/utilities/utilities';

// Pages
@import 'sass/pages/auth';
@import 'sass/pages/embed';
@import 'sass/pages/entries';
@import 'sass/pages/feed';
@import 'sass/pages/jobs';
@import 'sass/pages/lti';
@import 'sass/pages/marketing';
@import 'sass/pages/messages';
@import 'sass/pages/my-network';
@import 'sass/pages/notifications';
@import 'sass/pages/profile';
@import 'sass/pages/rubric-matrix-score';
@import 'sass/pages/styleguide';
@import 'sass/pages/widget';
@import 'sass/pages/settings';

// Mobile
@import 'sass/mobile';
