md-sidenav.pf-main-nav {
    $width: 288px;

    width: $width;
    max-width: $width;
    z-index: $z-index-modal;

    md-toolbar.md-portfolium-theme:not(.md-menu-toolbar) {
        $height: 60px;

        min-height: $height;
        background-color: $color-white;
        color: $color-licorice;
        border-bottom: 1px solid $color-border-base;
        outline: 0;
        cursor: pointer;

        .md-toolbar-tools {
            height: $height;
            max-height: $height;
        }

        .md-button {
            &.md-icon-button {
                md-icon {
                    color: $color-ash;
                }
            }
        }

        .pf-logo {
            height: 21px;
        }
    }

    md-content {
        display: flex;
        flex-direction: column;

        .md-button.md-portfolium-theme:not(.md-icon-button) {
            display: flex;
            box-sizing: border-box;
            flex-direction: row;
            margin: 0;
            padding: 0 16px 0 72px;
            line-height: 48px;
            text-transform: none;
            font-size: 15px;
            font-weight: $font-weight-medium;
            color: $color-oxford;
            letter-spacing: 0;
            border-radius: 0;
            text-align: left;

            &:first-child {
                margin-top: 8px;
            }

            &.pf-active {
                color: $color-electric;

                md-icon {
                    color: $color-electric;
                }
            }

            md-icon {
                color: $color-oxford;
                position: absolute;
                top: 12px;
                left: 16px;
            }
        }
    }
}
