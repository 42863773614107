pf-profile-about-panel-entry-order {
    md-list-item {
        .md-avatar {
            height: 54px !important;
            width: 96px !important;
            border-radius: 2px !important;
        }

        &.md-3-line > md-icon:first-child {
            margin-top: 32px;
            color: $color-ash;
            cursor: move;
        }
    }

    md-select {
        margin: 24px 0 0;
    }

    .md-button.md-icon-button {
        md-icon {
            transition: none;
        }
    }
}
