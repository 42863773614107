md-toolbar.pf-toolbar-header {
    $height: 60px;
    $color-border: $color-electric-dark-24;

    min-height: $height;
    background-color: $color-electric-dark-24;
    color: $color-ash;
    border-top: 1px solid $color-porcelain;
    box-shadow: $whiteframe-shadow-z1;

    .md-toolbar-tools {
        height: $height;
        max-height: $height;
    }

    .pf-logo {
        height: 21px;
        display: block;
        margin: 0 auto;
    }
}
