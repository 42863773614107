.pf-container {
    margin: 0 auto;
    width: $desktop;

    @import 'form';

    @include max-screen($mobile) {
        width: auto;
    }
}

&.mobile {
    .pf-container {
        // for fixed-width devices like tablets/iPads in landscape mode
        width: 960px;

        @include max-screen($mobile) {
            width: auto;
        }
    }
}
