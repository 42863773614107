.pf-ui-builder__add-new-section {
    display: block;
    position: relative;
    height: 1px;
    width: 100%;
    margin: 20px 0;
    background-color: $color-tiara;

    .md-button.md-portfolium-theme.md-fab {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: -20px;
        margin: 0 0 0 -20px;
        background-color: $color-white;
        border: 1px solid $color-tiara;

        &:not([disabled]) {
            &:hover {
                background-color: $color-porcelain;
            }

            &:focus,
            &.md-focused {
                background-color: $color-tiara;
            }
        }

        md-icon {
            color: $color-oxford;
        }
    }
}
