@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

pf-bottom-nav-tab {
    @include max-screen($md-breakpoint-md - 1) {
        flex: 1;
        flex-grow: 1;
        min-width: 56px;
        max-width: 96px;
        height: 100%;
        transition: min-width 0.2s linear, max-width 0.2s linear;
    }
}

.pf-bottom-nav-tab-footer-active {
    @include max-screen($md-breakpoint-md - 1) {
        min-width: 96px;
        max-width: 168px;
        transition: min-width 0.2s linear, max-width 0.2s linear;
    }
}

// Extreme override to avoid global talent match css (talent-match/../layout.scss line:138)
.pf-bottom-nav-tab.md-button,
.pf-bottom-nav-tab.md-button.md-portfolium-theme,
.pf-app--talent-match
    .pf-bottom-nav-tab.md-button.md-portfolium-theme:not(.md-icon-button) {
    width: 100%;
    min-width: 80px;
    margin: 0;
    padding: 8px 12px 10px;
    border-radius: 3px;
    line-height: 0;
    letter-spacing: 0;
    text-transform: none;
    background-color: transparent;
    text-align: center;

    // Stops hover state from sticking on mobile iOS
    @include max-screen($md-breakpoint-md - 1) {
        &:not([disabled]):not(.md-focused):hover {
            background-color: $color-white-alpha-16;
        }
    }

    @include max-screen($md-breakpoint-md - 1) {
        min-width: 0;
        height: 100%;
    }
}

.pf-bottom-nav-tab {
    &.md-button {
        border: 1px solid transparent;
    }
    // override an inline stylesheet
    &.md-button:hover,
    &.md-button:focus,
    &.md-button.md-portfolium-theme:not([disabled]):hover,
    &.md-button.md-portfolium-theme:not([disabled]):focus {
        background-color: $color-licorice;
    }
    &.md-button:focus,
    &.md-button.md-portfolium-theme:not([disabled]):focus {
        border-color: $color-white;
    }
    &--active.md-button {
        background-color: $color-white;

        &:hover,
        &:focus {
            background-color: $color-white;
            border-color: transparent;
        }
        .pf-bottom-nav-tab__text {
            color: $color-electric;
            transform: scale(1.24);
            transition: transform 0.2s linear, opacity 0.2s ease;

            @include max-screen($md-breakpoint-md - 1) {
                transform: none;
                opacity: 1;
            }
        }

        .pf-bottom-nav-tab__icon.material-icons {
            color: $color-electric;
            transform: translate3d(0, -2.2px, 0);
            transition: transform 0.2s linear, color 0.2s linear;

            @include max-screen($md-breakpoint-md - 1) {
                transform: translate3d(0, -8.2px, 0);
            }
        }

        .pf-bottom-nav-tab__avatar {
            transform: translate3d(0, -2.2px, 0);
            transition: transform 0.2s linear;

            @include max-screen($md-breakpoint-md - 1) {
                transform: translate3d(0, -8.2px, 0);
            }
        }
    }

    &__avatar {
        margin: 0 auto;
        @include avatar-circle(0, 24px);
    }

    &__avatar-label {
        display: inline-block;
        line-height: 12px;
        margin-top: 2px;
        transition: transform 0.2s linear;
    }

    &__text {
        line-height: 12px;
        margin-top: 2px;
        font-size: 12px;
        color: $color-white;
        transition: transform 0.2s linear, opacity 0.2s ease;

        @include max-screen($md-breakpoint-md - 1) {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            width: 100%;
            font-size: 16px;
            opacity: 0;
        }
    }
}

.pf-bottom-nav-tab__icon.md-portfolium-theme.material-icons {
    color: $color-tiara;
    transition: transform 0.2s linear, color 0.2s linear;
}

.pf-bottom-nav-tab__down-arrow.material-icons {
    min-height: 14px;
    min-width: 18px;
    height: 14px;
    width: 18px;
    margin: 0 0 0 -4px;
    font-size: 24px;
    line-height: 12px;
}
