.pf-hero--marketing {
    height: 300px;

    @include max-screen($mobile) {
        height: auto;
    }

    &.pf-hero {
        @include max-screen($mobile) {
            padding-top: 50px;
        }
    }

    .pf-container {
        height: 100%;
    }

    h1 {
        @extend .pf-display-2;
        margin-top: 50px;

        @include max-screen($mobile) {
            margin-top: 0;
            font-size: 24px;
            font-weight: $font-weight-regular;
        }
    }

    h2 {
        @include max-screen($mobile) {
            text-align: center;
        }
    }
}

.pf-sticky-press {
    position: fixed;
    top: 64px;
    box-shadow: $whiteframe-shadow-1dp;
}

.pf-press-nav {
    background-color: $color-white;
    width: 100%;
    text-align: center;
    padding: 6px 0;

    a:focus {
        background-color: $color-tiara;
    }
}

.pf-section--marketing {
    padding: 40px 0;

    @include max-screen($mobile) {
        padding: 32px 22px;
    }

    @import 'marketing/partners';
    @import 'marketing/press';
    @import 'marketing/team';
}

.c_marketing {
    &.a_goodbye {
        .pf-section {
            min-height: 700px;
        }
    }
}

.security {
    h4 {
        padding: 30px 0 20px 0;
        color: $color-oxford;
        font-weight: 600;
    }
}

p.logos {
    img {
        width: 450px;
    }
}

p.icons {
    img {
        height: 100px;
        width: 100px;
    }
}

.pf-section--goodbye {
    padding: 0;
    color: $color-licorice;
    font-size: 16px;

    .pf-goodbye-centered {
        width: 100%;
        max-width: 960px;
        margin: auto;

        @include max-screen($md-breakpoint-sm - 1) {
            padding: 0 5%;
        }

        .pf-goodbye-image-wrapper {
            padding: 40px 0 0;
            text-align: center;

            img {
                max-width: 600px;
            }
        }

        .pf-goodbye-content {
            padding: 40px 0;
            text-align: center;

            h1 {
                font-size: 34px;
                margin: 0 0 24px;
                font-weight: normal;

                @include max-screen($md-breakpoint-sm - 1) {
                    font-size: 24px;
                }
            }

            p {
                margin: 0;
            }

            @include min-screen($md-breakpoint-sm) {

                .pf-goodbye-go-mobile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                p {
                    margin-right: 24px;
                }
            }
        }

        .pf-goodbye-suggestions {
            margin-bottom: 80px;

            @include min-screen($md-breakpoint-sm) {
                display: flex;
            }

            .pf-goodbye-suggestion-item {
                display: flex;
                font-size: 16px;
                padding: 40px 30px;

                a {
                    color: $color-electric;
                    cursor:pointer;
                    display:inline;
                    font-family:proxima-nova, helvetica, arial, sans-serif;
                    font-size:16px;
                    font-weight:600;
                    height:auto;
                    line-height:24px;
                    text-decoration:none;
                    text-size-adjust:100%;
                    transition-delay:0s;
                    transition-duration:0.1s;
                    transition-property:color;
                    transition-timing-function:linear;
                    width:auto;
                    -webkit-box-direction:normal;
                    -webkit-font-smoothing:antialiased;
                    -webkit-tap-highlight-color:transparent;
                }

                p {
                    margin: 4px 0 0;
                }

                .pf-goodbye-suggestion-content {
                    padding-left: 28px;
                }

                .pf-goodbye-suggestion-image {
                    width: 40px;
                    padding-top: 16px;
                }

                @include max-screen($md-breakpoint-sm - 1) {

                    &.middle {
                        border: $color-tiara solid 0px;
                        border-top-width: 1px;
                        border-bottom-width: 1px;
                    }
                }

                @include min-screen($md-breakpoint-sm) {
                    height: 160px;
                    flex: 1;

                    &.middle {
                        border: $color-tiara solid 0px;
                        border-right-width: 1px;
                        border-left-width: 1px;
                    }
                }
            }
        }
    }
}
