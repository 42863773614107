md-menu-item.pf-mobile-menu-list__item {
    a.md-button:not([disabled]),
    .md-button {
        display: flex;
        padding-top: 0;
        height: 48px;
        @include typography-body-2();
        font-size: 15px;
        color: $color-licorice;

        md-icon {
            color: $color-oxford;
            margin-right: 24px;
        }

        &.pf-mobile-menu-list-item__active {
            color: $color-electric;

            md-icon {
                color: $color-electric;
            }
        }
    }
}

.pf-mobile-menu-list__separator {
    margin: 8px 0;
}
