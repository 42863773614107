@mixin size($size: 64px) {
    height: $size;
    width: $size;
}

@mixin min-size($size: 64px) {
    height: $size;
    width: $size;
    min-height: $size;
    min-width: $size;
}
