/**
 * These classes were created in accordance with Google's Material Design
 * Guidelines and should not be modified unless the guidelines are updated.
 *
 * See: https://material.io/design/typography/the-type-system.html#type-scale
 *
 *************************************************************************************
 * IMPORTANT *************************************************************************
 *************************************************************************************
 * If you add/remove classes from here, make sure you also update the constants here:
 * core/constants/text-styles.constant.js
 *************************************************************************************
 */

/////////////////////////////////////////////
// Text weight modifiers
/////////////////////////////////////////////

// 400
.pf-text--weight-regular {
    font-weight: $font-weight-regular;
}

// 600
.pf-text--weight-medium {
    font-weight: $font-weight-medium;
}

// 700
.pf-text--weight-bold {
    font-weight: $font-weight-bold;
}
