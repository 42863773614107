@import '~client/main/sass/base/mixins/background-gradient';

.pf-user-connect-card {
    overflow: hidden;
    width: 100%;
    white-space: normal;

    &.pf-standard-card {
        background-color: $color-white;
        box-shadow: $whiteframe-shadow-1dp;

        @include min-screen($md-breakpoint-sm) {
            border-radius: 2px;
        }

        .pf-user-details {
            margin: 52px 16px 16px;
        }
    }

    .pf-user-details {
        text-align: center;
    }

    .pf-user-cover-image {
        position: relative;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }

        .pf-image-wrap {
            background-color: $color-porcelain;
            @include position(absolute, 0, 0, 0, 0);

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }

    .pf-avatar {
        $size: 80px;
        $borderWidth: 3px;

        position: absolute;
        bottom: -(($size / 2) + $borderWidth);
        left: 50%;
        margin-left: -(($size / 2) + $borderWidth);
        z-index: 1;
        background-color: $color-porcelain;
        border-radius: 50%;
        height: $size + ($borderWidth * 2);
        width: $size + ($borderWidth * 2);
        border: $borderWidth solid $color-white;
        overflow: hidden;

        a {
            display: block;

            &:focus {
                img {
                    opacity: .7;
                }
            }
        }
    }

    .pf-sm-avatar {
        $size: 40px;
        $borderWidth: 3px;
        z-index: 1;
        background-color: $color-porcelain;
        border-radius: 50%;
        height: $size + ($borderWidth * 2);
        width: $size + ($borderWidth * 2);
        border: $borderWidth solid $color-white;
        overflow: hidden;

        img {
            height: $size + ($borderWidth * 2);
        }

        a {
            display: block;

            &:focus {
                img {
                    opacity: .7;
                }
            }
        }
    }

    .pf-detail--name {
        @extend .pf-subhead;
        line-height: 20px;
        font-weight: $font-weight-medium;
        margin: 0 0 8px;
        display: block;
        color: $color-licorice;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .pf-detail--school {
        @extend .pf-subhead;
        display: block;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(a) {
            color: $color-oxford;
        }
    }

    .pf-detail--major {
        @extend .pf-body;
        color: $color-oxford;
        min-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .pf-actions {
        padding: 0 10px 16px;
    }
}

.pf-user-card-cover-standard-photo {
    @include position(absolute, 0, 0, 0, 0);

    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 1;
}

.pf-user-card-cover-standard-photo--default {
    @include background-gradient-primary-secondary();
}

@import './card/list';
