body.c_messages.a_feed,
body.c_messages.a_index {

    .segment {

        .segment-sidebar {
            @include max-screen($mobile) {
                display: none;
            }

            a.ellipsis {
                width: 320px;
            }
        }

        article {
            @include max-screen($mobile) {
                h3 {
                    padding-left: 15px;
                }

                .message-archive {
                    margin-right: 15px;
                    padding: 0;
                    width: 100%;
                    margin: 0 15px 0 0;
                    min-width: 68px;
                }
            }

            form {
                padding: 30px 40px 0 40px;

                textarea {
                    height: 100px;
                }

                @include max-screen($mobile) {
                    padding: 20px 10px 0;

                    .reply-btn {
                        font-size: 12px;
                    }
                }
            }

            .title {
                .from {
                    font-size: 16px;
                    margin-bottom: 6px;
                    line-height: 18px;
                }
                .subject {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                .body {
                    font-size: 14px;
                    margin-bottom: 5px;
                    line-height: 18px;
                }
            }

            .table-list {
                margin: 0;

                & > tbody > tr {
                    &:not(:last-child) {
                        border-bottom: 1px solid $color-porcelain;
                    }
                }

                .table-link {
                    display: block;
                    padding: 10px 30px;

                    @include max-screen($mobile) {
                        padding: 0;
                    }

                    table td {
                        border-bottom: 0;
                    }
                }

                .actions {
                    vertical-align: top;

                     @include max-screen($mobile) {
                         text-align: left
                     }
                }
            }

            @include max-screen($mobile) {
                width: 100%;
                margin: 0 auto;

                h1 {
                    margin-top: 0;
                    font-size: 20px;
                    padding: 10px 15px;
                }

                .padding {
                     padding: 10px;
                }

                .table-list {
                    .threads {
                        &.logo {
                            width: 50px;

                            img {
                                padding: 0;
                                margin: 0 0 10px;
                            }
                        }
                    }

                    td.title {
                        width: auto !important;
                        padding-left: 10px;
                        vertical-align: top;
                    }
                }
            }
        }
    }

    .comment {
        float: left;
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid $color-porcelain;

        .comment-image {
            float: left;
            margin-right: 15px;
        }

        .comment-content {
            h3 {
                margin-top: 0;
                padding-top: 0;
            }

            p {
                margin: 12px 40px 0 67px;
            }
        }

        .comment-detail {
            color: $color-oxford;
            font-size: 14px;
            margin-bottom: 0;
            float: right;
        }

        fieldset {
            p {
                textarea {
                    height: 100px;
                }
            }
        }
    }
}

#add-message {
    h1 {
        @include max-screen($mobile) {
            font-size: 28px;
            margin: 4px 0;
        }
    }
}
