@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

pf-entry-viewer-footer {
    display: block;
    margin: 24px 0 32px;

    @include typography-body();
    color: $color-oxford;
}


.pf-entry-viewer-footer-report-abuse {
    color: inherit;
    text-decoration: underline;
}
