@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/extends/typography';

.pf-dialog--resume-upload {
    $width: 400px;

    min-width: $width;
    max-width: $width;
}

.pf-resume-upload__column {
    position: relative;
}

.pf-resume-upload-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-porcelain;
}

.pf-resume-upload__image--centered {
    margin: 16px auto 24px;
    // fixed size so image doesn't shift after loading
    width: 232px;
    height: 178px;
}

.pf-resume-upload-complete-message {
    margin-top: 216px;
}

.pf-resume-upload-modal__footer {
    color: $color-oxford;
    text-align: center;
}
