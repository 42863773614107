/**
 * @description
 * Include default styling for a action cards
 *
 * @param {Size in Units} Padding
 */
@mixin action-card($padding: '24px', $padding-mobile: $padding) {
    position: relative;
    display: block;
    padding: $padding;
    border-radius: 4px;
    background-color: $color-white;
    border: 1px solid $color-border-base;
    outline: none;
    transition: 0.15s;
    cursor: pointer;

    @include max-screen($md-breakpoint-md - 1) {
        padding: $padding-mobile;
        box-shadow: $whiteframe-shadow-z1;
        border: 0;
    }

    &:hover {
        @include min-screen($md-breakpoint-md) {
            background-color: $color-porcelain;
        }
    }

    &:focus {
        @include min-screen($md-breakpoint-md) {
            background-color: $color-porcelain;
        }
    }

    &:focus,
    &:hover {
        .pf-course-assignment__title {
            text-decoration: underline;
        }
         @include max-screen($md-breakpoint-md - 1) {
            box-shadow: $whiteframe-shadow-z5;
        }
    }
}
