textarea.pf-form-control {
    height: auto;
    border-width: 2px;
    padding: 10px;
    margin-bottom: 0;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: 24px;
    color: $color-font;
    border-color: $color-form-border;
    border-width: 2px;
    border-style: solid;
    border-radius: $base-border-radius;
    outline: 0;
    box-shadow: none;
    background-color: $color-white;
    margin-bottom: 0;

    &:not([disabled]) {
        &:focus {
            border-color: $color-form-border-focus;
            box-shadow: none;
        }
    }

    &[disabled] {
        background-color: $color-porcelain;
        border-color: $color-form-border;
        cursor: not-allowed;

        &::placeholder {
            color: $color-form-placeholder-disabled;
        }
    }

    &::placeholder {
        color: $color-form-placeholder;
    }
}

textarea.pf-form-control--invalid {
    &:not([disabled]) {
        border-color: $color-error;

        &:hover,
        &:focus {
            border-color: $color-error;
        }
    }
}

md-input-container.md-portfolium-theme {
    padding: 0;
    margin: 0;

    .md-input {
        color: $color-font;
        border-color: $color-form-border;

        &::placeholder {
            color: $color-form-placeholder;

            &[disabled] {
                color: $color-form-placeholder-disabled;
            }
        }

        &.ng-invalid {
            border-width: 2px;
            padding: 10px;
        }

        &[disabled] {
            color: $color-form-placeholder;
            border-bottom-color: $color-form-border;
            background-image: none;
            margin-bottom: 0;

            &::placeholder {
                color: $color-form-placeholder-disabled;
            }
        }

        & + .md-errors-spacer {
            display: none;
        }
    }

    .md-errors-spacer {
        display: none;
    }

    &.md-input-focused {
        .md-input {
            border-width: 2px;
            padding: 10px;
            border-color: $color-form-border-focus;

            &::placeholder {
                color: $color-form-placeholder;
            }

            &[disabled] {
                &::placeholder {
                    color: $color-form-placeholder-disabled;
                }
            }
        }
    }

    &.md-input-invalid {
        &:not(.md-input-focused) {
            .md-input {
                border-color: $color-form-border;
            }
        }
    }
}

// Style error state for submitted forms
.ng-submitted {
    md-input-container.md-portfolium-theme {
        &.md-input-invalid {
            .md-input {
                border-color: $color-error;
            }
        }
    }

    textarea.pf-form-control--outline {
        &.ng-invalid {
            border-color: $color-error;
        }
    }
}
