@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';

md-list.pf-job-list__list {
    background-color: $color-white;
    overflow: hidden;
    width: 100%;
    white-space: normal;
    padding: 0;
}

md-list-item.pf-job-list-list-item {
    div.md-button.md-no-style:first-child {
        padding: 16px;

        // Tablet
        @include mq($min-width: $md-breakpoint-sm, $max-width: $md-breakpoint-md - 1) {
            padding: 24px;
        }

        // Desktop
        @include min-screen($md-breakpoint-md) {
            padding: 24px 16px 24px;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid $color-form-border;
    }
}
