/**
 * Blank field flicker animation for loading content asynchronously in views
 * that are immediately transitioned to.
 */

 .pf-blank-list-no-results {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

     position: absolute;
     top: 0;
     right: 0;
     left: 0;
     bottom: 0;
     margin: auto;
     z-index: 1;
     text-align: center;

     &:before {
         content: '';
         opacity: 0.7;
         @include position(absolute, 0, 0, 0, 0);
         background-color: $color-white;

     }
 }

 .pf-blank-list-no-results__header {
     color: $color-licorice;
     z-index: 2;
     margin-bottom: 8px;
     @include typography-title();
 }

 .pf-blank-list-no-results__body {
     color: $color-oxford;
     z-index: 2;
     margin-bottom: 13px;
     @include typography-subhead-2();
 }

.pf-blank-field-container {
    height: 100%;
    width: 100%;
}

.pf-blank-avatar {
    & > span {
        @include avatar-square(0, 96px);
        display: inline-block;
        margin-right: 16px;
        background-color: $color-porcelain;
        animation: flickerAnimation 2s ease infinite;

        // tablet
        @include mq($min-screen: $md-breakpoint-sm, $max-width: $md-breakpoint-md - 1) {
            margin-right: 24px;
        }
    }
}

.pf-blank-avatar--static {
    & > span {
        animation: none;
    }
}

.pf-blank-avatar--solid {
    & > span {
        @include avatar-square(0, 96px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--medium {
    & > span {
        @include avatar-square(3px, 64px);
    }
}

.pf-blank-avatar--medium-solid {
    & > span {
        @include avatar-square(0, 64px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--small-solid-circle {
    & > span {
        @include avatar-circle(0, 64px, $color-porcelain);
    }
}

.pf-blank-avatar--small {
    & > span {
        @include avatar-square(3px, 48px);
    }
}

.pf-blank-avatar--small-solid {
    & > span {
        @include avatar-square(0, 48px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--small-solid-round {
    & > span {
        @include avatar-circle(0, 48px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--very-small {
    & > span {
        @include avatar-square(3px, 40px);
    }
}

.pf-blank-avatar--very-small-solid {
    & > span {
        @include avatar-square(0, 40px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--very-small-solid-round {
    & > span {
        @include avatar-circle(0, 40px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--tiny {
    & > span {
        @include avatar-square(3px, 24px);
    }
}

.pf-blank-avatar--tiny-solid {
    & > span {
        @include avatar-square(0, 24px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-avatar--tiny-solid-round {
    & > span {
        @include avatar-circle(0, 24px, $color-porcelain);
        background-color: transparent;
    }
}

.pf-blank-pill {
    & > span {
        display: inline-block;
        margin: 4px;
        height: 32px;
        width: 64px;
        border-radius: 50px;
        background-color: $color-porcelain;
    }
}

.pf-blank-pill--light {
    opacity: .8;
}

.pf-blank-pill--lighter {
    opacity: .6;
}

.pf-blank-pill--animated {
    animation: flickerAnimation 2s ease infinite;
}

.pf-blank-hero {
    width: 100%;
    padding-top: 25%;
    background-color: $color-porcelain;
    animation: flickerAnimation 2s ease infinite;
}

.pf-blank-list {
    position: relative;
    background-color: $color-white;
    overflow: hidden;
    width: 100%;
    white-space: normal;
    padding: 0;
}

.pf-blank-list__item {
    position: relative;
    padding: 16px;

    &:first-child {
        opacity: 1;
    }
    &:nth-child(2) {
        opacity: .8;
    }
    &:nth-child(3) {
        opacity: .6;
    }

    // Tablet
    @include mq($min-screen: $md-breakpoint-sm, $max-width: $md-breakpoint-md - 1) {
        padding: 24px;
    }

    // Desktop
    @include min-screen($md-breakpoint-md) {
        padding: 24px 16px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $color-border-base;
    }
}

.pf-blank-textarea {
    &:first-child {
        & > span {
            opacity: 1;
        }
    }

    &:nth-child(2) {
        & > span {
            opacity: .8;
        }
    }

    &:nth-child(3) {
        & > span {
            opacity: .6;
        }
    }

    & > span {
        display: inline-block;
        height: 96px;
        margin: 0;
        border: 2px solid $color-porcelain;
        width: 100%;
        max-width: 100%;
        border-radius: 4px;
        vertical-align: middle;
        animation: flickerAnimation 2s ease infinite;
    }
}

.pf-blank-field {
    &:first-child {
        & > span {
            opacity: 1;
        }
    }

    &:nth-child(2) {
        & > span {
            opacity: .8;
        }
    }

    &:nth-child(3) {
        & > span {
            opacity: .6;
        }
    }

    & > span {
        display: inline-block;
        height: 16px;
        margin: 2px 0;
        background-color: $color-porcelain;
        width: 128px;
        max-width: 100%;
        border-radius: 3px;
        vertical-align: middle;
        animation: flickerAnimation 2s ease infinite;
    }
}

.pf-blank-field--skinny {
    & > span {
        height: 10px;
    }
}

.pf-blank-field--tiny {
    & > span {
        width: 96px;
        margin: 0;
        vertical-align: top;
    }
}

.pf-blank-field--short {
    & > span {
        width: 192px;
    }
}

.pf-blank-field--medium {
    & > span {
        width: 256px;
    }
}

.pf-blank-field--long {
    & > span {
        width: 320px;
    }
}

.pf-blank-field--max {
    & > span {
        width: 100%;
    }
}

.pf-blank-field--thin {
    height: 12px;

    & > span {
        height: 12px;
        vertical-align: top;
    }
}

// Modifier for when loading is finished and there are no results
.pf-blank-list--no-results {
    .pf-blank-avatar,
    .pf-blank-field {
        & > span {
            animation: none;
        }
    }

    .pf-blank-list__item {
        opacity: .5;
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: .40;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: .40;
    }
}
