.bottom {
    @include clearfix;

    color: $color-ash;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 0;
    border-top: 1px solid $color-porcelain;

    .bottom-links {
        margin: 0 -8px;

        & > li {
            display: block;
            float: left;

            & > a {
                @extend .pf-body;
                color: $color-ash;
                padding: 0 8px;
                line-height: 20px;

                &:hover {
                    color: $color-licorice;
                }
            }
        }
    }
}

.bottom-gray {
    @include clearfix;

    background-color: $color-porcelain;
    border-top: 1px solid $color-border-base;
    color: $color-oxford;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;

    a {
        background-color: $color-porcelain;
        color: $color-oxford;
        font-weight: 600;
        padding-right: 20px;
        float: left;

         &:hover {
             color: $color-oxford;
        }
     }
 }

footer.bottom {
    padding: 10px 100px 10px 60px;
}
