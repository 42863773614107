@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';

/**
 * Salesforce
 */
.embeddedServiceHelpButton {
    .helpButton {
        .uiButton {
            background-color: $color-electric !important;
            font-family: $base-font-family !important;

            &:focus {
                outline: 1px solid $color-electric !important;
            }
        }
    }

    @include max-screen($md-breakpoint-md - 1) {
        display: none !important;
    }
}
