/**
 * This is the style from which all 'pf-form-control' class elements extend
 */

$color-font: $color-licorice;
$font-family: $raleway;
$font-size: 16px;
$font-weight: $font-weight-regular;

input.pf-form-control,
input[type="url"].pf-form-control {
    height: 40px;
    padding: 10px;
    margin: 0;
    border-color: $color-form-border;
    border-width: 2px;
    border-style: solid;
    box-shadow: none;
    font-size: $font-size;
    line-height: 1.4;
    font-weight: $font-weight;
    color: $color-licorice;
    border-radius: $base-border-radius;
    outline: 0;
    -webkit-appearance: none;

    &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
        }
    }

    &:not([disabled]) {
        &:focus {
            border-color: $color-form-border-focus;
            box-shadow: none;

            & + .pf-input-group-addon {
                border-color: $color-form-border-focus;
            }
        }
    }

    &[disabled] {
        border-color: $color-form-border;
        opacity: .54;
    }

    &.pf-form-control-lg {
        height: 48px;
        padding: 10px 14px;
        font-size: 16px;
    }
}

input.pf-form-control--material,
input[type="url"].pf-form-control--material {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    border-color: $color-form-border;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-radius: 0;
    background-color: transparent;
    @include typography-subhead();
    color: $color-licorice;
}

input.pf-form-control--invalid,
input[type="url"].pf-form-control--invalid {
    &:not([disabled]) {
        border-color: $color-error;

        &:hover,
        &:focus {
            border-color: $color-error;
        }
    }
}

// Style error state for submitted forms
.ng-submitted {
    .pf-form-control:not([disabled]) {
        &.ng-invalid {
            &, &:hover {
                border-color: $color-error;

                & + .pf-input-group-addon {
                    border-color: $color-error;
                }
            }
        }
    }
}

@import './form-control/autocomplete';
@import './form-control/checkbox';
@import './form-control/chips';
@import './form-control/chips-temp';
@import './form-control/radio-group';
@import './form-control/search-input';
@import './form-control/select';
@import './form-control/switch';
@import './form-control/textarea';
@import './form-control/tokenfield';
@import './form-control/twitter-typeahead';
