/**
 * @description
 * Display a list of inline labels
 *
 * ```
 * <span class="pf-inline-labels">
 *     <span class="pf-inline-label" ng-repeat="label in labels">
 *         {{::label.displayName}}
 *     </span>
 * </span>
 * ```
 **/
.pf-inline-labels {
    height: 28px;
    min-height: 28px;
    display: inline-block;

    .pf-inline-label {
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.pf-inline-label {
    display: inline-block;
    padding: 0 8px;
    margin: 0 4px;
    line-height: 28px;
    min-height: 28px;
    font-size: 13px;
    letter-spacing: .1px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $color-white;
    border-radius: 2px;
    background-color: $color-oxford;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pf-inline-label.pf-inline-label--primary {
    background-color: $color-electric;
    color: $color-white;
}
