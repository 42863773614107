$tab-height: 72px;

/**
 * @description
 * Display a list of tabs with icons on top and labels below.
 *
 * Spec: https://material.io/guidelines/components/tabs.html#tabs-specs
 *
 * ```
 * <div class="pf-tabs pf-tabs--icon-text">
 *     <div class="pf-tabs__inner">
 *         <md-button class="pf-tab-btn">
 *             <md-icon class="pf-tab-icon material-icons">edit</md-icon>
 *             <span class="pf-tab-label">Edit</span>
 *         </md-button>
 *         <md-button class="pf-tab-btn">
 *             <md-icon class="pf-tab-icon material-icons">edit</md-icon>
 *             <span class="pf-tab-label">Edit</span>
 *         </md-button>
 *     </div>
 * </div>
 * ```
 **/
.pf-tabs.pf-tabs--icon-text {
    height: $tab-height;

    .pf-tab-btn.md-button.md-portfolium-theme {
        position: relative;
        min-height: $tab-height;
        line-height: $tab-height;
    }

    .pf-tab-icon {
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -12px;
    }

    .pf-tab-label {
        position: absolute;
        bottom: 16px;
        left: 0;
        width: 100%;
        text-align: center;
        display: block;
        line-height: 12px;
        height: 12px;
    }
}

.pf-tabs.pf-tabs--icon-text {
    .pf-tab-btn--active {
        md-icon.pf-tab-icon {
            bottom: 42px;
        }

        .pf-tab-label {
            font-size: 14px;
        }
    }
}
