@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';
@import '~client/main/sass/base/mixins/avatars';
@import '~client/main/sass/base/mixins/position';

pf-job-result {
    display: block;
    max-width: 100%;
}

.pf-job-result__image {
    @include avatar-square(0, 64px);
    margin-right: 16px;
    pointer-events: none;

    // tablet
    @include mq($min-width: $md-breakpoint-sm, $max-width: $md-breakpoint-md - 1) {
        margin-right: 24px;
    }
}

.pf-job-result__middle {
    min-width: 0;
}

.pf-jobs-search-filters__header {
    padding: 10px 16px;
}

.pf-job-result__title {
    @include typography-subhead();
    margin-bottom: 0;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $color-licorice;

    @include max-screen($md-breakpoint-sm - 1) {
        font-size: 16px;
    }
}

.pf-job-result__company {
    @include typography-body();
    color: $color-electric-dark-24;
    font-size: 14px;
    margin-bottom: 4px;

    // desktop
    @include min-screen($md-breakpoint-md) {
        @include typography-subhead();
        color: $color-licorice;
        margin: 0;
    }
}

.pf-job-result__location-date {
    @include typography-body();
    margin-bottom: 8px;
    color: $color-oxford;
    font-size: 14px;

    // desktop
    @include min-screen($md-breakpoint-md) {
        color: $color-licorice;
    }
}

.pf-job-result__description {
    max-width: 100%;
    max-height: 40px;
    overflow: hidden;
    white-space: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;

    @include max-screen($md-breakpoint-sm - 1) {
        display: none;
    }
}

.pf-blank-job-list {
    &:after {
        width: 100%;
        content: '';
        height: 150px;
        @include position(absolute, none, 0, 0, 0);
        background-image: linear-gradient(0deg, $color-white 0%, $color-white-alpha-48 40%, transparent 100%);
    }
}
