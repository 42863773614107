.pf-app-frame {
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-width: 832px;

    .pf-header {
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        width: 100%;

        .dot {
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            margin: 0 4px 0 0;
            background: $color-white-alpha-24;
        }
    }

    &.pf-primary {
        .pf-header {
            background-color: $color-electric-dark-24;
        }
    }

    &.pf-accent {
        .pf-header {
            background-color: $color-oxford;
        }
    }
}
