/**
 * @description
 * Styles for data selection UI for importing external data
 */
.pf-data-import-selection-dialog {
    width: 100%;
    height: 800px;
    max-width: 960px;
    max-height: 90%;
}

.pf-data-import-selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
}

.pf-data-import-selection-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 64px;
    height: 64px;
    padding: 0 24px;
    border-bottom: 1px solid $color-border-base;
}

.pf-data-import-selection-header__heading {
    @include typography-title();
    color: $color-licorice;
}

.pf-data-import-selection-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 52px;
    height: 52px;
    padding: 0 8px;
    border-top: 1px solid $color-border-base;

    .md-button {
        padding: 0 8px;
        margin: 0 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.pf-data-import-selection-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: auto;
    background-color: $color-white;
    transition: background-color .8s ease;

    &.pf-content__loading {
        align-items: center;
        justify-content: center;
        background-color: $color-porcelain;

        .pf-data-import-selection-content__loading {
            opacity: 1;
        }
    }
}

.pf-data-import-selection-content__loading {
    text-align: center;
    opacity: 0;
    transition: .8s ease opacity;

    .pf-no-results__headline {
        font-weight: $font-weight-regular;
        margin: 24px 0 56px;
        font-size: 16px;
        color: $color-oxford;
    }
}

.pf-data-import-selection-content__spinner {
    margin: 16px auto 0;
}

.pf-data-import-selection-content__empty {
    padding: 64px 0;
}
