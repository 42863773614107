&.pf-section--partner-list {
    span {
        padding: 10px 0;

        @include max-screen($mobile) {
            padding: 2px 0;
            display: block;
        }
    }

    a {
        color: $color-oxford;

        &:hover {
            color: $color-electric;
        }
    }
}

h3 {
    color: $color-oxford;

    @include max-screen($mobile) {
        margin: 0;
    }
}
