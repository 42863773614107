@import '~client/main/sass/base/mixins/media_sizes';
@import '~client/main/sass/base/variables';
@import '~client/main/sass/base/mixins/typography';

.pf-assignment-submit-btn.md-button,
.pf-assignment-unsubmit-btn.md-button {
    margin: 6px 0 10px;
}

.pf-assignment-unsubmit-btn {
    color: $color-licorice;
}

.pf-assignment-submit-btn--outline.md-button {
    margin: 6px 0 10px;
    color: $color-licorice;
}

.pf-assignment-panel {
    padding: 24px;
    background: $color-porcelain;
    border-radius: 3px;
    box-shadow: $whiteframe-shadow-3dp;
    max-width: 400px;

    md-toolbar.md-portfolium-theme:not(.md-menu-toolbar) {
        background-color: $color-porcelain;
        color: $color-licorice;
    }

    @include max-screen($md-breakpoint-sm - 1) {
        width: 90%;
        border-radius: 0;
    }
}

.pf-assignment-panel__header {
    margin-bottom: 16px;
    color: $color-licorice;
    @include typography-title();
}

.pf-assignment-panel__content {
    width: 100%;

    p {
        margin: 0 0 16px;
    }
}

.pf-assignment-panel__lms-name {
    text-transform: capitalize;
}

.pf-assignment-panel__button-wrapper {
    margin: 0 -24px -24px;
    max-width: none;
}
