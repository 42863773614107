@import '~client/main/sass/base/variables';

pf-entry-card-preview {
    display: block;
    height: 100%;
}

.pf-entry-card-preview {
    height: 100%;

    .pf-entry-card-default-image {
        cursor: initial;
    }
}

.pf-entry-card-preview__title {
    color: $color-licorice;
}

.pf-entry-card-preview__description {
    flex: 1 1 auto;
    color: $color-licorice;
}

.pf-entry-card-preview__category {
    color: $color-oxford;
    margin-top: 60px;
}

