pf-profile-about-panel-entry {
    display: block;

    &:first-child {
        border-top: 1px solid $color-border-base;
    }

    md-list-item {
        border-bottom: 1px solid $color-border-base;

        .md-list-item-inner .md-avatar {
            height: 54px;
            width: 96px;
            border-radius: 2px;
        }

        &.pf-selected {
            background-color: $color-porcelain;
        }
    }

    .md-button.md-icon-button {
        md-icon {
            transition: none;
        }
    }
}
