.pf-notification-center-panel--sidenav {
    background-color: $color-white;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    box-shadow: $whiteframe-shadow-4dp;
    width: $notification-panel-width;
    max-width: 100%;

    @include min-screen($md-breakpoint-sm) {
        min-width: $notification-panel-width;
        max-width: $notification-panel-width;
    }

    &--white {
        md-toolbar.md-portfolium-theme:not(.md-menu-toolbar) {
            background-color: $color-white;
            color: $color-licorice;
        }
    }

    .pf-count {
        background-color: $color-crimson;
        border-radius: 2px;
        color: $color-white;
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        height: 16px;
        line-height: 16px;
        margin-left: 10px;
        min-width: 16px;
        padding: 0 5px;
        position: relative;
        text-align: center;
        transition: .2s ease opacity;
    }
}
