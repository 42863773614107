@import '~client/main/sass/base/mixins/typography.scss';

md-checkbox.md-portfolium-theme.pf-form-control {
    color: $color-licorice;
    @include typography-subhead();

    .md-container {
        top: 0;
        left: 0;
        margin-top: 2px;
        transform: none;
    }

    &:not([disabled]).md-primary.md-checked.md-icon:after {
        border-color: $color-white;
    }
}

.pf-checkbox-group {
    md-checkbox.pf-form-control {
        margin: 0;
        padding: 12px 0;
        min-width: 100%;

        .md-container {
            top: 15px;
            margin-top: 0;
        }

        .md-label {
            margin-left: 54px;
        }
    }
}
