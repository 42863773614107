body.c_lti.a_lander,
body.c_auth.a_lti {
    background-color: $color-white;

    .pf-lti-lander {
        position: relative;
    }

    .pf-lti-lander-banner {
        position: absolute;
        top: 16px;
        width: 908px;
        padding: 12px 0;
        border-radius: 4px;
        background-color: $color-porcelain;
        box-shadow: 0 3px 5px -1px $color-porcelain, 0 5px 8px 0 $color-porcelain, 0 1px 14px 0 $color-porcelain;
        z-index: 1;
    }

    .pf-lti-lander-banner__inner-container {
        position: relative;
    }

    .pf-lti-lander-banner__close-button {
        position: absolute;
        right: 8px;
        padding: 8px;
    }

    md-content {
        &.md-portfolium-theme {
            background-color: $color-white;
        }
    }

    .pf-lti-lander-left-column {
        min-width: 349px;
        width: 349px;
        padding: 40px 20px 40px 40px;
    }

    .pf-lti-lander-right-column {
        min-width: 679px;
        width: 679px;
        padding: 40px 40px 40px 20px;
    }

    .pf-lti-lander-left-column__inner,
    .pf-lti-lander-right-column__inner {
        width: 100%;
    }

    .pf-lti-lander__user {
        margin-bottom: 16px;
    }

    .pf-lti-lander__avatar {
        @include avatar-circle(0, 160px);

        margin-bottom: 20px;
    }

    .pf-lti-lander__tagline {
        margin-top: 4px;
    }

    .pf-lti-lander__stats {
        margin-bottom: 40px;

        a {
            width: 100%;
            padding: 12px 0;
            box-shadow: inset 0 -1px 0 0 $color-porcelain;

            // IE11 fix
            min-height: 48px;
            height: 48px;
        }
    }

    .pf-lti-lander-card {
        height: 483px;
        width: 619px;
        padding: 64px 64px 72px;
        box-shadow: $whiteframe-shadow-2dp;
        border-radius: 6px;

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .pf-lti-lander-card__inner {
        margin-bottom: 40px;
    }

    .pf-lti-lander-card__icon {
        display: inline-block;
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 50%;
        background-color: $color-porcelain;

        md-icon {
            color: $color-electric;
        }
    }

    .pf-lti-lander-card__header {
        margin-bottom: 4px;
    }

    .pf-lti-lander-card__subheader {
        margin-bottom: 24px;
    }

    .pf-lti-lander-card__list-item {
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        md-icon {
            margin: 0 16px 0 0;
            color: $color-success;
        }
    }

    /*
     * NOTE:
     * TODO:
     * These button styles need to be redone with the global design system update
     * THIS IS VERY IMPORANT
     */
    .pf-lti-lander-button {
        letter-spacing: 0.15px;
        line-height: 40px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        min-height: 40px;
        min-width: 88px;
        vertical-align: middle;
        align-items: center;
        text-align: center;
        border-radius: 3px;
        box-sizing: border-box;
        border: 0;
        padding: 0 16px;
        margin: 6px 8px;
        background: transparent;
        color: $color-white;
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        font-style: inherit;
        font-variant: inherit;
        font-family: inherit;
        text-decoration: none;
        overflow: hidden;
        transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

        &:first-child {
            margin-left: 0px;
        }

        md-icon {
            margin: -2px 8px 0 -8px;
            transition: 0.2s ease color;
            vertical-align: middle;
        }
    }

    .pf-lti-lander-button--cta {
        color: $color-white;
        background-color: $color-electric;
        box-shadow: 0 2px 5px 0 $color-tiara;

        &:hover {
            background-color: $color-electric;
            box-shadow: 0px 4px 5px -2px $color-porcelain, 0px 7px 10px 1px $color-porcelain, 0px 2px 16px 1px $color-porcelain
        }

        md-icon {
            color: $color-white;
        }
    }

    .pf-lti-lander-button--secondary-cta {
        height: 38px;
        line-height: 38px;
        color: $color-electric;
        border: 1px solid $color-border-base;

        &:hover {
            background-color: $color-porcelain;
        }

        md-icon {
            color: $color-electric;
        }
    }

    .pf-notification-center-jewel {
        position: relative;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        margin-left: 4px;
        border-radius: 50%;
        line-height: 0;
        text-align: center;
        background-color: $color-crimson;
    }

}
