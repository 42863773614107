.pf-contest-card {
    background-color: $color-white;
    box-shadow: $whiteframe-shadow-z1;
    overflow: hidden;
    transition: .3s ease box-shadow;
    border-radius: 3px;
    width: 100%;
    white-space: normal;
    // magic fix for child element border radius overflow
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    .pf-default-image {
        background-color: $color-porcelain;
        position: relative;

        // Maintain 16x9 aspect ratio while image is loading
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }

        .pf-image-wrap {
            overflow: hidden;
            @include position(absolute, 0, 0, 0, 0);

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        .pf-image-text {
            overflow: hidden;
            @include position(absolute, 0, 0, 0, 0);
            padding: 16px;
            color: $color-white;
            @extend .pf-subhead;
            font-weight: $font-weight-medium;
            z-index: 2;
            text-transform: uppercase;
            line-height: 20px;
            text-align: center;
        }

        &:after {
            content: '';
            @include position(absolute, 0, 0, 0, 0);
            z-index: 1;
            background-color: $color-tiara;
            transition: .3s ease opacity;
        }
    }

    .pf-field--title {
        @extend .pf-body;
        display: block;
        color: $color-licorice;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &:hover,
        &:focus {
            color: $color-electric;
        }
    }
}

@import './card/feed';
