.pf-form-container-wrapper {
    min-width: 0;
}

.pf-auth-column--content {
    position: relative;
    background-color: $color-background-base;

    @include min-screen($md-breakpoint-sm) {
        background-color: $color-white;
    }

    .pf-auth__sign-in-form {
        height: 100%;
    }

    .pf-actions {
        .md-button {
            margin-top: 0;
            margin-bottom: 0;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .md-button.md-icon-button {
            margin: 0 2px;

            md-icon {
                color: $color-ash;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .pf-icon-label {
            padding-right: 10px;
        }
    }
}

.pf-form-container__skip {
    text-align: center;
}

.pf-form-container-btn.md-button {
    margin: 0 0 24px;
    text-transform: capitalize;
}

.pf-employer-check {
    position: relative;
    margin-bottom: 16px;
    min-height: 0;

    &:after {
        display: block;
        content: '';
        border-bottom: 1px solid $color-border-base;
        position: absolute;
        left: 32px;
        right: 32px;
        top: 50%;
        z-index: 1;
    }

    .employer-copy {
        z-index: 2;
        background-color: $color-porcelain;
        color: $color-licorice;
        padding: 0 16px;
        font-size: 14px;

        @include min-screen($md-breakpoint-sm) {
            background-color: $color-white;
        }
    }
}

.pf-form-employer-check {
    margin-bottom: 24px;
}
